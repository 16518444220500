import {
    EMAIL_ENTERED,
    LOAD_USER_DETAILS,
    LOGIN_RESPONSE,
    SELECTED_COMPANY_DETAILS,
    CLEAR_SELECTED_VENDOR,
    RESTORE_VENDOR_NUMBER,
    CLEAR_VENDOR_INDEX,
    STORE_VENDOR_INDEX,
    CHANGE_CIP_RUN_FLAG,
    STORE_SELECTED_COUNTRY,
    DEFAULT,
    SELECTED_MARKET,
    SELECTED_REGION,
    CHANGE_LANGUAGE,
    REGIONS_SET,
    QUERY_ENTITY_SET,
    SUPPORT_RESPONSE,
    SET_INVOICELIST_DATA,
    SET_CARDVIEW
} from '../constants/actionTypes'

const initialUserState = {
    entered_email: "",
    company_codes: "",
    all_company_codes: "",
    vendor_number: "",
    vendor_name: "",
    supplier_address: "",
    vat_number: "",
    role: "",
    response_arr: "",
    validated_email: "",
    selected_company_code: "",
    selected_pmi_entity: "",
    cip_value: "",
    username: "",
    bkp_vendor_number: "",
    accessToken: "",
    isLoggedIn: false,
    isVendorSelected: false,
    appClass: "",
    page_selected: false,
    selected_vendor_index: "",
    isVendorNull: true,
    cip_scans_run: false,
    country_arr: "",
    selected_region: "",
    selected_market_list: "",
    selected_entity_list: "",
    language:"ENGLISH",
    pdf_data:"",
    company_val:"",
    query_entity_data:"",
    invoice_list_data: "",
    download_data: "",
    allData: "",
    filter_dropdown: "",
    tableCount: "",
    cardData:"",
    bannerImgSrc:"",
    bannerExist:false,
   // supplierIndustryKey:""
};

export default function reducer(state = initialUserState, action) {
    switch (action.type) {
        case EMAIL_ENTERED:
            return {...state, entered_email: action.payload.email }
        case LOAD_USER_DETAILS:
            return {
                ...state,
                company_codes: action.payload.company_codes,
                all_company_codes: action.payload.all_company_codes,
                vendor_number: action.payload.vendor_number,
                vendor_name: action.payload.vendor_name,
                supplier_address: action.payload.supplier_address,
                vat_number: action.payload.vat_number,
                role: action.payload.role,
                bkp_vendor_number: action.payload.bkp_vendor_number,
                isVendorSelected: true,
                selected_vendor_index: action.payload.selected_vendor_index,
                selected_region: "",
                selected_market_list: "",
                company_code_ERP:action.payload.company_code_ERP
               // supplierIndustryKey:action.payload.supplierIndustryKey
            }
        case LOGIN_RESPONSE:
            return {
                ...state,
                response_arr: action.payload.response_arr,
                validated_email: action.payload.validated_email,
                role: action.payload.role,
                username: action.payload.username,
                accessToken: action.payload.accessToken,
                isLoggedIn: true
            }
            case SUPPORT_RESPONSE:
                return {
                    ...state,
                    response_arr: action.payload.response_arr
                }
        case SELECTED_COMPANY_DETAILS:
            return {
                ...state,
                selected_company_code: action.payload.selected_company_code,
                selected_pmi_entity: action.payload.selected_pmi_entity,
                cip_value: action.payload.cip_value,
                invoice_type: action.payload.invoice_type,
                invoice_mailbox: action.payload.invoice_mailbox
            }
        case CLEAR_SELECTED_VENDOR:
            return {
                ...state,
                vendor_number: "",
                appClass: action.payload.appClass,
                page_selected: action.payload.page_stat,
                invoice_list_data : "",
                download_data : "",
                allData : "",
                filter_dropdown : "",
                tableCount : "",
                query_entity_data:"",
                pdf_data: "",
                company_val:""

            }
        case RESTORE_VENDOR_NUMBER:
            return {
                ...state,
                vendor_number: state.bkp_vendor_number
            }
        case CLEAR_VENDOR_INDEX:
            return {
                ...state,
                selected_vendor_index: ""
            }
        case STORE_VENDOR_INDEX:
            return {
                ...state,
                selected_vendor_index: action.payload.index,
                isVendorNull: false
            }
        case CHANGE_CIP_RUN_FLAG:
            return {
                ...state,
                cip_scans_run: action.payload.status
            }
        case STORE_SELECTED_COUNTRY:
                return {
                    ...state,
                    country_arr: action.payload.country
            }
        case SELECTED_REGION:
                return {
                    ...state,
                    selected_region: action.payload.selected_region,
                    selected_market_list:action.payload.selected_market_list
            }
        case REGIONS_SET:
                return{
                    ...state,
                    pdf_data: action.payload.pdf_data,
                    company_val:action.payload.company_val
                }
        case SET_CARDVIEW:
                return{
                    ...state,
                    cardData:action.payload.cardData,
                    bannerImgSrc:action.payload.bannerImgSrc,
                    bannerExist:action.payload.bannerExist

                }
        case QUERY_ENTITY_SET:
                return{
                    ...state,
                    query_entity_data:action.payload.query_entity_data
                }
        case SELECTED_MARKET:
            return {
                ...state,
                selected_market: action.payload.selected_market,
                selected_entity_list: action.payload.selected_entity_list
            }
            case CHANGE_LANGUAGE:
                return {
                    ...state,
                    language:action.payload.language
                }
            case SET_INVOICELIST_DATA:
                return{
                    ...state,
                    invoice_list_data : action.payload.data,
                    download_data : action.payload.download_data,
                    allData : action.payload.allData,
                   
                    tableCount : action.payload.tableCount
                }
        case DEFAULT:
            return {
                ...state,
                entered_email: "",
                company_codes: "",
                all_company_codes: "",
                vendor_number: "",
                vendor_name: "",
                supplier_address: "",
                vat_number: "",
                role: "",
                response_arr: "",
                validated_email: "",
                selected_company_code: "",
                selected_pmi_entity: "",
                cip_value: "",
                username: "",
                bkp_vendor_number: "",
                accessToken: "",
                isLoggedIn: false,
                isVendorSelected: false,
                appClass: "",
                page_selected: false,
                selected_vendor_index: "",
                isVendorNull: true,
                cip_scans_run: false,
                invoice_mailbox:"",
               
               // supplierIndustryKey:""
            }
        default:
            return state
    }
}
