import * as types from '../constants/actionTypes'

export function login_email(email) {
    return function (dispatch) {
        dispatch({
            type: types.EMAIL_ENTERED,
            payload: {
                email: email
            }
        });
    }
}

export function store_user_details(obj) {
    return function (dispatch) {
        dispatch({
            type: types.LOAD_USER_DETAILS,
            payload: {
                company_codes: obj.company_codes,
                all_company_codes: obj.all_company_codes,
                vendor_number: obj.vendor_number,
                vendor_name: obj.vendor_name,
                supplier_address: obj.supplier_address,
                vat_number: obj.vat_number,
                role: obj.role,
                bkp_vendor_number: obj.bkp_vendor_number,
                selected_vendor_index: obj.selected_vendor_index,
                company_code_ERP:obj.company_code_ERP
               // supplierIndustryKey :obj.supplierIndustryKey
            }
        });
    }
}

export function setRegionsValues(pdf_data, company_val) {
    return function (dispatch) {
        dispatch({
            type: types.REGIONS_SET,
            payload: {
                pdf_data: pdf_data,
                company_val: company_val
            }
        });
    }
}

export function setCardValues(cardData, bannerImgSrc,bannerExist) {
    return function (dispatch) {
        dispatch({
            type: types.SET_CARDVIEW,
            payload: {
                cardData: cardData,
                bannerImgSrc: bannerImgSrc,
                bannerExist:bannerExist
            }
        });
    }
}

export function saveQueryEntityData(data) {
    return function (dispatch) {
        dispatch({
            type: types.QUERY_ENTITY_SET,
            payload: {
                query_entity_data: data
            }
        });
    }
}

export function store_login_response(obj) {
    return function (dispatch) {
        dispatch({
            type: types.LOGIN_RESPONSE,
            payload: {
                response_arr: obj.response_arr,
                validated_email: obj.validated_email,
                role: obj.role,
                username: obj.username,
                accessToken: obj.accessToken
            }
        });
    }
}

export function setInvoiceListData(data,download_data,allData,tableCount){
    return function (dispatch) {
        dispatch({
            type: types.SET_INVOICELIST_DATA,
            payload: {
                data : data,
                download_data : download_data,
                allData : allData,
                tableCount : tableCount
            }
        });
    }
}

export function store_support_login_response(obj) {
    return function(dispatch) {
        dispatch({
            type: types.SUPPORT_RESPONSE,
            payload: {
                response_arr: obj
            }
        });
    }
}

export function selected_company_details(obj) {
    return function (dispatch) {
        dispatch({
            type: types.SELECTED_COMPANY_DETAILS,
            payload: {
                selected_company_code: obj.selected_company_code,
                selected_pmi_entity: obj.selected_pmi_entity,
                cip_value: obj.cip_value,
                invoice_type:obj.invoice_type,
                invoice_mailbox:obj.invoice_mailbox
            }
        });
    }
}

export function clear_selected_vendor(appClass, page_stat) {
    return function (dispatch) {
        dispatch({
            type: types.CLEAR_SELECTED_VENDOR,
            payload: {
                appClass: appClass,
                page_stat: page_stat
            }
        });
    }
}

export function restore_vendor_number() {
    return function (dispatch) {
        dispatch({
            type: types.RESTORE_VENDOR_NUMBER,
            payload: {

            }
        });
    }
}
export function clear_vendor_index() {
    return function (dispatch) {
        dispatch({
            type: types.CLEAR_VENDOR_INDEX,
            payload: {

            }
        });
    }
}
export function store_vendor_index(index) {
    return function (dispatch) {
        dispatch({
            type: types.STORE_VENDOR_INDEX,
            payload: {
                index: index
            }
        });
    }
}
export function change_cip_run_status(status) {
    return function (dispatch) {
        dispatch({
            type: types.CHANGE_CIP_RUN_FLAG,
            payload: {
                status: status
            }
        });
    }
}
export function return_default() {
    return function (dispatch) {
        dispatch({
            type: types.DEFAULT,
            payload: {

            }
        });
    }
}

export function store_selectedQuery_countires(country_arr) {
    return function (dispatch) {
        dispatch({
            type: types.STORE_SELECTED_COUNTRY,
            payload: {
                country_arr: country_arr
            }
        });
    }
}

export function regionSelection(region, market_list) {
    return function (dispatch) {
        dispatch({
            type: types.SELECTED_REGION,
            payload: {
                selected_region: region,
                selected_market_list: market_list
            }
        });
    }
}

export function marketSelection(market, entity_list) {
    return function (dispatch) {
        dispatch({
            type: types.SELECTED_MARKET,
            payload: {
                selected_market: market,
                selected_entity_list: entity_list
            }
        });
    }
}


export function change_language(lang) {
    return function (dispatch) {

        dispatch({
            type: types.CHANGE_LANGUAGE,
            payload: {
                language: lang
            }
        })
    }

}