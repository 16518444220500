import React, { Component } from 'react';
import '../regions.scss';
import NavBar from './NavBar'
import { labels } from '../resources'
import Grid from '@material-ui/core/Grid';
import AjaxService from "../services/AjaxServices";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FloatingButtons from '../component/floating-buttons';
import LinearProgress from '@material-ui/core/LinearProgress';
import IdleTimer from 'react-idle-timer';
import FormHelperText from '@material-ui/core/FormHelperText';
import NotFound from './NotFound';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as AllActions from '../actions'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie';
import Draggable from 'react-draggable';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';

// for invoice report//
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import moment from 'moment'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import * as _ from 'lodash';

//// end of imports///

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

var progress = false;
let options;
var main_query = {}
// var labels = require('./../resources.json')
class RegionList extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = null
        this.handleOnIdle = this.handleOnIdle.bind(this)
        this.textInput = React.createRef();
        this.checkboxInput = React.createRef();
        this.state = {
            access_cookie: Cookies.get("access-cookie"),
            region_list: [],
            selectedCountry: "",
            data: "",
            dialogOpen: false,
            msgOpen: false,
            declarationNeed: "",
            digitalSignature: false,
            selected_company_details: "",
            selected_company: "",
            full_name: "",
            vat_number: "",
            supplier_name: "",
            supplier_address: "",
            checked_class: "signature-label",
            submit_msg: false,
            field_error: false,
            error_text: "",
            loadingOpen: false,
            validate: false,
            validateMessage: "",
            selectedMarket: "",
            ap_ee_flag: false,
            ap_ee_val: "",
            selected_vat_number:[],
            viewReportFlag:false,
            data_arr:[],
            page:0,
            rowsPerPage:15,
            totalCount:0,
            filters_data: "",
            columns_list: [],
            on_search_active: false,
            search_text: "",
            sort_direction: undefined,
            sort_name: undefined,
        };
    }


    handleOnIdle(event) {
        //console.log('last active', this.idleTimer.getLastActiveTime())

        this.Ajax = new AjaxService();
        this.Ajax.logout().then(result => {
            //console.log("logged out")
            sessionStorage.clear();
            localStorage.clear()
            this.props.actions.return_default()
            this.props.history.length = 0;

            this.props.history.replace("/");
        })
    }
    componentWillMount() {
        if (this.props.user.role === "vendor") {
            this.Ajax = new AjaxService();
            if (this.props.user.pdf_data !== "") {

                this.setState({ data: this.props.user.pdf_data, selectedCountry: this.props.user.selected_region, market_list: this.props.user.selected_market_list, region_list: this.props.user.company_val })
            } else {
                this.Ajax.getRegions()
                    .then(result => result.json())
                    .then(res => {

                        let vendor_company_codes = this.props.user.company_codes
                        let country_val = Object.keys(res)
                        let market_list = Object.keys(res[country_val[0]])
                        if (this.props.user.role === 'vendor') {
                            let updated_list = country_val.map((item, j) => {

                                Object.keys(res[item]).forEach((el, i) => {

                                    res[item][el].forEach((opt, m) => {
                                        if (vendor_company_codes.includes(opt.company_code) || vendor_company_codes.includes(parseInt(opt.company_code))) {
                                            opt.display = false
                                            // res[item][el].splice(m, 1)
                                            // res[item][el].unshift(opt)
                                        } else {
                                            opt.display = true
                                        }
                                    })
                                })

                            })
                        }
                        let company_val = res[country_val[0]]
                        this.props.actions.setRegionsValues(res, company_val)
                        this.setState({ data: res, selectedCountry: this.props.user.selected_region, market_list: this.props.user.selected_market_list, region_list: company_val })

                    })
            }

        }
    }

    handleVat = (e) => {
        this.setState({
            selected_vat_number : e.target.value
        })
    }


    //the below code triggered when a region is selected

    handleCountrySelection = (country) => {
        console.log(this.state.data)
        console.log(country.toLowerCase())
        if (country.toLowerCase() === "asia pacific" || country.toLowerCase() === "eastern europe") {
            console.log("herrree")
            if (country.toLowerCase() === "asia pacific") {
                console.log("herrree")
                this.setState({
                    ap_ee_val: labels[this.props.user.language]["pdf_declaration"]["ap_msg"],
                    ap_ee_flag: true
                })
            } else {
                this.setState({
                    ap_ee_val: labels[this.props.user.language]["pdf_declaration"]["ee_msg"],
                    ap_ee_flag: true
                })
            }

        } else {
            let market_list = []
            // market_list = Object.keys(data[country])
            var data = JSON.parse(JSON.stringify(this.state.data));

            market_list = Object.keys(data[country])
            market_list = market_list.sort()
            let disabled_market_arr = []
            market_list.map((item, k) => {
                let count = 0
                data[country][item].map((val, j) => {
                    // if (val.display===false && count === 0) {
                    //     count = count + 1
                    //     data[country][item].push({ display: true, disable:"all" })
                    // } 

                    if ((this.props.user.company_codes.includes(val.company_code) || this.props.user.company_codes.includes(parseInt(val.company_code))) && count === 0) {
                        count = count + 1
                        data[country][item].push({ display: true, disable: "all" })
                    } else {

                    }
                })
            })


            market_list.map((item, k) => {
                data[country][item].map((val, j) => {
                    if (val.hasOwnProperty('disable')) {
                        disabled_market_arr.push({
                            market_name: item,
                            display: true
                        })
                    }
                })
            })


            market_list.forEach((el, o) => {
                disabled_market_arr.forEach(new_el => {

                    if (el === new_el.market_name) {
                        market_list[o] = 0
                    }
                })
            })


            market_list.map((item, i) => {
                if (item !== 0) {
                    disabled_market_arr.push({ market_name: item, display: false })
                }
            })


            this.props.actions.regionSelection(country, disabled_market_arr)
            // this.props.actions.regionSelection(country,market_list)
            this.setState({ selectedCountry: country, market_list: disabled_market_arr, region_list: [] })
            // this.setState({
            //     selectedCountry:country,market_list:market_list,region_list: []
            // })
        }

    }

    //the below code triggered when a market is selected 

    handleMarketSelection = (selected_market) => {
        console.log(this.state.data)
        let entity_list = this.state.data[this.state.selectedCountry]
        console.log("DAT", this.state.data, this.state.selectedCountry, selected_market)
        console.log(entity_list)
        this.props.actions.marketSelection(selected_market, entity_list[selected_market])
        this.setState({ region_list: entity_list[selected_market], selectedMarket: selected_market })
    }

    //the below code triggered when an entity is selected
    handleRegionSelection = (region_data) => {
        this.Ajax = new AjaxService();
        var companyCode = region_data.company_code
        var reg = this.state.selectedCountry
        var market = region_data.Country
        var vendor_num = ""
        var vendorName = region_data.company_name
        let erp_codes = this.props.user.company_code_ERP
        let get_erp_system;
        let erps = Object.keys(erp_codes)
        let foreign_supplier
        erps.forEach(el => {
            erp_codes[el].forEach(code => {
                if (code.code === companyCode) {
                    get_erp_system = el;
                    foreign_supplier = code.foreignSupplierFlag
                }
            })
        })
        console.log(get_erp_system);
        console.log(region_data)
        console.log(this.state.selectedCountry)
        if (region_data.region.toLowerCase() === "latin america & canada" && foreign_supplier.toLowerCase() === "x" && region_data.foreign_supplier_flag.toLowerCase() === "no") {
            this.setState({ latam_flag: true, msg_val: labels[this.props.user.language]["pdf_declaration"].latam_restrict, loadingOpen: false })
        }else{
            if (region_data.electronic_invoice.toLowerCase() === "paper/pdf" || region_data.electronic_invoice.toLowerCase() === "pdf") {

                this.setState({ loadingOpen: true })
                this.Ajax.getPdfDeclaration(companyCode, reg, market)
                    .then(result => result.json())
                    .then(res => {
                        if (res["Invoice Submission in VQP"].toLowerCase() === "yes") {
                            if (res["Declaration for PDF needed? -YES or - NO"].toLowerCase() === "yes") {
                                //check declaration code needs to be added here
                                this.Ajax.checkDeclaration(this.props.user.vendor_number, vendorName, res["Mailbox Id"])
                                    .then(new_res => new_res.json())
                                    .then(new_res => {
                                        console.log(new_res)
                                        if (new_res.hasOwnProperty("error")) {
                                            this.setState({ validate: true, validateMessage: labels[this.props.user.language]["pdf_declaration"]["err_3"], loadingOpen: false })
                                        } else {
                                            if (new_res.length !== 0) {
                                                this.setState({ loadingOpen: false })
                                              
                                                    this.props.history.push({
                                                        pathname: '/invoice-submission',
                                                        state: { details: region_data }
                                                    })
                                                
                                            } else {
                                                if (res["PDF Declaration Allowed in VQP"].toLowerCase() === "yes") {
                                                    // this.setState({ dialogOpen: true, vat_number: this.props.user.vat_number[get_erp_system], selected_company: region_data, selected_company_details: res, loadingOpen: false })
                                                    if(this.props.user.vat_number[get_erp_system].length === 1){
                                                        this.setState({ dialogOpen: true,selected_vat_number:this.props.user.vat_number[get_erp_system][0], vat_number: this.props.user.vat_number[get_erp_system], selected_company: region_data, selected_company_details: res, loadingOpen: false })
                                                    }else{
                                                        this.setState({ dialogOpen: true, vat_number: this.props.user.vat_number[get_erp_system], selected_company: region_data, selected_company_details: res, loadingOpen: false })
                                                    }
                                                } else {
                                                    this.setState({ msg_flag: true, msg_val: res["Inovice Mailing Address for PAPER / Message for PDF and E-INV"], loadingOpen: false })
                                                }
                                            }
                                        }
                                    })

                            } else {


                                this.setState({ loadingOpen: false })
                                    this.props.history.push({
                                        pathname: '/invoice-submission',
                                        state: { details: region_data }
                                    })


                            }

                        } else {
                            this.setState({ msgOpen: true, selected_company: region_data,loadingOpen:false })
                        }
                        console.log(res)
                        var regions_selected_Details = {
                            "selected_company_code": res["CoCds"],
                            "selected_pmi_entity": res["Company name"],
                            "cip_value": res["CIP Value"],
                            "invoice_type": res["Invoice type"],
                            "invoice_mailbox":res["Invoice mailbox"]
                         
                        }

                        this.props.actions.selected_company_details(regions_selected_Details)


                    })
            } else if (region_data.electronic_invoice.toLowerCase() === "e-inv") {
                this.setState({ msg_flag: true, msg_val: region_data.eInv, loadingOpen: false })
            } else if (region_data.electronic_invoice.toLowerCase() === "paper") {
                this.setState({ msgOpen: true, selected_company: region_data,loadingOpen: false })
            } else {
                this.setState({ msgOpen: true, selected_company: region_data,loadingOpen: false })
            }
        }
           
        


    }

    handleDialogClose = (dialog_name) => {
        if (dialog_name === "dialogOpen") {
            this.setState({ dialogOpen: false, full_name: "", digitalSignature: false,selected_vat_number:[] })
        } else if (dialog_name === "msgOpen") {
            this.setState({ msgOpen: false })
        } else if (dialog_name === "aff_flag") {
            this.setState({ aff_flag: false })
        } else if (dialog_name === "msg_flag") {
            this.setState({ msg_flag: false })
        } else if (dialog_name === "ap_ee_flag") {
            this.setState({ ap_ee_flag: false, latam_flag: false })
        } else if (dialog_name === "view_report") {
            this.setState({ viewReportFlag: false })
        } else {
            this.setState({ submit_msg: false })
            this.props.history.push({
                pathname: '/invoice-submission'
            })
        }

    }
    /// search for invoice report///
    onSearch = _.debounce((e) => {
        console.log("search text debounce: ", e)
        if (e !== null) {
          main_query.search_text = e
          main_query.page = 0
          options.page = 0
          this.setState({
            on_search_active: true,
            search_text: e,
            data_loading: true,
    
            deriveddata: []
          })
          this.Ajax = new AjaxService();
    
          console.log(main_query)
          this.Ajax.viewSubmissionLog(main_query)
            .then(search_result => search_result.json())
            .then(result => {
              if (result.hasOwnProperty("error")) {
                // this.setState({
                //   validate: true,
                //   validateMessage: labels[this.props.user.language].invoice_list.err,
                //   dataloaded: true,
                //   deriveddata: [],
    
                //   data_loading: false
                // });


              } else {
                var data_arr=[];
                result.data.forEach(item=>{
                var doc_date = item["uploadedDate"] === "" ? "" : item["uploadedDate"] === undefined ? "" : item["uploadedDate"] === "00000000" ? "" : moment(item["uploadedDate"]).format("DD-MMM-YYYY")
                var file=[];
                item["userFileArray"].forEach(item=>{ file.push(item.split('->')[0])});
                console.log(file)
                    data_arr.push([
                        item.company_code, item.pmi_entity_name, item.vendor_name, doc_date, file
                    ])
                })
                this.setState({data_arr: data_arr, totalCount: result.count, data_loading:false})
                
              }
    
            })
        }
    
      }, 2000)
/// search end////

onSearchClose = () => {
    main_query.page = 0
    console.log(main_query)
    this.Ajax.viewSubmissionLog(main_query).then(res => res.json()).then(result => {
      console.log(result)
      if (result.hasOwnProperty("error")) {
        this.setState({
        //   validate: true,
        // //  validateMessage: labels[this.props.user.language].invoice_list.err,
        //   dataloaded: true,
        //   deriveddata: [],

        //   data_loading: false
        });
      } else {

        
        var data_arr=[];
        result.data.forEach(item=>{
        var doc_date = item["uploadedDate"] === "" ? "" : item["uploadedDate"] === undefined ? "" : item["uploadedDate"] === "00000000" ? "" : moment(item["uploadedDate"]).format("DD-MMM-YYYY")
        var file=[];
        item["userFileArray"].forEach(item=>{ file.push(item.split('->')[0])});
        console.log(file)
            data_arr.push([
                item.company_code, item.pmi_entity_name, item.vendor_name, doc_date, file
            ])
        })
        this.setState({data_arr: data_arr, totalCount: result.count})
        
      }
    })
  }



    handleCheckBoxChange = (event) => {
        //console.log(event.target.checked)
        if (event.target.checked === true) {
            this.setState({ digitalSignature: event.target.checked, checkbox_error: false })
        } else {
            this.setState({ digitalSignature: event.target.checked })
        }

    }
    //// API call for getting data////
    commonAPIForTable =(page, rowsPerPage, sortOrder) =>{
        let mainQuery={vendor_number:this.props.user.vendor_number, 
                        vendor_email:this.props.user.validated_email,
                        page: page, 
                        rowsPerPage:rowsPerPage}


        if (sortOrder) {
            mainQuery.sort_name = sortOrder.name
            mainQuery.sort_direction = sortOrder.direction
                     }
        var data_arr=[];
        this.Ajax = new AjaxService();
        this.Ajax.viewSubmissionLog(mainQuery)
        .then(result => result.json())
        .then(res => {
            console.log("common API res--",res)
           
            res.data.forEach(item=>{
            var doc_date = item["uploadedDate"] === "" ? "" : item["uploadedDate"] === undefined ? "" : item["uploadedDate"] === "00000000" ? "" : moment(item["uploadedDate"]).format("DD-MMM-YYYY")
            var file=[];
            item["userFileArray"].forEach(item=>{ file.push(item.split('->')[0])});
            console.log(file)
                data_arr.push([
                    item.company_code, item.pmi_entity_name, item.vendor_name, doc_date, file
                ])
            })
            console.log(data_arr.length, page)
            if (res.page === "first") {
                this.setState({ data_arr: data_arr, page: page ,totalCount: res.count })
                options.count=res.count;
              
              } else {
                this.setState({
                     data_arr: data_arr, page: page 
                })              
      
              }

            // console.log(data_arr)
            // options.count=res.count;
            // this.setState({ data_arr: data_arr, page: page ,totalCount: res.count })
            // console.log(data_arr.length, page)

        }).catch(function (err) {
            console.log(err)
            // this.setState({
            //     textfield_error: true,
            //     error_text: "Error while submitting your request. Please try again later.",
            //     logout: true
            // })
        });  
    }
    handleSubmissionLogs = () =>{
        main_query={vendor_number:this.props.user.vendor_number, vendor_email:this.props.user.validated_email,page: this.state.page, rowsPerPage:this.state.rowsPerPage};
        this.Ajax = new AjaxService();
            this.Ajax.viewSubmissionLog(main_query)
                .then(result => result.json())
                .then(res => {
                    console.log(res)
                    var data_arr=[];
                    res.data.forEach(item=>{
                    var doc_date = item["uploadedDate"] === "" ? "" : item["uploadedDate"] === undefined ? "" : item["uploadedDate"] === "00000000" ? "" : moment(item["uploadedDate"]).format("DD-MMM-YYYY")
                    var file=[];
                    item["userFileArray"].forEach(item=>{ file.push(item.split('->')[0])});
                    console.log(file)
                        data_arr.push([
                            item.company_code, item.pmi_entity_name, item.vendor_name, doc_date, file
                        ])
                    })
                    this.setState({viewReportFlag:true, data_arr: data_arr, totalCount: res.count})

                }).catch(function (err) {
                    console.log(err)
                    // this.setState({
                    //     textfield_error: true,
                    //     error_text: "Error while submitting your request. Please try again later.",
                    //     logout: true
                    // })
                });  
    
        
    }


    handleTextChange = (event) => {
        //console.log(event.target.value)
        this.setState({ [event.target.name]: event.target.value, error_text: "", field_error: false })
    }
    handleSpinnerClose = () => {
        this.setState({ loadingOpen: false })
    }
    handleCloseFlag = () => {
        this.setState({ validate: false, validateMessage: "" })
    }

    handlePdfSubmit = () => {
        //console.log("submit");

        if (this.state.digitalSignature === false || this.state.full_name === "") {
            if (this.state.digitalSignature === false) {
                this.checkboxInput.current.focus();
                this.setState({ checked_class: "signature-label-shake", checkbox_error: true })
            }


            if (this.state.full_name === "") {
                this.textInput.current.focus();
                this.setState({
                    error_text: labels[this.props.user.language]["pdf_declaration"]["err_2"],
                    field_error: true
                })
            }
        }else if(this.state.selected_vat_number === ""){
            this.setState({
                vat_number_err_msg: labels[this.props.user.language]["pdf_declaration"]["err_2"],
                vat_number_err: true
            })
        }
        else {
            progress = true;
            var new_object = {
                vendor_number: this.props.user.vendor_number,
                companyCode: this.state.selected_company.company_code,
                invoice_mailing: this.state.selected_company.email_mailing,
                vat_number: this.state.selected_vat_number,
                full_name: this.state.full_name,
                supplier_name: this.state.supplier_name,
                supplier_address: this.state.supplier_address,
                mailbox_id: this.state.selected_company_details["Mailbox Id"]
            }

            this.Ajax = new AjaxService();
            this.Ajax.submitDeclaration(new_object)
                .then(result => result.json())
                .then(res => {
                    //console.log(res)
                    if (res === "updated field value") {
                        progress = false;
                        this.setState({ dialogOpen: false, submit_msg: true })
                        var pdf_declaration = {
                            "vendor_number": this.props.user.vendor_number,
                            "company code": this.state.selected_company.company_code,
                            "full_name": this.state.full_name,

                            "vat_number": this.state.selected_vat_number,

                            "pmi_entityname": this.props.user.selected_pmi_entity,
                            "submission_time": new Date(),
                            "mailbox_id": this.state.selected_company_details["Mailbox Id"]
                        }
                        this.Ajax.savePdfLogs(pdf_declaration)
                            .then(response => response.json())
                            .then(details => {

                            })

                    } else {

                        if (res.hasOwnProperty("error")) {
                            if (res.error === "Mailbox id empty") {
                                var pdf_declaration = {
                                    "vendor_number": this.props.user.vendor_number,
                                    "company code": this.state.selected_company.company_code,
                                    "full_name": this.state.full_name,
                                    "vat_number": this.state.selected_vat_number,

                                    "pmi_entityname": this.props.user.selected_pmi_entity,
                                    "submission_time": new Date(),
                                    "pmi_email": '',
                                    "mailbox_id": this.state.selected_company_details["Mailbox Id"],
                                    "error_reason": "Empty Mailbox Id"
                                }
                                this.Ajax.savePdfLogs(pdf_declaration)
                                    .then(response => response.json())
                                    .then(details => {
                                        this.setState({ validate: true, validateMessage: labels[this.props.user.language]["pdf_declaration"]["err_1"], loadingOpen: false, dialogOpen: false })
                                    })

                            } else {

                                var pdf_declaration = {
                                    "vendor_number": this.props.user.vendor_number,
                                    "company code": this.state.selected_company.company_code,
                                    "full_name": this.state.full_name,
                                    "vat_number": this.state.selected_vat_number,

                                    "pmi_entityname": this.props.user.selected_pmi_entity,
                                    "submission_time": new Date(),
                                    "pmi_email": '',
                                    "mailbox_id": this.state.selected_company_details["Mailbox Id"],
                                    "error_reason": "Request submission failed"
                                }
                                this.Ajax.savePdfLogs(pdf_declaration)
                                    .then(response => response.json())
                                    .then(details => {
                                        this.setState({ validate: true, validateMessage: labels[this.props.user.language]["pdf_declaration"]["err_1"], loadingOpen: false })
                                    })
                            }

                        }

                    }

                })
        }


    }

    handleFilterSubmit = applyFilters => {
        if (this.props.user.role === "vendor" || this.props.user.role === "support") {
    
    
          let filterList = applyFilters();
          console.log(filterList);
          let columns_list = this.state.columns_list
          var filters_obj = {}
          if (this.state.columns_list.length !== 0) {
            columns_list.forEach((item, i) => {
              filterList.forEach((opt, j) => {
                if (opt.length !== 0 && i === j) {
                  filters_obj[item.name] = opt
                }
              })
            })
          }
          console.log(filters_obj)
          console.log(main_query)
          main_query.filters = filters_obj
          main_query.page = 0
          options.page = 0
          this.setState({
            applied_filters: true,
            filters_obj: filters_obj,
            data_loading: true,
            deriveddata: [],
            main_query: main_query
          })
          if (Object.keys(filters_obj).length !== 0) {

                this.Ajax.viewSubmissionLog(main_query)
              .then(res => res.json())
              .then(res => {
                console.log(res)
                if (res.hasOwnProperty("error")) {
                  this.setState({
                    // validate: true,
                    // validateMessage: labels[this.props.user.language].invoice_list.err,
                    // dataloaded: true,
                    // deriveddata: [],
    
                    // data_loading: false
                  });
                } else {
    
                    var data_arr=[];
                    res.data.forEach(item=>{
                    var doc_date = item["uploadedDate"] === "" ? "" : item["uploadedDate"] === undefined ? "" : item["uploadedDate"] === "00000000" ? "" : moment(item["uploadedDate"]).format("DD-MMM-YYYY")
                    var file=[];
                     item["userFileArray"].forEach(item=>{ file.push(item.split('->')[0])});
                     console.log(file)
                        data_arr.push([
                            item.company_code, item.pmi_entity_name, item.vendor_name, doc_date, file
                        ])
                    })
                    this.setState({data_arr: data_arr, totalCount: res.count})
                }
              })
          } else { 
            delete main_query.filters
            console.log(main_query)
            this.Ajax.viewSubmissionLog(main_query)
              .then(res => res.json())
              .then(res => {
                console.log(res)
                if (res.hasOwnProperty("error")) {
                  this.setState({
                    // validate: true,
                    // validateMessage: labels[this.props.user.language].invoice_list.err,
                    // dataloaded: true,
                    // deriveddata: [],
    
                    // data_loading: false
                  });
                } else {
                    var data_arr=[];
                    res.data.forEach(item=>{
                    var doc_date = item["uploadedDate"] === "" ? "" : item["uploadedDate"] === undefined ? "" : item["uploadedDate"] === "00000000" ? "" : moment(item["uploadedDate"]).format("DD-MMM-YYYY")
                    var file=[];
                     item["userFileArray"].forEach(item=>{ file.push(item.split('->')[0])});
                     console.log(file)
                        data_arr.push([
                            item.company_code, item.pmi_entity_name, item.vendor_name, doc_date, file
                        ])
                    })
                    this.setState({data_arr: data_arr, totalCount: res.count})
                
                }
              })
          }
        }
    
      }

      
      setColumns = (columns_list) => {
        this.setState({
          columns_list: columns_list
        })
      } 
      getMuiTheme = () =>
      createMuiTheme({
          overrides: {
              MUIDataTableFilter: {
                  padding: "9px 20px 33px 20px",
                  fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                  title: {
                      fontWeight: 400,
                      marginLeft: 0
                  },
                  resetLink: {
                      fontSize: 14,
                      fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                      fontWeight: 400
                  }
              },
              MuiFormControl: {
                  root: {
                      border: "1px solid #ccc !important",
                      width: "100% !important"
                  }
              },
              MuiFormLabel: {
                  root: {
                      fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                      fontWeight: 400
                  }
              },
              MuiButton: {
                  textPrimary: {
                      color: "#004f90"
                  }
              },
              MUIDataTableBodyCell: {
                  root: {
                      textAlign: "left"
                  }
              },
              MuiGridListTile: {
                  root: {
                      paddingTop: "0 !important"
                  },
                  tile: {
                      marginTop: "0 !important"
                  }
              },
              MUIDataTable: {
                  responsiveScroll: {
                      overflowY: "scroll"
                  }
              },
              
  
              MUIDataTableHeadCell: {
                  fixedHeader: {
                      backgroundColor: "#f6f6f6",
                      color: "#0160a5 !important"
                  },
                  root: {
                      padding: "10px",
                      verticalAlign: "top"
                  }
              },
              MuiToolbar: {
                  root: {
                      background: "#f6f6f6"
                  },
                  regular: {
                      paddingLeft: "0"
                  },
                  gutters: {
                      paddingLeft: "0"
                  }
              },
              MuiTableCell: {
                  root: {
                      padding: "15px",
                      verticalAlign: "top",
                      fontFamily:
                          "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                  },
                  body: {
                      fontSize: "14px"
                  },
                  head: {
                      fontSize: "15px",
                      fontWeight: "600"
                  }
              },
              MuiSelect: {
                  icon: {
                      top: "0 !important"
                  }
              },
              MUIDataTableSearch: {
                  main: {
                      position: "absolute",
                      right: "12rem",
                      bottom: "10px",
                      width: "48%"
                  },
                  searchIcon: {
                      display: "none"
                  }
              },
              MuiInputBase: {
                  root: {
                      fontFamily:
                          "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                  },
                  input: {
                      paddingTop: "5px",
                      border: "1px solid #ccc",
                      paddingLeft: "5px",
                      paddingRight: "20px",
                      borderBottom: "1px solid #ccc",
                      fontSize: 13,
                      fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                  }
              },
              MUIDataTableToolbar: {
                  filterPaper: {
                      maxWidth: "46% !important",
                      left: "650px !important"
                  },
                  root: {
                      //display: ''
                  }
  
  
              },
              MUIDataTablePagination: {
                  root: {
                      fontFamily:
                          "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                      "&:last-child": {
                          padding: "0px"
                      }
                  }
              },
              MuiTableFooter: {
  
              },
              MuiMenuItem: {
                  root: {
                      fontFamily:
                          "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                      fontSize: 13
                  }
              },
              MuiIconButton: {
                  root: {
                      padding: "5px",
                      "&:focus": {
                          outline: "none !important"
                      },
                      "&:hover": {
                          // color: "#004f90 !important",
                          backgroundColor: "transparent !important"
                      }
                  }
              },
              MuiPaper: {
                  elevation4: {
                      boxShadow: "none !important",
                      borderRadius: "0px",
                      border: "1px solid #ccc !important",
                      width: "100%"
                  }
  
              },
              MuiDialogContent:{
                  root:{
                      overflowY:"auto"
                  }},
              MuiSvgIcon: {
                  root: {
                      width: "1em",
                      height: "1em",
                      fontFamily:
                          "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                      // paddingLeft: "15px"
                  }
              },
              MuiTablePagination: {
                  select: {
                      border: "none"
                  },
                  selectIcon: {
  
                  }
              },
              MuiTable: {
                  root: {
                      fontFamily:
                          "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                  }
              },
  
              MuiTypography: {
                  caption: {
                      fontFamily:
                          "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                      fontSize: "1rem"
                  },
                  h6: {
                      paddingLeft: 8,
                      fontSize: "1.5rem",
                      fontWeight: "normal",
                      fontFamily:
                          "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                  },
  
              },
              MUIDataTableFilterList: {
                  chip: {
                      margin: "8px 8px 8px 0px"
                  }
              },
              MuiChip: {
                  root: {
                      fontSize: 13,
                      fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                      backgroundColor: "#f4f4f4",
                      border: "1px solid #004f90",
                      margin: "0 5px"
                  },
                  deleteIcon: {
                      fill: "#004f90"
                  }
              },
              MuiCheckbox: {
                  MuiIconButton: {
                      root: {
                          color: "#004f90"
                      }
                  }
              },
              MuiTableRow: {
                  root: {
                      '&$selected': {
                          backgroundColor: '#0067bc2e !important'
                      }
                  }
              },
              MUIDataTableSelectCell: {
                  checked: { color: "#004f90 !important" }
              }
          }
      });
    render() {
        let language_labels = labels[this.props.user.language].pdf_declaration
        var language_labelsTable = labels[this.props.user.language].invoice_submission
        var language_labels1 = labels[this.props.user.language].invoice_list

        let agreed_terms = language_labels.agreed_terms
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };
        const columns = [
            {
                name: "company_code",
                label:language_labelsTable.company_code,
                options: {
                    sort: true,
                    filter: true,
                    filterType: "custom",
                    filterOptions: {
                      display: (filterList, onChange, index, column) => {
        
                        return (
                          <div>
                            {this.state.filters_data.length !== 0 && <div>
                              <InputLabel >{language_labelsTable.company_code}</InputLabel>
                              <Select
                                multiple
                                className="custom-filters"
                                value={filterList[index]}
                                renderValue={selected => selected.join(', ')}
                                onChange={event => {
                                  filterList[index] = event.target.value;
                                  onChange(filterList[index], index, column);
                                }}
                              >
                                {this.state.filters_data["company_code"].map(item => (
                                  <MenuItem key={item} value={item}>
                                    <Checkbox
                                      color='primary'
                                      checked={filterList[index].indexOf(item) > -1}
                                    />
                                    <ListItemText primary={item} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>}
                          </div>
                        )
        
        
                      }
                    }
                  }
        
            },
            {    
                name: "pmi_entity_name",
                label:language_labelsTable.pmi_entity_name,
                options: {
                    sort: true,
                    filter: true,
                    filterType: "custom",
                    filterOptions: {
                      display: (filterList, onChange, index, column) => {
        
                        return (
                          <div>
                            {this.state.filters_data.length !== 0 && <div>
                              <InputLabel >{language_labelsTable.pmi_entity_name} </InputLabel>
                              <Select
                                multiple
                                className="custom-filters"
                                value={filterList[index]}
                                renderValue={selected => selected.join(', ')}
                                onChange={event => {
                                  filterList[index] = event.target.value;
                                  onChange(filterList[index], index, column);
                                }}
                              >
                                {this.state.filters_data["pmi_entity_name"].map(item => (
                                  <MenuItem key={item} value={item}>
                                    <Checkbox
                                      color='primary'
                                      checked={filterList[index].indexOf(item) > -1}
                                    />
                                    <ListItemText primary={item} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>}
                          </div>
                        )
        
                      }
                    }
                  }
               
            },
            {
                name: "vendor_name",
                label:language_labelsTable.supplier_name,
                options: {
                    sort: false,
                    filter: true,
                    filterType: "custom",
                    filterOptions: {
                      display: (filterList, onChange, index, column) => {
        
                        return (
                          <div>
                            {this.state.filters_data.length !== 0 && <div>
                              <InputLabel >{language_labelsTable.supplier_name}</InputLabel>
                              <Select
                                multiple
                                className="custom-filters"
                                value={filterList[index]}
                                renderValue={selected => selected.join(', ')}
                                onChange={event => {
                                  filterList[index] = event.target.value;
                                  onChange(filterList[index], index, column);
                                }}
                              >
                                {this.state.filters_data["vendor_name"].map(item => (
                                  <MenuItem key={item} value={item}>
                                    <Checkbox
                                      color='primary'
                                      checked={filterList[index].indexOf(item) > -1}
                                    />
                                    <ListItemText primary={item} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>}
                          </div>
                        )
        
        
                      }
                    }
                  }
        
                
            },
            {
                name: "uploadedDate",
                label:language_labelsTable.submission_date,
                options: {
                    sort: true,
                    filter: false,
                    filterType: "custom",
                    filterOptions: {
                      display: (filterList, onChange, index, column) => {
        
                        return (
                          <div>
                            {this.state.filters_data.length !== 0 && <div>
                              <InputLabel >{language_labelsTable.submission_date}</InputLabel>
                              <Select
                                multiple
                                className="custom-filters"
                                value={filterList[index]}
                                renderValue={selected => selected.join(', ')}
                                onChange={event => {
                                  filterList[index] = event.target.value;
                                  onChange(filterList[index], index, column);
                                }}
                              >
                                {this.state.filters_data["uploadedDate"].map(item => (
                                  <MenuItem key={item} value={item}>
                                    <Checkbox
                                      color='primary'
                                      checked={filterList[index].indexOf(item) > -1}
                                    />
                                    <ListItemText primary={item} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>}
                          </div>
                        )
        
        
                      }
                    }
                  }
        
               
            },
            {
                name:"userFileArray",
                label:language_labelsTable.file_name,

                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                       // console.log(value, tableMeta, updateValue)
                        return ( 
                        // <div> {value.map(item=>(<div style={{ textAlign: "left" }}> {item.split('->')[0]}</div>))}  </div> 
                        <div>{value.map(item=>(<div style={{ textAlign: "left" }}> {item}</div>))}</div>
                        )
             
                    }
                }
            }
        ];

         options = {
            print: false,
            search: true,
            download: true,
            filter: true,
            filterType: "dropdown",
            viewColumns: false,
            tableBodyHeight: 'auto',
            selectableRows:'none',
            count:this.state.totalCount,
            rowsPerPage:this.state.rowsPerPage,
            serverSide:true,
            styles: {
                filterList: {
                  root: {
                    color: "red"
                  },
                  chip: {
                    color: "#FEFEF0"
                  }
                }
              },
              responsive: "vertical",
            downloadOptions: {
                filename: "Invoice Submission Report.csv",
                separator: ','
            },
            textLabels: {
                body: {
                  noMatch: this.state.data_loading ? language_labels1.loading_wait : language_labels1.no_results,
                  toolTip: language_labels1.sort,
                },
        
                toolbar: {
                  search: language_labels1.search,
                  downloadCsv: language_labels1.download,
                  viewColumns: language_labels1.view_columns,
                  filterTable: language_labels1.filter_table,
                },
                viewColumns: {
                  title: language_labels1.view_columns_heading
                },
                pagination: {
                  next: language_labels1.next,
                  previous: language_labels1.previous,
                  rowsPerPage: language_labels1.rowsPerPage,
                  displayRows: language_labels1.displayRows
                },
                filter: {
                  all: language_labels1.all,
                  title: language_labels1.title,
                  reset: language_labels1.reset,
                },
              },
        
            onTableChange: (action, tableState) => {
                console.log("ontablechange", action, tableState, this.state);
        
        
                switch (action) {
        
                  case "changePage":
                    if (this.props.user.role === "vendor" || this.props.user.role === "support") {
                      console.log(tableState.page)
                      this.commonAPIForTable(tableState.page, tableState.rowsPerPage, tableState.sortOrder)
                    }
        
                    break;
                    case "propsUpdate":
                        if (this.state.columns_list.length === 0) {
                          this.setColumns(tableState.columns)
                        }
            
                        break;
                    case "changeRowsPerPage":
                            if (this.props.user.role === "vendor" || this.props.user.role === "support") {
                              this.setState({
                                tableState: tableState,
                                rowsPerPage: tableState.rowsPerPage,
                                page: 0
                              })
                              tableState.page = 0;
                              console.log("tablestate page", tableState.page)
                              this.commonAPIForTable(0, tableState.rowsPerPage, tableState.sortOrder)
                
                            }
                        break;

                        case "sort":

                        if ((this.state.sort_name !== tableState.sortOrder.name || this.state.sort_direction !== tableState.sortOrder.direction)) {
                          if (tableState.page === 0) {
                            this.setState({
                              sort_name: tableState.sortOrder.name,
                              sort_direction: tableState.sortOrder.direction,
            
                            })
            
                          } else {
                            this.setState({
                              sort_name: tableState.sortOrder.name,
                              sort_direction: tableState.sortOrder.direction,
                              tableState: tableState,
                              rowsPerPage: tableState.rowsPerPage,
                              page: 0
                            })
                            tableState.page = 0;
                          }
                          if (this.props.user.role === "vendor" || this.props.user.role === "support") {
                            this.commonAPIForTable(tableState.page, tableState.rowsPerPage, tableState.sortOrder)
                          }
                        } else {
                          this.setState({
                            tableState: tableState,
                            rowsPerPage: tableState.rowsPerPage,
                            page: 0
                          })
                          tableState.page = 0;
                          if (this.props.user.role === "vendor" || this.props.user.role === "support") {
                            this.commonAPIForTable(tableState.page, tableState.rowsPerPage, tableState.sortOrder)
                          }
                        }
            
                        break;
            
                }},
                customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
                    return (
                      <div style={{ marginTop: '40px' }}>
                        {this.state.filters_data.length !== 0 && <Button variant="contained" onClick={() => this.handleFilterSubmit(applyNewFilters)}>Apply</Button>}
                        {this.state.filters_data.length === 0 && <p >{this.state.filtermsg}</p>}
                      </div>
                    );
                  },
                onFilterChange: (column, filterList, type) => {
      
                    // var len_flag = false
                    if (type === 'chip' || type === "reset") {
            
                      var newFilters = () => (filterList);
            
                      this.handleFilterSubmit(newFilters);
            
                    }
            
                  },
                onFilterDialogOpen: () => {
                    console.log("filter_data", this.state.filters_data)
                    if (this.state.filters_data.length === 0) {
                      var filter_dropdown;
                      this.Ajax = new AjaxService()
                      this.Ajax.getReportsFiltersDropDown(this.props.user.validated_email, this.props.user.vendor_number)
                        .then(filters => filters.json())
                        .then(filters_list => {
                            console.log(filters_list)
                          if (filters_list.length !== 0) {
                            filter_dropdown = filters_list[0]
                           
                            filter_dropdown["company_code"] = filter_dropdown["company_code"].sort()
                            filter_dropdown["pmi_entity_name"] = filter_dropdown["pmi_entity_name"].sort()
                            filter_dropdown["vendor_name"] = filter_dropdown["vendor_name"].sort()
                           
                            this.setState({
                              filters_data: filter_dropdown,
                              filtermsg: ""
                            });
                          } else {
                            filter_dropdown = filters_list
                            this.setState({
                              filters_data: filter_dropdown,
                              filtermsg: language_labels.no_filters
                            });
                          }
            
                        }).catch(err => {
                          console.log(err);
                        });
                    }
            
                  },
                onSearchChange: (searchText) => {
                    if (this.props.user.role === "vendor" || this.props.user.role === "support") {
                      this.onSearch(searchText)
                    }
                  },
                onSearchClose: () => {
                    if (this.props.user.role === "vendor" || this.props.user.role === "support") {
            
                      console.log(main_query.search_text)
                      if (main_query.search_text !== undefined) {
                        delete main_query.search_text
                        this.onSearchClose()
                      }
            
            
                    }
                  },
                  


        };
        const DialogTitle = ((props) => {
            const { children,  onClose, ...other } = props;
            return (
              <MuiDialogTitle  className="dialogTitleCustom" disableTypography >
                {console.log( children)}
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                  <IconButton aria-label="close"  onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                ) : null}
              </MuiDialogTitle>
            );
          });
        return (
            <div>
                {/* && this.state.access_cookie === this.props.user.accessToken */}
                {(this.props.user.role === "vendor") && <div className="container-fluid no-padding worldmap-bg">
                    <NavBar appClass="inv-submission" />
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref }}
                        timeout={1000 * 60 * 10}
                        onIdle={this.handleOnIdle}
                        debounce={250}
                    />
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.validate}
                        onClose={this.handleCloseFlag}
                        autoHideDuration={6000}
                        message={this.state.validateMessage}
                    />
                    <Backdrop disableBackdropClick={true} open={this.state.loadingOpen} onClick={(event) => this.handleSpinnerClose("spinner")}>
                        <CircularProgress className="circular-spinner" />
                    </Backdrop>
                    {progress && <LinearProgress className="linear-progress" />}
                    <div>

                        <Grid container className="market-container">
                        <Grid container >
                            <Grid item xs={6} className="page-heading">  
                                <h5 className="page-heading">{language_labels.page_heading}</h5>
                            </Grid>
                            <Grid item xs={6} style={{maxWidth: "40%",flexDirection: "row-reverse", display: "flex"}}>  
                            
                                <Button disableRipple onClick={(event) => this.handleSubmissionLogs()} className="msg-btn">
                                          {language_labelsTable.view_report_btn}
                                        </Button>

                            </Grid>
                            <Grid item xs={12}>

                            <Dialog
                                    open={this.state.viewReportFlag}
                                    onClose={(event) => this.handleDialogClose("view_report")}
                                    disableBackdropClick={true}
                                    disableEscapeKeyDown={true}
                                    className='customTable'
                                    PaperComponent={PaperComponent}
                                    scroll={'paper'}
                                >
                                    <DialogTitle className="msg-dialog-title" style={{ cursor: 'move' }} id="draggable-dialog-title" onClose={(event) => this.handleDialogClose("view_report")}>{language_labelsTable.report_table_header}</DialogTitle>
                                    <DialogContent>
                                    {<MuiThemeProvider theme={this.getMuiTheme()}> <MUIDataTable
                                                    data={this.state.data_arr}
                                                    columns={columns}
                                                    options={options}
                                                /></MuiThemeProvider>}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button disableRipple onClick={(event) => this.handleDialogClose("view_report")} className="msg-btn">
                                            {language_labels.ok_btn}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                            </Grid> 

                            <Grid item xs={2} className="country-grid">
                        {/* Region list is being displayed below */}
                                <List className="country-list"> 
                                    {this.state.data && Object.keys(this.state.data).sort().map((item, i) => {
                                        return (
                                            <ListItem
                                                button
                                                disableRipple={true}
                                                onClick={(event) => this.handleCountrySelection(item)}
                                                key={i}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography component="h5" className={item === this.state.selectedCountry ? "country-list-text-selected" : "country-list-text"}>
                                                            {item}
                                                        </Typography>
                                                    } />
                                            </ListItem>

                                        )
                                    })}
                                </List>
                                <Dialog
                                    open={this.state.ap_ee_flag}
                                    onClose={(event) => this.handleDialogClose("ap_ee_flag")}
                                    disableBackdropClick={true}
                                    disableEscapeKeyDown={true}
                                    className="msg-paper"
                                    PaperComponent={PaperComponent}
                                    scroll={'paper'}
                                >
                                    <DialogTitle className="msg-dialog-title" style={{ cursor: 'move' }} id="draggable-dialog-title">{language_labels.dialog_title_1}</DialogTitle>
                                    <DialogContent>
                                        <Typography className="msg-txt">{this.state.ap_ee_val}</Typography> 
                                        <p  className="msg-txt" style={{marginTop:'20px'}}dangerouslySetInnerHTML={{ __html: language_labelsTable["disclaimer"] }}></p>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button disableRipple onClick={(event) => this.handleDialogClose("ap_ee_flag")} className="msg-btn">
                                            {language_labels.ok_btn}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>

                            <Grid item xs={4} className="country-grid">

                                <Grid container className="market-grid">
                                    {this.state.market_list && this.state.market_list.map((item, i) => {
                                        return (
                                            <Grid item xs={6}>
                                                <Button
                                                    // className={item === this.state.selectedMarket ? "country-list-text-selected" : "country-list-text"}
                                                    className="market-list"
                                                    disableRipple
                                                    onClick={(event) => this.handleMarketSelection(item.market_name)}
                                                    key={i}
                                                    disabled={item.display === false ? true : false}
                                                >
                                                    {item.market_name}
                                                </Button>

                                            </Grid>
                                        )
                                    })}

                                </Grid>
                            </Grid>
                            <Grid item xs={6} className="regions-grid">
                                {this.state.selectedCountry && this.state.selectedMarket && <Grid container className="reg-container" >
                                    {this.state.region_list.map((opt, index) => {
                                        return (
                                            <Grid item xs={4} key={index}>
                                                <Button
                                                    className="region-list"
                                                    disableRipple
                                                    disabled={opt.display}
                                                    onClick={(event) => this.handleRegionSelection(opt)}
                                                >
                                                    {opt.company_name}
                                                </Button>

                                            </Grid>
                                        )
                                    })}
                                    <Dialog
                                        open={this.state.dialogOpen}
                                        onClose={(event) => this.handleDialogClose("dialogOpen")}
                                        scroll={'paper'}
                                        disableBackdropClick={true}
                                        disableEscapeKeyDown={true}
                                        PaperComponent={PaperComponent}
                                    >
                                        <DialogTitle className="msg-dialog-title" style={{ cursor: 'move' }} id="draggable-dialog-title">{language_labels.form_dialog_heading}</DialogTitle>
                                        <DialogContent dividers={true} className="form-dialog-content">
                                            <DialogContentText className="msg-container">
                                                {language_labels.msg_container_text}
                                            </DialogContentText>
                                            {this.state.selected_company && <Grid container>
                                                {/* <Grid item xs={5} className="input-label-grid">
                                                <Typography className="form-txt-label">Client</Typography>
                                            </Grid>
                                            <Grid item xs={7} className="input-field-grid">
                                                <TextField
                                                    value={this.state.selected_company.company_code}
                                                    className="input-field-div"
                                                    id="client"
                                                    inputProps={{
                                                        style: {
                                                            height: 15,
                                                            fontSize: 14,
                                                            fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                                                            fontWeight: 400
                                                        }
                                                    }}
                                                />
                                            </Grid> */}
                                                <Grid item xs={5} className="input-label-grid">
                                                    <Typography className="form-txt-label">{language_labels.text_label_pmiEntity}</Typography>
                                                </Grid>
                                                <Grid item xs={7} className="input-field-grid">
                                                    <TextField
                                                        value={this.state.selected_company.company_name}
                                                        className="input-field-div"
                                                        id="pmi-entity"
                                                        disabled
                                                        inputProps={{
                                                            style: {
                                                                height: 15,
                                                                fontSize: 14,
                                                                fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                                                                fontWeight: 400
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                {/* <Grid item xs={5} className="input-label-grid">
                                                <Typography className="form-txt-label">PMI e-mail address for only .pdf invoices</Typography>
                                            </Grid>
                                            <Grid item xs={7} className="input-field-grid">
                                                <TextField
                                                    value={this.state.selected_company.email_mailing}
                                                    className="input-field-div"
                                                    id="pmi-email"
                                                    inputProps={{
                                                        style: {
                                                            height: 15,
                                                            fontSize: 14,
                                                            fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                                                            fontWeight: 400
                                                        }
                                                    }}
                                                />
                                            </Grid> */}
                                                <Grid item xs={5} className="input-label-grid">
                                                    <Typography className="form-txt-label">{language_labels.supplier_name_label}</Typography>
                                                </Grid>
                                                <Grid item xs={7} className="input-field-grid">
                                                    <TextField
                                                        value={this.props.user.vendor_name}
                                                        className="input-field-div"
                                                        id="supplier_name"
                                                        name="supplier_name"
                                                        disabled
                                                        inputProps={{
                                                            style: {
                                                                height: 15,
                                                                fontSize: 14,
                                                                fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                                                                fontWeight: 400
                                                            }
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={5} className="input-label-grid">
                                                    <Typography className="form-txt-label">{language_labels.supplier_address_label}</Typography>
                                                </Grid>
                                                <Grid item xs={7} className="input-field-grid">
                                                    <TextField
                                                        value={this.props.user.supplier_address}
                                                        className="input-field-div"
                                                        id="supplier_address"
                                                        name="supplier_address"
                                                        disabled
                                                        inputProps={{
                                                            style: {
                                                                height: 15,
                                                                fontSize: 14,
                                                                fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                                                                fontWeight: 400
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={5} className="input-label-grid">
                                                    <Typography className="form-txt-label">{language_labels.supplier_vat_label}</Typography>
                                                </Grid>
                                                <Grid item xs={7} className="input-field-grid">
                                                {(this.state.vat_number && this.state.vat_number.length === 1) && <TextField
                                                        className="input-field-div"
                                                        value={this.state.vat_number}
                                                        id="vat_number"
                                                        name="vat_number"
                                                        disabled
                                                        inputProps={{
                                                            style: {
                                                                height: 15,
                                                                fontSize: 14,
                                                                fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                                                                fontWeight: 400
                                                            }
                                                        }}
                                                    />}
                                                {(this.state.vat_number && this.state.vat_number.length > 1) &&
                                                        <FormControl style={{width:"100%"}} error={this.state.vat_number_err ? true : false}>
                                                            <Select
                                                                className="multi-select-vat"
                                                                multiple
                                                                required
                                                                value={this.state.selected_vat_number}
                                                                onChange={(event) => this.handleVat(event)}
                                                                // inputProps={{
                                                                //     style: {
                                                                //         height: 15,
                                                                //         fontSize: 14,
                                                                //         fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                                                                //         fontWeight: 400
                                                                //     }
                                                                // }}
                                                                input={<Input id="select-multiple-chip" placeholder={"Select VAT Number"} />}
                                                                renderValue={(selected) => {
                                                                    if (selected.length === 0) {
                                                                        return (<em>{"Select VAT Number"}</em>);
                                                                    }
                                                                    return (<div >
                                                                        {selected.map((value) => (
                                                                            <Chip key={value} label={value} />
                                                                        ))}
                                                                    </div>)
                                                                }
                                                            }
                                                            MenuProps={MenuProps}
                                                            >
                                                                {console.log(this.state.vat_number)}
                                                                {/* {this.state.vat_number.map((name, i) => {

                                                                    return (
                                                                    
                                                                    <MenuItem key={i} value={name}>
                                                                        {console.log(name)}
                                                                        {name}
                                                                    </MenuItem>)
                                                                })} */}
                                                                 {this.state.vat_number && this.state.vat_number.map((name, i) => (
                                                            <MenuItem key={i} value={name}>
                                                                <Checkbox checked={this.state.selected_vat_number.indexOf(name) > -1} />
                                                                <ListItemText primary={name} />
                                                            </MenuItem>
                                                        ))}
                                                            </Select>
                                                            <FormHelperText>{this.state.vat_number_err_msg}</FormHelperText>
                                                        </FormControl>
                                                    }
                                                </Grid>
                                                <Grid item xs={5} className="input-label-grid">
                                                    <Typography className="form-txt-label">{language_labels.full_name_label}<sup className="mandatory_asterisk">{language_labels.full_name_mandatory} </sup></Typography>
                                                </Grid>
                                                <Grid item xs={7} className="input-field-grid">
                                                    <TextField
                                                        className="input-field-div"
                                                        value={this.state.full_name}
                                                        error={this.state.field_error}
                                                        helperText={this.state.error_text}
                                                        id="full_name"
                                                        name="full_name"
                                                        onChange={this.handleTextChange}
                                                        inputRef={this.textInput}

                                                        inputProps={{
                                                            style: {
                                                                height: 15,
                                                                fontSize: 14,
                                                                fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                                                                fontWeight: 400
                                                            }
                                                        }}
                                                    />
                                                </Grid>

                                            </Grid>}

                                            {/* <List>
                                            {language_labels.bottom_text.map((el, k) => {
                                                return (
                                                    <ListItem className="bottom-list-item" key={k}>
                                                        <ListItemIcon>
                                                            <ArrowRightIcon />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={<Typography className="bottom-list-text">{el.text}</Typography>}
                                                        />
                                                    </ListItem>
                                                )
                                            })}
                                        </List> */}
                                            <h5 className="agreed-terms-heading">
                                                {language_labels.agreed_terms_label}
                                            </h5>
                                            <List>
                                                {agreed_terms.map((point, ind) => {
                                                    return (<><ListItem className="bottom-list-item" key={ind}>
                                                        <ListItemIcon>
                                                            <ArrowRightIcon />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={<Typography className="bottom-list-text">{point.text}</Typography>}
                                                        />
                                                    </ListItem>
                                                        {point.subtext &&
                                                            <List className="subtext-list">
                                                                {point.subtext.map((sub, j) => {
                                                                    return (<ListItem className="bottom-list-item" key={j}>
                                                                        <ListItemIcon>
                                                                            <ArrowRightIcon />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary={<Typography className="bottom-list-text">{sub.text}</Typography>}
                                                                        />
                                                                    </ListItem>)
                                                                })}

                                                            </List>}
                                                    </>

                                                    )
                                                })}
                                            </List>
                                            <Grid container>
                                                <Grid item xs={5}>
                                                    <Typography className="form-txt-label">{language_labels.digital_sig}</Typography>
                                                </Grid>
                                                <Grid item xs={7} className="input-field-grid">
                                                    <label
                                                        className={this.state.checked_class}
                                                        onAnimationEnd={() => this.setState({ checkbox_class: "signature-label" })}
                                                    >
                                                        <Checkbox
                                                            className="signature-checkbox"
                                                            required={true}
                                                            checked={this.state.digitalSignature}
                                                            onChange={this.handleCheckBoxChange}
                                                            name="digitalSignature"
                                                            disableRipple
                                                            inputRef={this.checkboxInput}
                                                        />
                                                        {language_labels.declaration_statement}
                                                    </label>
                                                    {this.state.checkbox_error && <FormHelperText style={{ color: "red" }}>{language_labels.terms_agree}</FormHelperText>}

                                                </Grid>
                                            </Grid>

                                        </DialogContent>
                                        <DialogActions>
                                            {/* disabled={!this.state.digitalSignature} */}
                                            <Button onClick={(event) => this.handlePdfSubmit()} className="msg-btn">
                                                {language_labels.submit_btn}
                                            </Button>
                                            <Button onClick={(event) => this.handleDialogClose("dialogOpen")} className="msg-btn">
                                                {language_labels.cancel_btn}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Dialog
                                        open={this.state.msgOpen}
                                        onClose={(event) => this.handleDialogClose("msgOpen")}
                                        disableBackdropClick={true}
                                        disableEscapeKeyDown={true}
                                        className="msg-paper"
                                        scroll={'paper'}
                                        PaperComponent={PaperComponent}
                                    >
                                        <DialogTitle className="msg-dialog-title" style={{ cursor: 'move' }} id="draggable-dialog-title">{language_labels["dialog_title_1"]}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText className="msg-container">
                                                {language_labels.msg_dialog_heading} 
                                            </DialogContentText>
                                            {this.state.selected_company && <Grid container>
                                                <Grid item xs={4} >
                                                    <Typography className="msg-txt-label">{language_labels.mail_address}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography className="msg-txt">{this.state.selected_company.mailing_address}</Typography>

                                                </Grid>
                                                <Grid item xs={12} >
                                                <p  className="msg-txt" style={{marginTop:'20px'}}dangerouslySetInnerHTML={{ __html: language_labelsTable["disclaimer"] }}></p>
                                                </Grid>
                                            </Grid>}
                                        </DialogContent>
                                        <DialogActions>
                                            <Button disableRipple onClick={(event) => this.handleDialogClose("msgOpen")} className="msg-btn">
                                                {language_labels.ok_btn}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>

                                    <Dialog
                                        open={this.state.submit_msg}
                                        onClose={(event) => this.handleDialogClose("submit-msg")}
                                        disableBackdropClick={true}
                                        disableEscapeKeyDown={true}
                                        className="msg-paper"
                                        PaperComponent={PaperComponent}
                                        scroll={'paper'}
                                    >
                                        <DialogTitle className="msg-dialog-title" style={{ cursor: 'move' }} id="draggable-dialog-title">{language_labels["success_pdf"]}</DialogTitle>
                                        <DialogContent>
                                            <Typography className="msg-txt">{language_labels.success_txt}</Typography>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button disableRipple onClick={(event) => this.handleDialogClose("submit-msg")} className="msg-btn">
                                                {language_labels.ok_btn}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Dialog
                                        open={this.state.aff_flag}
                                        onClose={(event) => this.handleDialogClose("aff_flag")}
                                        disableBackdropClick={true}
                                        disableEscapeKeyDown={true}
                                        className="msg-paper"
                                        PaperComponent={PaperComponent}
                                        scroll={'paper'}
                                    >
                                        <DialogTitle className="msg-dialog-title" style={{ cursor: 'move' }} id="draggable-dialog-title">{language_labels.e_pdf_not_allowed}</DialogTitle>
                                        <DialogContent>
                                            <Typography className="msg-txt">{language_labels.aff_text}</Typography>
                                            <p  className="msg-txt" style={{marginTop:'20px'}}dangerouslySetInnerHTML={{ __html: language_labelsTable["disclaimer"] }}></p>

                                        </DialogContent>
                                        <DialogActions>
                                            <Button disableRipple onClick={(event) => this.handleDialogClose("aff_flag")} className="msg-btn">
                                                {language_labels.ok_btn}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Dialog
                                        open={this.state.msg_flag}
                                        onClose={(event) => this.handleDialogClose("msg_flag")}
                                        disableBackdropClick={true}
                                        disableEscapeKeyDown={true}
                                        className="msg-paper"
                                        PaperComponent={PaperComponent}
                                        scroll={'paper'}
                                    >
                                        <DialogTitle className="msg-dialog-title" style={{ cursor: 'move' }} id="draggable-dialog-title">{language_labels.e_pdf_not_allowed}</DialogTitle>
                                        <DialogContent>
                                            <Typography className="msg-txt">{this.state.msg_val}</Typography>
                                            <p  className="msg-txt" style={{marginTop:'20px'}}dangerouslySetInnerHTML={{ __html: language_labelsTable["disclaimer"] }}></p>

                                        </DialogContent>
                                        <DialogActions>
                                            <Button disableRipple onClick={(event) => this.handleDialogClose("msg_flag")} className="msg-btn">
                                                {language_labels.ok_btn}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Dialog
                                        open={this.state.latam_flag}
                                        onClose={(event) => this.handleDialogClose("ap_ee_flag")}
                                        disableBackdropClick={true}
                                        disableEscapeKeyDown={true}
                                        className="msg-paper"
                                        PaperComponent={PaperComponent}
                                        scroll={'paper'}
                                    >
                                        <DialogTitle className="msg-dialog-title" style={{ cursor: 'move' }} id="draggable-dialog-title">{language_labels.dialog_title_1}</DialogTitle>
                                        <DialogContent>
                                            <Typography className="msg-txt">{this.state.msg_val}</Typography>
                                            <p  className="msg-txt" style={{marginTop:'20px'}}dangerouslySetInnerHTML={{ __html: language_labelsTable["disclaimer"] }}></p>

                                        </DialogContent>
                                        <DialogActions>
                                            <Button disableRipple onClick={(event) => this.handleDialogClose("ap_ee_flag")} className="msg-btn">
                                                {language_labels.ok_btn}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>

                                </Grid>}
                            </Grid>
                        </Grid>
                        <FloatingButtons {...this.props} />
                    </div>
                </div>}
                {(this.props.user.role !== "vendor") && <NotFound history={this.props.history} />}
                {/* || this.state.access_cookie !== this.props.user.accessToken */}
            </div>

        );
    }
}

RegionList.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    user: state.user
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(AllActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(RegionList);