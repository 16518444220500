import React, { Component } from 'react';
import '../login.scss';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as AllActions from '../actions'
import PropTypes from 'prop-types'
import Popover from "@material-ui/core/Popover";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import pmiLogo from './../assests/pmi-logo.svg'
import { labels } from './../resources';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TextField from '@material-ui/core/TextField';
import exampleVid from './../assests/VQP_PMI_Video_1.mp4';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AjaxService from "../services/AjaxServices";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import * as _ from 'lodash';
import Snackbar from '@material-ui/core/Snackbar';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HelpIcon from '@material-ui/icons/Help';
import FormControl from '@material-ui/core/FormControl';
import TranslateIcon from '@material-ui/icons/Translate';
// import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

// import Cookies from 'universal-cookie';
//import Cookies from 'js-cookie';
require('dotenv').config()
//dummy line
//push
// const cookies = new Cookies();
// var labels = require('./../resources.json')

const styles = (theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

class NewLoginPage extends Component {
    constructor(props) {
        super(props);

        // this.vidRef = React.createRef();
        this.handleClick = this.handleClick.bind(this);
        this.handleClosePop = this.handleClosePop.bind(this);
        this.state = {
            showVideo: false,
            loginCheckbox: false,
            selectedEmail: "",
            checkbox_class: "form-control-label",
            textfield_class: "id-field",
            errorText: "",
            showVendorDialog: false,
            email_response: "",
            validated_email: "",
            selected_email_response: "",
            validate: false,
            validateMessage: "",
            loadingOpen: false,
            waitmsg: false,
            waitmsg_text:"",
            openInvite: false,
            helpComments: "",
            user_vat_number: "",
            supplier_name_help: "",
            userEmailHelp: "",
            formatError: false,
            format_error_text: "",
            emailCheckFlag: undefined,
            apiFlag: false,
            issue_error_text: "",
            issue_error: false,
            comments_error: false,
            comments_error_text: "",
            support_email: "",
            selectedHelpIssue: "",
            anchorEl: null,
            open: false,
            id: undefined
        };
    }

    handleClick(event) {
        console.log("here")
        console.log(event.currentTarget)
        this.setState({ anchorEl: event.currentTarget, open: Boolean(event.currentTarget), id: "simple-popover" });
    }

    handleClosePop(event) {
        console.log("here leave")
        console.log(event.currentTarget)
        this.setState({ anchorEl: event.currentTarget, open: false, id: undefined });
    }

    componentDidMount() {
        this.props.actions.return_default()
    }

    handleVideoClick = () => {
        this.setState(
            {
                showVideo: true,
                showDialog: false,
                showTermsDialog: false
            }
        )
        // this.vidRef.current.play();/
    }
    handleRegisterCLick = () => {
        this.setState({ showDialog: true })
    }
    handleClickOk = () => {
        const url = 'https://www.ibm.com/account/reg/us-en/signup?formid=urx-19776';

        window.open(url)
        this.setState({ showDialog: false })
    }
    clickFAQ = () => {
        const faq_url = "https://www.ibm.com/ibmid/myibm/help/us/helpdesk.html"
        window.open(faq_url)
    }

    handleCloseClick = () => {
        this.setState(
            {
                showVideo: false,
            }
        )
    }

    handleClose = () => {
        if (this.state.category === "no-access") {
            this.setState(
                {
                    showDialog: false,
                    showTermsDialog: false,
                    showVendorDialog: false,
                    openHelp: false,
                    openInvite: false,
                    successDialog: false,
                    field_error: false,
                    emailCheckFlag: undefined,
                    apiFlag: false,
                    userEmailHelp: "",
                    selectedHelpIssue: "",
                    helpComments: "",
                    issue_error: false,
                    comments_error: false
                }, function () {
                    this.props.history.length = 0;
                    sessionStorage.clear();
                    localStorage.clear()
                    window.location = '/logout'
                }
            )
        } else {
            this.setState(
                {
                    showDialog: false,
                    showTermsDialog: false,
                    showVendorDialog: false,
                    openHelp: false,
                    openInvite: false,
                    successDialog: false,
                    field_error: false,
                    emailCheckFlag: undefined,
                    apiFlag: false,
                    userEmailHelp: "",
                    selectedHelpIssue: "",
                    helpComments: "",
                    issue_error: false,
                    comments_error: false
                }
            )
        }
    }
    handleCloseFlag = () => {
        this.setState({ validate: false, validateMessage: "" })
    }

    handleNav = (e) => {

        this.Ajax = new AjaxService();
        console.log(this.props.user.entered_email)
        if ((this.state.loginCheckbox === false || this.state.selectedEmail === "" || this.state.errorText === labels[this.props.user.language].login_page.valid_email) && (localStorage.getItem('validLogin') == 0 || localStorage.getItem('validLogin') == null)) {
            if (this.state.loginCheckbox === false) {
                this.setState({ checkbox_class: "form-control-label-shake", category: "checkbox-popup", showTermsDialog: true })
            }
            if (this.state.selectedEmail === "") {
                //console.log("inside textfield if")
                this.setState({ textfield_class: "id-field-focus" })
            }
        } else {

            this.Ajax.getLoginDetails()
                // .then(response => response.json())
                .then(res => {
                    // console.log(res)
                    var userLog = {}
                    if (res.status === 401) {
                        localStorage.setItem('validLogin', 1);
                        sessionStorage.setItem('checkLogin', 1)
                        window.location = '/login'
                    }
                    else {
                        localStorage.setItem('validLogin', 0);
                        sessionStorage.setItem('checkLogin', 0)
                        localStorage.setItem('user_email', res._json.email)
                        localStorage.setItem('username', res._json.displayName)
                        this.setState({ loadingOpen: true })
                        if (localStorage.getItem("entered_email").toLowerCase() === res._json.email.toLowerCase()) {
                            this.Ajax.getUserDetails()
                                .then(response => response.json())
                                .then(details => {
                                    console.log("cw api response", details)
                                    console.log(details.hasOwnProperty("role"))
                                    if (!details.hasOwnProperty("error_msg")) {
                                        console.log("no error msg")

                                        if (details instanceof Array && details[0].hasOwnProperty("vendors")) {
                                            var userLog = {
                                                "loggedinTime": new Date(),
                                                "role": "vendor",
                                                "successful_attempt": true,
                                                "description": "Successfully logged in"
                                            }
                                            console.log("userlog", userLog)
                                            this.Ajax.saveUserLogs(userLog)
                                                .then(response => response.json())//
                                                .then(logs_Response => {
                                                    console.log(logs_Response)
                                                    var login_response_obj = {
                                                        "response_arr": details[0],
                                                        "validated_email": res._json.email,
                                                        "role": "vendor",
                                                        "username": res._json.displayName,
                                                        "accessToken": res.accessToken
                                                    }
                                                    this.setState({ loadingOpen: false })
                                                    this.props.actions.store_login_response(login_response_obj)
                                                    // if (details[0].role === "vendor") {
                                                    this.props.history.push(`/home`)
                                                    // } else if (details[0].role === "admin") {
                                                    //     this.props.history.push(`/business-admin-page`)
                                                    // }

                                                }).catch(err => {
                                                    console.log(err)
                                                    alert(labels[this.props.user.language].login_page.login_failed_error)//
                                                });

                                        } else {
                                            console.log("cw-here")
                                            if (details.hasOwnProperty("role")) {
                                                if (details.role === "support") {

                                                    var userLog = {
                                                        "loggedinTime": new Date(),
                                                        "role": "support",
                                                        "successful_attempt": true,
                                                        "description": "Successfully logged in"
                                                    }
                                                    this.Ajax.saveUserLogs(userLog)
                                                        .then(response => response.json())//
                                                        .then(logs_Response => {

                                                            var login_response_obj = {
                                                                "response_arr": details.data,
                                                                "validated_email": res._json.email,
                                                                "role": "support",
                                                                "username": res._json.displayName,
                                                                "accessToken": res.accessToken
                                                            }
                                                            this.setState({ loadingOpen: false })
                                                            this.props.actions.store_login_response(login_response_obj)
                                                            this.props.history.push(`/home`)
                                                        })


                                                } else {
                                                    var userLog = {
                                                        "loggedinTime": new Date(),
                                                        "role": "admin",
                                                        "successful_attempt": true,
                                                        "description": "Successfully logged in"
                                                    }

                                                    this.Ajax.saveUserLogs(userLog)
                                                        .then(response => response.json())//
                                                        .then(logs_Response => {
                                                            var login_response_obj = {
                                                                "response_arr": "",
                                                                "validated_email": res._json.email,
                                                                "role": "admin",
                                                                "username": res._json.displayName,
                                                                "accessToken": res.accessToken
                                                            }
                                                            this.setState({ loadingOpen: false })
                                                            this.props.actions.store_login_response(login_response_obj)
                                                            this.props.history.push(`/business-admin-page`)
                                                        })


                                                }
                                            } else {
                                                this.setState({ category: "no-access", showTermsDialog: true, loadingOpen: false })
                                            }

                                        }
                                    } else {
                                        console.log("support here ")
                                        if (details.hasOwnProperty("error")) {
                                            this.setState({
                                                validate: true,
                                                validateMessage: labels[this.props.user.language].login_page.fetch_details_error,
                                                loadingOpen: false
                                            })
                                        } else {
                                            if (details.hasOwnProperty("error_msg")) {
                                                console.log(details.error_msg)
                                                this.setState({ category: "no-access", showTermsDialog: true, loadingOpen: false })
                                            }
                                            // if (details.role && details.role === "support") {
                                            //     var login_response_obj = {
                                            //         "response_arr": details.data,
                                            //         "validated_email": res._json.email,
                                            //         "role": details.role,
                                            //         "username": res._json.displayName,
                                            //         "accessToken": res.accessToken
                                            //     }
                                            //     this.setState({ loadingOpen: false })
                                            //     this.props.actions.store_login_response(login_response_obj)
                                            //     this.props.history.push(`/home`)
                                            // }
                                        }
                                    }

                                }).catch(err => {
                                    console.log(err);

                                });
                        } else {
                            var userLog = {

                                "loggedinTime": new Date(),
                                "role": "",
                                "successful_attempt": false,
                                "description": "Email Id doesn't match"//
                            }
                            this.Ajax.saveUserLogs(userLog)
                                .then(response => response.json())
                                .then(logs_response => {
                                    console.log(logs_response)
                                    this.setState({ category: "no-access", showTermsDialog: true })
                                })
                        }

                    }
                    // 
                })
        }
    }
    handleTermsClick = (terms) => {
        this.setState(
            {
                showTermsDialog: true,
                category: terms
            }
        )
    }
    handleCheckBoxChange = (event) => {
        //console.log(event.target.checked)
        this.setState({ loginCheckbox: event.target.checked })
    }
    handleSpinnerClose = () => {
        this.setState({ loadingOpen: false })
    }
    handleEmailChange = (event) => {

        const emailRegex = new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]');
        if (event.target.value.match(emailRegex)) {
            localStorage.setItem('entered_email', event.target.value);
            this.props.actions.login_email(event.target.value)
            if (this.props.user.language === "ENGLISH") {
                this.setState({ errorText: '', selectedEmail: event.target.value, textfield_class: "id-field" })
            } else {
                this.setState({ errorText: '', selectedEmail: event.target.value, textfield_class: "id-field-esp" })
            }

        } else {

            this.setState({ errorText: labels[this.props.user.language].login_page.valid_email, selectedEmail: event.target.value, textfield_class: "id-field-focus" })
        }

    }
    handleSelectVendor = () => {

    }

    selected_vendor = (selected_email_response) => {
        this.setState({
            selected_email_response: selected_email_response
        })
    }
    clickHereToInvite = () => {
        this.setState({
            openInvite: true
        })
    }
    clickHereForHelp = () => {
        this.setState({
            openHelp: true
        })
    }
    handleInviteValuesChange = (event) => {
        console.log(event.target.name)
        const emailRegex = new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]');
        if (event.target.name === "userEmailHelp") {
            if (event.target.value.match(emailRegex)) {
                this.setState({
                    formatError: false,
                    format_error_text: "",
                    [event.target.name]: event.target.value
                })
            } else {
                this.setState({
                    formatError: true,
                    format_error_text: labels[this.props.user.language]["login_page"].valid_email,
                    [event.target.name]: event.target.value
                })
            }
        } else if (event.target.name === "helpComments") {
            this.setState({
                comments_error: false,
                comments_error_text: "",
                [event.target.name]: event.target.value
            })
        } else {
            this.setState({
                [event.target.name]: event.target.value
            })
        }

    }

    clickInviteOk = () => {

        if (this.state.userEmailHelp === "") {
            this.setState({
                field_error: true,
                error_text: labels[this.props.user.language]["login_page"].req
            })
        } else if (this.state.selectedHelpIssue === "") {
            this.setState({
                issue_error: true,
                issue_error_text: labels[this.props.user.language]["login_page"].req
            })
        } else if (this.state.helpComments === "") {
            this.setState({
                comments_error: true,
                comments_error_text: labels[this.props.user.language]["login_page"].req
            })

        } else {
            this.Ajax = new AjaxService();
            console.log(this.state.userEmailHelp)
            this.setState({
                waitmsg: true,
                waitmsg_text:labels[this.props.user.language]["login_page"].submit_wait
            })
            this.Ajax.submitHelp(this.state.userEmailHelp, this.state.selectedHelpIssue, this.state.helpComments)
                .then(response => response.json())
                .then(res => {
                    if (res.hasOwnProperty("error")) {
                        this.setState({
                            showTermsDialog: true,
                            category: "api-error",
                            waitmsg: false,
                            waitmsg_text:""
                        })
                    } else {
                        console.log(res)
                        this.setState({
                            field_error: false,
                            emailCheckFlag: undefined,
                            apiFlag: false,
                            userEmailHelp: "",
                            selectedHelpIssue: "",
                            helpComments: "",
                            issue_error: false,
                            comments_error: false,
                            successDialog: true,
                            waitmsg: false,
                            waitmsg_text:""
                        })
                    }
                })

        }
    }
    handleLanguageChange = (code) => {

        console.log(code)
        this.props.actions.change_language(code)
        this.setState({
            selected_language: code,
            open: false, id: undefined
        })
        sessionStorage.setItem("language", code)
    }
    handleVerifyEmail = () => {
        this.Ajax = new AjaxService();
        console.log(this.state.userEmailHelp)
        this.Ajax.verifyUser(this.state.userEmailHelp)
            .then(response => response.json())
            .then(res => {
                if (res.hasOwnProperty("error")) {
                    this.setState({
                        showTermsDialog: true,
                        category: "api-error"
                    })
                } else {
                    console.log(res)
                    this.setState({
                        emailCheckFlag: res.status,
                        apiFlag: true,
                        support_email: res.help_email,
                        support_email_popup:res.need_help_query
                    })
                }

            })
    }

    handleChangeHelp = (event) => {
        console.log(event.target.value)
        this.setState({
            selectedHelpIssue: event.target.value,
            issue_error: false,
            issue_error_text: ""
        })
    }
    render() {
        const { user, classes } = this.props;

        // const classes = useStyles();
        // Below list is the full list, add more language as per requirement. Here and in navbar.js
        // var countires = ["ENGLISH", "ESPAÑOL", "PORTUGUÊS", "FRANÇAIS", "БЪЛГАРСКИ", "POLSKI", "BAHASA", "Русский", "한국어", "中文", "日本語", "ไทย"]
        var countires = ["ENGLISH", "FRANÇAIS", "POLSKI", "BAHASA", "한국어", "中文", "日本語", "ไทย",  "ESPAÑOL"]
        var language_labels = labels[this.props.user.language].login_page;

        if (sessionStorage.getItem('checkLogin') == 1) {
            this.handleNav();
        }


        // if (localStorage.getItem('validLogin') == null) {
        //     this.handleNav();
        // }

        return (
            <div className="container-fluid no-padding">

                <div className="new-login-div">
                    <AppBar className="login-app-bar">
                        <Toolbar className="tool-bar">
                            <img src={pmiLogo} />
                            <div className="translate-div">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    aria-owns={this.state.open ? 'simple-popover' : undefined}
                                    onClick={this.handleClick}
                                    className='lang-main-btn'
                                >

                                    <TranslateIcon />
                                </Button>
                                <Popover
                                    id="simple-popover"
                                    open={this.state.open}
                                    anchorEl={this.state.anchorEl}
                                    onClose={this.handleClosePop}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    PaperProps={{
                                        style: { width: '25%', left: "890px" },
                                    }}
                                >

                                    <div>
                                        <h5 className='language-change-heading' >Change the language</h5>
                                        <Grid container>
                                            {countires.map((country, i) => {
                                                return (<Grid item xs={4} key={i} className='lang-grid-item'>
                                                    <Button className='lang-btn' onClick={(event) => this.handleLanguageChange(country)}>
                                                        {country}
                                                    </Button>
                                                </Grid>)
                                            }
                                            )}
                                        </Grid>
                                    </div>

                                </Popover>


                            </div>
                        </Toolbar>
                    </AppBar>
                    <Snackbar style={{ whiteSpace: "pre-wrap" }} ContentProps={{

                    }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}

                        open={this.state.validate}
                        onClose={this.handleCloseFlag}
                        autoHideDuration={6000}
                        message={this.state.validateMessage}
                    />

                    <Snackbar style={{ whiteSpace: "pre-wrap" }} ContentProps={{

                    }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}

                        open={this.state.waitmsg}
                        onClose={this.handleCloseFlag}
                        autoHideDuration={6000}
                        message={this.state.waitmsg_text}
                    />
                    <Backdrop disableBackdropClick={true} open={this.state.loadingOpen} onClick={(event) => this.handleSpinnerClose()}>
                        <CircularProgress className="circular-spinner" />
                    </Backdrop>
                    {/* <div className="backdrop-div">
                    <Backdrop disableBackdropClick={true} open={this.state.needhelpLoading}  onClick={(event) => this.handleSpinnerClose()}>
                        <CircularProgress className="circular-spinner" />
                    </Backdrop>
                    </div> */}

                    <Grid container>

                        <Grid item xs={12} sm={12} md={4} lg={4} className="left-grid-login">
                            <div className={this.props.user.language.toLowerCase() === "english" ? "left-inside-div" : "left-inside-div-esp"}>
                                <h1 className={this.props.user.language.toLowerCase() === "english" ? "welcome-heading" : "welcome-heading-esp"}>{language_labels.app_heading}</h1>
                                <TextField
                                    value={this.state.selectedEmail}
                                    onChange={(event) => this.handleEmailChange(event)}
                                    className={this.state.textfield_class}
                                    error={this.state.errorText.length === 0 ? false : true}
                                    id="emailId"
                                    placeholder="Email"
                                    helperText={this.state.errorText}
                                    inputProps={{
                                        style: {
                                            color: "#fff",
                                            paddingLeft: "5px",
                                            fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                                        }
                                    }}
                                />

                                <h6 className="browser-heading">{language_labels.supporting_environment}</h6>

                                <FormControlLabel
                                    className={this.state.checkbox_class}
                                    onAnimationEnd={() => this.setState({ checkbox_class: "form-control-label" })}
                                    control={
                                        <Checkbox
                                            checked={this.state.loginCheckbox}
                                            onChange={this.handleCheckBoxChange}
                                            name="loginCheckbox"
                                            disableRipple
                                        />
                                    }
                                    label={language_labels.terms_checkbox}
                                />


                                <Button className="login-btn" id="loginButton" onClick={(event) => this.handleNav(event)} endIcon={<ArrowForwardIcon />}>{language_labels.login_btn}</Button>
                                <div className='bottom-btn-grp'>
                                    <Button
                                        disableRipple={true}
                                        className={this.props.user.language.toLowerCase() === "english" ? "faq-bt" : "faq-bt-esp"}
                                        startIcon={< PersonAddIcon className="faq-icon" />}
                                        onClick={(event) => this.clickHereToInvite()}
                                    >
                                        {language_labels.invite_others}
                                    </Button>

                                    <Button
                                        disableRipple={true}
                                        className="faq-bt"
                                        startIcon={< HelpIcon className="faq-icon" />}
                                        onClick={(event) => this.clickHereForHelp()}
                                    >
                                        {language_labels.need_help}
                                    </Button>
                                </div>

                            </div>

                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <div className="video-div">
                                {this.state.showVideo && <div className="play-video-div">
                                    <IconButton className="close-btn" onClick={(event) => this.handleCloseClick()}>
                                        <CloseIcon />
                                    </IconButton>
                                    <video width="450" height="285" autoPlay={true} controls className="video-container">
                                        <source src={exampleVid} type="video/mp4" />
                                        {language_labels.not_supported_video}
                                    </video>
                                </div>}
                                {!this.state.showVideo && <div className="intro-div">

                                    <h5 className="intro-text">{language_labels.video_intro}</h5>
                                    <div className='video-btn-grp'>
                                        <Button
                                            disableRipple={true}
                                            className="video-button"
                                            startIcon={<PlayArrowIcon className="play-icon" />}
                                            onClick={(event) => this.handleVideoClick()}
                                        >
                                            {language_labels.video_btn}
                                        </Button>
                                        {/* dummy line */}
                                        <Button
                                            disableRipple={true}
                                            className="reg-button"
                                            startIcon={<ChevronRightIcon className="play-icon" />}
                                            onClick={(event) => this.handleRegisterCLick()}
                                        >
                                            {language_labels.reg_btn}
                                        </Button>
                                        <Button
                                            disableRipple={true}
                                            className="reg-button"
                                            startIcon={<QuestionAnswerIcon className="play-icon" />}
                                            onClick={(event) => this.clickFAQ()}
                                        >
                                            {language_labels.faq_btn}
                                        </Button>
                                    </div>

                                    {/* <Button
                                            disableRipple={true}
                                            className="faq-bt"
                                            startIcon={< PersonAddIcon className="faq-icon" />}
                                            onClick={(event) => this.clickHereToInvite()}
                                        >
                                            {language_labels.invite_others}
                                        </Button> */}

                                    {/* <Button
                                            disableRipple={true}
                                            className="faq-bt"
                                            startIcon={< HelpIcon className="faq-icon" />}
                                            onClick={(event) => this.clickHereForHelp()}
                                        >
                                            {language_labels.need_help}
                                        </Button> */}
                                    <Dialog
                                        open={this.state.openInvite}
                                        onClose={this.handleClose}
                                        disableBackdropClick={true}
                                        disableEscapeKeyDown={true}
                                    >
                                        <DialogTitle className="text-dialog">{language_labels.invite_heading}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText className="text-dialog">
                                                {language_labels.invite_matter}
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleClose} className="msg-btn">
                                                {language_labels.ok_btn}
                                            </Button>

                                        </DialogActions>
                                    </Dialog>
                                    <Dialog
                                        open={this.state.openHelp}
                                        onClose={this.handleClose}
                                        disableBackdropClick={true}
                                        disableEscapeKeyDown={true}
                                    >
                                        <DialogTitle className="text-dialog">{language_labels.submit_heading}</DialogTitle>
                                        {this.state.emailCheckFlag !== false && <DialogContent>
                                            <DialogContentText className="text-dialog">
                                                {language_labels.submit_matter}<br></br>
                                                {language_labels.submit_matter_2}

                                            </DialogContentText>
                                            <Grid container className="help-div">
                                                <Grid item xs={5} className="help-grid-item">
                                                    {language_labels.ur_email} <span style={{ fontSize: 12 }}>{language_labels.required} </span>:
                                                </Grid>
                                                <Grid item xs={7} className="help-grid-item">

                                                    <TextField
                                                        value={this.state.userEmailHelp}
                                                        className="email-div"
                                                        name="userEmailHelp"
                                                        onChange={(event) => this.handleInviteValuesChange(event)}
                                                        error={(this.state.userEmailHelp === "" && this.state.field_error === true) ? true : (this.state.formatError === true) ? true : false}
                                                        helperText={(this.state.userEmailHelp === "" && this.state.field_error === true) ? this.state.error_text : (this.state.formatError === true) ? this.state.format_error_text : ""}
                                                        disabled={this.state.emailCheckFlag}
                                                        inputProps={{
                                                            style: {
                                                                height: 15,
                                                                fontSize: 14,
                                                                fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                                                                fontWeight: 400,
                                                            }
                                                        }}
                                                    />
                                                    {(this.state.emailCheckFlag && this.state.apiFlag) && <CheckCircleOutlineIcon className="check-icon" />}
                                                    {(!this.state.emailCheckFlag && this.state.apiFlag) && <HighlightOffIcon className="wrong-icon" />}
                                                    {(!this.state.emailCheckFlag) && <Button className="verify-btn" disabled={(this.state.userEmailHelp !== "" && this.state.formatError === false) ? false : true} onClick={(event) => this.handleVerifyEmail()}>{language_labels.verify}</Button>}
                                                </Grid>


                                                <Grid item xs={5} className="help-grid-item">
                                                    {language_labels.issue} <span style={{ fontSize: 12 }}>{language_labels.required} </span> :
                                                </Grid>
                                                <Grid item xs={7} className="help-grid-item">
                                                    <FormControl error={this.state.issue_error}>
                                                        <RadioGroup value={this.state.selectedHelpIssue} onChange={(event) => this.handleChangeHelp(event)}>
                                                            <FormControlLabel disabled={this.state.emailCheckFlag ? false : true} value={"Unable to Log in"} control={<Radio />} label={language_labels.issue_1} />
                                                            <FormControlLabel disabled={this.state.emailCheckFlag ? false : true} value={"Unable to register"} control={<Radio />} label={language_labels.issue_2} />


                                                        </RadioGroup>
                                                        <FormHelperText>{this.state.issue_error_text}</FormHelperText>
                                                    </FormControl>

                                                    {
                                                        this.state.selectedHelpIssue === "Unable to register" && <p dangerouslySetInnerHTML={{ __html: language_labels.faqs_line }}></p>
                                                    }
                                                </Grid>
                                                <Grid item xs={5} className="help-grid-item">
                                                    {language_labels.comments} <span style={{ fontSize: 12 }}>{language_labels.required} </span> :
                                                </Grid>
                                                <Grid item xs={7} className="help-grid-item">
                                                    <TextField
                                                        value={this.state.helpComments}
                                                        className="input-field-div"
                                                        name="helpComments"
                                                        multiline
                                                        disabled={this.state.emailCheckFlag ? false : true}
                                                        error={this.state.comments_error}
                                                        helperText={this.state.comments_error_text}
                                                        onChange={(event) => this.handleInviteValuesChange(event)}
                                                        rows={4}
                                                        inputProps={{
                                                            maxLength: 250,
                                                            style: {
                                                                fontSize: 14,
                                                                fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                                                                fontWeight: 400,
                                                                paddingLeft: 5
                                                            }
                                                        }}
                                                    />

                                                </Grid>

                                            </Grid>

                                        </DialogContent>}
                                        {this.state.emailCheckFlag === false && <DialogContent>
                                            <DialogContentText className="text-dialog">
                                                {language_labels.not_allowed_1}
                                            </DialogContentText>
                                            <List className="subtext-list">
                                                <ListItem className="listItem">
                                                    <ListItemIcon>
                                                        <ArrowRightIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography className="list-ans" dangerouslySetInnerHTML={{ __html: language_labels.not_allowed_2 }}></Typography>

                                                        }

                                                    />
                                                </ListItem>
                                                <p>{language_labels.or}</p>
                                                <ListItem className="listItem" >
                                                    <ListItemIcon>
                                                        <ArrowRightIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography className="list-ans">{language_labels.not_allowed_3} <span style={{ color: "#0056b3" }}>{this.state.support_email_popup}</span> {language_labels.not_allowed_4}</Typography>

                                                        }

                                                    />
                                                </ListItem>
                                            </List>
                                        </DialogContent>}
                                        <DialogActions>
                                            {(this.state.emailCheckFlag !== false) && <Button disabled={(this.state.emailCheckFlag === true) ? false : true} onClick={this.clickInviteOk} className="msg-btn">
                                                {language_labels.ok_btn}
                                            </Button>}
                                            {(this.state.emailCheckFlag === false) && <Button onClick={this.handleClose} className="msg-btn">
                                                {language_labels.ok_btn}
                                            </Button>}
                                            {(this.state.emailCheckFlag !== false) && <Button onClick={this.handleClose} className="msg-btn">
                                                {language_labels.cancel_btn}
                                            </Button>}
                                        </DialogActions>
                                    </Dialog>
                                    <Dialog
                                        open={this.state.successDialog}
                                        onClose={this.handleClose}
                                        disableBackdropClick={true}
                                        disableEscapeKeyDown={true}
                                    >
                                        <DialogTitle className="text-dialog"> {"Success"}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText className="text-dialog">
                                                {language_labels.final_msg}
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>

                                            <Button onClick={this.handleClose} className="msg-btn">
                                                {language_labels.ok_btn}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>

                                    <Dialog
                                        open={this.state.showDialog}
                                        onClose={this.handleClose}
                                        disableBackdropClick={true}
                                        disableEscapeKeyDown={true}
                                    >
                                        <DialogTitle className="text-dialog">{language_labels.reg_btn}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText className="text-dialog">
                                                {language_labels.reg_dialog}
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleClickOk} className="msg-btn">
                                                {language_labels.ok_btn}
                                            </Button>
                                            <Button onClick={this.handleClose} className="msg-btn">
                                                {language_labels.cancel_btn}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>

                                    <Dialog
                                        open={this.state.showTermsDialog}
                                        onClose={this.handleClose}
                                        disableBackdropClick={true}
                                        disableEscapeKeyDown={true}
                                        scroll={'paper'}
                                    >
                                        {this.state.category === "terms" && <DialogTitle className="text-dialog">{language_labels.terms_heading}</DialogTitle>}
                                        {this.state.category === "cookie" && <DialogTitle className="text-dialog">{language_labels.cookie_heading}</DialogTitle>}
                                        {this.state.category === "checkbox-popup" && <DialogTitle className="text-dialog">{language_labels.checkbox_popup}</DialogTitle>}
                                        {this.state.category === "no-access" && <DialogTitle className="text-dialog">{language_labels.error_heading}<span><HighlightOffIcon className="no-access-icon" /></span></DialogTitle>}
                                        {this.state.category === "api-error" && <DialogTitle className="text-dialog">{language_labels.err_new_2}<span><HighlightOffIcon className="no-access-icon" /></span></DialogTitle>}
                                        <DialogContent dividers={true}>
                                            {this.state.category === "terms" && <p style={{ fontSize: "14px", color: "#007bff" }}>
                                                <i>{language_labels.translation_txt}</i>
                                            </p>}
                                            {this.state.category === "terms" && <List className="subtext-list">
                                                {language_labels.terms_and_conditions.map((el, k) => {
                                                    return (
                                                        <ListItem className="bottom-list-item" key={k}>

                                                            <ListItemText
                                                                primary={
                                                                    <Typography className="bottom-list-que">{el.question}</Typography>

                                                                }
                                                                secondary={
                                                                    el.answer.map((ans, l) => {
                                                                        if (k === 0 && l === 1) {
                                                                            return (
                                                                                <Typography className="bottom-list-ans">{ans.ans_text} <a href="https://www.pmiprivacy.com/en/business-partner" target="_blank">
                                                                                    {language_labels.business_partner} <span className="bottom-list-ans">{this.props.user.language === "POLSKI" ? "PMI" : ""}</span>
                                                                                </a>. </Typography>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <Typography className="bottom-list-ans">{ans.ans_text}</Typography>
                                                                            )
                                                                        }

                                                                    })
                                                                }
                                                            />
                                                        </ListItem>
                                                    )
                                                })}
                                            </List>}
                                            {this.state.category === "cookie" && <List className="subtext-list">
                                                {language_labels.cookie_notice.map((el, k) => {
                                                    return (
                                                        <ListItem className="bottom-list-item" key={k}>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography className="bottom-list-que">{el.question}</Typography>
                                                                }
                                                                secondary={
                                                                    el.answer.map((ans, l) => {
                                                                        return (
                                                                            <Typography className="bottom-list-ans">{ans.ans_text}</Typography>
                                                                        )
                                                                    })
                                                                }
                                                            />
                                                        </ListItem>
                                                    )
                                                })}
                                            </List>}
                                            {this.state.category === "no-access" && <Typography>
                                                {language_labels.not_authorized}
                                            </Typography>}
                                            {this.state.category === "checkbox-popup" && <Typography>
                                                {language_labels.terms_text}
                                            </Typography>}
                                            {this.state.category === "api-error" && <Typography>
                                                {language_labels.submit_err}
                                            </Typography>}

                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleClose} className="msg-btn">
                                                {language_labels.ok_btn}
                                            </Button>
                                        </DialogActions>

                                    </Dialog>
                                </div>}
                            </div>
                        </Grid>
                    </Grid>
                    <footer>
                       
                       <p>
                           <a onClick={(event) => this.handleTermsClick("terms")}>{language_labels.TandC} </a>

                       </p>
                       {/* <p style ={{"background": "brown","margin-bottom": "3px"}}>
                           Users may be experiencing slowness accessing SSP due to some technical difficulties. We are working to resolve the issue as quickly as possible and apologize for any inconvenience. 

                       </p> */}
                       </footer>
                </div>
            </div>
        );
    }
}

NewLoginPage.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    user: state.user
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(AllActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewLoginPage));
// withStyles(styles)