export const EMAIL_ENTERED = 'EMAIL_ENTERED'
export const LOAD_USER_DETAILS = 'LOAD_USER_DETAILS'
export const LOGIN_RESPONSE = "LOGIN_RESPONSE"
export const SELECTED_COMPANY_DETAILS = "SELECTED_COMPANY_DETAILS"
export const CLEAR_SELECTED_VENDOR = "CLEAR_SELECTED_VENDOR"
export const RESTORE_VENDOR_NUMBER = "RESTORE_VENDOR_NUMBER"
export const CLEAR_VENDOR_INDEX = "CLEAR_VENDOR_INDEX"
export const STORE_VENDOR_INDEX = "STORE_VENDOR_INDEX"
export const CHANGE_CIP_RUN_FLAG = "CHANGE_CIP_RUN_FLAG"
export const STORE_SELECTED_COUNTRY = "STORE_SELECTED_COUNTRY"
export const SELECTED_REGION = "SELECTED_REGION"
export const SELECTED_MARKET = "SELECTED_MARKET"
export const DEFAULT = "DEFAULT"
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE"
export const REGIONS_SET = "REGIONS_SET"
export const QUERY_ENTITY_SET = "QUERY_ENTITY_SET"
export const SUPPORT_RESPONSE = "SUPPORT_RESPONSE"
export const SET_INVOICELIST_DATA = "SET_INVOICELIST_DATA"
export const SET_CARDVIEW = "SET_CARDVIEW"
