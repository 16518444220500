import React, { Component } from "react";
import "../App.css";
import NavBar from "./NavBar";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import IdleTimer from 'react-idle-timer'
import AjaxService from "../services/AjaxServices";
import NotFound from './NotFound';
import { labels } from './../resources';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as AllActions from '../actions'
import PropTypes from 'prop-types'



class FaqPage extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null
    this.handleOnIdle = this.handleOnIdle.bind(this)
    this.state = {
    
      expanded: false,
      accordion_index: "",
    };
  }

  handleOnIdle(event) {
    //console.log('last active', this.idleTimer.getLastActiveTime())

    this.Ajax = new AjaxService();
    this.Ajax.logout().then(result => {
      //console.log("logged out")
      this.props.actions.return_default()
      sessionStorage.clear();
      localStorage.clear()
      this.props.history.length = 0;

      this.props.history.replace("/");
    })
  }

  handleExpand = (index) => (event, isExpanded) => {
    if (isExpanded) {
      this.setState({ accordion_index: index, expanded: true });
    } else {
      this.setState({ accordion_index: "", expanded: false });
    }
  };

  clickHereForPaymentSchedule = () => {
    this.props.history.push(`/paymentSchedule`)
  }
  render() {
    var language_labels = labels[this.props.user.language]["faq_page"];
    const faq_Data = [
      {
        question:
          language_labels.question_1,
        answer:
          language_labels.answer_1,
        link: language_labels.answer_1_link,
        answer_2: language_labels.answer_part_2
      },
      {
        question: language_labels.question_2,
        answer:
          language_labels.answer_2,
      },
      {
        question: language_labels.question_3,
        answer:
          language_labels.answer_3_part_1 +
          language_labels.answer_3_part_2 +
          language_labels.answer_3_part_3 +
          language_labels.answer_3_part_4 +
          language_labels.answer_3_part_5 +
          language_labels.answer_3_part_6
      },
      {
        question: language_labels.question_4,
        answer:
          language_labels.answer_4,
      },
      {
        question:
          language_labels.question_5,
        answer:
          language_labels.answer_5,
      },
      {
        question:
          language_labels.question_6,
        answer:
           language_labels.answer_6_part_1 +
         language_labels.answer_6_part_2 +
         language_labels.answer_6_part_3 +
         language_labels.answer_6_part_4 +
          // language_labels.answer_6_part_5 +
          // language_labels.answer_6_part_14 +
          // language_labels.answer_6_part_15 +
        
          language_labels.answer_6_part_18+
          language_labels.answer_6_part_20+
          language_labels.answer_6_part_22+
          language_labels.answer_6_part_24+
          language_labels.answer_6_part_26+
          language_labels.answer_6_part_28+
          language_labels.answer_6_part_30+
          language_labels.answer_6_part_32+
          language_labels.answer_6_part_34+
          language_labels.answer_6_part_36+
          language_labels.answer_6_part_38+
          language_labels.answer_6_part_40+
          language_labels.answer_6_part_42+
          language_labels.answer_6_part_6 +
          language_labels.answer_6_part_7 + 
         
          "</ul>" +
          language_labels.answer_6_part_8 +
          language_labels.answer_6_part_9 +
          language_labels.answer_6_part_10 +
          language_labels.answer_6_part_11 +
          // language_labels.answer_6_part_12 +
          // language_labels.answer_6_part_16 +
          // language_labels.answer_6_part_17 +
         
          language_labels.answer_6_part_19+
          language_labels.answer_6_part_21+
          language_labels.answer_6_part_23+
          language_labels.answer_6_part_25+
          language_labels.answer_6_part_27+
          language_labels.answer_6_part_29+
          language_labels.answer_6_part_31+
          language_labels.answer_6_part_33+
          language_labels.answer_6_part_35+
          language_labels.answer_6_part_37+
          language_labels.answer_6_part_39+
          language_labels.answer_6_part_41+
          language_labels.answer_6_part_43+
          language_labels.answer_6_part_13+
       
          "<li> &nbsp;&nbsp;</li>" +
          "</ul>"
      },
      // {
      //   question: "I want to submit the PDF declaration but I can't. ",
      //   answer:
      //     "Please check if the PDF declaration is available for your country. The list of countries for which the PDF declaration is applicable is available here (link?)",
      // },
      // {
      //   question: "I want to submit an invoice but I can't.",
      //   answer:
      //     "Please check if the PDF declaration has been filled in correctly (your e-mial address and signature must be put in place).",
      // },
      {
        question: language_labels.question_7,
        answer:
          language_labels.answer_7,
      },
      {
        question:
         language_labels.question_8,
        answer: language_labels.answer_8,
      },
      // {
      //   question:
      //     "I can't register in the Portal.",
      //   answer: "You need to follow the guidelines from the invitation e-mail. (should we add the narration 'If you have followed the guideline and still not be able to register, please contact the help desk'?)",
      // },
      // {
      //   question:
      //     "I can't log in to the Portal.",
      //   answer:
      //     "Please contact the help desk at: (help desk will check such individual cases?) (also in case when the vendor was deleted in VMD=> and in VQP)",
      // },
      {
        question: language_labels.question_9,
        answer:
          language_labels.answer_9,
      },
      {
        question: language_labels.question_10,
        answer:
         language_labels.answer_10,
      },
      {
        question:
          language_labels.question_11,
        answer:
          language_labels.answer_11,
      },
      // {
      //   question: "What do i do if i forgot my password?",
      //   answer:
      //     "To change your password please follow the following steps: <br>- Click on the Forgot Password option enter your email ID and press Submit. You will receive an email with a one-time password<br>- Enter the one-time password in the portal screen. You will be redirected to a change password screen<br>- Enter your new password, confirm your password and select the change password option.<br>(this should be confirmed by IBM - I put comment as well in SDD)",
      // },
      // {
      //   question: "How I can change my password?",
      //   answer:
      //     "You can change your password through the forgot password option (to be confirmed by IBM - I put comment in SDD)",
      // },
      // {
      //   question:
      //     "Why I can't" +
      //     ' continue to put the comment in "Queries Management" feature',
      //   answer:
      //     "We have limitation on 250 character only. Please ensure that your comment is not above the limit.",
      // },
      // {
      //   question:
      //     "I'm already submitting invoices for one of the PM Affiliate  and now I would like to start sending invoices for another Affiliate.",
      //   answer:
      //     "Please submit a new PDF declaration for another Affiliate. Please note that the separate PDF declaration is required for each of the PM Affiliate.",
      // },
      // {
      //   question:
      //     "I've submitted several invoices but I recieved the payment for only one invoice from this batch.",
      //   answer:
      //     "Please note that each invoice must be submitted separately and they cannot be sent as one pdf file.",
      // },
      {
        question: language_labels.question_12,
        answer:
          language_labels.answer_12,
      },
      {
        question:
         language_labels.question_13,
        answer:
          language_labels.answer_13,
      },
      {
        question:
         language_labels.question_14,
        answer:
          language_labels.answer_14,
      },
      {
        question:
         language_labels.question_15,
        answer:
          language_labels.answer_15,
      },
      {
        question:
         language_labels.question_16,
        answer:
          language_labels.answer_16,
      },
      // {
      //   question: "In the contact us form there are 4 categories, what is the difference on each category?",
      //   answer: "Invoice Status: Use this for all unanswered inquiry related to your invoice payment status" +
      //     "Payment Schedule: Use this for questions related to the payment schedule" +
      //     "Invoice Requirements:  Use this for questions related to the required documents to submit invoice" +
      //     "General Question: Use this for questions that can't be classified in other categories"
      // },
      // {
      //   question: "I've changed my company's name and address, how to reflect this on the system?",
      //   answer: "Please contact your local procurement or counterpart in the respective PMI Affiliate"
      // },
      // {
      //   question: "What does each invoice status mean?",
      //   answer: "Being processed: Invoice has been received by FSC in Surabaya and is being processed by our team." +
      //     "Waiting for payment: Invoice has been fully processed and waiting for the due date." +
      //     "Paid: Invoice has been paid."
      // },
      // {
      //   question: "What is the accepted invoice requirement?",
      //   answer: "Please use Invoice Requirements feature for more detailed information"
      // }
    ];
    return (
      <div>
        { (this.props.user.role === "vendor" || this.props.user.role === "support") && <div className="container-fluid no-padding faq-container">
          <NavBar appClass="faq" />
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            timeout={1000 * 60 * 10}
            onIdle={this.handleOnIdle}
            debounce={250}
          />
          <h3 className="faq-heading">{language_labels["page_heading"]}</h3>
          {faq_Data.map((element, index) => {
            return (
              <Accordion
                key={index}
                expanded={this.state.accordion_index === index}
                onChange={this.handleExpand(index)}
              >
                <AccordionSummary
                  expandIcon={
                    this.state.expanded &&
                      this.state.accordion_index === index ? (
                        <RemoveIcon className="plus-icon" />
                      ) : (
                        <AddIcon className="plus-icon" />
                      )
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  // className="faq-summary"
                  className={
                    this.state.expanded && this.state.accordion_index === index
                      ? "faq-summary-collapsed"
                      : "faq-summary"
                  }
                >
                  <Typography className="faq-question">
                    {element.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
              
                  {index !== 0 && <Typography className="faq-answer" dangerouslySetInnerHTML={{ __html: element.answer }}></Typography>}
                  {index === 0 && <Typography className="faq-answer">{element.answer} <a className="faqs-anchor"  onClick={(event) => this.clickHereForPaymentSchedule()}>{element.link}</a> {element.answer_2}</Typography>}
                </AccordionDetails>
              </Accordion>
            );
          })}

          {/* <div className="col-12 navBorder "></div> */}
        </div>}
        {this.props.user.role !== 'vendor' && this.props.user.role !== "support" && <NotFound history={this.props.history}/>}
      </div>
    );
  }
}

FaqPage.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  user: state.user
})
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(AllActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(FaqPage);
