import React, { Component } from 'react';
import '../inv-submission.scss';
import NavBar from './NavBar';
import { labels } from './../resources';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as AllActions from '../actions'
import PropTypes from 'prop-types'


// var labels = require('./../resources.json')
class FloatingButtons extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showText_one: false,
            showText_two: false
        };
    }
    handleHover = (status, index) => () => {
        if (index === "0") {
            this.setState({
                showText_one: status
            })
        } else {
            this.setState({
                showText_two: status
            })
        }
    }
    handleNavigation = (page) => {
        if(page === "faq"){
            this.props.history.push(`/faqs`)
        }

        if(page === "raise-query"){
            this.props.history.push(`/QueryHome`)
        }
    }
  //
    render() {
        let language_labels = labels[this.props.user.language]
        // //console.log(this.props.location.state.details)
        let style_class = ""
        if(this.props.appClass !== 'home'){
            style_class = "floating-div-others"
        }else{
            style_class = "floating-div"
        }
        return (
                 <div className={style_class}>
                        {this.props.appClass !== "query" && this.props.user.role !== "support" && <div onMouseEnter={this.handleHover(true, "0")}
                            onMouseLeave={this.handleHover(false, "0")}
                        >
                            {this.state.showText_one ? <div className="list-text"><span className="floating-text" onClick={(event) => this.handleNavigation("raise-query")}>{language_labels["home_page"]["query_heading"]}</span> </div> : <QuestionAnswerIcon className="listitem-icon" />}
                        </div>}
                       <div onMouseEnter={this.handleHover(true, "1")}
                            onMouseLeave={this.handleHover(false, "1")}>
                            {this.state.showText_two ? <div className="list-text"><span className="floating-text" onClick={(event) => this.handleNavigation("faq")}>{language_labels["home_page"]["faq_heading"]}</span></div> : <HelpOutlineIcon className="listitem-icon" />}
                        </div>
                    </div>
        );
    }
}

FloatingButtons.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    user: state.user
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(AllActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(FloatingButtons);


