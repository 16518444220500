import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import AjaxService from "../services/AjaxServices";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
 
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
  } from "@material-ui/core";
  import { labels } from "./../resources";
function BannerContent (props){
    const [file,setFile]=useState('')
    const [ loadingSpinOpen, setLoadingSpinOpen]=useState(false);
    const [success_dialog, setsuccess_dialog]=useState(false);
    const [msg_display, setmsg_display]=useState('');
    let role = props.user.role;
   
    var language_labels = labels[props.user.language]["admin_page"];

   
    const handleSuccessDialog =()=>{
        setsuccess_dialog(false);
       
      }
     
   const  handleChange=(e)=>{
        const data=e.target.files[0]
         setFile(data)
    }
    const saveBanner=() =>{
        setLoadingSpinOpen(true);
        console.log("save button clicked")
        let Ajax = new AjaxService();
        Ajax.SaveBannerImgDB(file,"banner")
        .then(res => {
            console.log(res)
            if (res.status==200){


                setmsg_display(language_labels.banner_upload_success);
                props.getCardDataFromDB();
                setLoadingSpinOpen(false);
                setsuccess_dialog(true);
               
            }
           else{
            setmsg_display(language_labels.save_failure)
            setsuccess_dialog(true);
           
           }
           

        });
        
        const data=""
        setFile(data)

    } 
    const cancelBanner =()=>{
        console.log("cancel button clicked")
        const data=""
        setFile(data)
    }

  
    return(   
        <div style={role=="support"? { padding: '5px', border: '1px dotted grey',margin: '5px', textAlign:'center'}:{textAlign:'center'}}>
         
        {(props.bannerImgSrc !=='' && !file && props.bannerExist)  && <div>
        <>
            < img  loading="lazy"  className = 'bannerclass' src={props.bannerImgSrc}/>
            {console.log(props.bannerImgSrc)}
            </>
        </div >}
        {(role == "support")  && <div>
        <>
        <Backdrop onBackdropClick="false" open={loadingSpinOpen} >
         <CircularProgress className="circular-spinner" />
            </Backdrop>
            
            <label htmlFor="upload-button"  style={{ display: (file?'none':'inline-block' ), marginRight:'10px' }} >{language_labels.img_upload} </label>
             <input 
             type="file" 
             onChange={handleChange} 
             style={{ display: (file?'none':'inline-block' ) }}
             id="upload-button"
    
             />   
             
              {file &&
                
                  <div> 
                      <span>{language_labels.img_preview}</span>
                      <img   style={{ width: '100%' , height: '120px'  }} src={URL.createObjectURL(file)}/>
                      <Button className="customAdd" variant="outlined" onClick={(event) => saveBanner()}>{language_labels.save}</Button>
                      <Button className="customAdd" variant="outlined" onClick={(event) => cancelBanner()}>{language_labels.cancel}</Button>
      

                     </div>  
      
      } 
       </>
       </div >}  
       <Dialog
                                                open={success_dialog}
                                                keepMounted
                                                onClose={handleSuccessDialog}
                                                disableBackdropClick={true}
                                                disableEscapeKeyDown={true}
                                            >
                                                <DialogTitle >
                                              {language_labels["save_success"]}
                                                </DialogTitle>
                                               
                                                <DialogContent>
                                                    <DialogContentText >
                                                    {msg_display}

                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleSuccessDialog} className="msg-btn" color="primary">
                                                        {language_labels["ok"]}
                                                    </Button>
                                                   
                                                </DialogActions>
                                            </Dialog>      
        </div>
     )

}

   
    
        
  
export default BannerContent;