import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import Chip from '@material-ui/core/Chip';
import MuiTooltip from '@material-ui/core/Tooltip';
import Fade from "@material-ui/core/Fade";
import Checkbox from '@material-ui/core/Checkbox';
import NavBar from './NavBar'
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import AjaxService from "../services/AjaxServices";
import "../admin-page.scss";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import moment from 'moment'
import IdleTimer from 'react-idle-timer'
import Cookies from 'js-cookie';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as AllActions from '../actions'
import PropTypes from 'prop-types'
import NotFound from './NotFound';

class AuditTrail extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = null
        this.handleOnIdle = this.handleOnIdle.bind(this)
        this.state = {
            
            displayData: []
        };
    }

    handleOnIdle(event) {
        //console.log('last active', this.idleTimer.getLastActiveTime())

        this.Ajax = new AjaxService();
        this.Ajax.logout().then(result => {
            //console.log("logged out")
            this.props.actions.return_default()
            sessionStorage.clear();
            localStorage.clear()
            this.props.history.length = 0;

            this.props.history.replace("/");
        })
    }

    componentWillMount() {
        if (this.props.user.role === "admin" || this.props.user.role === "support") {
            var tableData = []
            this.Ajax = new AjaxService();
            this.Ajax.getAuditTrailData()
                .then(result => result.json())
                .then(res => {
                    //console.log(res)

                    res.map((item, i) => {
                        var email_list = item.vendor_email.flatMap(email =>
                            (email.sent_invite === true) ? email : []
                        )
                        var admin_list = item.vendor_email.flatMap(email =>
                            (email.sent_invite === true) ? email.admin : []
                        )
                        var date_list = item.vendor_email.flatMap(email =>
                            (email.sent_invite === true) ? moment(email.sent_invite_date).format('DD-MMM-YYYY') : []
                        )
                        //console.log(email_list)
                        if (item.role === "vendor" ) {
                            tableData.push([
                                item.vendor_number,
                                item.vendor_name,
                                email_list,
                                admin_list,
                                date_list
                            ])
                        }

                        //console.log(tableData)
                    })
                    this.setState({ displayData: tableData })
                })
        }
    }




    getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTableFilter: {
                    padding: "9px 20px 33px 20px",
                    fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                    title: {
                        fontWeight: 400,
                        marginLeft: 0
                    },
                    resetLink: {
                        fontSize: 14,
                        fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                        fontWeight: 400
                    }
                },
                MuiFormLabel: {
                    root: {
                        fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                        fontWeight: 400
                    }
                },
                MuiButton: {
                    textPrimary: {
                        color: "#004f90"
                    }
                },
                MUIDataTableBodyCell: {
                    root: {
                        textAlign: "left"
                    }
                },
                MuiGridListTile: {
                    root: {
                        paddingTop: "0 !important"
                    },
                    tile: {
                        marginTop: "0 !important"
                    }
                },
                MUIDataTable: {
                    responsiveScroll: {
                        overflowY: "scroll"
                    }
                },

                MUIDataTableHeadCell: {
                    fixedHeader: {
                        backgroundColor: "#f6f6f6",
                        color: "#0160a5"
                    },
                    root: {
                        padding: "10px",
                        verticalAlign: "top"
                    }
                },
                MuiToolbar: {
                    root: {
                        background: "#f6f6f6"
                    },
                    regular: {
                        paddingLeft: "0"
                    },
                    gutters: {
                        paddingLeft: "0"
                    }
                },
                MuiTableCell: {
                    root: {
                        padding: "15px",
                        verticalAlign: "top",
                        fontFamily:
                            "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                    },
                    body: {
                        fontSize: "14px"
                    },
                    head: {
                        fontSize: "15px",
                        fontWeight: "600"
                    }
                },
                MuiSelect: {
                    icon: {
                        top: "0 !important"
                    }
                },
                MUIDataTableSearch: {
                    main: {
                        position: "absolute",
                        right: "12rem",
                        bottom: "10px",
                        width: "48%"
                    },
                    searchIcon: {
                        display: "none"
                    }
                },
                MuiInputBase: {
                    root: {
                        fontFamily:
                            "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                    },
                    input: {
                        paddingTop: "5px",
                        border: "1px solid #ccc",
                        paddingLeft: "5px",
                        paddingRight: "20px",
                        borderBottom: "1px solid #ccc",
                        fontSize: 13,
                        fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                    }
                },
                MUIDataTableToolbar: {
                    filterPaper: {
                        maxWidth: "46% !important",
                        left: "650px !important"
                    }
                },
                MUIDataTablePagination: {
                    root: {
                        fontFamily:
                            "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                        "&:last-child": {
                            padding: "0px"
                        }
                    }
                },
                MuiMenuItem: {
                    root: {
                        fontFamily:
                            "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                        fontSize: 13
                    }
                },
                MuiIconButton: {
                    root: {
                        padding: "5px",
                        "&:focus": {
                            outline: "none !important"
                        },
                        "&:hover": {
                            // color: "#004f90 !important",
                            backgroundColor: "transparent !important"
                        }
                    }
                },
                MuiPaper: {
                    elevation4: {

                    }

                },
                MuiSvgIcon: {
                    root: {
                        width: "1em",
                        height: "1em",
                        fontFamily:
                            "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                        // paddingLeft: "15px"
                    }
                },
                MuiTablePagination: {
                    select: {
                        border: "none"
                    },
                    selectIcon: {

                    }
                },
                MuiTable: {
                    root: {
                        fontFamily:
                            "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                    }
                },
                MuiTypography: {
                    caption: {
                        fontFamily:
                            "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                        fontSize: "1rem"
                    },
                    h6: {
                        paddingLeft: 8,
                        fontSize: "1.5rem",
                        fontWeight: "normal",
                        fontFamily:
                            "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                    }
                },
                MUIDataTableFilterList: {
                    chip: {
                        margin: "8px 8px 8px 0px"
                    }
                },
                MuiChip: {
                    root: {
                        fontSize: 13,
                        fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                        backgroundColor: "#f4f4f4",
                        border: "1px solid #004f90"
                    },
                    deleteIcon: {
                        fill: "#004f90"
                    }
                },
                MuiCheckbox: {
                    MuiIconButton: {
                        root: {
                            color: "#004f90"
                        }
                    }
                },
                MuiTableRow: {
                    root: {
                        '&$selected': {
                            backgroundColor: '#0067bc2e !important'
                        }
                    }
                },
                MUIDataTableSelectCell: {
                    checked: { color: "#004f90 !important" }
                }
            }
        });

    render() {
        const { displayData } = this.state
        const columns = [
            {
                name: 'Vendor Number',
                options:{
                    sort:false
                }
            },
            {
                name: 'Vendor Name',
                options:{
                    sort:false
                }
            },
            {
                name: 'Vendor Email',
                options: {
                    sort:false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <List className="email-list">
                            {value.map((email) => {
                                return (
                                    <ListItem className="email-list-item">

                                        <ListItemText primary={
                                            <Typography>
                                                {email.email}
                                            </Typography>
                                        } />
                                    </ListItem>
                                )
                            })}
                        </List>

                    )
                }
            },
            {
                name: 'Admin',
                options: {
                    sort:false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <List className="email-list">
                            {value.map((admin) => {
                                return (
                                    <ListItem className="email-list-item">

                                        <ListItemText primary={
                                            <Typography>
                                                {admin}
                                            </Typography>} />
                                    </ListItem>
                                )
                            })}
                        </List>

                    )
                }
            },
            {
                name: 'Sent Invitation Date',
                options: {
                    sort:false,
                    customBodyRender: (value, tableMeta, updateValue) => (
                        <List className="email-list">
                            {value.map((date) => {
                                return (
                                    <ListItem className="email-list-item">

                                        <ListItemText primary={
                                            <Typography>
                                                {date}
                                            </Typography>} />
                                    </ListItem>
                                )
                            })}
                        </List>

                    )
                }
            }

        ];

        let options = {
            print: false,
            search: true,
            download: true,
            filter: false,
            viewColumns: false,
            selectableRows: false,
            downloadOptions: {
                filename: "AuditTrailReport.csv",
                separator: ','
            },
            customSearch: (searchQuery, currentRow, columns) => {
                let isFound = false;
                currentRow.forEach((col,i) => {
                  if (col.toString().indexOf(searchQuery) >= 0) {
                    isFound = true;
                  } else {
                    if (i === 2) {
                      col.forEach(emailobj => {
                      if (emailobj["email"].toString().indexOf(searchQuery) >= 0) {
                        isFound = true;
                      }
                    })
                    }
                  }
                });
                return isFound;
              },
            onDownload: (buildHead, buildBody, columns, data) => {
                //console.log(data)
                //console.log(columns)

                var download_arr = data.map(y => {
                    let sent = []
                    let not_sent = []
                    y.data[2].forEach(p => {
                        if (p.sent_invite === true) {
                            sent.push(p.email)
                        } else {
                            not_sent.push(p.email)
                        }
                    })

                    return ([y.data[0], y.data[1], sent, y.data[3], y.data[4]])
                })

                return (
                    buildHead([
                        {
                            name: "Vendor Number",
                            download: true
                        },
                        {
                            name: "Vendor Name",
                            download: true
                        },
                        {
                            name: "Vendor Email",
                            download: true
                        },
                        {
                            name: "Admin",
                            download: true
                        },
                        {
                            name: "Sent Invitation Date",
                            download: true
                        }
                    ]) +
                    buildBody(
                        download_arr.map(x => ({
                            ...x,
                            data: [
                                x[0],
                                x[1],
                                x[2].join(","),
                                x[3].join(","),
                                x[4].join(",")
                            ]

                        }))
                    )
                )
            },
        };

        return (
            <div>
                {(this.props.user.role === "admin"||this.props.user.role === "support") && <div className="container-fluid custom-container">
                    <NavBar appClass="audit-trail" />
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref }}
                        timeout={1000 * 60 * 10}
                        onIdle={this.handleOnIdle}
                        debounce={250}
                    />
                    <div className="col-12 admin-list-col">
                        {displayData && <MuiThemeProvider theme={this.getMuiTheme()}> <MUIDataTable
                            title={"Business Transaction Listing"}
                            data={displayData}
                            columns={columns}
                            options={options}
                        /></MuiThemeProvider>}

                    </div>


                </div>}
                {this.props.user.role !== "admin" && this.props.user.role !== "support" && <NotFound history={this.props.history} />}
            </div>

        );
    }
}

AuditTrail.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    user: state.user
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(AllActions, dispatch)
})


export default connect(mapStateToProps, mapDispatchToProps)(AuditTrail);