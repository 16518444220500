import React, { Component } from "react";
import error from "../error.jpg";
import "../App.css";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { NavLink, Link, withRouter } from "react-router-dom";
import {

  Button
} from "@material-ui/core";
// import '../styles/NotFound.css';
import pmiLogo from "./../assests/pmi-logo.svg";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as AllActions from '../actions'
import PropTypes from 'prop-types'
import AjaxService from "../services/AjaxServices";

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = { users: [] };
  }

  componentDidMount() {
    // this.callAPI();
    // localStorage.clear();
    // document.getElementsByClassName("navbar-menu");
    // document.getElementsByClassName('footer')[0].style.display="none";
    // //console.log(document.getElementsByClassName('footer'));
  }

  handleImgClick = () => {
    this.props.history.push(`/home`)
  }
  handleClickBack = () => {
    this.props.history.goBack()
  }

  handleLogout = () => {
    this.Ajax = new AjaxService();
    this.Ajax.logout().then(result => {
        //console.log("logged out")
        sessionStorage.clear();
        localStorage.clear()
        this.props.actions.return_default()
        // this.props.history.length = 0;

        this.props.history.replace("/");
    })
  }
  render() {
    return (
      <div>
        {/* <div className="app-bar-faq">
          
        </div> */}
        <AppBar className="app-bar-faq" style={{boxShadow: "none"}}>
          <Toolbar className="tool-bar">
          {this.props.user.role === "support" && <img className="logo-btn" src={pmiLogo} onClick={() => this.props.history.push('/home')}/>}
            <h4 className="navbrand-margin">Self Service Portal</h4>
            {this.props.user.role && <div>
            <Button className="logout-btn" onClick={() => this.props.history.goBack()}>GO BACK</Button>
            <Button className="logout-btn" onClick={(event)=>this.handleLogout()}>LOG OUT</Button>
            </div>}
          </Toolbar>
        </AppBar>

        <div style={{ textAlign: "center" ,marginTop:"6%"}}>
          <img className="errorLogo" alt="img" src={error} />
          <h2 className="errorText mt-3">Sorry! You are not Authorized.</h2>
          <Link to="/">
            <Button className="errorButton">
              Go to Login Page
            </Button>{" "}
          </Link>
        </div>
      </div>
    );
  }
}

NotFound.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  user: state.user
})
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(AllActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
