import React, { Component } from 'react';
import '../inv-submission.scss';
import NavBar from './NavBar';
import { labels } from './../resources';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import Snackbar from '@material-ui/core/Snackbar';
import FloatingButtons from '../component/floating-buttons'
import AjaxService from "../services/AjaxServices";
import Button from "@material-ui/core/Button";
import IdleTimer from 'react-idle-timer';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import NotFound from './NotFound';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as AllActions from '../actions'
import PropTypes from 'prop-types'

import Cookies from 'js-cookie';

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType)
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

// var labels = require('./../resources.json')
class InvoiceSubmission extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = null
        this.handleOnIdle = this.handleOnIdle.bind(this)
        this.state = {
         
            submit_msg: false,
            validate: false,
            invalid: false,
            validateMessage: '',
            files: [],
            loadingOpen: false
        };
    }

    handleOnIdle(event) {
        //console.log('last active', this.idleTimer.getLastActiveTime())

        this.Ajax = new AjaxService();
        this.Ajax.logout().then(result => {
            //console.log("logged out")
            this.props.actions.return_default()
            sessionStorage.clear();
            localStorage.clear()
            this.props.history.length = 0;

            this.props.history.replace("/");
        })
    }

    handleInit() {
        //console.log("FilePond instance has initialised", this.pond);
    }
    handleSubmitInvoice = () => {
        //console.log(this.state.files.length)
        let files = this.state.files.length !== 0 && [...this.state.files]
        var validFiles = []
        var size = 0;
        var len = this.state.files.length !== 0 ? this.state.files.length : '';
        var count = 0;
        let accepFileFormat = ["application/pdf"]
        var totalsize = 0;

        if (this.state.files.length !== 0) {
            validFiles = files.reduce((results, file) => {
                totalsize = totalsize + file["size"];
                size += file["size"]
                console.log(file["size"]);
                
                if(this.props.user.selected_region.toLowerCase() === "latin america & canada"){
                    if ((file["type"] !== undefined) && ((accepFileFormat.includes(file["type"]) && file["size"] <= 5242880  && totalsize < 18874368))) {
                        this.setState({ invalid: false })
                        count++;
                        results.push(file)
                    }else if ((file["type"] !== undefined) && (!accepFileFormat.includes(file["type"]) || file["size"] > 5242880 || totalsize > 18874368)) {
                        //console.log("inside else")
                        this.setState({
                            validate: true, invalid: true, validateMessage: labels[this.props.user.language]["invoice_submission"]["review"]
                        })
                    }
                }else{
                    if ((file["type"] !== undefined) && ((accepFileFormat.includes(file["type"]) && file["size"] <= 5242880 ))) {
                        this.setState({ invalid: false })
                        count++;
                        results.push(file)
                    }
                    else if ((file["type"] !== undefined) && (!accepFileFormat.includes(file["type"]) || file["size"] > 5242880)) {
                        //console.log("inside else")
                        this.setState({
                            validate: true, invalid: true, validateMessage: labels[this.props.user.language]["invoice_submission"]["review"]
                        })
                    }
                }

                return results
            }, [])
         

        }
        else {
            this.setState({
                validate: true, invalid: true, validateMessage: labels[this.props.user.language]["invoice_submission"]["upload"]
            })
        }
        console.log(this.state.validate, count, len)
        if (count === len) {
            this.Ajax = new AjaxService();
            var vendorNum = this.props.user.vendor_number
            var vendorName = this.props.user.vendor_name
            var pmi_entity = this.props.user.selected_pmi_entity
            var companyCode = this.props.user.selected_company_code
            var cip_value = this.props.user.cip_value
            var country= this.props.user.selected_market
            console.log("inv mail",this.props.user.invoice_mailbox)
            this.setState({ loadingOpen: true })
            if (this.props.user.invoice_type.toLowerCase() === "mailbox") {
                let erp_codes = this.props.user.company_code_ERP
                let get_erp_system;
                let erps = Object.keys(erp_codes)
                let scf_value

                erps.forEach(el => {
                    erp_codes[el].forEach(code => {
                        if (code.code === companyCode) {
                            scf_value = code.supplyChainFinanceVendor;
                        }
                    })
                })
                console.log("here in inv submission", scf_value)
                this.Ajax.invoiceEmail(vendorNum, vendorName, pmi_entity, companyCode, cip_value, this.state.files,scf_value,country,this.props.user.invoice_mailbox)
                    .then(res => {
                        console.log("email response inv", res)
                        if (res.data.status === 200) {
                            this.setState({ submit_msg: true, loadingOpen: false, files: [], file_length: this.state.files.length })
                        } else {
                            if (res.data.status === 500) {
                                this.setState({
                                    validate: true, invalid: true, loadingOpen: false, validateMessage: res.data.msg
                                })
                            }
                        }
                    }).catch(error => {
                        console.log(error)
                    })
            }else{
                this.Ajax.invoiceUploadApi(vendorNum, vendorName, pmi_entity, companyCode, cip_value, this.state.files)
                // .then(result => result.json())
                .then(res => {
                    console.log(res)
                    if (res.data.status === 200) {
                        this.setState({ submit_msg: true, loadingOpen: false, files: [], file_length:this.state.files.length })
                    } else {
                        console.log(res)
                        if (res.data.status === 500) {
                            this.setState({
                                validate: true, invalid: true, loadingOpen: false, validateMessage: res.data.msg
                            })
                        }
                    }

                })
            }
            

        }
    }

    handleCloseFlag = () => {
        this.setState({ validate: false })
    }

    handleDialogClose = (dialog_name) => {

        this.setState({ submit_msg: false, loadingOpen: false })
    }

    render() {

        var language_labels = labels[this.props.user.language]
        return (
           
            <div>
                {(this.props.user.role === 'vendor') && <div className="container-fluid no-padding page-bg">
                    <Snackbar ContentProps={{

                    }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                      
                        open={this.state.validate}
                        onClose={this.handleCloseFlag}
                        autoHideDuration={6000}
                        message={this.state.validateMessage}
                    />
                    <NavBar appClass="inv-final" />
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref }}
                        timeout={1000 * 60 * 10}
                        onIdle={this.handleOnIdle}
                        debounce={250}
                    />
                    <div className="inv-sub-container">
                        <div className="field-container">
                            <Grid container>
                                <Grid item xs={12} style={{ textAlign: "center" }} className="inv-grids">
                                    <h5 className="inv-head">{language_labels["invoice_submission"]["invoice_submit_header"]}</h5>
                                </Grid>
                                <Grid item xs={12}>
                            <label className="pmi-label">{language_labels["invoice_submission"]["pmi_entity"]}</label>
                            </Grid>
                            <Grid item xs={12} className="inv-grids">
                               {/* dummy */}
                                
                                <TextField
                                    className="inv-fields"
                                    value = {this.props.user.selected_pmi_entity}
                                    // placeholder="PMI Entity"
                                    inputProps={{
                                        style: {
                                            fontSize: 13,
                                            border: '1px solid #004f90 !important',
                                            color: '#464545',
                                            marginLeft:5
                                        }
                                    }}
                                />
                            </Grid>
                                <Grid item xs={12} className="inv-grids">
                                    <FilePond
                                        className="inv-filepond"
                                        ref={(ref) => (this.pond = ref)}
                                       
                                        files={this.state.files}
                                        allowFileTypeValidation={true}
                                        allowFileSizeValidation={true}
                                        maxFileSize="5120KB"
                                        maxTotalFileSize={this.props.user.selected_region.toLowerCase() === "latin america & canada"? "18874368Bytes":null}
                                        labelIdle={language_labels["invoice_submission"]["drag_drop_1"] + ' <span class="filepond--label-action">' + language_labels[["invoice_submission"]]["browse"] + '</span>'}
                                        labelMaxTotalFileSizeExceeded = {language_labels["invoice_submission"]["max_upload"]}
                                        labelMaxFileSizeExceeded={language_labels["invoice_submission"]["max_file_size"]}
                                        labelFileProcessing={language_labels["query_page"]["uploading"]}
                                        labelFileProcessingComplete={language_labels["invoice_submission"]["upload_complete"]}
                                        labelFileProcessingError={language_labels["query_page"]["err_upload"]}
                                        acceptedFileTypes="application/pdf"
                                        labelFileTypeNotAllowed={language_labels["invoice_submission"]["type_not_supported"]}
                                        allowMultiple={true}
                                        name="files"
                                        server="/upload"
                                        oninit={() => this.handleInit()}
                                        onupdatefiles={(fileItems) => {
                                            this.setState({
                                                files: fileItems.map((fileItem) => fileItem.file),
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className="inv-text-grids">
                                    <h6 className="imp-note">{language_labels["invoice_submission"]["important_note"]}</h6>
                                    <ul style={{ marginLeft: "10%" }}>
                                        <li>{language_labels["invoice_submission"]["note_1"]}</li>
                                        <li>{language_labels["invoice_submission"]["note_2"]}</li>

                                        {this.props.user.selected_region && this.props.user.selected_region.toLowerCase() === "latin america & canada" && <li>{language_labels["invoice_submission"]["note_3"]}</li>}
                                        {/* <li><a href="https://www.pmiprivacy.com/en/business-partner" target="_blank">{language_labels["invoice_submission"]["note_4_link"]}</a></li> */}

                                    </ul>
                                    {/* <p>
                                            Note : Please submit the invoices as single file with supporting docs.
                                        </p>
                                        <p>
                                            File extension allowed : .pdf, .tiff, .tif
                                        </p>
                                        <p>
                                            Maximum File size : 10 MB
                                        </p> */}
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "center" }} className="inv-grids">
                                    {/* {localStorage.getItem('role') !== 'helpdesk' ? <a class="submit-button" onClick={this.handleSubmitInvoice}>Submit</a> : <Button disabled disable-submit-btn>Submit</Button>} */}
                                    <a class="submit-button" onClick={this.handleSubmitInvoice}>{language_labels["invoice_submission"]["submit_button"]}</a>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "center" , marginTop:"20px"}}>
                                <p dangerouslySetInnerHTML={{ __html: language_labels["invoice_submission"]["disclaimer"] }}></p>
                                   
                                </Grid>
                                <Dialog
                                    open={this.state.submit_msg}
                                    onClose={(event) => this.handleDialogClose()}
                                    disableBackdropClick={true}
                                    disableEscapeKeyDown={true}
                                    className="msg-paper"
                                >
                                    <DialogTitle className="msg-dialog-title">{language_labels["invoice_submission"]["success"]}</DialogTitle>
                                    <DialogContent>
                                    <Typography className="msg-txt">{language_labels["invoice_submission"]["total_upload"]} {this.state.file_length}</Typography>
                                        <Typography className="msg-txt">{language_labels["invoice_submission"]["dailog_box"]}</Typography>
                                        {/* <Typography className='msg-txt'>{language_labels["invoice_submission"]["dialog_box_2"]}</Typography> */}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button disableRipple onClick={(event) => this.handleDialogClose("submit-msg")} className="msg-btn">
                                            OK
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Backdrop disableBackdropClick={true} open={this.state.loadingOpen} onClick={(event) => this.handleDialogClose("spinner")}>
                                    <CircularProgress className="circular-spinner" />
                                </Backdrop>

                            </Grid>
                        </div>
                        <FloatingButtons {...this.props} appClass="inv-list" />
                    </div>


                </div>}
                {(this.props.user.role !== 'vendor') && <NotFound history={this.props.history}/>}
               
            </div>
        );
    }
}

InvoiceSubmission.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    user: state.user
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(AllActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSubmission);