import React, { Component } from 'react';
import '../App.css';
// import Login from '../component/login';
// import BeforeHeaderLogin from '../component/BeforeHeaderLogin';
// import Footer from '../component/footer';
// import AfterHeaderLogin from '../component/AfterHeaderLogin';
import InvoiceList from '../component/invoiceList';

export default class DashboardInvoiceListPage extends Component {

    render() {
        // if(!localStorage.getItem('id_token'))
        // {
        //     return(
        //     <div className="App full-heigh bodybg-gray">

        //         <BeforeHeaderLogin />
        //         <Login />


        //     </div>
        //     );
        // }
        return (
            <div>
                {/* // <div className="container-fluid full-eight bodybg-gray " >
            // <AfterHeaderLogin/>
            // <p className="margin-left-50px">Welcome User!</p> */}
                <InvoiceList />

            </div>
        );
    }
}
