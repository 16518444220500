import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import Chip from '@material-ui/core/Chip';
import MuiTooltip from '@material-ui/core/Tooltip';
import Fade from "@material-ui/core/Fade";
import NavBar from './NavBar'
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import AjaxService from "../services/AjaxServices";
import "../admin-page.scss";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import moment, { lang } from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IdleTimer from 'react-idle-timer';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import NotFound from './NotFound';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as AllActions from '../actions'
import PropTypes from 'prop-types'
import { labels } from './../resources';







class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null
    this.handleOnIdle = this.handleOnIdle.bind(this)
    this.state = {
      selectedCompanyCode: "Select Company Code",
      displayData: [],
      company_codes: [],
      dialogOpen: false,
      category: "",
      loadingOpen: false
    };
  }

  handleOnIdle(event) {
    ////console.log('last active', this.idleTimer.getLastActiveTime())

    this.Ajax = new AjaxService();
    this.Ajax.logout().then(result => {
      ////console.log("logged out")
      this.props.actions.return_default()
      sessionStorage.clear();
      localStorage.clear()
      this.props.history.length = 0;

      this.props.history.replace("/");
    })
  }

  componentWillMount() {

    var company_codes = [];
    var tableData = []
    var modified_main = []
    this.Ajax = new AjaxService();
    //console.log(this.props.user.role)
    if (this.props.user.role === "admin" || this.props.user.role === "support") {
      this.Ajax.getVendorMasterData()
        .then(result => result.json())
        .then(res => {
          console.log(res)
          this.Ajax.getAllCompanyCodes()
            .then(codes => codes.json())
            .then(codes => {
           
              res.map((item, i) => {
                var cocd_list = []
                if (item.role !== "support" && item.role !== "admin") {
                  cocd_list = item.CoCds_assigned.map(code => {
                    if (code.active_vendor.toLowerCase() === "x" && code.companyBlockedForPosting.toLowerCase() === "x") {
                    } else {
                      return code.code
                    }

                  })
                  cocd_list = cocd_list.filter(function (element) {
                    return element !== undefined;
                  });
                }




                var status_list = item.vendor_email.flatMap(status => (status.sent_invite === true) ? labels[this.props.user.language]["admin_page"].sent_invite : labels[this.props.user.language]["admin_page"].not_invite)
                var dateList = item.vendor_email.flatMap(date => (date.sent_invite === true) ? moment(date.sent_invite_date).format("DD-MMM-YYYY") : "")
                var check_deletion = []
                var disable_status
                if (item.role !== "support" && item.role !== "admin") {
                  item.CoCds_assigned.forEach(x => {
                    if (x.active_vendor == "") {
                      check_deletion.push(1)
                    } else {
                      check_deletion.push(0)
                    }
                  })
                  if (check_deletion.every(v => v === 0)) {
                    disable_status = false
                  } else {
                    disable_status = true
                  }

                }


                ////console.log(check_deletion)


                if (cocd_list.length !== 0 && item.role === "vendor") {
                  tableData.push([
                    item.vendor_number,
                    item.vendor_name,
                    item.vendor_email,
                    status_list,
                    dateList,
                    cocd_list.join(","),
                    disable_status

                  ])

                  modified_main.push(item)
                }
                ////console.log(tableData)
              })
              this.setState({ displayData: tableData, mainData: modified_main, company_codes: codes })
            })

        })
    }



  }

  handleChange = (event) => {
    console.log(event)
    var tableData = []
    var modified_main = []
    if (event.target.value !== "reset") {
      this.Ajax = new AjaxService();
      this.Ajax.filterCompanyCodes(event.target.value)
        .then(data => data.json())
        .then(filter_data => {
          if (filter_data.length !== 0) {
            tableData = []
            filter_data.map((item, i) => {
            
              var cocd_list = []
              if (item.role !== "support" && item.role !== "admin") {
                 cocd_list = item.CoCds_assigned.map(code => {
                  if (code.active_vendor.toLowerCase() === "x" && code.companyBlockedForPosting.toLowerCase() === "x") {
                  } else {
                    return code.code
                  }
                })
  
                cocd_list = cocd_list.filter(function (element) {
                  return element !== undefined;
                });
                var check_deletion = []
                var disable_status
                item.CoCds_assigned.forEach(x => {
                  if (x.active_vendor == "") {
                    check_deletion.push(1)
                  } else {
                    check_deletion.push(0)
                  }
                })
  
                ////console.log(check_deletion)
                if (check_deletion.every(v => v === 0)) {
                  disable_status = false
                } else {
                  disable_status = true
                }
              }
             

              var status_list = item.vendor_email.flatMap(status => (status.sent_invite === true) ? labels[this.props.user.language]["admin_page"].sent_invite : labels[this.props.user.language]["admin_page"].not_invite)
              var dateList = item.vendor_email.flatMap(date => (date.sent_invite === true) ? moment(date.sent_invite_date).format("DD-MMM-YYYY") : "")
              ////console.log(status_list)
             
              if (cocd_list.length !== 0) {
                tableData.push([
                  item.vendor_number,
                  item.vendor_name,
                  item.vendor_email,
                  status_list,
                  dateList,
                  cocd_list.join(","),
                  disable_status
                ])

                modified_main.push(item)
              }
              ////console.log(tableData)
            })
            this.setState({ displayData: tableData, mainData: modified_main, selectedCompanyCode: event.target.value })
          } else {
            this.setState({ displayData: [], selectedCompanyCode: event.target.value })
          }


        })
    } else {
      this.getAllData()
      this.setState({ selectedCompanyCode: labels[this.props.user.language]["admin_page"].select_cc })
    }


  }
  getAllData = () => {
    var modified_main = []
    this.Ajax = new AjaxService();
    this.Ajax.getVendorMasterData()
      .then(result => result.json())
      .then(res => {
        var tableData = []
        // console.log(res)
        res.map((item, i) => {
         
          var cocd_list = []
          
          if (item.role !== "support" && item.role !== "admin") {
            cocd_list = item.CoCds_assigned.map(code => {
              if (code.active_vendor.toLowerCase() === "x" && code.companyBlockedForPosting.toLowerCase() === "x") {
             
              } else {
              
                return code.code
              }
            })
  
            cocd_list = cocd_list.filter(function (element) {
              return element !== undefined;
            });
            var check_deletion = []
            var disable_status
            item.CoCds_assigned.forEach(x => {
              if (x.active_vendor == "") {
                check_deletion.push(1)
              } else {
                check_deletion.push(0)
              }
            })
  
          
            if (check_deletion.every(v => v === 0)) {
              disable_status = false
            } else {
              disable_status = true
            }
          }
          
          var status_list = item.vendor_email.flatMap(status => (status.sent_invite === true) ? labels[this.props.user.language]["admin_page"].sent_invite : labels[this.props.user.language]["admin_page"].not_invite)
          var dateList = item.vendor_email.flatMap(date => (date.sent_invite === true) ? moment(date.sent_invite_date).format("DD-MMM-YYYY") : "")
         
          if (cocd_list.length !== 0 && item.role === "vendor") {
            tableData.push([
              item.vendor_number,
              item.vendor_name,
              item.vendor_email,
              status_list,
              dateList,
              cocd_list.join(","),
              disable_status
            ])

            modified_main.push(item)
          }
          ////console.log(tableData)
        })
        this.setState({ displayData: tableData, mainData: modified_main })


      })
  }

  sendInvitation = (selectedRows, displayData) => {
    let app_url;
    this.Ajax = new AjaxService();
    this.setState({ loadingOpen: true })
    this.Ajax.getAppUrl()
      // .then(result => result.json())
      .then(res => {
        console.log(res)
        if (res.hasOwnProperty('url')) {
          app_url = res.url;
          var email_list = []
          
          console.log("after api called---", app_url)
          var html_content = [
            {
              "type": "text/plain",
              "value": labels[this.props.user.language]["admin_page"].recipient +
              labels[this.props.user.language]["admin_page"].pmi +
              labels[this.props.user.language]["admin_page"].mail_part_1 +
              labels[this.props.user.language]["admin_page"].mail_part_2 +
              labels[this.props.user.language]["admin_page"].mail_part_3 +
              labels[this.props.user.language]["admin_page"].mail_part_4 +
              labels[this.props.user.language]["admin_page"].mail_part_5 +
              labels[this.props.user.language]["admin_page"].mail_part_16 +

              labels[this.props.user.language]["admin_page"].mail_part_6 +
              labels[this.props.user.language]["admin_page"].mail_part_7 +
              labels[this.props.user.language]["admin_page"].mail_part_8 +
                app_url +"\r\n\r\n"+
                labels[this.props.user.language]["admin_page"].mail_part_9 +
                labels[this.props.user.language]["admin_page"].mail_part_14 +
                labels[this.props.user.language]["admin_page"].mail_part_15 +
                labels[this.props.user.language]["admin_page"].mail_part_10 +
                labels[this.props.user.language]["admin_page"].mail_part_13 +
               
                labels[this.props.user.language]["admin_page"].mail_part_11 +
                labels[this.props.user.language]["admin_page"].mail_part_12

            },
            {
              "type": "text/html",
              "value": "<html> Dear Recipient," + "<br></br>" +
                "Greetings from Philip Morris International!" + "<br></br>" +
                "As part of our ongoing efforts to improve our services to our vendors, we are delighted to invite you to our new <b>Supplier Self Service Portal</b>. This online portal will be your one stop destination for:" + "<br></br>" +
                "<li>Reviewing and extracting all invoice and payment related information</li>" +
                "<li>Submitting queries to the Accounts Payable helpdesk </li>" +
                "<li>Invoice submission for processing (for selected regions & markets) </li>" +
                "<li>e-PDF declaration for electronic invoice submission (for selected regions & markets) </li>" + "<br></br>" +
                "Note: In Asia, Self Service Portal will be replacing the current vendor portal EIPOS."+ "<br></br>" +
                "For you to be able to login to the portal, please complete your registration by clicking on Register below. It will not take you more than a few minutes to register. For security purposes, this online portal leverages IBM’s Single Sign On service for user authentication. " + "<br></br>" +
                "Once you click on  Register below, you will be redirected to the self-service portal where you will be able to watch a short video on how to register for IBM Single Sign On service. " + "<br></br>" +
                "<a href=" + app_url + " target='_blank'><button style='font-size:16px; color:#fff; padding:5px 25px; border:none; background:#004f90; text-align:center; margin: 0 auto; max-width:150px; width:100%;' > Register</button></a> " + "<br></br>" +
                "In case you have any difficulty in accessing the portal or need help with registration, please feel free to reach out to us at  PMISelfServicePortal_SupportforSuppliers.PMIFIN@pmi.com" + "<br></br>" +
                
                "Please be aware that this invitation is unique to your individual registration purposes. Do not forward to other users, as they will not be able to log in through this link."+"<br></br>" +
                "If you wish to invite a person from your company who has not been invited to the Self Service Portal yet, please log in, go to the “Raise a Query” section and select the “Invite Others” feature. A simple and intuitive form will guide you through the invitation process.  Upon successful verification, the invitation will be sent to your invitee. "+ "<br></br>" +
                "Thanking you and assuring you the best of our services always." + "<br></br>" +
                "Note: We process personal data about you in accordance with the <a href='https://pmiprivacy.com/en/business-partner' target='_blank'>PMI business partner privacy notice.</a>" + "<br></br>" +
                "Sincerely," + "<br></br>" +
                "PMI Purchase to Pay" + "<br></br>" +
                "Philip Morris International Inc., or one or more of its subsidiaries."+"<br></br>" +
                "<img   width='20%' style='margin-top:30px; max-width:201px; min-width:100px; max-height:60px; min-height:40px' alt='Phillips Morris International' src='http://cdn.mcauto-images-production.sendgrid.net/a1dec2fc1cf76e19/6dd38a13-0306-4b43-9e42-dbb702b0ee1c/800x184.png'></html>"

            }


          ]
          var subject = "Self Service Portal Vendor Onboarding Invitation"
          var data_index = []
          var v_numbers = []
          console.log(this.state.mainData)
          console.log(selectedRows)
          const update_sent_date = this.state.mainData.map((data, k) => {
            Object.keys(selectedRows.lookup).map((ind) => {
              if (k == ind) {

                v_numbers.push(data.vendor_number)
                // data.vendor_email.forEach(el => { ///avoid this and only use vendor numbers in the backend
                //   if (el.sent_invite === false) {
                //     email_list.push(el.email)
                //     // data_index.push(k)
                //     // el.sent_invite = true
                //     // el.admin = this.props.user.username
                //     // el.sent_invite_date = moment(new Date()).format()
                //   }
                // })
              }

            })

            // return data
          })

          let updated_data = []
          // update_sent_date.map((obj1, index) => {
          //   data_index.map(obj2 => {
          //     if (index === obj2) {
          //       updated_data.push(obj1)
          //     }
          //   })
          // })

  

          // email_list = email_list.filter(function (item, index, inputArray) {
          //   return inputArray.indexOf(item) == index;
          // });
    
          console.log(v_numbers)
          // console.log(updated_data)
          // if (email_list.length !== 0) {
            this.Ajax = new AjaxService();
            this.Ajax.sendInvitation(subject, html_content,v_numbers,this.props.user.username )
              .then(result => result.json())
              .then(res => {
                console.log(res)
                if (res.status === "success") {
                  // this.getAllData()

                  this.setState({ loadingOpen: false, dialogOpen: true, category: "success" })
                } else if(res.status === "already exists"){ 
                  this.setState({ dialogOpen: true, category: "empty",loadingOpen: false })
                } else{
                  this.setState({ loadingOpen: false, dialogOpen: true, category: "failed" })
                }
              })
          // } else {
          //   this.setState({ dialogOpen: true, category: "empty",loadingOpen: false })
          // }
        } else {
          this.setState({ dialogOpen: true, category: "failed",loadingOpen: false })
        }
      })
      }

  handleClickOk = () => {
    this.getAllData()
    this.setState({ dialogOpen: false })
  }

  handleClose = () => {

  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiBackdrop: {
          root: {
            zIndex: 1
          }
        },
        MUIDataTableFilter: {
          padding: "9px 20px 33px 20px",
          fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
          title: {
            fontWeight: 400,
            marginLeft: 0
          },
          resetLink: {
            fontSize: 14,
            fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
            fontWeight: 400
          }
        },
        MuiFormLabel: {
          root: {
            fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
            fontWeight: 400
          }
        },
        MuiButton: {
          textPrimary: {
            color: "#004f90"
          }
        },
        MUIDataTableBodyCell: {
          root: {
            textAlign: "left"
          }
        },
        MuiGridListTile: {
          root: {
            paddingTop: "0 !important"
          },
          tile: {
            marginTop: "0 !important"
          }
        },
        MUIDataTable: {
          responsiveScroll: {
            overflowY: "scroll"
          }
        },

        MUIDataTableHeadCell: {
          fixedHeader: {
            backgroundColor: "#f6f6f6",
            color: "#0160a5"
          },
          root: {
            padding: "10px",
            verticalAlign: "top",
            '&:nth-child(3)': {
              width: '10% !important'
            },
          }
        },
        MuiToolbar: {
          root: {
            background: "#f6f6f6"
          },
          regular: {
            paddingLeft: "0"
          },
          gutters: {
            paddingLeft: "0"
          }
        },
        MuiTableCell: {
          root: {
            padding: "13px",
            paddingLeft: "11px",
            verticalAlign: "top",
            fontFamily:
              "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
          },
          body: {
            fontSize: "14px !important",
            // fontWeight: "600"
            '&:nth-child(7)': {
              wordBreak: "break-all !important"
            }
          },
          head: {
            fontSize: "15px !important",
            fontWeight: "600",
            '&:nth-child(6)': {
              width: "10% !important"
            },
            '&:nth-child(7)': {
              width: "25% !important"
            },
          }
        },
        MUIDataTableSearch: {
          main: {
            position: "absolute",
            right: "12rem",
            bottom: "10px",
            width: "48%"
          },
          searchIcon: {
            display: "none"
          }
        },
        MuiInputBase: {
          root: {
            fontFamily:
              "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
          },
          input: {
            paddingTop: "5px",
            border: "1px solid #ccc",
            paddingLeft: "5px",
            paddingRight: "20px",
            borderBottom: "1px solid #ccc",
            fontSize: "13px !important",
            fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
          }
        },
        MUIDataTableToolbar: {
          filterPaper: {
            maxWidth: "46% !important",
            left: "650px !important"
          }
        },
        MUIDataTablePagination: {
          root: {
            fontFamily:
              "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
            "&:last-child": {
              padding: "0px"
            }
          }
        },
        MuiMenuItem: {
          root: {
            fontFamily:
              "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
            fontSize: "13px !important"
          }
        },
        MuiIconButton: {
          root: {
            padding: "5px",
            "&:focus": {
              outline: "none !important"
            },
            "&:hover": {
              // color: "#004f90 !important",
              backgroundColor: "transparent !important"
            }
          }
        },
        MuiPaper: {
          elevation4: {

          }

        },
        MuiSelect: {
          icon: {
            top: "-3px",
            right: "-9px"
          }
        },
        MuiSvgIcon: {
          root: {
            width: "1.3em",
            height: "1.3em",
            fontFamily:
              "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
            // paddingLeft: "15px"
          }
        },
        MuiTablePagination: {
          select: {
            border: "none"
          },
          selectIcon: {

          }
        },
        MuiTable: {
          root: {
            fontFamily:
              "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
          }
        },
        MuiTypography: {
          caption: {
            fontFamily:
              "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
            fontSize: "1rem !important"
          },
          h6: {
            paddingLeft: 8,
            fontSize: "1.5rem !important",
            fontWeight: "normal",
            fontFamily:
              "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
          }
        },
        MUIDataTableFilterList: {
          chip: {
            margin: "8px 8px 8px 0px"
          }
        },
        MuiChip: {
          root: {
            fontSize: 13,
            fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
            backgroundColor: "#f4f4f4",
            border: "1px solid #004f90"
          },
          deleteIcon: {
            fill: "#004f90"
          }
        },
        MuiCheckbox: {
          MuiIconButton: {
            root: {
              color: "#004f90"
            }
          }
        },
        MuiTableRow: {
          root: {
            '&$selected': {
              backgroundColor: '#0067bc2e !important'
            },
            '&$disabled': {
              backgroundColor: '#0067bc2e !important'
            }
          }
        },
        MUIDataTableSelectCell: {
          checked: { color: "#004f90 !important" }
        }
      }
    });
  //
  render() {
    const { displayData, company_codes } = this.state
    var language_labels = labels[this.props.user.language]["admin_page"];
    // ////console.log(company_codes)
    ////console.log(this.state.loadingOpen)
    const columns = [
      {
        name: 'Vendor Number',
        options: {
          sortThirdClickReset: true,
          sort: false
        }
      },
      {
        name: 'Vendor Name',
        options: {
          sortThirdClickReset: true,
          sort: false
        }
      },
      {
        name: 'Vendor Email',
        options: {
          sortThirdClickReset: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => (
            <List className="email-list">
              {value.map((email, key) => {
                return (
                  
                  <ListItem key={key} className="email-list-item">

                    <ListItemText primary={
                      <Typography>
                        {email.email}{email.sent_invite === true ? <span><CheckCircleOutlineIcon className="done-icon" /></span> : <span></span>}</Typography>} />
                  </ListItem>
                )
              })}
            </List>

          )
        }
      },
      {
        name: 'Status',
        options: {
          sortThirdClickReset: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => (
            <List className="email-list">
              {value.map((status) => {
                ////console.log(status)
                return (
                  <ListItem className="email-list-item">

                    <ListItemText primary={
                      <Typography>
                        {status}
                      </Typography>} />
                  </ListItem>
                )
              })}
            </List>

          )
        }
      },
      {
        name: 'Invitation sent date',
        options: {
          sortThirdClickReset: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => (
            <List className="email-list">
              {value.map((date) => {
                ////console.log(date)
                return (
                  <ListItem className="email-list-item">

                    <ListItemText primary={
                      <Typography>
                        {date}
                      </Typography>} />
                  </ListItem>
                )
              })}
            </List>

          )
        }
      },
      {
        name: 'Company Code',
        options: {
          sortThirdClickReset: true,
          sort: false
        }
      },
      {
        name: 'Disable Status',
        options: {
          display: 'excluded',
          sort: false
        }
      },


    ];

    let options = {
      selectableRows: 'multiple',
      print: false,
      search: true,
      download: true,
      filter: false,
      viewColumns: false,
      textLabels: {
        selectedRows: {
          text: "Vendor(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        }
      },
      downloadOptions: {
        filename: "VendorInvitationReport.csv",
        separator: ','
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        ////console.log(data)
        var download_arr = data.map(y => {
          let sent = []
          let not_sent = []
          y.data[2].forEach(p => {
            if (p.sent_invite === true) {
              sent.push(p.email)
            } else {
              not_sent.push(p.email)
            }
          })
          var arr = y.data[5].split(',')
          ////console.log(arr)

          ////console.log(y.data[5].split(","))
          return ([y.data[0], y.data[1], sent, not_sent, y.data[3], y.data[4], y.data[5]])
        })

        ////console.log(download_arr)
        return (
          buildHead([
            {
              name: "Vendor Number",
              download: true
            },
            {
              name: "Vendor Name",
              download: true
            },
            {
              name: "Vendor Email sent",
              download: true
            },
            {
              name: "Vendor Email not sent",
              download: true
            },
            {
              name: "Company Code",
              download: true
            }
          ]) +
          buildBody(
            download_arr.map(x => ({
              ...x,
              data: [
                x[0],
                x[1],
                x[2].join(","),
                x[3].join(","),
                "\"" + x[6].split(",") + "\""
              ]

            }))
          )
        )
      },
      customToolbar: () => {
        return (
          <span>
            <Select
              value={this.state.selectedCompanyCode}
              placeholder={language_labels.admin_company_code}
              onChange={(event) => this.handleChange(event)}
            >
              <MenuItem value="Select Company Code" style={{ paddingLeft: "5 !important" }}>{language_labels.admin_company_code}</MenuItem>
              <MenuItem value="reset" style={{ paddingLeft: "5 !important" }}>{language_labels.admin_all }</MenuItem>
              {this.state.company_codes && this.state.company_codes.map((option, o) => {
                return (<MenuItem style={{ paddingLeft: "5 !important" }} value={option} key={o}>{option}</MenuItem>)
              })}

            </Select>

          </span>
        )

      },
      customToolbarSelect: (selectedRows, displayData) => (
        <div>
        {this.props.user.role !== "support" &&  <Button
            onClick={(event) => this.sendInvitation(selectedRows, displayData)}
            className="send-btn"
            disabled={true}
          >
          
            {language_labels.send_invite}
        </Button>}
        </div>


      ),
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach((col,i) => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          } else {
            if (i === 2) {
              col.forEach(emailobj => {
              if (emailobj["email"].toString().indexOf(searchQuery) >= 0) {
                isFound = true;
              }
            })
            }
          }
        });
        return isFound;
      }
      // isRowSelectable: (dataIndex, selectedRows) => {
      //   ////console.log(this.state.displayData)
      //   return this.state.displayData[dataIndex][6]
      // }
    };

    return (
      <div>
        {(this.props.user.role === "admin" || this.props.user.role === "support") && <div className="container-fluid custom-container ">
          <NavBar appClass="admin-page" />
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            timeout={1000 * 60 * 10}
            onIdle={this.handleOnIdle}
            debounce={250}
          />
          <Backdrop onBackdropClick="false" open={this.state.loadingOpen} onClick={(event) => this.handleLoadingClose("spinner")}>
            <CircularProgress className="circular-spinner" />
          </Backdrop>
          <Dialog
            open={this.state.dialogOpen}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {this.state.category === "success" && <DialogTitle className="invite-dialog">{"Invitation Sent"}<span><CheckCircleOutlineIcon className="sent-icon" /></span></DialogTitle>}
            {this.state.category === "failed" && <DialogTitle className="invite-dialog">{"Failed"}<span><HighlightOffIcon className="fail-icon" /></span></DialogTitle>}
            {this.state.category === "empty" && <DialogTitle className="invite-dialog">{"Invitation Sent Already"}</DialogTitle>}
            
            <DialogContent>
              <DialogContentText >
                {this.state.category === "success" && "Registration Invitation has been sent to the selected Vendors"}
                {this.state.category === "failed" && "Request has failed. Please try again later."}
                {this.state.category === "empty" && "Registration Invitation has been sent already"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClickOk} className="msg-btn">
                OK
          </Button>

            </DialogActions>
          </Dialog>
          <div className="col-12 admin-list-col admin-div">
            {displayData && <MuiThemeProvider theme={this.getMuiTheme()}> <MUIDataTable
              title={"Vendors list"}
              data={displayData}
              columns={columns}
              options={options}
            /></MuiThemeProvider>}

          </div>

        </div>}
        {this.props.user.role !== "admin" && this.props.user.role !== "support" && <NotFound history={this.props.history} />}

      </div>

    );
  }
}

AdminPage.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  user: state.user
})
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(AllActions, dispatch)
})


export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);