// import { applyMiddleware, createStore } from "redux"
import { createLogger } from 'redux-logger'

// import promise from "redux-promise-middleware"

// import reducer from "./reducers"

// const middleware = applyMiddleware(promise(), thunk, createLogger())

// const store = createStore(reducer, middleware)

// export default store


import { createStore,applyMiddleware } from "redux";
import reducer from "./reducers";
 import thunk from "redux-thunk"

const store = createStore(reducer, applyMiddleware(thunk,createLogger()))

export default store
