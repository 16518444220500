import React, { Component } from "react";
import "../queryPage.scss";
import Navbar from "./NavBar";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import AjaxService from "../services/AjaxServices";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { labels } from "./../resources";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
);


class QueryHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      left: "",
      right: "",
      container: "",
      copied: "",
      selectedCategory: "",
      randomString: "",
      textAreaValue: "",
      textFieldValue: "",
      is_slide: false,
      textFieldClass: "id-field-query",
      headerTextFieldClass: "textFieldRoot",
      files: [
        {
          source: "index.html",
          options: {
            type: "local",
          },
        },
      ],
      open: false,
      note_msg : false
    };
  }

  handleInit() {
    console.log("FilePond instance has initialised", this.pond);
  }

  componentDidMount() {
    let left = document.querySelector(".left");
    let right = document.querySelector(".right");
    let container = document.querySelector(".containerQuery");
    this.setState({
      left: left,
      right: right,
      container: container,
    });
  }


  handleTextAreaChange = (e) => {
    this.setState({ textAreaValue: e.target.value });
  }

  handleTextFieldChange = (e) => {
    this.setState({ textFieldValue: e.target.value });
  }


  handleHoverSlide = (classUsed, first_label, second_label) => () => {
    var selectedCategory = first_label + " " + second_label;
    this.state.container.classList.add(classUsed);
    this.setState({
      selectedCategory: selectedCategory,
      is_slide: true,
    });
    console.log(selectedCategory);
    if (selectedCategory === "General Enquiry") {
      this.setState({
        textFieldClass: "id-field-query-none",
        headerTextFieldClass: "textFieldRoot_enquiry",
      });
    } else {
      this.setState({
        textFieldClass: "id-field-query",
        headerTextFieldClass: "textFieldRoot",
      });
    }
  };
  handleHoverSlideLeave = (classUsed) => () => {
    this.state.container.classList.remove(classUsed);
  };

  handleRaiseQuery = () => {
    this.setState({note_msg : true})
  };

  handleSubmitQuery = () =>{
    this.Ajax = new AjaxService();
    var chars = "1234567890";
    var string_length = 8;
    var randomstring = "";
    var charCount = 0;
    var numCount = 0;
    for (var i = 0; i < string_length; i++) {
      if (
        (Math.floor(Math.random() * 2) == 0 && numCount < 3) ||
        charCount >= 5
      ) {
        var rnum = Math.floor(Math.random() * 10);
        randomstring += rnum;
        numCount += 1;
      } else {
        var rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(rnum, rnum + 1);
        charCount += 1;
      }
    }

    this.Ajax.queryUploadApi(randomstring, this.state.textAreaValue, this.state.textFieldValue, this.state.selectedCategory, this.state.files)
      .then(res => {
        console.log(res)
      })

    this.setState({
      open: true,
      randomString: randomstring,
      copied: false,
      note_msg:false
    });
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  render() {
    let language_labels = labels["english_labels"]["query_page"];
    return (
      <div>
        <Navbar
          appClass="query_page"
          logout_class={this.state.is_slide}
        ></Navbar>
        <div className="containerQuery">
          <div className="split left">
            <div>
              <h1 className="headerText">
                {language_labels["header_text_left"]}
                <p className="header-subtext">
                  {language_labels["header_subtext"]}
                </p>
              </h1>

              <Grid container spacing={2} className="paper" justify="center">
                <Grid item xs={6} sm={6} md={3} lg={3}>
                  <div
                    onClick={this.handleHoverSlide(
                      "hover-right",
                      language_labels["invoice"],
                      language_labels["status"]
                    )}
                  >
                    <Card className="cardRoot" variant="outlined">
                      <CardContent align="center">
                        <DescriptionOutlinedIcon className="invoicePaymentIcon" />
                        <Typography className="cardTitle" color="textSecondary">
                          {language_labels["invoice"]}
                          <br></br> {language_labels["status"]}
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>


                <Grid item xs={6} sm={6} md={3} lg={3}>
                  <div
                    onClick={this.handleHoverSlide(
                      "hover-right",
                      language_labels["payment"],
                      language_labels["remittance"]
                    )}
                  >
                    <Card className="cardRoot" variant="outlined">
                      <CardContent align="center">
                        <MonetizationOnOutlinedIcon className="invoicePaymentIcon" />
                        <Typography className="cardTitle" color="textSecondary">
                          {language_labels["payment"]}
                          <br></br> {language_labels["remittance"]}
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3}>
                  <div
                    onClick={this.handleHoverSlide(
                      "hover-right",
                      language_labels["general"],
                      language_labels["enquiry"]
                    )}
                  >
                    <Card className="cardRoot" variant="outlined">
                      <CardContent align="center">
                        <ContactSupportOutlinedIcon className="invoicePaymentIcon" />
                        <Typography className="cardTitle">
                          {language_labels["general"]}
                          <br></br> {language_labels["enquiry"]}
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="split right">
            <h1 className="headerText_general selectedCategory">
              {language_labels["header_text_right"]}{" "}
              {this.state.selectedCategory}
            </h1>
            <form className={this.state.headerTextFieldClass}>
              {this.state.selectedCategory === "Payment Remittance" ? (
                <TextField
                  className={this.state.textFieldClass}
                  onChange={this.handleTextFieldChange}
                  placeholder={language_labels["remittance_placeholder"]}
                  required
                  inputProps={{
                    style: {
                      color: "black",
                      paddingLeft: "5px",
                    },
                  }}
                />
              ) : (
                  <TextField
                    className={this.state.textFieldClass}
                    onChange={this.handleTextFieldChange}
                    placeholder={language_labels["invoice_no_placeholder"]}
                    required
                    inputProps={{
                      style: {
                        color: "black",
                        paddingLeft: "5px",
                      },
                    }}
                  />
                )}

              <br />
              <br />
              <TextField
                className="id-field-query"
                placeholder={language_labels["query_placeholder"]}
                multiline
                rows={4}
                onChange={this.handleTextAreaChange}
                inputProps={{
                  maxLength: 250,
                  style: {
                    color: "black",
                    paddingLeft: "5px",
                  },
                }}
                rowsMax={5}
              />

              <FilePond
                className="filepond"
                ref={(ref) => (this.pond = ref)}
                name="userfiles"
                allowFileTypeValidation={true}
                allowFileSizeValidation={true}
                required
                maxFileSize="10MB"
                labelMaxFileSizeExceeded="Maximum file size should be 10MB"
                acceptedFileTypes="image/*, application/pdf, 
                  application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.spreadsheet, .xlsx, .csv"
                labelFileTypeNotAllowed="Files type is not supported"
                allowMultiple={true}
                oninit={() => this.handleInit()}
                onupdatefiles={(fileItems) => {
                  this.setState({
                    files: fileItems.map((fileItem) => fileItem.file),
                  });
                }}
              />
              <br />
              <br />
              <div></div>
              <Grid container spacing={1} className="paper" justify="center">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography className="note" component="p">
                    {language_labels["note"]}
                  </Typography>
                  <br></br>
                  <Typography className="note_part_two" component="p">
                    {language_labels["note_part_two"]}
                  </Typography>
                
                  {/* <ul className="bullet-list">
                    <li> {language_labels["note"]}</li>
                    <li>{language_labels["note_part_two"]}</li>
                    <li>{language_labels["note_three"]}</li>
                  </ul> */}
                  <a
                    onClick={this.handleRaiseQuery}
                    className="button leftPosition"
                  >
                    {language_labels["submit_query"]}
                  </a>
                  <Dialog
                    open={this.state.open}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle id="alert-dialog-slide-title">
                      {language_labels["dailog_header"]}
                      {"   "}
                      {/* 
                      <CopyToClipboard
                        text={this.state.randomString}
                        onCopy={() => this.setState({ copied: true })}
                      >
                        <Tooltip title="Copy Number" placement="bottom">
                          <FileCopyIcon className="copyIcon"></FileCopyIcon>
                        </Tooltip>
                      </CopyToClipboard>
                      {this.state.copied ? (
                        <span
                          style={{ color: "#008000bf", fontSize: "0.85rem" }}
                        >
                          {" "}
                          {language_labels["copied"]}
                        </span>
                      ) : null} */}
                    </DialogTitle>

                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        {language_labels["dailog_content"]}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleClose} color="primary">
                        {language_labels["close_button"]}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={this.state.note_msg}
                    keepMounted
                    onClose={this.handleSubmitQuery}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                  >
                    <DialogTitle >
                    {"Please note"}
                    </DialogTitle>

                    <DialogContent>
                      <DialogContentText >
                      {language_labels["note_three"]}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleSubmitQuery} color="primary">
                        {"OK"}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid Grid item xs={12} sm={12} md={6} lg={6}>
                  {/* <a
                    onClick={this.handleHoverSlideLeave("hover-right")}
                    className="button rightPosition"
                  >
                    {language_labels["select_another_category"]}
                  </a> */}
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default QueryHome;
