import React, { Component } from 'react';
import '../home.scss';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as AllActions from '../actions'
import PropTypes from 'prop-types'
import NavBar from './NavBar'
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import { labels } from './../resources'
import schedule from './../assests/icons8-schedule-100.png'
import inv_status from './../assests/inv-status.png'
import inv_submission from './../assests/inv-submission.png'
import query_img from './../assests/icons8-query-100.png'
import faq_img from './../assests/icons8-faq-100.png'
import IdleTimer from 'react-idle-timer'
import AjaxService from "../services/AjaxServices";
import NotFound from './NotFound';
import Cookies from 'js-cookie';
import Snackbar from '@material-ui/core/Snackbar';
import Backdrop from '@material-ui/core/Backdrop';

import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import LandingCard from './landingCard';
import { useState } from 'react';
import BannerContent from './bannerContent';


//changes for dummy push changes
//changes
// var labels = require('./../resources.json')
class LandingPage extends Component {
    constructor(props) {
        super(props);

        this.idleTimer = null
        this.handleOnIdle = this.handleOnIdle.bind(this)
        this.state = {
            access_cookie: Cookies.get("access-cookie"),
            showText_one: false,
            showText_two: false,
            showVendorDialog: false,
            selected_email_response: "",
            checkedVendor: "",
            isVendorNull: true,
            validate: false,
            validateMessage: '',
            loadingOpen: false,
            loadingOpenSpinner: false,
            showEmailDialog: false,
            textfield_error: false,
            error_text: "",
            logout: false,
            userEmail: "",
            userNotFound:false,
            cardData :[
               
               
              ],
            bannerExist:false,
            //   editcardVal:"",
            //   editcard:false,
            //   editInd:"",
            //   editcardTitle:"",
            //   charLeft: ''
            // {id: 1, title: 'CARD 1', content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.", imgUrl: 'https://unsplash.it/200/200'},
            // {id: 2, title: 'CARD 2', content: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section 1.10.32.", imgUrl: 'https://unsplash.it/201/200'},
            // {id: 3, title: 'CARD 3', content: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).", imgUrl: 'https://unsplash.it/200/201'},
            // {id: 4, title: 'CARD 4', content: 'Tony Stark', imgUrl: 'https://unsplash.it/201/201'},
           
        };
        this.timer = null;
        this.bannerImgSrc='';
    }

    componentDidMount() {
        this.getCardDataFromDB();
        // this.props.user.cip_scans_run === false && 
        if (this.props.user.role !== "support" && this.props.user.role === "vendor") {
            
            if (sessionStorage.getItem("language") !== null) {
                var lang = sessionStorage.getItem("language")
                this.props.actions.change_language(lang)
            }
            this.Ajax = new AjaxService();
            //  console.log("1234---",this.props.user.response_arr )
            this.setState({
                loadingOpen: true,
                userEmail: this.props.user.response_arr.vendorEmail,
            });
            if (this.props.user.response_arr.vendors.length === 1) {
                this.setState({
                    selected_email_response: this.props.user.response_arr.vendors[0],
                    loadingOpen: false
                }, function () {
                    this.handleSelectVendor();
                   
                })

            } else {
                if (this.props.user.vendor_number === "") {
                    this.setState({
                        showVendorDialog: true,
                        loadingOpen: false
                    })
                } else {
                    this.setState({
                        loadingOpen: false
                    })
                }
            }

        } else {
            if(this.props.user.role === "support"){
                if (this.props.user.isVendorNull) {
                    this.setState({
                        showEmailDialog: true
                    })
                } else {
                    if (this.props.user.vendor_number === "" & this.props.user.response_arr.vendors.length !== 0) {
                        this.setState({
                            showVendorDialog: true
                        })
                    }
                }
            }else{
                this.setState({
                    userNotFound: true
                })
            }
          
        }


    }




    componentDidUpdate(prevProps) {
        if (prevProps.user.vendor_number !== this.props.user.vendor_number) {
            console.log("in if")
            if (this.props.user.vendor_number === "") {
                this.setState({
                    showVendorDialog: true
                })
            }
        }
    }

    handleClose = () => {
        if (this.props.user.bkp_vendor_number === "") {
            this.setState(
                {
                    validate: true,
                    validateMessage: labels[this.props.user.language]["home_page"].proceed_select
                }
            )
        } else {
            this.props.actions.restore_vendor_number()
            this.setState(
                {
                    showVendorDialog: false
                }
            )
        }

    }
    getCardDataFromDB= ()=>{
        if (this.props.user.cardData !== "" && this.props.user.role !== "support" ) {

            this.setState({ cardData: this.props.user.cardData, bannerExist: this.props.user.bannerExist, bannerImgSrc: this.props.user.bannerImgSrc })
        }
        else{
            this.Ajax = new AjaxService() ;
            this.Ajax.getCardFromDB()
            .then(result => {
                console.log("card api called",result);
                if(result.status== 200){
                   let res= result.card_result
                    this.setState({ cardData:res.card_details})
                    if(res.bannerTitle){
                        this.setState({bannerExist:true})
                        this.Ajax.downloadBannerImg()
                        .then(result => {
                            if(result.status== 200){
                                console.log("card api called",result);
                                this.setState({bannerImgSrc:result.msg})
                               //this.state.bannerImgSrc=result;
                                console.log(this.state.bannerImgSrc);
                                this.props.actions.setCardValues(res.card_details, result.msg,true)
                            }
                            else{
                                console.log("card api called",result); 
                            }
                          
                            
                        })
                    }
                    else{
                        this.setState({bannerExist:false})  
                        this.props.actions.setCardValues(res.card_details, "",false)
                    }
                   
                }
                else {
                    this.setState({ cardData:[]}) 
                   // this.props.actions.setCardValues([], "",false)
                }
              
            })
        }
      
    }
    handleSelectVendor = () => {
        let user_details
        let selectVendorObj = {}
        this.Ajax = new AjaxService()
        console.log("vendor obj--", this.state.selected_email_response, this.state.userEmail);

        if(this.props.user.response_arr.vendors.length === 1){
            selectVendorObj = this.props.user.response_arr.vendors[0]
            this.setState({
                loadingOpen: true
            })
        }else{
            selectVendorObj = this.props.user.response_arr.vendors[this.props.user.selected_vendor_index]
            this.setState({
                loadingOpenSpinner: true
            })
            
        }


        this.Ajax.getSelectedVendorDetails(selectVendorObj, this.state.userEmail)
            .then(response => response.json())//
            .then(vendor_details => {
                console.log(vendor_details)
                // if (this.props.user.selected_vendor_index !== "" && this.props.user.isVendorNull === false && this.state.selected_email_response === "") {
                //     console.log("in here")
                //     user_details = this.props.user.response_arr.vendors[this.props.user.selected_vendor_index]
                // } else {
                    user_details = vendor_details[0]
                // }
                let all_company_codes = user_details.CoCds_assigned.flatMap(code => code.code)
                let company_codes = user_details.CoCds_assigned.map(code => {
                    // console.log(code)
                    if (code.active_vendor.toLowerCase() !== "x" && code.companyBlockedForPosting.toLowerCase() !== "x") {
                        return code.code
                    }
                })
                company_codes = company_codes.filter(function (element) {
                    return element !== undefined;
                });
                var supplier_address = user_details.address + "," + user_details.city + "," + user_details.country
                console.log(company_codes)
                console.log(all_company_codes)
                let user_details_obj


                if (this.props.user.selected_vendor_index !== "" && this.props.user.isVendorNull === false) {
                    user_details_obj = {
                        "company_codes": company_codes,
                        "all_company_codes": all_company_codes,
                        "vendor_number": user_details.vendor_number,
                        "bkp_vendor_number": user_details.vendor_number,
                        "vendor_name": user_details.vendor_name,
                        "supplier_address": supplier_address,
                        "vat_number": user_details.vatNumber,
                        "role": this.props.user.role,
                        "selected_vendor_index": this.props.user.selected_vendor_index,
                        "company_code_ERP": user_details.company_code_ERP
                      //  "supplierIndustryKey":user_details.supplierIndustryKey
                    }
                } else {
                    user_details_obj = {
                        "company_codes": company_codes,
                        "all_company_codes": all_company_codes,
                        "vendor_number": user_details.vendor_number,
                        "bkp_vendor_number": user_details.vendor_number,
                        "vendor_name": user_details.vendor_name,
                        "supplier_address": supplier_address,
                        "vat_number": user_details.vatNumber,
                        "role": this.props.user.role,
                        "selected_vendor_index": this.props.user.selected_vendor_index,
                        "company_code_ERP": user_details.company_code_ERP
                    }
                }

                this.props.actions.store_user_details(user_details_obj)
                this.setState(
                    {
                        showVendorDialog: false,
                        loadingOpenSpinner: false,
                        loadingOpen:false
                    }
                )

            })

    }
    selected_vendor = (selected_email_response, index) => {
        console.log(selected_email_response)
        // if (this.props.user.selected_vendor_index !== "") {
        //     this.props.actions.clear_vendor_index()
        //     // this.props.actions.store_vendor_index(index)
        //     this.setState({
        //         selected_email_response: selected_email_response,
        //         checkedVendor: index
        //     })
        // } else {
            this.props.actions.store_vendor_index(index)
            this.setState({
                selected_email_response: selected_email_response,
                checkedVendor: index
            })
        // }

    }

    handleEmailChange = (e) => {
        const emailRegex = new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]');
        if (e.target.value.match(emailRegex)) {
            this.setState({ textfield_error: false, error_text: "", email_entered: e.target.value })
        } else {
            this.setState({ textfield_error: true, error_text: "Please enter valid email id", email_entered: e.target.value })
        }

    }

    handleSupportCW = () => {
        this.Ajax = new AjaxService();
        if (this.props.user.validated_email !== this.state.email_entered) {
            this.Ajax.getSupportDetails(this.state.email_entered)
                .then(response => response.json())
                .then(details => {
                    console.log(details)
                    if (!details.hasOwnProperty("error_msg")) {
                        if (details[0].vendorEmail !== "" && details[0].vendors.length !== 0) {
                            this.props.actions.store_support_login_response(details[0])
                            this.setState({
                                showVendorDialog: true,
                                showEmailDialog: false,
                                textfield_error: false,
                                error_text: "",
                                logout: false
                            })
                        } else {
                            this.setState({
                                textfield_error: true,
                                error_text: "This vendor does not exist. Please try again with a different vendor",
                                logout: true
                            })
                        }
                    } else {
                        this.setState({
                            textfield_error: true,
                            error_text: "This vendor does not exist. Please try again with a different vendor",
                            logout: true
                        })
                    }

                }).catch(function (err) {
                    console.log(err)
                    this.setState({
                        textfield_error: true,
                        error_text: "Error while submitting your request. Please try again later.",
                        logout: true
                    })

                })
        } else {
            this.setState({
                textfield_error: true,
                error_text: "The Email Id entered is of support access. Please enter a vendor Email Id",
                logout: true
            })
        }

    }

    handleOnIdle() {


        this.Ajax = new AjaxService();
        this.Ajax.logout().then(result => {
            //console.log("logged out")
            sessionStorage.clear();
            localStorage.clear()
            this.props.actions.return_default()
            this.props.history.length = 0;

            this.props.history.replace("/");
        })
    }
    handleHover = (status, index) => () => {
        if (index === "0") {
            this.setState({
                showText_one: status
            })
        } else {
            this.setState({
                showText_two: status
            })
        }
    }

    handleCloseFlag = () => {
        this.setState({ validate: false })
    }
    handleCloseEmailDialog = () => {
        if (this.state.logout) {
            this.handleOnIdle()
        } else {
            this.setState({ showEmailDialog: false })
        }

    }

    handleDifferentEmail = () => {
        this.setState({ showEmailDialog: true, showVendorDialog: false, email_entered: "" })
    }

    handleNavigation = (page) => {
        if (page === "faq") {
            this.props.history.push(`/faqs`)
        }

        if (page === "raise-query") {
            if (this.props.user.isVendorSelected && this.props.user.vendor_number !== "") {
                this.props.history.push(`/QueryHome`)
            } else {

            }

        }

        if (page === "inv-status") {
            //console.log("here")
            if (this.props.user.isVendorSelected && this.props.user.vendor_number !== "") {
                this.props.history.push(`/invoicelist`)
            } else {

            }

        }
        if (page === "inv-submission") {
            if (this.props.user.isVendorSelected && this.props.user.vendor_number !== "") {
                this.props.history.push(`/regions`)
            } else {

            }
        }
        if (page === "pay") {
            if (this.props.user.isVendorSelected && this.props.user.vendor_number !== "") {
                this.props.history.push(`/paymentSchedule`)
            } else {

            }
        }


    }
    addCard =()=>{
        var len=this.state.cardData.length;
        len++;
        console.log(this.state.cardData)
        console.log("new card ind --",len)
         this.setState({ cardData: [...this.state.cardData,{id: len, title: 'CARD  Title', content: 'Card Content' }]})
         
         console.log(this.state.cardData)
       }
   
       saveCard=(i)=>{
         console.log(i,"save data")
         let ind= --i;
         console.log("--"+ind+"--",this.state.editcardVal)
         let cardData = [...this.state.cardData];
         let item = {...cardData[ind]};
         console.log("----"+item.content)
         item.content=this.state.editcardVal;
         item.title=this.state.editcardTitle;
         cardData[ind] = item;
         this.setState({cardData});
         console.log(JSON.stringify(this.state.cardData))
         this.setState({editcard:false})
         this.setState({editInd:""})
        
       }
      

    render() {
        const { user } = this.props

        console.log(sessionStorage.getItem("language"))

        var language_labels = labels[this.props.user.language]["home_page"];
        var language_text=labels[this.props.user.language]["admin_page"];
        const assistance_data = [
            {
                "icon": 'question_answer',
                "text": language_labels.floating_query
            },
            {
                "icon": 'help_outline',
                "text": language_labels.floating_faq
            }
        ]
         

        return (
            <div className='bck-img'>
                {user.role && (user.role === "vendor" || user.role === "support") && <div className="container-fluid no-padding home-main-div">
                    <NavBar appClass="home" />
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref }}
                        timeout={1000 * 60 * 10}
                        onIdle={this.handleOnIdle}
                        debounce={250}
                    />
                    <Backdrop onBackdropClick="false" open={this.state.loadingOpen} onClick={(event) => this.handleDialogClose("spinner")}>
                        <CircularProgress className="circular-spinner" />
                    </Backdrop>
                    <Snackbar ContentProps={{

                    }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}

                        open={this.state.validate}
                        onClose={this.handleCloseFlag}
                        autoHideDuration={6000}
                        message={this.state.validateMessage}
                    />
                    <Dialog
                        open={this.state.showEmailDialog}
                        onClose={this.handleCloseEmailDialog}
                        disableBackdropClick={true}
                        disableEscapeKeyDown={true}
                        className="vendor-dialog"
                    >
                        <DialogTitle className="text-dialog">{"Please enter a Vendor Email ID"}</DialogTitle>
                        <DialogContent>

                            <TextField
                                value={this.state.email_entered}
                                onChange={(event) => this.handleEmailChange(event)}
                                // className={this.state.textfield_class}
                                error={this.state.textfield_error}
                                id="emailId"
                                placeholder="Email"
                                helperText={this.state.error_text}
                                inputProps={{
                                    style: {

                                        paddingLeft: "5px",
                                        fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                                    }
                                }}
                            />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleSupportCW} className="msg-btn">
                                {language_labels.ok_btn}
                            </Button>
                           
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.showVendorDialog}
                        onClose={this.handleClose}
                        disableBackdropClick={true}
                        disableEscapeKeyDown={true}
                        className="vendor-dialog"
                    >
                        <DialogTitle className="text-dialog">{language_labels["vendor_selection"]}</DialogTitle>
                        <DialogContent className='vendor-list-content'>
                        <Backdrop disableBackdropClick={true} open={this.state.loadingOpenSpinner}>
                                        <CircularProgress className="circular-spinner-home" />
                                    </Backdrop>
                            <List component="nav" >
                                {user.response_arr.vendors && user.response_arr.vendors.map((num, i) => {
                                    return (
                                          
                                        <ListItem button key={i} onClick={(event) => this.selected_vendor(num, i)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={this.state.checkedVendor === i || this.props.user.selected_vendor_index === i}
                                                    git exdisableRipple

                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={num.vendorName} secondary={num.vendorId} />
                                          
                                        </ListItem>

                                        
                                     
                                    )
                                })}

                            </List>
                        </DialogContent>
                        <DialogActions>
                            {this.props.user.role === "support" && <Button onClick={this.handleDifferentEmail} className="msg-btn">
                                {"Enter different Email Id"}
                            </Button>}
                            <Button disabled={this.props.user.isVendorNull} onClick={this.handleSelectVendor} className="msg-btn">
                                {language_labels.ok_btn}
                            </Button>
                          

                        </DialogActions>
                    </Dialog>
                    <div >

                        <div className="content-subheader" style={(this.state.cardData.length==0 && this.state.bannerExist === false)?{height:'100vH'}:{height:'80vh'}}>


                            <Grid container className="card-container">
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <h3 className="marquee-text">
                                        {language_labels["welcome"]}
                                    </h3>
                                </Grid>
                                <Grid item xs={12} sm={6} md={2} lg={2} className="card-item">
                                    <div className="tiles" onClick={(event) => this.handleNavigation("inv-status")}>
                                        <img src={inv_status} className="tile-image" />
                                        <p className="card-title">{language_labels["inv_status_title"]}</p>
                                        <p className="text-description">{language_labels["card_1"]}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={2} lg={2} className="card-item">
                                    {this.props.user.role !== "support" && <div className="tiles" onClick={(event) => this.handleNavigation("inv-submission")}>
                                        <img src={inv_submission} className="tile-image" />
                                        <p className="card-title">{language_labels["inv_sub_title"]}</p>
                                        <p className="text-description">{language_labels["card_2"]}</p>
                                    </div>}
                                    {this.props.user.role === "support" && <div className="tiles">
                                        <img src={inv_submission} className="tile-image" />
                                        <p className="card-title" style={{ color: "#8080809e" }}>{language_labels["inv_sub_title"]}</p>
                                        <p className="text-description">{language_labels["card_2"]}</p>
                                    </div>}
                                </Grid>
                                <Grid item xs={12} sm={6} md={2} lg={2} className="card-item">
                                    {this.props.user.role !== "support" && <div className="tiles" onClick={(event) => this.handleNavigation("raise-query")}>
                                        <img src={query_img} className="tile-image" />
                                        <p className="card-title">{language_labels.raise_query}</p>
                                        <p className="text-description">{language_labels["card_3"]}</p>
                                    </div>}
                                    {this.props.user.role === "support" && <div className="tiles" >
                                        <img src={query_img} className="tile-image" />
                                        <p className="card-title" style={{ color: "#8080809e" }}>{language_labels.raise_query}</p>
                                        <p className="text-description">{language_labels["card_3"]}</p>
                                    </div>}
                                </Grid>
                                <Grid item xs={12} sm={6} md={2} lg={2} className="card-item">
                                    <div className="tiles" onClick={(event) => this.handleNavigation("faq")}>
                                        <img src={faq_img} className="tile-image" />
                                        <p className="card-title">{language_labels.faq}</p>
                                        <p className="text-description">{language_labels["card_4"]}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={2} lg={2} className="card-item">
                                    <div className="tiles" onClick={(event) => this.handleNavigation("pay")}>
                                        <img src={schedule} className="tile-image" />
                                        <p className="card-title">{labels[this.props.user.language]["payment_schedule"]["title"]}</p>
                                        <p className="text-description">{labels[this.props.user.language]["payment_schedule"]["payment_description"]}</p>
                                    </div>
                                </Grid>

                            </Grid>
                        </div>


                       
                    </div>


                 <div className="containerGrid">
       
                     <BannerContent  user={user} getCardDataFromDB={this.getCardDataFromDB} bannerImgSrc={this.state.bannerImgSrc}  bannerExist={this.state.bannerExist}/>
        

                     {user.role =='support' &&
                     <Button className="customAdd" variant="contained" onClick={this.addCard}  >
                           {language_text.add_card}
                    </Button>
                     }
                   
                
                <div className="cards-container-grid" style={this.state.cardData.length == 0? {padding:'0px !important'}:{padding:'10px !important'}}>
                <div style={{display:'inline-flex', width:'2400px',overflowX:'auto'}}>
                    
                        {this.state.cardData.map(card => (
                        <LandingCard 
                        key={card.id} title={ card.title }
                        content={ card.content }
                        imgUrl={ card.imgUrl } 
                        s3link={card.s3link}
                        link={card.link}
                        link1={card.link1}
                        link2={card.link2}
                        displayLink={card.displayLink}
                        displayLink1={card.displayLink1}
                        displayLink2={card.displayLink2}
                        id={card.id} editcard={this.state.editcard}  editInd={this.state.editInd} 
                        user={user} card={this.state.cardData} getCardDataFromDB={this.getCardDataFromDB}
                         />
                    ))}  
                    </div>
                </div>
            </div>

                    <footer className="home-footer">
                        <p>
                            {language_labels.inactivity}
                        </p></footer>


                </div>}
                {/* || this.state.access_cookie !== user.accessToken */}
                {(user.role !== "vendor" && user.role !== "support" && this.state.userNotFound === true) && <NotFound history={this.props.history} />}
                {/* {this.state.access_cookie == undefined && <NotFound />} */}
            </div>

        );
    }
}

LandingPage.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    user: state.user
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(AllActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);