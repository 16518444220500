import React, { Component, useCallback } from "react";
import "../App.css";
import { NavLink, Link, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import pmiLogo from "./../assests/pmi-logo.svg";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AjaxService from "../services/AjaxServices";
import HomeIcon from '@material-ui/icons/Home';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import { labels } from './../resources'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as AllActions from '../actions'
import PropTypes from 'prop-types'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TranslateIcon from '@material-ui/icons/Translate';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function NavBarComponent(props) {
  let history = useHistory();
  let style_class = "";
  let tool_style = "";
  let btn_div_style = ""
  let lang_style = ""
  let role = props.user.role
  console.log("logout_clas",props.logout_class)
  const [language, setLanguage] = React.useState(props.user.language);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // "FRANÇAIS",
  // const countires =["ENGLISH", "ESPAÑOL", "PORTUGUÊS", "FRANÇAIS", "БЪЛГАРСКИ", "POLSKI","BAHASA","Русский","한국어","中文","日本語","ไทย"]
  var countires = ["ENGLISH", "FRANÇAIS", "POLSKI", "BAHASA", "한국어", "中文", "日本語", "ไทย",  "ESPAÑOL"]
  // "한국어","ไทย","中文"
  const handleClickPop = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePop = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (props.appClass === "home" || props.appClass === "query_page") {
    style_class = "app-bar";
    tool_style = "tool-bar";
    if (props.appClass === "query_page" && props.logout_class === true) {
      lang_style = "lang-nav-btn-query"
      btn_div_style = "btn-div-query"
    } else {
      lang_style = "lang-nav-btn"
      btn_div_style = "btn-div" 
    }
   
  } else if (props.appClass === "faq") {
    style_class = "app-bar-faq";
    tool_style = "tool-bar";
    btn_div_style = "btn-div-not-home"
    lang_style = "lang-nav-btn"
  } else if (props.appClass === "admin-page") {
    style_class = "app-bar-faq";
    tool_style = "tool-bar-inv-status";
    btn_div_style = "btn-div-not-home"
    lang_style = "lang-nav-btn"
  } else {
    style_class = "app-bar-faq";
    tool_style = "tool-bar-inv-status";
    btn_div_style = "btn-div-not-home"
    lang_style = "lang-nav-btn"
  }

  // function handleLanguageChange (code) {
  //   console.log(code)
  //   props.actions.change_language(code)
  //   setLanguage(code);
  // }
  
  function handleClick() {
    history.push("/business-transaction-listing");
  }

  function handleClickAdmin() {
    history.push("/business-admin-page");
  }

  function handleClickAdminSupp() {
    if (role === "support") {
      history.push("/home");
    } else {
      history.push("/business-admin-page");
    }
  }

  function handleImgClick() {
    history.push("/home");
  }

  function handleLanguageChange (code) {
    console.log(code)
    props.actions.change_language(code)
    sessionStorage.setItem("language",code)
    setLanguage(code);
    setAnchorEl(null);
  }


  //testing comment
  function handleClickApiButton() {
    history.push("/apibuttons");
  }

  const handleChange = useCallback(() => {
    console.log("clicked", props)
    if (props.appClass === "home") {
      props.actions.clear_selected_vendor(props.appClass, true)
    } else {
      props.actions.clear_selected_vendor(props.appClass, false,true)
      history.push("/home");
    }

  }, [props])

  function handleLogout() {
    history.length = 0;

    sessionStorage.clear();
    localStorage.clear()
   
    window.location = '/logout-feedback'
  
  }

  return (
    <div className="">
      <HideOnScroll {...props}>
        <AppBar className={style_class}>
          <Toolbar className={tool_style}>
            {role !== "admin" && <img className="logo-btn" src={pmiLogo} onClick={handleImgClick} />}
            {role === "admin" && <img className="logo-btn" src={pmiLogo} onClick={handleClickAdmin} />}
           
            <div className={ btn_div_style}>
            
              {props.appClass === "admin-page" && (role === "admin" || role === "support") && <Button onClick={handleClick} style={{ margin: "0 20px" }} className="logout-btn">{ labels[props.user.language]["nav_bar"].business_trans_button}</Button>}
              {(props.appClass === "home" || props.appClass === "audit-trail") && (role === "support") && <Button onClick={handleClickAdmin} style={{ margin: "0 20px" }} className="logout-btn">{ labels[props.user.language]["nav_bar"].business_page_button}</Button>}
              
              
              
              {/* {role === "vendor" && <Select
                
                value={props.user.vendor_number}
                onChange={handleChange} 
              >
                 {props.user.response_arr.map((option,i)=>{
                 return(<MenuItem value={option.vendor_number}>{option.vendor_number}</MenuItem>)
                })}
                
              
              </Select>}  */}
            

                
            
              

              {(role === "support") && <Button onClick={handleClickApiButton} className="logout-btn">{labels[props.user.language]["nav_bar"].api_button}</Button>}
            
              {(role === "vendor") && props.user.response_arr.vendors.length !== 1 && <Button onClick={handleChange} style={{ margin: "0 20px" }} className={props.logout_class ? "logout-btn-slide" : "logout-btn"}>{labels[props.user.language]["nav_bar"].select_vendor}</Button>}
              {(role === "support") && props.user.response_arr.length !== 1 && <Button onClick={handleChange} style={{ margin: "0 20px" }} className={props.logout_class ? "logout-btn-slide" : "logout-btn"}>{labels[props.user.language]["nav_bar"].select_vendor}</Button>}
              

              {props.appClass === "audit-trail" && <IconButton className={"home-btn"} onClick={handleClickAdminSupp}><HomeIcon /></IconButton>}
              {(props.logout_class) ? (
                props.appClass != "home" && <IconButton className={props.appClass === "admin-page" ? "home-hidden" : props.appClass === "audit-trail" ? "home-hidden" : "home-btn-slide"} onClick={handleImgClick}><HomeIcon /></IconButton>
              ) : (
                props.appClass != "home" && <IconButton className={props.appClass === "admin-page" ? "home-hidden" : props.appClass === "audit-trail" ? "home-hidden" : "home-btn"} onClick={handleImgClick}><HomeIcon /></IconButton>
              )}
              
              {(role === "vendor" || role=== "support") &&
                <Button
                  variant="contained"
                  color="primary"
                  aria-describedby={id}
                  onClick={handleClickPop}
                  className={lang_style}
                >
                 
                  <TranslateIcon />
                </Button>
              }
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePop}
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                  }}
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                  }}
                  PaperProps={{
                      style: { width: '25%', left:"890px" },
                  }}
              >

                  <div>
                      <h5 className='language-change-heading-nav' >Change the language</h5>
                      <Grid container>
                          {countires.map((country, i) => {
                              return (<Grid item xs={4} key={i} className='lang-grid-item'>
                                  <Button className='lang-btn' onClick={(event)=>handleLanguageChange(country)}>
                                      {country}
                                  </Button>
                              </Grid>)
                          }
                          )}
                      </Grid>
                  </div>

              </Popover>
              {props.logout_class ? (
                <Tooltip title={labels[props.user.language].nav_bar.logout}><IconButton className="logout-btn-slide" onClick={handleLogout}><ExitToAppIcon/></IconButton></Tooltip>
              ) : (
                <Tooltip title={labels[props.user.language].nav_bar.logout}><IconButton className="logout-btn" onClick={handleLogout}><ExitToAppIcon/></IconButton></Tooltip>
              )}
            </div>

         
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      
    </div>
  );
}

NavBarComponent.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  user: state.user
})
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(AllActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(NavBarComponent);
