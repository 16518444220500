import React, { Component } from "react";
import "../App.css";
import NavBar from "./NavBar";
import '../apibuttons.scss';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Button from "@material-ui/core/Button";
import AjaxService from "../services/AjaxServices";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import Grid from '@material-ui/core/Grid';
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import Snackbar from '@material-ui/core/Snackbar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

export default class ApiButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      validate: false,
      validateMessage: "",
      disabled: false,
      newFiles: [],
      language: ""
    };
  }

  handlePDFAPI = () => {

    let files = this.state.files

    console.log(this.state.files)
    let accepFileFormat = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"]
    if (this.state.files.length !== 0) {

      if ((files["type"] !== undefined) && !accepFileFormat.includes(files["type"])) {
        this.setState({
          validate: true, invalid: true, validateMessage: 'Please review the documents attached before submitting your invoice'
        })
      } else {
        this.Ajax = new AjaxService();

        //console.log(cip_value)
        this.setState({ loadingOpen: true })
        this.Ajax.uploadMasterDataFilepond(this.state.files, "new-upload")

          .then(res => {
            console.log(res)
            this.setState({ validateMessage: "Successfully uploaded", validate: true, loadingOpen: false, files: [] })
            if (res.data.status === 200) {
              this.setState({ validateMessage: "Successfully uploaded", validate: true, loadingOpen: false, files: [] })
            } else {
              console.log(res)
              if (res.data.status === 500) {
                this.setState({
                  validate: true, invalid: true, loadingOpen: false, validateMessage: "Error while uploading the file. Please try again later"
                })
              }
            }

          })
      }



    }
    else {
      this.setState({
        validate: true, invalid: true, validateMessage: 'Please upload a file to Submit'
      })
    }


  }

  handlePaymentSchedule = () => {
    let files = this.state.newFiles

    console.log(this.state.newFiles)
    let accepFileFormat = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"]
    if (this.state.newFiles.length !== 0) {

      if ((files["type"] !== undefined) && !accepFileFormat.includes(files["type"])) {
        this.setState({
          validate: true, invalid: true, validateMessage: 'Please review the documents attached before submitting your invoice'
        })
      } else {
        this.Ajax = new AjaxService();

        //console.log(cip_value)
        this.setState({ loadingOpen: true })
        if (this.state.language !== "") {
          this.Ajax.uploadPaymentSchedule(this.state.newFiles, "new-upload", this.state.language)

            .then(res => {
              // console.log(res)
              // this.setState({ validateMessage: "Successfully uploaded", validate: true, loadingOpen: false, newFiles: [] })
              if (res.data.status === 200) {
                this.setState({ validateMessage: "Successfully uploaded", validate: true, loadingOpen: false, newFiles: [] })
              } else {
                console.log(res)
                if (res.data.status === 500) {
                  this.setState({
                    validate: true, invalid: true, loadingOpen: false, validateMessage: "Error while uploading the file. Please try again later"
                  })
                }
              }

            })
        } else {
          this.setState({ validateMessage: "Please first select a language", validate: true })
        }

      }



    }
    else {
      this.setState({
        validate: true, invalid: true, validateMessage: 'Please upload a file to Submit'
      })
    }


  }


  handlePDFAPIWithoutFile = () => {
    this.Ajax = new AjaxService();
    this.setState({ loadingOpen: true })
    this.Ajax.uploadMasterDataFilepond(null, "re-upload")
      .then(res => {
        console.log(res)
        this.setState({ validateMessage: "Successfully uploaded", validate: true, loadingOpen: false, files: [] })
        if (res.data.status === 200) {
          this.setState({ validateMessage: "Successfully uploaded", validate: true, loadingOpen: false, files: [] })
        } else {
          console.log(res)
          if (res.data.status === 500) {
            this.setState({
              validate: true, invalid: true, loadingOpen: false, validateMessage: "Error while uploading the file. Please try again later"
            })
          }
        }

      })

  }

  handleInit() {

  }

  handleChangeLanguage = (event) => {
    console.log(event.target.value)
    this.setState({
      language: event.target.value
    })
  }


  handleCloseFlag = () => {
    this.setState({ validate: false, validateMessage: "" })
  }


  render() {
    // var countires = ["English", "Spanish", "Portuguese", "French", "Bulgarian", "Polish", "Korean", "Thai", "Chinese"]
    // const countires = ["ENGLISH", "ESPAÑOL", "PORTUGUÊS","FRANÇAIS","БЪЛГАРСКИ", "POLSKI","한국어","ไทย","中文"]
     
    var countries = [
      {
        name:"ENGLISH",
        label:"English"
      },
      {
        name:"ESPAÑOL",
        label:"Spanish"
      },
      {
        name:"PORTUGUÊS",
        label:"Portuguese"
      },
      {
        name:"FRANÇAIS",
        label:"French"
      },
      {
        name:"БЪЛГАРСКИ",
        label:"Bulgarian"
      },
      {
        name:"POLSKI",
        label:"Polish"
      },
      {
        name:"한국어",
        label:"Korean"
      },
      {
        name:"ไทย",
        label:"Thai"
      },
      {
        name:"中文",
        label:"Chinese(Mandarin)"
      },
      {
        name:"日本語",
        label:"Japanese"
      },
      {
        name:"Русский",
        label:"Russian"
      },
      {
        name:"BAHASA",
        label:"Bahasa(Indonesian)"
      }

    ]
    return (
      <div className="container-fluid no-padding faq-container">
        <NavBar appClass="api" />
        <Snackbar ContentProps={{

        }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}

          open={this.state.validate}
          onClose={this.handleCloseFlag}
          autoHideDuration={6000}
          message={this.state.validateMessage}
        />
        {/* <Button style={{ marginTop: "10%" }} onClick={(event) => this.handleAPI("sap")}>SAP extract</Button>
        <Button style={{ marginTop: "10%" }} onClick={(event) => this.handleAPI("coupa")}>Coupa extract</Button> */}
        {/* <Button style={{ marginTop: "10%" }} onClick={(event) => this.handlePDFAPI()}>Invoice Master Data upload</Button> */}
        <Grid container className="apibuttons-main">
          <Grid item xs={6} className="apibuttons-grid">
            <Typography className="headings-text">
              Please upload the master data file here
            </Typography>
            <div >
              <FilePond
                className="filepond"
                ref={(ref) => (this.pond = ref)}
                name="userfiles"
                files={this.state.files}
                maxFiles={1}
                allowPaste={false}
                allowFileTypeValidation={true}
                allowFileSizeValidation={true}
                acceptedFileTypes=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                labelFileTypeNotAllowed="Files type is not supported"
                labelButtonProcessItem="Upload"
                allowMultiple={false}
                fileValidateTypeDetectType={true}
                oninit={() => this.handleInit()}
                server="/upload"
                onremovefile={(err, file) => {
                  console.log(err)
                  console.log("here remove files", file)
                }}
                onupdatefiles={(fileItems) => {
                  console.log("here in fileupdate", fileItems, this.pond)
                  // console.log("here in fileupdate", fileItems[0].file)

                  this.setState({
                    files: fileItems.map((fileItem) => fileItem.file),

                  });

                }}
              />
            </div>
            <Grid item xs={12} style={{ marginTop: "5%", textAlign: "center" }} >
              {/* {localStorage.getItem('role') !== 'helpdesk' ? <a class="submit-button" onClick={this.handleSubmitInvoice}>Submit</a> : <Button disabled disable-submit-btn>Submit</Button>} */}
              <a class="submit-button" onClick={this.handlePDFAPI}>Submit</a>
            </Grid>
          </Grid>
          <Grid item xs={6} className="apibuttons-grid">
            <Typography className="headings-text">
              Click this button to re-upload the master data file that was last uploaded :
            </Typography>
            <Grid item xs={12} style={{ marginTop: "5%", textAlign: "center" }} >
              <a class="submit-button" onClick={this.handlePDFAPIWithoutFile} >Re-upload previous file</a>
            </Grid>
          </Grid>
          <Grid item xs={6}>

            <Typography className="headings-text">
              Please upload the Payment Schedule file here
            </Typography>
            <Grid container className="language-grid-container">
              <Grid item xs={4}>
                <h6 class="country-head">Please select a country</h6>
              </Grid>
              <Grid item xs={6}>
                <FormControl className="select-formcontrol">
                  <Select
                    onChange={(event) => this.handleChangeLanguage(event)}
                    value={this.state.language}
                    required
                  >
                    <MenuItem value="">Select a language</MenuItem>
                    {countries.map((item, i) => {
                      return(<MenuItem key={i} value={item.name}>{item.label}</MenuItem>)
                    })}


                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <div >
              <FilePond
                className="filepond"
                ref={(ref) => (this.pond = ref)}
                name="userfiles"
                files={this.state.newFiles}
                maxFiles={1}
                allowPaste={false}
                allowFileTypeValidation={true}
                allowFileSizeValidation={true}
                acceptedFileTypes=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                labelFileTypeNotAllowed="Files type is not supported"
                labelButtonProcessItem="Upload"
                allowMultiple={false}
                fileValidateTypeDetectType={true}
                oninit={() => this.handleInit()}
                server="/upload"
                onremovefile={(err, file) => {
                  console.log(err)
                  console.log("here remove files", file)
                }}
                onupdatefiles={(fileItems) => {
                  console.log("here in fileupdate", fileItems, this.pond)
                  // console.log("here in fileupdate", fileItems[0].file)

                  this.setState({
                    newFiles: fileItems.map((fileItem) => fileItem.file),

                  });

                }}
              />
            </div>
            <Grid item xs={12} style={{ marginTop: "5%", textAlign: "center" }} >
              {/* {localStorage.getItem('role') !== 'helpdesk' ? <a class="submit-button" onClick={this.handleSubmitInvoice}>Submit</a> : <Button disabled disable-submit-btn>Submit</Button>} */}
              <a class="submit-button" onClick={this.handlePaymentSchedule}>Submit</a>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
