import React, { Component } from "react";
import "../new_query.scss";
import Navbar from "./NavBar";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    DialogContentText,
    DialogTitle,
    Tooltip,
} from "@material-ui/core";
import AjaxService from "../services/AjaxServices";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
// import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// import HelpIcon from "@material-ui/icons/Help";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
// import DescriptionIcon from "@material-ui/icons/Description";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { labels } from "./../resources";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import IdleTimer from 'react-idle-timer';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import FloatingButtons from '../component/floating-buttons';
import NotFound from './NotFound';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as AllActions from '../actions'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FormHelperText from '@material-ui/core/FormHelperText';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ViewListIcon from '@material-ui/icons/ViewList';
// for report//
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import moment from 'moment'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import * as _ from 'lodash';
import Draggable from 'react-draggable';
import Paper from '@material-ui/core/Paper';
function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

//dummy push
registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateSize,
    FilePondPluginFileValidateType
);
let options;
var main_query = {}
class QueryPage extends Component {
    constructor(props) {
        super(props);

        this.idleTimer = null
        this.handleOnIdle = this.handleOnIdle.bind(this)
        this.checkboxInput = React.createRef();
        this.state = {
            access_cookie: Cookies.get("access-cookie"),
            left: "",
            right: "",
            container: "",
            copied: "",
            selectedCategory: "",
            randomString: "",
            textAreaValue: "",
            textFieldValue: "",
            is_slide: true,
            validate: false,
            invalid: false,
            validateMessage: '',
            textFieldClass: "id-field-query-new",
            headerTextFieldClass: "",
            charLeft: '',
            files: [],
            open: false,
            note_msg: false,
            selected_entities: [],
            pmi_entities: [],
            selected_cocds: [],
            selected_countries: [],
            loadingOpen: false,
            invite_supplier_name: "",
            vat_number: "",
            invitee_email_1: "",
            invitee_email_2: "",
            invitee_email_3: "",
            yourEmailError: false,
            yourEmail_error_text: "",
            inviteeError: false,
            invitee_error_text: "",
            InviteComments: "",
            invitee_email_arr: [],
            digitalSignature: false,
            checkbox_error: false,
            checked_class: "signature-label",
            entity_error: false,
            entity_error_text: "",
            pmi_entities_invite: [],
            selected_region_invite: "",
            selected_global_mailbox: [],
            selected_vmd_mailbox: [],
            inv_num_error: false,
            region_error: false,
            region_error_msg: "",
            selected_region: "",

            /// for report//
            viewReportFlag:false,
            data_arr:[],
            page:0,
            rowsPerPage:15,
            totalCount:0,
            filters_data: "",
            columns_list: [],
            on_search_active: false,
            search_text: "",
            sort_direction: undefined,
            sort_name: undefined,
          
        };
    }


    handleOnIdle(event) {
        //console.log('last active', this.idleTimer.getLastActiveTime())

        this.Ajax = new AjaxService();
        this.Ajax.logout().then(result => {
            //console.log("logged out")
            this.props.actions.return_default()
            sessionStorage.clear();
            localStorage.clear()
            this.props.history.length = 0;

            this.props.history.replace("/");
        })
    }

    componentDidMount = () => {
        if (this.props.user.role === "vendor") {
            this.Ajax = new AjaxService()
            if (this.props.user.query_entity_data !== "") {
                this.setState({ pmi_entities: this.props.user.query_entity_data })
            } else {
                
                this.Ajax.getEntityData()
                    .then(res => res.json())
                    .then(res => {
                        console.log(res)
                        let vendor_company_codes = this.props.user.all_company_codes
                        let country_val = Object.keys(res)
                        let updated_list = country_val.map((item, j) => {
                            res[item].forEach(el => {
                                if (vendor_company_codes.includes(el.company_code) || vendor_company_codes.includes(parseInt(el.company_code))) {
                                    el.display = false
                                } else {
                                    el.display = true
                                }

                            })
                        })

                        let sorted_list = country_val.map((opt, k) => {
                            res[opt].forEach((obj, m) => {
                                if (obj.display === true) {
                                    res[opt].splice(m, 1)
                                    res[opt].unshift(obj)
                                }
                            })
                        })
                        this.setState({ regions: res })
                    })
            }
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.user.language !== this.props.user.language) {
            this.setState({
                selectedCategory: ""
            })

        }
    }



    handleInit() {
        //console.log("FilePond instance has initialised", this.pond);
    }

    handleTextAreaChange = (e) => {
        //console.log(e.target.value.length)
        var len = 500 - e.target.value.length;
        if (e.target.value.length === 0) {
            len = '';
        }
        this.setState({ textAreaValue: e.target.value, charLeft: len, multiline_err: false, multiline_err_msg: "" });
    }

    handleTextFieldChange = (e) => {
        this.setState({ textFieldValue: e.target.value, inv_num_error: false, invnum_err_msg: "" });
    }

    handleHoverSlide = (classUsed, first_label, second_label) => () => {
        var selectedCategory = first_label + " " + second_label;
        // this.state.container.classList.add(classUsed);
        // this.setState({
        //   selectedCategory: selectedCategory
        // });
        //console.log(selectedCategory);
        if (selectedCategory === "Invoice Status") {
            this.setState({
                textFieldClass: "id-field-query-new",
                selectedCategory: `${labels[this.props.user.language]["query_page"]["invoice"]} ${labels[this.props.user.language]["query_page"]["status"]}`,
                files: [],
                textAreaValue: '',
                textFieldValue: '',
                textAreaValue: '',
                selected_region:"",
                selected_entities: [],
                selected_cocds: [],
                charLeft: "",
                entity: "inv stat",
                inv_num_error:false,
                invnum_err_msg:"",
                multiline_err:false,
                multiline_err_msg:"",
                region_error:false,
                region_error_msg:"",
                entities_error:false,
                entities_error_msg:""

                
            });
        }
        else if (selectedCategory === "General Enquiry") {
            this.setState({
                textFieldClass: "id-field-query-none",
                selectedCategory: `${labels[this.props.user.language]["query_page"]["general"]} ${labels[this.props.user.language]["query_page"]["enquiry"]}`,
                files: [],
                textAreaValue: '',
                textFieldValue: '',
                textAreaValue: '',
                selected_region:"",
                selected_entities: [],
                selected_cocds: [],
                charLeft: "",
                entity: "general",
                inv_num_error:false,
                invnum_err_msg:"",
                multiline_err:false,
                multiline_err_msg:"",
                region_error:false,
                region_error_msg:"",
                entities_error:false,
                entities_error_msg:""
            });
        } else if (selectedCategory === "Invite Others") {
           
            this.setState({
                openInviteDialog: true,
                selectedCategory: "",
                selected_region:"",
                selected_entities: [],
                entity: "invite others",
                inv_num_error:false,
                invnum_err_msg:"",
                multiline_err:false,
                multiline_err_msg:"",
                region_error:false,
                region_error_msg:"",
                entities_error:false,
                entities_error_msg:""
            })
        }else if (selectedCategory === "view report") {
           
            this.setState({
                viewReportFlag: true,
                selectedCategory: "",
                selected_region:"",
                selected_entities: [],
                entity: "view report",
                inv_num_error:false,
                invnum_err_msg:"",
                multiline_err:false,
                multiline_err_msg:"",
                region_error:false,
                region_error_msg:"",
                entities_error:false,
                entities_error_msg:"",
                data_arr:[],
                page:0,
                rowsPerPage:15,
                totalCount:0,
                filters_data: "",
                columns_list: [],
                on_search_active: false,
                search_text: "",
                sort_direction: undefined,
                sort_name: undefined,
            })
            this.handleSubmissionLogs();
        }
         else if (selectedCategory === "Payment Remittance") {
            this.setState({
                textFieldClass: "id-field-query-new",
                selectedCategory: `${labels[this.props.user.language]["query_page"]["payment"]} ${labels[this.props.user.language]["query_page"]["remittance"]}`,
                files: [],
                textAreaValue: '',
                textFieldValue: '',
                textAreaValue: '',
                selected_region:"",
                selected_entities: [],
                selected_cocds: [],
                charLeft: "",
                entity: "payment rem",
                inv_num_error:false,
                invnum_err_msg:"",
                multiline_err:false,
                multiline_err_msg:"",
                region_error:false,
                region_error_msg:"",
                entities_error:false,
                entities_error_msg:""
            });
        } else {
            this.setState({
                textFieldClass: "id-field-query-new",
                selectedCategory: selectedCategory,
                files: [],
                textAreaValue: '',
                textFieldValue: '',
                textAreaValue: '',
                selected_region:"",
                selected_entities: [],
                selected_cocds: [],
                charLeft: "",
                entity: "",
                inv_num_error:false,
                invnum_err_msg:"",
                multiline_err:false,
                multiline_err_msg:"",
                region_error:false,
                region_error_msg:"",
                entities_error:false,
                entities_error_msg:""
            });
        }
    };

    handleRaiseQuery = (e) => {
        if (this.props.user.role === "vendor") {

            e.preventDefault();
            let files = [...this.state.files]
            var validFiles = []
            var len = this.state.files.length;
            var count = 0;
            var size = 0;
            var validToSubmit = false;
            var totalsize = 0;
            // let accepFileFormat = ["image/png", "image/jpeg", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/docx", "application/x-zip-compressed",  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.oasis.opendocument.spreadsheet", ".xlsx", "image/TIF", "image/TIFF", "image/tif", "image/tiff"]
            let accepFileFormat = ["image/png", "image/jpeg", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/docx", "application/x-zip-compressed", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.oasis.opendocument.spreadsheet", ".xlsx", "image/TIF", "image/TIFF", "image/tiff", "image/tif"]
            // ["image/png", "image/jpeg", "application/zip", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/docx", "application/x-zip-compressed", "application/pdf", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.oasis.opendocument.spreadsheet", ".xlsx", "image/TIF", "image/TIFF", "image/tif", "image/tiff"]
            if (this.state.files.length !== 0 && this.state.selected_entities.length !== 0 &&
                (this.state.textFieldValue !== "" || this.state.entity === "general") && this.state.textAreaValue !== "") {
               
                validFiles = files.reduce((results, file) => {
                    totalsize = totalsize + file["size"];
                    console.log("total size=", totalsize);
                    console.log(file["size"])
                    size += file["size"]
                    if ((file["type"] !== undefined) && ((accepFileFormat.includes(file["type"]) && file["size"] <= 5242880 && totalsize < 18874368))) {
                        this.setState({ invalid: false })
                        count++;
                        results.push(file)
                        validToSubmit = true
                        console.log("validSubmit 1", validToSubmit)
                    }
                    else if ((file["type"] !== undefined) && ((file["size"] > 5242880))) {
                        console.log("here second")
                        this.setState({
                            validate: true, invalid: true, validateMessage: labels[this.props.user.language]["query_page"]["review_comment"]
                        })
                        validToSubmit = false
                        console.log("validSubmit 2a", validToSubmit)
                    }
                    else if ((file["type"] !== undefined) && ((totalsize > 18874368))) {
                        console.log("here second")
                        this.setState({
                            validate: true, invalid: true, validateMessage: labels[this.props.user.language]["query_page"]["review_comment"]
                        })
                        validToSubmit = false
                        console.log("validSubmit 3a", validToSubmit)
                    }
                    else if ((file["type"] !== undefined) && ((!accepFileFormat.includes(file["type"])))) {
                        this.setState({
                            validate: true, invalid: true, validateMessage: labels[this.props.user.language]["query_page"]["review_comment"]
                        })
                        validToSubmit = false
                        console.log("validSubmit 2b", validToSubmit)
                    }
                    return results
                }, [])

                // if (size < 1e+7) {
                //     this.setState({ invalid: false })
                //     validToSubmit = true
                //     console.log("validSubmit 3", validToSubmit)
                // } else {
                //     this.setState({
                //         validate: true, invalid: true, validateMessage: 'Please review the documents attached before submitting your query'
                //     })
                //     validToSubmit = false
                //     console.log("validSubmit 4", validToSubmit)
                // }


            } else {
                if (this.state.selected_region === "") {
                    this.setState({
                        region_error: true,
                        region_error_msg: labels[this.props.user.language].query_page.field_empty
                    })
                }
                if (this.state.selected_entities.length === 0) {
                    this.setState({
                        entities_empty: true,
                        entities_error_msg: labels[this.props.user.language].query_page.field_empty
                    })
                }
                if (this.state.textFieldValue === "" && this.state.entity !== "general") {
                    this.setState({
                        inv_num_error: true,
                        invnum_err_msg: labels[this.props.user.language].query_page.field_empty
                    })
                }
                if (this.state.textAreaValue === "") {
                    this.setState({
                        multiline_err: true,
                        multiline_err_msg: labels[this.props.user.language].query_page.field_empty
                    })
                }

                if (this.state.selected_entities.length !== 0 &&
                    (this.state.textFieldValue !== "" || this.state.entity === "general") && this.state.textAreaValue !== "") {
                    validToSubmit = true
                }

            }
            if (count === len && validToSubmit) {
                this.setState({
                    note_msg: true,
                    files: files,
                    entities_empty: false,
                    entities_error_msg: "",
                    inv_num_error: false,
                    invnum_err_msg: "",
                    multiline_err: false,
                    multiline_err_msg: ""
                })
            }
        }
    };
    ///dummy line
    ///dummy
    handleSubmitQuery = () => {

        if (!this.state.invalid && this.props.user.role === "vendor") {
            var supplier_name = this.props.user.vendor_name
            var supplier_number = this.props.user.vendor_number
            var user_email = this.props.user.validated_email

            // if(!this.state.validate){
            this.Ajax = new AjaxService();
            var chars = "1234567890";
            var string_length = 8;
            var randomstring = "";
            var charCount = 0;
            var numCount = 0;
            for (var i = 0; i < string_length; i++) {
                if (
                    (Math.floor(Math.random() * 2) == 0 && numCount < 3) ||
                    charCount >= 5
                ) {
                    var rnum = Math.floor(Math.random() * 10);
                    randomstring += rnum;
                    numCount += 1;
                } else {
                    var rnum = Math.floor(Math.random() * chars.length);
                    randomstring += chars.substring(rnum, rnum + 1);
                    charCount += 1;
                }
            }

            // //console.log((this.state.files).length, this.state.textAreaValue, this.state.textFieldValue, this.state.files[0].source);

            this.setState({ loadingOpen: true, note_msg: false })
            // Axios.post("http://localhost/9000/upload/queryPage", params ).then(res => //console.log(res)).catch(err => //console.log(err))
            // if ((validFiles).length > 0 && validFiles[0].source !== "index.html") {

            //console.log("SELECTED Entities",this.state.selected_entities)
            this.Ajax.queryUploadApi(randomstring, this.state.textAreaValue, this.state.textFieldValue, this.state.selectedCategory, this.state.files, supplier_name, supplier_number, this.state.selected_entities, this.state.selected_cocds, this.state.selected_countries, this.state.selected_snow_mailbox, this.state.selected_global_mailbox, this.state.selected_region )//this.props.user.supplierIndustryKey
                .then(res => {
                    console.log(res)
                    if (res.data.status === 200) {
                        this.setState({
                            open: true,
                            randomString: randomstring,
                            copied: false,
                            note_msg: false,
                            files: [],
                            textAreaValue: '',
                            textFieldValue: '',
                            textAreaValue: '',
                            selected_region:[],
                            selected_entities: [],
                            selected_cocds: [],
                            charLeft: '',
                            loadingOpen: false,
                            entities_error: false,
                            entities_error_msg: "",
                            inv_num_error: false,
                            invnum_err_msg: "",
                            multiline_err: false,
                            multiline_err_msg: ""
                        });
                    } else {
                        var msg = ""
                        if (res.data.status === 500) {
                            console.log(this.props.user.language)
                            if (res.data.msg === "Each file size should be less than 1MB.") {
                                msg = labels[this.props.user.language].query_page.file_size
                            } else if (res.data.msg === "File type not supported.") {
                                msg = labels[this.props.user.language].query_page.type_support
                            } else if (res.data.msg === 'Error while processing the files') {
                                msg = labels[this.props.user.language].query_page.deletion_box
                            } else if (res.data.msg === "Error while sending email") {
                                msg = labels[this.props.user.language].query_page.sending_email
                            } else if (res.data.msg === 'Error while processing.') {
                                msg = labels[this.props.user.language].query_page.update_error
                            } else if (res.data.msg === 'Error while handling files') {
                                msg = labels[this.props.user.language].query_page.upload_error
                            } else if (res.data.msg === "Too many requests. Please try again in some time.") {
                                msg = labels[this.props.user.language].query_page.request_limit
                            }

                            this.setState({
                                validate: true,
                                loadingOpen: false,
                                validateMessage: msg //backend_err
                            })
                        }

                    }

                })
            // }
            // }
        }
    }

    handleClose = () => {
        this.setState({
            open: false,
            
            success_dialog: false,
            
            selected_entities: [],
            invitee_email_1: "",
            invitee_email_2: "",
            invitee_email_3: "",
            inviteeError: false,
            invitee_error_text: "",
            inviteeError_2: false,
            invitee_error_text_2: "",
            inviteeError_3: false,
            invitee_error_text_3: "",
            InviteComments: "",
            digitalSignature: false,
            errorDialog: false
        });
    };

    handleCancel = () =>{
        this.setState({
            note_msg: false,
            openInviteDialog: false
        })
    }

    handleEntityChange = (event) => {
        console.log(event.target.value)

        let cocds = []
        let country_arr = []
        let snow_mailbox_arr = []
        let global_mailbox_arr = []
        let vmd_mailbox_arr = []

   
       console.log(this.state.pmi_entities)
       console.log(event.target.value)

        this.state.pmi_entities.forEach(el => {
            event.target.value.forEach(name => {
                if (name === el["company_name"]) {
                    cocds.push(el["company_code"])
                    country_arr.push(el["Country"])
                    snow_mailbox_arr.push(el["snow_mailbox"])
                    global_mailbox_arr.push(el["global_mailbox"])
                    vmd_mailbox_arr.push(el["vmd_mailbox"])
                }
            })
        })
        console.log("vmd_mailbox_arr",vmd_mailbox_arr)
        this.setState({ selected_entities: event.target.value, selected_cocds: cocds, selected_countries: country_arr, selected_snow_mailbox: snow_mailbox_arr, entities_error: false, entities_error_msg: "", selected_global_mailbox: global_mailbox_arr, selected_vmd_mailbox: vmd_mailbox_arr })
        //console.log(this.state.selected_entities)
    };

    handleEntityChangeInvite = (event) => {
        console.log(event.target.value)

        let cocds = []
        let country_arr = []
        let snow_mailbox_arr = []
        let global_mailbox_arr = []
        let vmd_mailbox_arr = []

   
       console.log(this.state.pmi_entities_invite)
       console.log(event.target.value)

        this.state.pmi_entities_invite.forEach(el => {
            event.target.value.forEach(name => {
                if (name === el["company_name"]) {
                    cocds.push(el["company_code"])
                    country_arr.push(el["Country"])
                    snow_mailbox_arr.push(el["snow_mailbox"])
                    global_mailbox_arr.push(el["global_mailbox"])
                    vmd_mailbox_arr.push(el["vmd_mailbox"])
                }
            })
        })
        console.log("vmd_mailbox_arr",vmd_mailbox_arr)
        this.setState({ selected_entities: event.target.value, selected_cocds: cocds, selected_countries: country_arr, selected_snow_mailbox: snow_mailbox_arr, entity_error: false, entity_error_text: "", selected_global_mailbox: global_mailbox_arr, selected_vmd_mailbox: vmd_mailbox_arr })
        //console.log(this.state.selected_entities)
    };


    handleRegionChange = (event) => {
        console.log(event.target.value)
        console.log("REGIONS", this.state.regions)
        let entity_data = this.state.regions[event.target.value]
     
        let updated_entity_data = entity_data.filter((item, k) => {
            return item.display === false
        })
        console.log(updated_entity_data)
        this.setState({ selected_region: event.target.value, pmi_entities: updated_entity_data, selected_entities: [], region_error: false, region_error_msg: "" })

    }
    handleRegionChangeInvite = (event) => {

        let entity_data = this.state.regions[event.target.value]
        let erp = this.state.regions[event.target.value][0]["system"]
        let updated_entity_data = entity_data.filter((item, k) => {
            return item.display === false
        })
        console.log(erp)
        console.log(this.props.user.vat_number)
        let vat_number 
        if(this.props.user.vat_number[erp]){
            console.log("in vat if")
            vat_number = this.props.user.vat_number[erp]
        }else{
            console.log("in vat else")
            vat_number = []
        }
       
        console.log(vat_number)
        console.log(updated_entity_data)
        this.setState({ selected_region_invite: event.target.value, pmi_entities_invite: updated_entity_data, selected_entities: [], region_err_invite: false, region_err_invite_msg: "",vat_number:vat_number })
    }
    handleCloseFlag = () => {
        this.setState({ validate: false })
    }
    InvalidMsg = (e) => {
        if (e.target.value === '') {
            e.target.setCustomValidity('Required email address');
        }
        else {
            e.target.setCustomValidity('');
        }
        return true;
    }

    handleInviteValuesChange = (event) => {
        // console.log(event.target.name)
        const emailRegex = new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]');
        if (event.target.name === "invitee_email_1" || event.target.name === "invitee_email_2" || event.target.name === "invitee_email_3") {

            if (event.target.name === "invitee_email_1") {
                console.log("inside email1 change")
                if (event.target.value.match(emailRegex)) {
                    this.setState({
                        inviteeError: false,
                        invitee_error_text: "",
                        [event.target.name]: event.target.value,

                    })
                } else {

                    this.setState({
                        inviteeError: true,
                        invitee_error_text: labels[this.props.user.language].query_page.email_valid,
                        [event.target.name]: event.target.value,

                    })
                }
            } else if (event.target.name === "invitee_email_2") {
                console.log("inside email 2 change")
                if (event.target.value.match(emailRegex)) {
                    this.setState({
                        inviteeError_2: false,
                        invitee_error_text_2: "",
                        [event.target.name]: event.target.value,

                    })
                } else {

                    this.setState({
                        inviteeError_2: true,
                        invitee_error_text_2: labels[this.props.user.language].query_page.email_valid,
                        [event.target.name]: event.target.value,

                    })
                }
            } else {
                if (event.target.value.match(emailRegex)) {
                    this.setState({
                        inviteeError_3: false,
                        invitee_error_text_3: "",
                        [event.target.name]: event.target.value,

                    })
                } else {

                    this.setState({
                        inviteeError_3: true,
                        invitee_error_text_3: labels[this.props.user.language].query_page.email_valid,
                        [event.target.name]: event.target.value,

                    })
                }
            }

        } else if (event.target.name === "InviteComments") {
            var len = 250 - event.target.value.length;
            if (event.target.value.length === 0) {
                len = '';
            }
            this.setState({ InviteComments: event.target.value, invite_charLeft: len });
        } else {
            this.setState({
                [event.target.name]: event.target.value
            })
        }

    }




    handleCheckBoxChange = (event) => {
        //console.log(event.target.checked)
        if (event.target.checked === true) {
            this.setState({ digitalSignature: event.target.checked, checkbox_error: false })
        } else {
            this.setState({ digitalSignature: event.target.checked })
        }

    }

    handleDeleteEmail = (name) => {
        if (name === "invitee_email_2") {
            this.setState({
                [name]: "",
                inviteeError_2: false
            })
        } else {
            this.setState({
                [name]: "",
                inviteeError_3: false
            })
        }

    }
    clickInviteOk = () => {
        console.log(this.state.selected_entities.length)
        if (this.state.digitalSignature === false || this.state.invitee_email_1 === "" || this.state.selected_entities.length === 0 || this.state.selected_region_invite === "") {

            if (this.state.digitalSignature === false) {
                console.log("sig==", this.state.digitalSignature)
                this.checkboxInput.current.focus();
                this.setState({ checked_class: "signature-label-shake", checkbox_error: true })
            } 
            
            if (this.state.invitee_email_1 === "") {
                this.setState({
                    field_error: true,
                    error_text: labels[this.props.user.language].query_page.field_empty
                })
            }
            if (this.state.selected_entities.length === 0) {
                this.setState({
                    entity_error: true,
                    entity_error_text: labels[this.props.user.language].query_page.field_empty
                })
            }
            if (this.state.selected_region_invite === "") {
                this.setState({
                    region_err_invite: true,
                    region_err_invite_msg: labels[this.props.user.language].query_page.field_empty
                })
            }
        } else {
            this.Ajax = new AjaxService();
            this.Ajax.Invite_Others(this.props.user.validated_email, this.props.user.vendor_name, this.state.vat_number, this.state.invitee_email_1, this.state.invitee_email_2, this.state.invitee_email_3, this.state.InviteComments, this.state.selected_entities, this.state.selected_vmd_mailbox)
                .then(response => response.json())
                .then(res => {
                    console.log(res)
                    if (res.hasOwnProperty("error")) {
                        this.setState({
                            errorDialog: true
                        })
                    } else {
                        console.log(res)
                        this.setState({
                            success_dialog: true,
                            openInviteDialog: false,
                            field_error: false,
                            error_text: "",
                            selected_entities: [],
                            invitee_email_1: "",
                            invitee_email_2: "",
                            invitee_email_3: "",
                            inviteeError: false,
                            invitee_error_text: "",
                            inviteeError_2: false,
                            invitee_error_text_2: "",
                            inviteeError_3: false,
                            invitee_error_text_3: "",
                            InviteComments: "",
                            digitalSignature: false
                        })
                    }
                })

        }
    }

    /// for report///
    onSearch = _.debounce((e) => {
        console.log("search text debounce: ", e)
        if (e !== null) {
          main_query.search_text = e
          main_query.page = 0
          options.page = 0
          this.setState({
            on_search_active: true,
            search_text: e,
            data_loading: true,
    
            deriveddata: []
          })
          this.Ajax = new AjaxService();
    
          console.log(main_query)
          this.Ajax.viewQuerySubmissionLog(main_query)
            .then(search_result => search_result.json())
            .then(result => {
              if (result.hasOwnProperty("error")) {
                // this.setState({
                //   validate: true,
                //   validateMessage: labels[this.props.user.language].invoice_list.err,
                //   dataloaded: true,
                //   deriveddata: [],
    
                //   data_loading: false
                // });


              } else {
                var data_arr=[];
                result.data.forEach(item=>{
                var doc_date = item["uploadedDate"] === "" ? "" : item["uploadedDate"] === undefined ? "" : item["uploadedDate"] === "00000000" ? "" : moment(item["uploadedDate"]).format("DD-MMM-YYYY")
                var file=item["userFileArray"]== "No files uploaded by user" ? "No files uploaded by user":[];
            if (file instanceof Array){
                item["userFileArray"].forEach(item=>{ file.push(item.split('->')[0])});

            }
            console.log(file)
                data_arr.push([
                    item.supplier_number,item["selected_pmi_entities"],item["Invoice number"],item["Payment amount"], item.selectedCategory, item.comments, doc_date, file
                ])
            })
                this.setState({data_arr: data_arr, totalCount: result.count, data_loading:false})
                
              }
    
            })
        }
    
      }, 2000);

      onSearchClose = () => {
        main_query.page = 0
        console.log(main_query)
        this.Ajax.viewQuerySubmissionLog(main_query).then(res => res.json()).then(result => {
          console.log(result)
          if (result.hasOwnProperty("error")) {
            this.setState({
            //   validate: true,
            // //  validateMessage: labels[this.props.user.language].invoice_list.err,
            //   dataloaded: true,
            //   deriveddata: [],
    
            //   data_loading: false
            });
          } else {
    
            
            var data_arr=[];
            result.data.forEach(item=>{
            var doc_date = item["uploadedDate"] === "" ? "" : item["uploadedDate"] === undefined ? "" : item["uploadedDate"] === "00000000" ? "" : moment(item["uploadedDate"]).format("DD-MMM-YYYY")
            var file=item["userFileArray"]== "No files uploaded by user" ? "No files uploaded by user":[];
            if (file instanceof Array){
                item["userFileArray"].forEach(item=>{ file.push(item.split('->')[0])});

            }
            console.log(file)
                data_arr.push([
                    item.supplier_number,item["selected_pmi_entities"],item["Invoice number"], item["Payment amount"], item.selectedCategory, item.comments, doc_date, file
                ])
            })
            this.setState({data_arr: data_arr, totalCount: result.count})
            
          }
        })
      }
    
    commonAPIForTable =(page, rowsPerPage, sortOrder) =>{
        let mainQuery={supplier_number:this.props.user.vendor_number, 
                        supplier_email:this.props.user.validated_email,
                        page: page, 
                        rowsPerPage:rowsPerPage}


        if (sortOrder) {
            mainQuery.sort_name = sortOrder.name
            mainQuery.sort_direction = sortOrder.direction
                     }
        var data_arr=[];
        this.Ajax = new AjaxService();
        this.Ajax.viewQuerySubmissionLog(mainQuery)
        .then(result => result.json())
        .then(res => {
            console.log("common API res--",res)
           
            res.data.forEach(item=>{
            var doc_date = item["uploadedDate"] === "" ? "" : item["uploadedDate"] === undefined ? "" : item["uploadedDate"] === "00000000" ? "" : moment(item["uploadedDate"]).format("DD-MMM-YYYY")
            var file=item["userFileArray"]== "No files uploaded by user" ? "No files uploaded by user":[];
            if (file instanceof Array){
                item["userFileArray"].forEach(item=>{ file.push(item.split('->')[0])});

            }
            console.log(file)
                data_arr.push([
                    item.supplier_number,item["selected_pmi_entities"],item["Invoice number"], item["Payment amount"], item.selectedCategory, item.comments, doc_date, file
                ])
            })
            console.log(data_arr.length, page)
            if (res.page === "first") {
                this.setState({ data_arr: data_arr, page: page ,totalCount: res.count })
                options.count=res.count;
              
              } else {
                this.setState({
                     data_arr: data_arr, page: page 
                })              
      
              }

            // console.log(data_arr)
            // options.count=res.count;
            // this.setState({ data_arr: data_arr, page: page ,totalCount: res.count })
            // console.log(data_arr.length, page)

        }).catch(function (err) {
            console.log(err)
            // this.setState({
            //     textfield_error: true,
            //     error_text: "Error while submitting your request. Please try again later.",
            //     logout: true
            // })
        });  
    }
    handleFilterSubmit = applyFilters => {
        if (this.props.user.role === "vendor" || this.props.user.role === "support") {
    
    
          let filterList = applyFilters();
          console.log(filterList);
          let columns_list = this.state.columns_list
          var filters_obj = {}
          if (this.state.columns_list.length !== 0) {
            columns_list.forEach((item, i) => {
              filterList.forEach((opt, j) => {
                if (opt.length !== 0 && i === j) {
                  filters_obj[item.name] = opt
                }
              })
            })
          }
          console.log(filters_obj)
          console.log(main_query)
          main_query.filters = filters_obj
          main_query.page = 0
          options.page = 0
          this.setState({
            applied_filters: true,
            filters_obj: filters_obj,
            data_loading: true,
            deriveddata: [],
            main_query: main_query
          })
          if (Object.keys(filters_obj).length !== 0) {

                this.Ajax.viewQuerySubmissionLog(main_query)
              .then(res => res.json())
              .then(res => {
                console.log(res)
                if (res.hasOwnProperty("error")) {
                  this.setState({
                    // validate: true,
                    // validateMessage: labels[this.props.user.language].invoice_list.err,
                    // dataloaded: true,
                    // deriveddata: [],
    
                     data_loading: false
                  });
                } else {
                  
                    var data_arr=[];
                    res.data.forEach(item=>{
                    var doc_date = item["uploadedDate"] === "" ? "" : item["uploadedDate"] === undefined ? "" : item["uploadedDate"] === "00000000" ? "" : moment(item["uploadedDate"]).format("DD-MMM-YYYY")
                    var file=item["userFileArray"]== "No files uploaded by user" ? "No files uploaded by user":[];
                    if (file instanceof Array){
                        item["userFileArray"].forEach(item=>{ file.push(item.split('->')[0])});
        
                    }
                    console.log(file)
                        data_arr.push([
                            item.supplier_number,item["selected_pmi_entities"],item["Invoice number"], item["Payment amount"], item.selectedCategory, item.comments, doc_date, file
                        ])
                    })
                    this.setState({data_arr: data_arr, totalCount: res.count,  data_loading: false})
                
              
                }
              })
          } else { 
            delete main_query.filters
            console.log(main_query)
            this.Ajax.viewQuerySubmissionLog(main_query)
              .then(res => res.json())
              .then(res => {
                console.log(res)
                if (res.hasOwnProperty("error")) {
                  this.setState({
                    // validate: true,
                    // validateMessage: labels[this.props.user.language].invoice_list.err,
                    // dataloaded: true,
                    // deriveddata: [],
    
                    // data_loading: false
                  });
                } else {
                    var data_arr=[];
                    res.data.forEach(item=>{
                    var doc_date = item["uploadedDate"] === "" ? "" : item["uploadedDate"] === undefined ? "" : item["uploadedDate"] === "00000000" ? "" : moment(item["uploadedDate"]).format("DD-MMM-YYYY")
                    var file=item["userFileArray"]== "No files uploaded by user" ? "No files uploaded by user":[];
                    if (file instanceof Array){
                        item["userFileArray"].forEach(item=>{ file.push(item.split('->')[0])});
        
                    }
                    console.log(file)
                        data_arr.push([
                            item.supplier_number,item["selected_pmi_entities"],item["Invoice number"], item["Payment amount"], item.selectedCategory, item.comments, doc_date, file
                        ])
                    })
                    this.setState({data_arr: data_arr, totalCount: res.count})
                
                }
              })
          }
        }
    
      }

    handleSubmissionLogs = () =>{
        main_query={supplier_number:this.props.user.vendor_number, supplier_email:this.props.user.validated_email,page: this.state.page, rowsPerPage:this.state.rowsPerPage};
        this.Ajax = new AjaxService();
            this.Ajax.viewQuerySubmissionLog(main_query)
                .then(result => result.json())
                .then(res => {
                    console.log(res)
                    var data_arr=[];
                    res.data.forEach(item=>{
                    var doc_date = item["uploadedDate"] === "" ? "" : item["uploadedDate"] === undefined ? "" : item["uploadedDate"] === "00000000" ? "" : moment(item["uploadedDate"]).format("DD-MMM-YYYY")
                    var file=item["userFileArray"]== "No files uploaded by user" ? "No files uploaded by user":[];

                    if (file instanceof Array){
                        item["userFileArray"].forEach(item=>{ file.push(item.split('->')[0])});
        
                    }
                    
                    console.log(file)
                        data_arr.push([
                            item.supplier_number, item["selected_pmi_entities"],item["Invoice number"], item["Payment amount"], item.selectedCategory, item.comments, doc_date, file
                        ])
                    })
                    this.setState({viewReportFlag:true, data_arr: data_arr, totalCount: res.count})

                }).catch(function (err) {
                    console.log(err)
                    // this.setState({
                    //     textfield_error: true,
                    //     error_text: "Error while submitting your request. Please try again later.",
                    //     logout: true
                    // })
                });  
    
        
    }
    setColumns = (columns_list) => {
        this.setState({
          columns_list: columns_list
        })
      } 
    getMuiTheme = () =>
    createMuiTheme({
        overrides: {
            MUIDataTableFilter: {
                padding: "9px 20px 33px 20px",
                fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                title: {
                    fontWeight: 400,
                    marginLeft: 0
                },
                resetLink: {
                    fontSize: 14,
                    fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                    fontWeight: 400
                }
            },
            MuiFormControl: {
                root: {
                    border: "1px solid #ccc !important",
                    width: "100% !important"
                }
            },
            MuiFormLabel: {
                root: {
                    fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                    fontWeight: 400
                }
            },
            MuiButton: {
                textPrimary: {
                    color: "#004f90"
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    textAlign: "left"
                }
            },
            MuiGridListTile: {
                root: {
                    paddingTop: "0 !important"
                },
                tile: {
                    marginTop: "0 !important"
                }
            },
            MUIDataTable: {
                responsiveScroll: {
                    overflowY: "scroll"
                }
            },
            

            MUIDataTableHeadCell: {
                fixedHeader: {
                    backgroundColor: "#f6f6f6",
                    color: "#0160a5 !important"
                },
                root: {
                    padding: "10px",
                    verticalAlign: "top"
                }
            },
            MuiToolbar: {
                root: {
                    background: "#f6f6f6"
                },
                regular: {
                    paddingLeft: "0"
                },
                gutters: {
                    paddingLeft: "0"
                }
            },
            MuiTableCell: {
                root: {
                    padding: "15px",
                    verticalAlign: "top",
                    fontFamily:
                        "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                },
                body: {
                    fontSize: "14px"
                },
                head: {
                    fontSize: "15px",
                    fontWeight: "600"
                }
            },
            MuiSelect: {
                icon: {
                    top: "0 !important"
                }
            },
            MUIDataTableSearch: {
                main: {
                    position: "absolute",
                    right: "12rem",
                    bottom: "10px",
                    width: "48%"
                },
                searchIcon: {
                    display: "none"
                }
            },
            MuiInputBase: {
                root: {
                    fontFamily:
                        "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                },
                input: {
                    paddingTop: "5px",
                    border: "1px solid #ccc",
                    paddingLeft: "5px",
                    paddingRight: "20px",
                    borderBottom: "1px solid #ccc",
                    fontSize: 13,
                    fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                }
            },
            MUIDataTableToolbar: {
                filterPaper: {
                    maxWidth: "46% !important",
                    left: "650px !important"
                },
                root: {
                    //display: ''
                }


            },
            MUIDataTablePagination: {
                root: {
                    fontFamily:
                        "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                    "&:last-child": {
                        padding: "0px"
                    }
                }
            },
            MuiTableFooter: {

            },
            MuiMenuItem: {
                root: {
                    fontFamily:
                        "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                    fontSize: 13
                }
            },
            MuiIconButton: {
                root: {
                    padding: "5px",
                    "&:focus": {
                        outline: "none !important"
                    },
                    "&:hover": {
                        // color: "#004f90 !important",
                        backgroundColor: "transparent !important"
                    }
                }
            },
            MuiPaper: {
                elevation4: {
                    boxShadow: "none !important",
                    borderRadius: "0px",
                    border: "1px solid #ccc !important",
                    width: "100%"
                }

            },
            MuiDialogContent:{
                root:{
                    overflowY:"auto"
                }},
            MuiSvgIcon: {
                root: {
                    width: "1em",
                    height: "1em",
                    fontFamily:
                        "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                    // paddingLeft: "15px"
                }
            },
            MuiTablePagination: {
                select: {
                    border: "none"
                },
                selectIcon: {

                }
            },
            MuiTable: {
                root: {
                    fontFamily:
                        "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                }
            },

            MuiTypography: {
                caption: {
                    fontFamily:
                        "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                    fontSize: "1rem"
                },
                h6: {
                    paddingLeft: 8,
                    fontSize: "1.5rem",
                    fontWeight: "normal",
                    fontFamily:
                        "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                },

            },
            MUIDataTableFilterList: {
                chip: {
                    margin: "8px 8px 8px 0px"
                }
            },
            MuiChip: {
                root: {
                    fontSize: 13,
                    fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                    backgroundColor: "#f4f4f4",
                    border: "1px solid #004f90",
                    margin: "0 5px"
                },
                deleteIcon: {
                    fill: "#004f90"
                }
            },
            MuiCheckbox: {
                MuiIconButton: {
                    root: {
                        color: "#004f90"
                    }
                }
            },
            MuiTableRow: {
                root: {
                    '&$selected': {
                        backgroundColor: '#0067bc2e !important'
                    }
                }
            },
            MUIDataTableSelectCell: {
                checked: { color: "#004f90 !important" }
            }
        }
    });
    render() {


        var language_labels = labels[this.props.user.language]["query_page"];
        var language_labelsTable = labels[this.props.user.language].invoice_submission
        var language_labels1 = labels[this.props.user.language].invoice_list

        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };
        const columns = [
            {
                name: "supplier_number",
                label: "Supplier Number",//language_labelsTable.company_code,
                options: {
                    sort: true,
                    filter: false,
                    filterType: "custom",
                    display:false,
                    filterOptions: {
                    //   display: (filterList, onChange, index, column) => {
        
                    //     return (
                    //       <div>
                    //         {this.state.filters_data.length !== 0 && <div>
                    //           <InputLabel >{language_labelsTable.company_code}</InputLabel>
                    //           <Select
                    //             multiple
                    //             className="custom-filters"
                    //             value={filterList[index]}
                    //             renderValue={selected => selected.join(', ')}
                    //             onChange={event => {
                    //               filterList[index] = event.target.value;
                    //               onChange(filterList[index], index, column);
                    //             }}
                    //           >
                    //             {this.state.filters_data["supplier_number"].map(item => (
                    //               <MenuItem key={item} value={item}>
                    //                 <Checkbox
                    //                   color='primary'
                    //                   checked={filterList[index].indexOf(item) > -1}
                    //                 />
                    //                 <ListItemText primary={item} />
                    //               </MenuItem>
                    //             ))}
                    //           </Select>
                    //         </div>}
                    //       </div>
                    //     )
        
        
                    //   }
                    }
                  }
        
            },
            {
                name: "selected_pmi_entities",
                label: language_labels.pmi_entity_name,
                options: {
                    sort: true,
                    filter: true,
                    filterType: "custom",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        // console.log(value, tableMeta, updateValue)
                         return ( 
                         <div>{value.map(item=>(<div style={{ textAlign: "left", whiteSpace:"nowrap" }}> {item}</div>))}</div>
                         )
              
                     },
                    filterOptions: {
                      display: (filterList, onChange, index, column) => {
        
                        return (
                          <div>
                            {this.state.filters_data.length !== 0 && <div>
                              <InputLabel >{language_labels.pmi_entity_name}</InputLabel>
                              <Select
                                multiple
                                className="custom-filters"
                                value={filterList[index]}
                                renderValue={selected => selected.join(', ')}
                                onChange={event => {
                                  filterList[index] = event.target.value;
                                  onChange(filterList[index], index, column);
                                }}
                              >
                                {this.state.filters_data["selected_pmi_entities"].map(item => (
                                  <MenuItem key={item} value={item}>
                                    <Checkbox
                                      color='primary'
                                      checked={filterList[index].indexOf(item) > -1}
                                    />
                                    <ListItemText primary={item} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>}
                          </div>
                        )
        
        
                      }
                    }
                  }
        
            },
            {
                name: "Invoice number",
                label: language_labels.invoice_no,
                options: {
                    sort: true,
                    filter: true,
                    filterType: "custom",
                    filterOptions: {
                      display: (filterList, onChange, index, column) => {
        
                        return (
                          <div>
                            {this.state.filters_data.length !== 0 && <div>
                              <InputLabel >{language_labels.invoice_no}</InputLabel>
                              <Select
                                multiple
                                className="custom-filters"
                                value={filterList[index]}
                                renderValue={selected => selected.join(', ')}
                                onChange={event => {
                                  filterList[index] = event.target.value;
                                  onChange(filterList[index], index, column);
                                }}
                              >
                                {this.state.filters_data["Invoice number"].map(item => (
                                  <MenuItem key={item} value={item}>
                                    <Checkbox
                                      color='primary'
                                      checked={filterList[index].indexOf(item) > -1}
                                    />
                                    <ListItemText primary={item} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>}
                          </div>
                        )
        
        
                      }
                    }
                  }
        
            },
            {
                name: "Payment amount",
                label: language_labels.pay_amount,
                options: {
                    sort: true,
                    filter: true,
                    filterType: "custom",
                    filterOptions: {
                      display: (filterList, onChange, index, column) => {
        
                        return (
                          <div>
                            {this.state.filters_data.length !== 0 && <div>
                              <InputLabel >{language_labels.pay_amount}</InputLabel>
                              <Select
                                multiple
                                className="custom-filters"
                                value={filterList[index]}
                                renderValue={selected => selected.join(', ')}
                                onChange={event => {
                                  filterList[index] = event.target.value;
                                  onChange(filterList[index], index, column);
                                }}
                              >
                                {this.state.filters_data["Payment amount"].map(item => (
                                  <MenuItem key={item} value={item}>
                                    <Checkbox
                                      color='primary'
                                      checked={filterList[index].indexOf(item) > -1}
                                    />
                                    <ListItemText primary={item} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>}
                          </div>
                        )
        
        
                      }
                    }
                  }
        
            },
            {    
                name: "selectedCategory",
                label:language_labels.header_text_right.split(":")[0],
                options: {
                    sort: true,
                    filter: true,
                    filterType: "custom",
                    filterOptions: {
                      display: (filterList, onChange, index, column) => {
        
                        return (
                          <div>
                            {this.state.filters_data.length !== 0 && <div>
                              <InputLabel >{language_labels.header_text_right.split(":")[0]} </InputLabel>
                              <Select
                                multiple
                                className="custom-filters"
                                value={filterList[index]}
                                renderValue={selected => selected.join(', ')}
                                onChange={event => {
                                  filterList[index] = event.target.value;
                                  onChange(filterList[index], index, column);
                                }}
                              >
                                {this.state.filters_data["selectedCategory"].map(item => (
                                  <MenuItem key={item} value={item}>
                                    <Checkbox
                                      color='primary'
                                      checked={filterList[index].indexOf(item) > -1}
                                    />
                                    <ListItemText primary={item} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>}
                          </div>
                        )
        
                      }
                    }
                  }
               
            },
            {
                name: "comments",
                label:language_labels.comments,
                options: {
                    sort: false,
                    filter: false,
                    filterType: "custom",
                    filterOptions: {
                      display: (filterList, onChange, index, column) => {
        
                        return (
                          <div>
                            {this.state.filters_data.length !== 0 && <div>
                              <InputLabel >{language_labelsTable.supplier_name}</InputLabel>
                              <Select
                                multiple
                                className="custom-filters"
                                value={filterList[index]}
                                renderValue={selected => selected.join(', ')}
                                onChange={event => {
                                  filterList[index] = event.target.value;
                                  onChange(filterList[index], index, column);
                                }}
                              >
                                {this.state.filters_data["comments"].map(item => (
                                  <MenuItem key={item} value={item}>
                                    <Checkbox
                                      color='primary'
                                      checked={filterList[index].indexOf(item) > -1}
                                    />
                                    <ListItemText primary={item} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>}
                          </div>
                        )
        
        
                      }
                    }
                  }
        
                
            },
            {
                name: "uploadedDate",
                label:language_labels.submission_date,
                options: {
                    sort: true,
                    filter: false,
                    filterType: "custom",
                    filterOptions: {
                      display: (filterList, onChange, index, column) => {
        
                        return (
                          <div>
                            {this.state.filters_data.length !== 0 && <div>
                              <InputLabel >{language_labelsTable.submission_date}</InputLabel>
                              <Select
                                multiple
                                className="custom-filters"
                                value={filterList[index]}
                                renderValue={selected => selected.join(', ')}
                                onChange={event => {
                                  filterList[index] = event.target.value;
                                  onChange(filterList[index], index, column);
                                }}
                              >
                                {this.state.filters_data["uploadedDate"].map(item => (
                                  <MenuItem key={item} value={item}>
                                    <Checkbox
                                      color='primary'
                                      checked={filterList[index].indexOf(item) > -1}
                                    />
                                    <ListItemText primary={item} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>}
                          </div>
                        )
        
        
                      }
                    }
                  }
        
               
            },
            {
                name:"userFileArray",
                label:language_labels.file_name,

                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                       // console.log(value, tableMeta, updateValue)
                        return ( 
                        // <div> {value.map(item=>(<div style={{ textAlign: "left" }}> {item.split('->')[0]}</div>))}  </div> 
                        <div>{value=="No files uploaded by user" ? <div style={{ textAlign: "left" }}> {value}</div>: value.map(item=>(<div style={{ textAlign: "left" }}> {item}</div>))}</div>
                        )
             
                    }
                }
            }
        ];

         options = {
            print: false,
            search: true,
            download: true,
            filter: true,
            filterType: "dropdown",
            viewColumns: false,
            tableBodyHeight: 'auto',
            selectableRows:'none',
            count:this.state.totalCount,
            rowsPerPage:this.state.rowsPerPage,
            serverSide:true,
            styles: {
                filterList: {
                  root: {
                    color: "red"
                  },
                  chip: {
                    color: "#FEFEF0"
                  }
                }
              },
              responsive: "vertical",
            downloadOptions: {
                filename: "Query Submission Report.csv",
                separator: ','
            },
            textLabels: {
                body: {
                  noMatch: this.state.data_loading ? language_labels1.loading_wait : language_labels1.no_results,
                  toolTip: language_labels1.sort,
                },
        
                toolbar: {
                  search: language_labels1.search,
                  downloadCsv: language_labels1.download,
                  viewColumns: language_labels1.view_columns,
                  filterTable: language_labels1.filter_table,
                },
                viewColumns: {
                  title: language_labels1.view_columns_heading
                },
                pagination: {
                  next: language_labels1.next,
                  previous: language_labels1.previous,
                  rowsPerPage: language_labels1.rowsPerPage,
                  displayRows: language_labels1.displayRows
                },
                filter: {
                  all: language_labels1.all,
                  title: language_labels1.title,
                  reset: language_labels1.reset,
                },
              },
        
            onTableChange: (action, tableState) => {
                console.log("ontablechange", action, tableState, this.state);
        
        
                switch (action) {
        
                  case "changePage":
                    if (this.props.user.role === "vendor" || this.props.user.role === "support") {
                      console.log(tableState.page)
                      this.commonAPIForTable(tableState.page, tableState.rowsPerPage, tableState.sortOrder)
                    }
        
                    break;
                    case "propsUpdate":
                        if (this.state.columns_list.length === 0) {
                          this.setColumns(tableState.columns)
                        }
            
                        break;
                    case "changeRowsPerPage":
                            if (this.props.user.role === "vendor" || this.props.user.role === "support") {
                              this.setState({
                                tableState: tableState,
                                rowsPerPage: tableState.rowsPerPage,
                                page: 0
                              })
                              tableState.page = 0;
                              console.log("tablestate page", tableState.page)
                              this.commonAPIForTable(0, tableState.rowsPerPage, tableState.sortOrder)
                
                            }
                        break;

                        case "sort":

                        if ((this.state.sort_name !== tableState.sortOrder.name || this.state.sort_direction !== tableState.sortOrder.direction)) {
                          if (tableState.page === 0) {
                            this.setState({
                              sort_name: tableState.sortOrder.name,
                              sort_direction: tableState.sortOrder.direction,
            
                            })
            
                          } else {
                            this.setState({
                              sort_name: tableState.sortOrder.name,
                              sort_direction: tableState.sortOrder.direction,
                              tableState: tableState,
                              rowsPerPage: tableState.rowsPerPage,
                              page: 0
                            })
                            tableState.page = 0;
                          }
                          if (this.props.user.role === "vendor" || this.props.user.role === "support") {
                            this.commonAPIForTable(tableState.page, tableState.rowsPerPage, tableState.sortOrder)
                          }
                        } else {
                          this.setState({
                            tableState: tableState,
                            rowsPerPage: tableState.rowsPerPage,
                            page: 0
                          })
                          tableState.page = 0;
                          if (this.props.user.role === "vendor" || this.props.user.role === "support") {
                            this.commonAPIForTable(tableState.page, tableState.rowsPerPage, tableState.sortOrder)
                          }
                        }
            
                        break;
            
                }},
                customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
                    return (
                      <div style={{ marginTop: '40px' }}>
                        {this.state.filters_data.length !== 0 && <Button variant="contained" onClick={() => this.handleFilterSubmit(applyNewFilters)}>Apply</Button>}
                        {this.state.filters_data.length === 0 && <p >{this.state.filtermsg}</p>}
                      </div>
                    );
                  },
                onFilterChange: (column, filterList, type) => {
      
                    // var len_flag = false
                    if (type === 'chip' || type === "reset") {
            
                      var newFilters = () => (filterList);
            
                      this.handleFilterSubmit(newFilters);
            
                    }
            
                  },
                onFilterDialogOpen: () => {
                    console.log("filter_data", this.state.filters_data)
                    if (this.state.filters_data.length === 0) {
                      var filter_dropdown;
                      this.Ajax = new AjaxService()
                      this.Ajax.getQueryReportsFiltersDropDown(this.props.user.validated_email, this.props.user.vendor_number)
                        .then(filters => filters.json())
                        .then(filters_list => {
                            console.log(filters_list)
                          if (filters_list.length !== 0) {
                            filter_dropdown = filters_list[0]
                           console.log( filter_dropdown["selectedCategory"]);
                           console.log( filter_dropdown["selected_pmi_entities"]);
                           
                            filter_dropdown["selectedCategory"] = filter_dropdown["selectedCategory"].sort()
                            filter_dropdown["Invoice number"] = filter_dropdown["Invoice number"].sort()
                            filter_dropdown["Payment amount"] = filter_dropdown["Payment amount"].sort()
                            filter_dropdown["selected_pmi_entities"]=filter_dropdown["selected_pmi_entities"].sort()
                           
                            this.setState({
                              filters_data: filter_dropdown,
                              filtermsg: ""
                            });
                          } else {
                            filter_dropdown = filters_list
                            this.setState({
                              filters_data: filter_dropdown,
                              filtermsg: language_labels.no_filters
                            });
                          }
            
                        }).catch(err => {
                          console.log(err);
                        });
                    }
            
                  },
                onSearchChange: (searchText) => {
                    if (this.props.user.role === "vendor" || this.props.user.role === "support") {
                      this.onSearch(searchText)
                    }
                  },
                onSearchClose: () => {
                    if (this.props.user.role === "vendor" || this.props.user.role === "support") {
            
                      console.log(main_query.search_text)
                      if (main_query.search_text !== undefined) {
                        delete main_query.search_text
                        this.onSearchClose()
                      }
            
            
                    }
                  },

                  ///
                  


        };
        const DialogTitle = ((props) => {
            const { children,  onClose, ...other } = props;
            return (
              <MuiDialogTitle  className="dialogTitleCustom" disableTypography >
                {console.log( children)}
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                  <IconButton aria-label="close"  onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                ) : null}
              </MuiDialogTitle>
            );
          });
        return (
            <div>
                {/* && this.state.access_cookie === this.props.user.accessToken */}
                {(this.props.user.role === "vendor") && <div>
                    <Snackbar style={{ whiteSpace: "pre-wrap" }} ContentProps={{

                    }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}

                        open={this.state.validate}
                        onClose={this.handleCloseFlag}
                        autoHideDuration={6000}
                        message={this.state.validateMessage}
                    />
                    <Navbar
                        appClass="query_page"
                        logout_class={this.state.selectedCategory === "" ? false : true}
                    ></Navbar>
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref }}
                        timeout={1000 * 60 * 10}
                        onIdle={this.handleOnIdle}
                        debounce={250}
                    />
                    <div>
                   
                        <Grid container className="query-main-container">
                            <Grid item xs={this.state.selectedCategory === "" ? 12 : 6} className="left-query-div">
                           
                                <h1 className={this.state.selectedCategory === "" ? "left-heading-text-full" : "left-heading-text"}>
                                    {language_labels["header_text_left"]}
                                    <p className="header-subtext">
                                        {language_labels["header_subtext"]}
                                    </p>
                                </h1>


                                <Grid container spacing={2} className={this.state.selectedCategory === "" ? "grid-container-left-full" : "grid-container-left"} justify="center">
                                    <Grid item xs={6} sm={6} md={6} lg={6} className={this.state.selectedCategory === "" ? "left-grid-items" : ""}>
                                        <div
                                            onClick={this.handleHoverSlide(
                                                "hover-right",
                                                "Invoice", "Status"
                                            )}
                                        >

                                            <Card className="category-cards" variant="outlined">
                                                <CardContent align="center">
                                                    <DescriptionOutlinedIcon className="invoicePaymentIcon" />
                                                    <Typography className="cardTitle" color="textSecondary">
                                                        {language_labels["invoice"]}
                                                        <br></br> {language_labels["status"]}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    </Grid>


                                    <Grid item xs={6} sm={6} md={6} lg={6} className={this.state.selectedCategory === "" ? "left-grid-items" : ""}>
                                        <div
                                            onClick={this.handleHoverSlide(
                                                "hover-right",
                                                "Payment",
                                                "Remittance"
                                            )}
                                        >
                                            <Card className="category-cards" variant="outlined">
                                                <CardContent align="center">
                                                    <MonetizationOnOutlinedIcon className="invoicePaymentIcon" />
                                                    <Typography className="cardTitle" color="textSecondary">
                                                        {language_labels["payment"]}
                                                        <br></br> {language_labels["remittance"]}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} className={this.state.selectedCategory === "" ? "left-grid-items" : ""}>
                                        <div
                                            onClick={this.handleHoverSlide(
                                                "hover-right",
                                                "General", "Enquiry"
                                            )}
                                        >
                                            <Card className="category-cards" variant="outlined">
                                                <CardContent align="center">
                                                    <ContactSupportOutlinedIcon className="invoicePaymentIcon" />
                                                    <Typography className="cardTitle">
                                                        {language_labels["general"]}
                                                        <br></br> {language_labels["enquiry"]}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} className={this.state.selectedCategory === "" ? "left-grid-items" : ""}>
                                        <div
                                            onClick={this.handleHoverSlide(
                                                "hover-right",
                                                "Invite", "Others"
                                            )}
                                        >
                                            <Card className="category-cards" variant="outlined">
                                                <CardContent align="center">
                                                    <PersonAddIcon className="invoicePaymentIcon" />
                                                    <Typography className="cardTitle">
                                                        {language_labels["invite"]}
                                                        <br></br> {language_labels["others"]}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} className={this.state.selectedCategory === "" ? "left-grid-items" : ""}>
                                        <div
                                            onClick={this.handleHoverSlide(
                                                "hover-right",
                                                "view", "report"
                                            )}
                                        >
                                            <Card className="category-cards" variant="outlined">
                                                <CardContent align="center">
                                                    <ViewListIcon className="invoicePaymentIcon" />
                                                    <Typography className="cardTitle">
                                                    {language_labels.query_card_title}
                                                        <br></br> {language_labels.query_card_sub}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    </Grid>

                                </Grid>
                                <Dialog
                                    open={this.state.openInviteDialog}
                                    onClose={this.handleClose}
                                    disableBackdropClick={true}
                                    disableEscapeKeyDown={true}
                                >
                                    <DialogTitle className="text-dialog">{language_labels["invite_heading"]}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText className="text-dialog">

                                            {language_labels["invite_matter_2"]}
                                        </DialogContentText>
                                        <Grid container className="help-div">
                                            <Grid item xs={5} className="help-grid-item">
                                                {language_labels["ur_email"]} :
                                            </Grid>
                                            <Grid item xs={7} className="help-grid-item">
                                                <TextField
                                                    value={this.props.user.validated_email}
                                                    className="input-field-div"
                                                    name="yourEmail"
                                                    disabled={true}
                                                    inputProps={{
                                                        style: {
                                                            height: 15,
                                                            fontSize: 14,
                                                            fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                                                            fontWeight: 400,
                                                            paddingLeft: 5,

                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={5} className="help-grid-item">
                                                {language_labels["supplier_name"]} :
                                            </Grid>
                                            <Grid item xs={7} className="help-grid-item">
                                                <TextField
                                                    value={this.props.user.vendor_name}
                                                    className="input-field-div"
                                                    name="invite_supplier_name"
                                                    disabled={true}
                                                    inputProps={{
                                                        style: {
                                                            height: 15,
                                                            fontSize: 14,
                                                            fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                                                            fontWeight: 400,
                                                            paddingLeft: 5
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={5} className="help-grid-item">
                                                {language_labels["vat_no"]} :
                                            </Grid>
                                            <Grid item xs={7} className="help-grid-item">
                                                <TextField
                                                    value={this.state.vat_number}
                                                    className="input-field-div"
                                                    disabled={true}
                                                    name="vat_number"
                                                    multiline
                                                    rows={2}
                                                    inputProps={{
                                                        style: {
                                                            height: 15,
                                                            fontSize: 14,
                                                            fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                                                            fontWeight: 400,
                                                            paddingLeft: 5
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={5} className="help-grid-item">
                                                {language_labels["select_region"]} <span style={{ fontSize: "13px" }}>{language_labels["required"]} </span> :
                                            </Grid>
                                            <Grid item xs={7} className="help-grid-item">
                                                <FormControl className="multi-select-formcontrol" error={this.state.region_err_invite ? true : false}>
                                                    <Select
                                                        className="multi-select-invite"
                                                        required
                                                        value={this.state.selected_region_invite}
                                                        placeholder={language_labels["select_entity"]}
                                                        onChange={(event) => this.handleRegionChangeInvite(event)}
                                                        MenuProps={MenuProps}
                                                      
                                                    >
                                                        {this.state.regions && Object.keys(this.state.regions).map((name, i) => (
                                                            <MenuItem key={i} value={name}>
                                                                {name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText>{this.state.region_err_invite_msg}</FormHelperText>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={5} className="help-grid-item">
                                                {language_labels["select_entity"]} <span style={{ fontSize: "13px" }}>{language_labels["required"]} </span> :
                                            </Grid>
                                            <Grid item xs={7} className="help-grid-item">
                                                <FormControl className="multi-select-formcontrol" error={this.state.entity_error ? true : false}>
                                                    <Select
                                                        className="multi-select-invite"
                                                        multiple
                                                        required
                                                        value={this.state.selected_entities}
                                                        placeholder={language_labels["select_entity"]}
                                                        onChange={(event) => this.handleEntityChangeInvite(event)}
                                                        input={<Input id="select-multiple-chip" placeholder={language_labels["select_entity"]} />}
                                                        disabled={this.state.pmi_entities_invite.length === 0 ? true : false}
                                                        renderValue={(selected) => {
                                                            if (selected.length === 0) {
                                                                return (<em>{language_labels["select_entity"]}</em>);
                                                            }
                                                            return (<div >
                                                                {selected.map((value) => (
                                                                    <Chip key={value} label={value} />
                                                                ))}
                                                            </div>)
                                                        }
                                                        }
                                                        MenuProps={MenuProps}
                                                    >

                                                        {this.state.pmi_entities_invite && this.state.pmi_entities_invite.map((name, i) => (
                                                            <MenuItem key={i} value={name["company_name"]}>
                                                                <Checkbox checked={this.state.selected_entities.indexOf(name["company_name"]) > -1} />
                                                                <ListItemText primary={name["company_name"]} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText>{this.state.entity_error_text}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={5} className="help-grid-item">
                                                {language_labels["invitee_email"]} <span style={{ fontSize: "13px" }}>{language_labels["required"]} </span>:
                                            </Grid>
                                            <Grid item xs={7} className="help-grid-item">
                                                <TextField
                                                    value={this.state.invitee_email_1}
                                                    className="invitee-two-div"
                                                    name="invitee_email_1"
                                                    placeholder={language_labels["email_id"] + " 1"}
                                                    id="email1"
                                                    autoComplete='no'
                                                    error={(this.state.invitee_email_1 === "" && this.state.field_error === true) ? true : (this.state.inviteeError === true) ? true : false}
                                                    helperText={(this.state.invitee_email_1 === "" && this.state.field_error === true) ? this.state.error_text : (this.state.inviteeError === true) ? this.state.invitee_error_text : ""}
                                                    onChange={(event) => this.handleInviteValuesChange(event)}
                                                    inputProps={{
                                                        autoComplete: 'no',
                                                        style: {
                                                            height: 15,
                                                            fontSize: 14,
                                                            fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                                                            fontWeight: 400,
                                                            paddingLeft: 5
                                                        }
                                                    }}

                                                />

                                            </Grid>
                                            <Grid item xs={5} className="help-grid-item">

                                            </Grid>
                                            <Grid item xs={7} className="help-grid-item">
                                                <TextField
                                                    value={this.state.invitee_email_2}
                                                    className="invitee-two-div"
                                                    name="invitee_email_2"
                                                    placeholder={language_labels["email_id"] + " 2"}
                                                    id="email2"
                                                    autoComplete='no'
                                                    disabled={this.state.invitee_email_1 === "" ? true : false}
                                                    error={(this.state.inviteeError_2 === true) ? true : false}
                                                    helperText={(this.state.inviteeError_2 === true) ? this.state.invitee_error_text_2 : ""}
                                                    onChange={(event) => this.handleInviteValuesChange(event)}
                                                    inputProps={{
                                                        style: {
                                                            height: 15,
                                                            fontSize: 14,
                                                            fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                                                            fontWeight: 400,
                                                            paddingLeft: 5
                                                        }
                                                    }}
                                                />
                                                <IconButton className="delete-btn" onClick={() => this.handleDeleteEmail("invitee_email_2")}>
                                                    <DeleteOutlineOutlinedIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={5} className="help-grid-item">

                                            </Grid>
                                            <Grid item xs={7} className="help-grid-item">
                                                <TextField
                                                    value={this.state.invitee_email_3}
                                                    className="invitee-two-div"
                                                    name="invitee_email_3"
                                                    placeholder={language_labels["email_id"] + " 3"}
                                                    id="email3"
                                                    autoComplete='no'
                                                    disabled={this.state.invitee_email_1 === "" ? true : false}
                                                    error={(this.state.inviteeError_3 === true) ? true : false}
                                                    helperText={(this.state.inviteeError_3 === true) ? this.state.invitee_error_text_3 : ""}
                                                    onChange={(event) => this.handleInviteValuesChange(event)}
                                                    inputProps={{
                                                        style: {
                                                            height: 15,
                                                            fontSize: 14,
                                                            fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                                                            fontWeight: 400,
                                                            paddingLeft: 5
                                                        }
                                                    }}
                                                />
                                                <IconButton className="delete-btn" onClick={() => this.handleDeleteEmail("invitee_email_3")}>
                                                    <DeleteOutlineOutlinedIcon />
                                                </IconButton>
                                                <p className="bottom-p">{language_labels["invitee_note"]}</p>
                                            </Grid>

                                            <Grid item xs={5} className="help-grid-item">
                                                {language_labels["comments"]} <span style={{ fontSize: "13px" }}>{language_labels["if_any"]} </span>:
                                            </Grid>
                                            <Grid item xs={7} className="help-grid-item">
                                                <TextField
                                                    value={this.state.InviteComments}
                                                    className="input-field-div"
                                                    name="InviteComments"
                                                    onChange={(event) => this.handleInviteValuesChange(event)}
                                                    multiline
                                                    rows={4}
                                                    inputProps={{
                                                        maxLength: 250,
                                                        style: {

                                                            fontSize: 14,
                                                            fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                                                            fontWeight: 400,
                                                            paddingLeft: 5
                                                        }
                                                    }}
                                                />
                                                {this.state.invite_charLeft !== '' && <div style={{ color: 'black', width: '70%', fontSize: "13px" }}>{language_labels["characters_left"]}{this.state.invite_charLeft}</div>}

                                            </Grid>
                                            <Grid item xs={5}>
                                                {language_labels["digital_sig"]} :
                                            </Grid>
                                            <Grid item xs={7} className="input-field-grid">

                                            </Grid>
                                            <Grid item xs={12}>
                                                <label
                                                    className={this.state.checked_class}
                                                    onAnimationEnd={() => this.setState({ checkbox_class: "signature-label" })}
                                                >
                                                    <Checkbox
                                                        className="signature-checkbox"
                                                        required={true}
                                                        checked={this.state.digitalSignature}
                                                        onChange={this.handleCheckBoxChange}
                                                        name="digitalSignature"
                                                        disableRipple
                                                        inputRef={this.checkboxInput}
                                                    />
                                                    {language_labels["digital_confirm"]}
                                                </label>
                                                {this.state.checkbox_error && <FormHelperText style={{ color: "red" }}>{language_labels["terms_agree"]}</FormHelperText>}

                                            </Grid>

                                        </Grid>

                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.clickInviteOk} className="msg-btn">
                                            {language_labels["ok"]}
                                        </Button>
                                        <Button onClick={this.handleCancel} className="msg-btn">
                                            {language_labels["cancel"]}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={this.state.viewReportFlag}
                                    onClose={(event) =>  this.setState({ viewReportFlag: false })}
                                    disableBackdropClick={true}
                                    disableEscapeKeyDown={true}
                                    className='customTable'
                                    PaperComponent={PaperComponent}
                                    scroll={'paper'}
                                >
                                    <DialogTitle className="msg-dialog-title" style={{ cursor: 'move' }} id="draggable-dialog-title" onClose={(event) => this.setState({ viewReportFlag: false })}>{language_labels.table_title}</DialogTitle>
                                    <DialogContent>
                                    {<MuiThemeProvider theme={this.getMuiTheme()}> <MUIDataTable
                                                    data={this.state.data_arr}
                                                    columns={columns}
                                                    options={options}
                                                /></MuiThemeProvider>}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button disableRipple onClick={(event) => this.setState({ viewReportFlag: false })} className="msg-btn">
                                        {language_labels["ok"]}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={this.state.success_dialog}
                                    keepMounted
                                    onClose={this.handleClose}

                                >
                                    <DialogTitle >
                                        {language_labels["dailog_header"]}
                                        {"   "}

                                    </DialogTitle>

                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-slide-description">
                                            {language_labels["dailog_content"]}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleClose} color="primary">
                                            {language_labels["close_button"]}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={this.state.errorDialog}
                                    onClose={this.handleClose}
                                    disableBackdropClick={true}
                                    disableEscapeKeyDown={true}
                                >
                                    <DialogTitle className="text-dialog"> {language_labels.raise_req_err_title}<span><HighlightOffIcon className="no-access-icon" /></span></DialogTitle>
                                    <DialogContent>
                                        <DialogContentText className="text-dialog">
                                          {labels[this.props.user.language].login_page.submit_err}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>

                                        <Button onClick={this.handleClose} className="msg-btn">
                                            {language_labels["ok"]}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>

                            <form onSubmit={e => this.handleRaiseQuery(e)} style={{ display: 'contents' }} name="myform" novalidate>
                                {this.state.selectedCategory !== "" && <Grid item xs={6} className="right-query-div">
                                    <h1 className="right-heading">
                                        {language_labels["header_text_right"]}{" "}
                                        {this.state.selectedCategory}
                                    </h1>

                                    <FormControl className="multi-select-formcontrol" error={this.state.region_error ? true : false}>
                                        <p className="para">{language_labels["select_region"]}</p>
                                        <Select
                                            className="multi-select"

                                            value={this.state.selected_region}
                                            placeholder={language_labels["select_entity"]}
                                            onChange={(event) => this.handleRegionChange(event)}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.regions && Object.keys(this.state.regions).map((name, i) => (
                                                <MenuItem key={i} value={name}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{this.state.region_error_msg}</FormHelperText>
                                    </FormControl>
                                    <FormControl placeholder={language_labels["select_entity"]} className="multi-select-formcontrol" error={this.state.entities_error}>
                                        <p className="para">{language_labels["select_entity"]}</p>
                                        <Select
                                            className="multi-select"
                                            multiple
                                            disabled={this.state.pmi_entities.length === 0 ? true : false}
                                            // error={this.state.multiline_err}
                                            // helperText={(this.state.multiline_err === true) ? this.state.multiline_err_msg : ""}
                                            value={this.state.selected_entities}
                                            placeholder={language_labels["select_entity"]}
                                            onChange={(event) => this.handleEntityChange(event)}
                                            input={<Input id="select-multiple-chip" placeholder={language_labels["select_entity"]} />}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return (<em>{language_labels["select_entity"]}</em>);
                                                }
                                                return (<div >
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={value} />
                                                    ))}
                                                </div>)
                                            }
                                            }
                                            MenuProps={MenuProps}
                                        >

                                            {this.state.pmi_entities && this.state.pmi_entities.map((name, i) => (
                                                <MenuItem disabled={name.display} key={i} value={name["company_name"]}>
                                                    <Checkbox checked={this.state.selected_entities.indexOf(name["company_name"]) > -1} />
                                                    <ListItemText primary={name["company_name"]} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{this.state.entities_error_msg}</FormHelperText>
                                    </FormControl>
                                    {/* <form className={this.state.headerTextFieldClass}> */}
                                    {this.state.selectedCategory === "Payment Remittance" ? (
                                        <TextField
                                            className={this.state.textFieldClass}
                                            onChange={this.handleTextFieldChange}
                                            value={this.state.textFieldValue}
                                            placeholder={language_labels["remittance_placeholder"]}
                                            // required={this.state.selectedCategory === "Payment Remittance" ? true : false}
                                            error={this.state.inv_num_error}
                                            helperText={(this.state.inv_num_error === true) ? this.state.invnum_err_msg : ""}
                                            inputProps={{
                                                style: {
                                                    color: "black",
                                                    paddingLeft: "5px",
                                                    fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                                                },
                                            }}
                                        />
                                    ) : (
                                        <TextField
                                            className={this.state.textFieldClass}
                                            onChange={this.handleTextFieldChange}
                                            value={this.state.textFieldValue}
                                            placeholder={language_labels["invoice_no_placeholder"]}
                                            // required={this.state.selectedCategory === "Invoice Status" ? true : false}
                                            error={this.state.inv_num_error}
                                            helperText={(this.state.inv_num_error === true) ? this.state.invnum_err_msg : ""}
                                            inputProps={{
                                                style: {
                                                    color: "black",
                                                    paddingLeft: "5px",
                                                    fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                                                },
                                            }}
                                        />


                                    )}

                                    {/* <br />
              <br /> */}

                                    <TextField
                                        className="id-field-query-new"
                                        placeholder={this.state.selectedCategory === "Payment Remittance" ? language_labels["reference_number"] + language_labels["query_placeholder"] + ")" : language_labels["query_placeholder"]}
                                        multiline
                                        error={this.state.multiline_err}
                                        helperText={(this.state.multiline_err === true) ? this.state.multiline_err_msg : ""}
                                        rows={4}
                                        style={{ border: ((this.state.charLeft !== "" && this.state.charLeft <= 500 && this.state.charLeft > 300) && "3px solid green" || (this.state.charLeft !== "" && this.state.charLeft < 300 && this.state.charLeft > 100) && "3px solid yellow" || (this.state.charLeft !== "" && this.state.charLeft < 100) && '3px solid red') }}
                                        value={this.state.textAreaValue}
                                        onChange={this.handleTextAreaChange}
                                        inputProps={{
                                            maxLength: 500,
                                            style: {
                                                color: "black",
                                                paddingLeft: "5px",
                                                fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                                            },
                                        }}
                                        rowsMax={5}
                                    />
                                    {this.state.charLeft !== '' && <div style={{ color: 'red', width: '70%' }}>{language_labels["characters_left"]}{this.state.charLeft}</div>}
                                    <FilePond
                                        className="filepond"
                                        ref={(ref) => (this.pond = ref)}
                                        name="userfiles"
                                        files={this.state.files}
                                        labelIdle={language_labels["drag_drop_1"] + ' <span class="filepond--label-action">' + language_labels["browse"] + '</span>'}
                                        allowPaste={false}
                                        allowFileTypeValidation={true}
                                        allowFileSizeValidation={true}
                                        ////////comment
                                        maxFileSize="5MB"
                                        maxTotalFileSize="18874368Bytes"
                                        labelMaxFileSizeExceeded={language_labels["max_file_size"]}
                                        acceptedFileTypes="image/png, image/jpeg,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/docx,application/x-zip-compressed, image/tiff,image/tif,
                 application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.spreadsheet, .xlsx"
                                        labelFileTypeNotAllowed={language_labels["supported_file_type"]}
                                        labelFileProcessing={language_labels["uploading"]}
                                        labelFileProcessingError={language_labels["err_upload"]}
                                        labelFileProcessingComplete={language_labels["upload_complete"]}
                                        labelMaxTotalFileSizeExceeded={language_labels["max_upload"]}
                                        labelButtonProcessItem="Upload"
                                        allowMultiple={true}
                                        fileValidateTypeDetectType={true}
                                        oninit={() => this.handleInit()}
                                        server="/upload"
                                        onremovefile={(err, file) => {
                                            console.log(err)
                                            console.log("here remove files", file)
                                        }}
                                        onupdatefiles={(fileItems) => {
                                            console.log("here in fileupdate", fileItems, this.pond)
                                            // console.log("here in fileupdate", fileItems[0].file)

                                            this.setState({
                                                files: fileItems.map((fileItem) => fileItem.file),
                                                // files: fileItems.map((fileItem) => {
                                                //     // console.log("each fileitem ", fileItem.file)
                                                //     return fileItem.file
                                                // }),
                                            });
                                            // this.pond.processFiles().then(fileItems => {
                                            //     // files have been processed
                                            //     console.log("here file is processsed")
                                            // })
                                        }}
                                    />
                                    <ul className="bullet-list-new">
                                        <li> {language_labels["note"]}</li>

                                        <li>{language_labels["note_part_two"]}</li>
                                        <li> {language_labels["note_five"]}</li>
                                    </ul>


                                    <Button
                                        type="submit"
                                        // onClick={this.handleRaiseQuery}
                                        className="submit-query-btn"
                                    >
                                        {language_labels["submit_query"]}
                                    </Button>
                                   
                                    <Grid container spacing={1} justify="center">
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Backdrop onBackdropClick="false" open={this.state.loadingOpen} onClick={(event) => this.handleDialogClose("spinner")}>
                                                <CircularProgress className="circular-spinner" />
                                            </Backdrop>
                                            <Dialog
                                                open={this.state.open}
                                                keepMounted
                                                onClose={this.handleClose}

                                            >
                                                <DialogTitle >
                                                    {language_labels["dailog_header"]}
                                                    {"   "}

                                                </DialogTitle>

                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-slide-description">
                                                        {language_labels["dailog_content"]}
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={this.handleClose} color="primary">
                                                        {language_labels["close_button"]}
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                            <Dialog
                                                open={this.state.note_msg}
                                                keepMounted
                                                onClose={this.handleSubmitQuery}
                                                disableBackdropClick={true}
                                                disableEscapeKeyDown={true}
                                            >
                                                <DialogTitle >
                                                    {language_labels["Confirmation"]}
                                                </DialogTitle>

                                                <DialogContent>
                                                    <DialogContentText >
                                                        <List className="subtext-list">

                                                            <ListItem className="bottom-list-item" >
                                                                <ListItemIcon>
                                                                    <ArrowRightIcon />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography className="bottom-list-anss">{language_labels["note_three"]}</Typography>
                                                                    }

                                                                />
                                                            </ListItem>
                                                            <ListItem className="bottom-list-item">
                                                                <ListItemIcon>
                                                                    <ArrowRightIcon />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography className="bottom-list-anss">{language_labels["note_four"]}</Typography>
                                                                    }

                                                                />
                                                            </ListItem>

                                                        </List>

                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={this.handleSubmitQuery} className="msg-btn" color="primary">
                                                        {language_labels["ok"]}
                                                    </Button>
                                                    <Button onClick={this.handleCancel} className="msg-btn" color="primary">
                                                        {language_labels["cancel"]}
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </Grid>

                                        <Grid Grid item xs={12} sm={12} md={6} lg={6}>

                                        </Grid>
                                    </Grid>
                                    {/* </form> */}
                                </Grid>}
                            </form>
                        </Grid>
                    </div>
                    {this.state.selectedCategory !== "" && <FloatingButtons {...this.props} appClass="query" />}
                </div >}
                {(this.props.user.role !== "vendor") && <NotFound history={this.props.history} />}
                {/* || this.state.access_cookie !== this.props.user.accessToken */}
            </div >

        )
    }
}

QueryPage.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    user: state.user
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(AllActions, dispatch)
})


export default connect(mapStateToProps, mapDispatchToProps)(QueryPage);