import React, { Component } from 'react';
import '../App.css';
// import Login from '../component/login';
// import BeforeHeaderLogin from '../component/BeforeHeaderLogin';
// import Footer from '../component/footer';
// import AfterHeaderLogin from '../component/AfterHeaderLogin';
import AdminPage from '../component/admin-page';
import NotFound from '../component/NotFound';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as AllActions from '../actions'
import PropTypes from 'prop-types'

import Cookies from 'js-cookie';


class AdminScreen extends Component {

    constructor(props) {
        super(props);
      
        this.state = {
          access_cookie : Cookies.get("access-cookie")
        };
      }

    render() {
        if(this.props.user.role !== "admin")
        {
            return(
           <NotFound/>
            );
        }
        return (
            <div>
                {/* // <div className="container-fluid full-eight bodybg-gray " >
            // <AfterHeaderLogin/>
            // <p className="margin-left-50px">Welcome User!</p> */}
                <AdminPage />

            </div>
        );
    }
}

AdminScreen.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    user: state.user
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(AllActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminScreen);
