import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AjaxService from "../services/AjaxServices";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
 
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import { labels } from "./../resources";


function LandingCard (props) {
    const [editcardTitle, setCardTitle] = useState('');
    const [editcard, setEditcard]=useState(false);
    const [editInd, setEditInd]=useState('');
    const [editcardVal, seteditcardVal]=useState('');
    const [editlinkval, seteditLinkVal]=useState('');
    const [editlinkval1, seteditLinkVal1]=useState('');
    const [editlinkval2, seteditLinkVal2]=useState('');
    const [editdisplayval, seteditdisplayval]=useState('');
    const [editdisplayval1, seteditdisplayval1]=useState('');
    const [editdisplayval2, seteditdisplayval2]=useState('');
    const [ loadingOpen, setLoadingOpen]=useState(false);
    const [file,setFile]=useState('');
    const [confirm_dialog, setconfirm_dialog]=useState(false);
    const [success_dialog, setsuccess_dialog]=useState(false);
    const [deleteIndex, setdeleteIndex]=useState('');
    let role = props.user.role;
   
    var language_labels = labels[props.user.language]["admin_page"];
   
    const editCard=(i)=>{
      console.log(" edit index"+i)
      console.log(props)
      // this.setState({editcard:true})
      setEditcard(true);
      setEditInd(i);
      seteditcardVal(props.content);
      setCardTitle(props.title);
      seteditLinkVal(props.link ==""? "":props.link)
      seteditLinkVal1(props.link1 ==""? "":props.link1)
      seteditLinkVal2(props.link2 ==""? "":props.link2)
      seteditdisplayval(props.displayLink ==""? "":props.displayLink)
      seteditdisplayval1(props.displayLink1 ==""? "":props.displayLink1)
      seteditdisplayval2(props.displayLink2 ==""? "":props.displayLink2)
      console.log(editlinkval1,editlinkval2)
      
    }
    const  handleChange=(e)=>{
      const data=e.target.files[0]
       setFile(data)
  }
  const cancelcardImgUpload =()=>{
    console.log("cancel button clicked")
    const data=""
    setFile(data)
}

    const handleLink=(e)=>{
      console.log("dattta") 
    seteditLinkVal(e.target.value )
     
    }
    const handleLink1=(e)=>{
      console.log("dattta") 
    seteditLinkVal1(e.target.value )
     
    }
    const handleLink2=(e)=>{
      console.log("dattta") 
    seteditLinkVal2(e.target.value )
     
    }
    const handleDisplayLink=(e)=>{
      console.log("dattta") 
    seteditdisplayval(e.target.value )
     
    }
    const handleDisplayLink1=(e)=>{
      console.log("dattta") 
      seteditdisplayval1(e.target.value )
     
    }
    const handleDisplayLink2=(e)=>{
      console.log("dattta") 
      seteditdisplayval2(e.target.value )
     
    }

    const handleCard=(e)=>{
      console.log("dattta")
    
    seteditcardVal(e.target.value )
     
    }
    const handleCardTitle=(e)=>{
      console.log("edit title")
    
      setCardTitle(e.target.value )
    }
    const cancelEdit=()=>{
      setEditcard(false)
      setEditInd("")
    } 
    const saveCard=(i)=>{
      console.log(i,"save data")
       let ind= (i-1);
       let file_name='';
      console.log("--"+ind+"--",editcardVal)
      setEditcard(false)
      setEditInd("")
      let cardData = props.card;
      console.log(cardData);
      cardData.forEach(item=>{
        console.log("id ---",item.id)
        if (item.id == i){
          console.log("id matched---")
          item.content=editcardVal;
          item.title=editcardTitle;
          item.link=editlinkval;
          item.link1=editlinkval1;
          item.link2=editlinkval2;
          item.displayLink=editdisplayval;
          item.displayLink1=editdisplayval1;
          item.displayLink2=editdisplayval2;
        file_name = (item.hasOwnProperty("imgUrl")? item.imgUrl :"card")
        }

      })
      setLoadingOpen(true);
      let Ajax = new AjaxService();
      if (file != "") {
        console.log("inside if of save", file)
      
        Ajax.SaveBannerImgDB(file,file_name).then(res => {
          console.log(res)
          if(res.status==200){
            if (file_name == "card")
          cardData[ind].imgUrl= res.data.name;
              Ajax.saveCardFromDB(cardData).then(result => {
                    console.log("card api called",result);
                    props.getCardDataFromDB();
                    const data=""
                    setFile(data)
                    setLoadingOpen(false);
                    setsuccess_dialog(true);
              
              })
            }
            else {
              setLoadingOpen(false);
            }
        });
            
         
          
        
      }
      else {
        console.log("inside else of save", file)
        Ajax.saveCardFromDB(cardData).then(result => {
                console.log("card api called",result);
                console.log(result)
                props.getCardDataFromDB();
                const data=""
                setFile(data)
                setLoadingOpen(false);
                setsuccess_dialog(true);
           
        })
      }

     
     
     
    }
    const handleDialogOk = ()=>{
      setconfirm_dialog(false);

      let cardData = props.card;
      let delete_file=props.imgUrl;
      console.log(cardData);
      let i= deleteIndex;
      console.log("index"+i+"--",delete_file)
      cardData=cardData.filter(item => item.id != i);
    
      cardData.forEach((item, i) => {
        item.id = (i + 1);
   })
      console.log(cardData);
       setLoadingOpen(true);
       let Ajax = new AjaxService();
      Ajax.deleteImgS3(delete_file).then(res=>{
        console.log(res);
        if(res.status== 200){
            Ajax.saveCardFromDB(cardData)
                  .then(result => {
                      console.log("card api called",result);
                      props.getCardDataFromDB()
                      setLoadingOpen(false);
                  })



        }
        else{

          console.log("couldn't delete file");
          setLoadingOpen(false);
        }
      })
     

    }

    const handleSuccessDialog =()=>{
      setsuccess_dialog(false);
    }
   
    const handleCancel =()=>{
      setconfirm_dialog(false);
    }
   const  deleteCard=(i)=>{
    setconfirm_dialog(true);
    setdeleteIndex(i);
     
      
    }

   
     return(   
      
    <div className="cardGrid" key={"key"+props.id}> 
       <Backdrop onBackdropClick="false" open={loadingOpen} >
                        <CircularProgress className="circular-spinner" />
                    </Backdrop>
     
      <div className="card-content-grid">
      {console.log(role)}
      {(role === "vendor") && <div>
    
    
        <>
        <img src={ props.s3link } 
        alt={ language_labels["img_add"] } />
        <h2>{ props.title }</h2>
       {console.log("hereeee vendor")}
           <p>{ props.content }</p> 
           <a  style={props.link == "" ?{display :'none'}:{display:'block', marginTop:'5px', padding:'5px'}} href={props.link} target="_blank">{props.displayLink}</a>
          <a  style={props.link1 == "" ?{display :'none'}:{display:'block', marginTop:'5px', padding:'5px'}} href={props.link1} target="_blank">{props.displayLink1}</a>
          <a  style={props.link2 == "" ?{display :'none'}:{display:'block', marginTop:'5px', padding:'5px'}} href={props.link2} target="_blank">{props.displayLink2}</a>

        </>  
        </div >}
     {(role == "support" && editcard === false) && <div>
          <>
          <img src={ props.s3link } 
        alt={ props.alt || language_labels["img_add"] } />
          <h2>{ props.title }</h2>
         {console.log("hereeee admin , edit false")}
         {/* {console.log(this.state.cardData)} */}
        <p>{ props.content }</p> 
       
          <a  style={props.link == "" ?{display :'none'}:{display:'block', marginTop:'5px', padding:'5px'}} href={props.link} target="_blank">{props.displayLink}</a>
          <a  style={props.link1 == "" ?{display :'none'}:{display:'block', marginTop:'5px', padding:'5px'}} href={props.link1} target="_blank">{props.displayLink1}</a>
          <a  style={props.link2 == "" ?{display :'none'}:{display:'block', marginTop:'5px', padding:'5px'}} href={props.link2} target="_blank">{props.displayLink2}</a>

        <Button className="customAdd" variant="outlined" onClick={(event) => editCard(props.id)}>{language_labels.edit}</Button>
        <Button className="customAdd" variant="outlined" startIcon={<DeleteIcon />} onClick={(event) => deleteCard(props.id)}>{language_labels.delete}</Button>
       
          </> 

          </div >}
      {(role == "support" && editcard === true )  && <div>
      {editInd == props.id  && <div>
          <> {console.log("hereeee admin", props)} 
          <input 
             type="file" 
             onChange={handleChange} 
             style={{ display: (file?'none':'inline-block' ) }}
             id="upload-button"
             />   
           <img   style={{ width: '100%' , height: '120px'  }} src={file ?URL.createObjectURL(file) : props.s3link }/>

              {file &&
                
                  <div> 
                      <span>{language_labels.preview}</span>
                      {/* <Button className="msg-btn" variant="outlined" onClick={(event) => saveBanner()}>Save</Button> */}
                      <Button className="msg-btn"  style={{ display: 'block' }}variant="outlined" onClick={(event) => cancelcardImgUpload()}>{language_labels.img_different}</Button>
      

                     </div>  
      
      } 
        <TextField
                    className="customtext"
                     multiline
                    rows={3}
                    key="headerKey1"
                    // style={{ border: ((this.state.charLeft !== "" && this.state.charLeft <= 500 && this.state.charLeft > 300) && "3px solid green" || (this.state.charLeft !== "" && this.state.charLeft < 300 && this.state.charLeft > 100) && "3px solid yellow" || (this.state.charLeft !== "" && this.state.charLeft < 100) && '3px solid red') }}
                    value={editcardTitle}
                    onChange={handleCardTitle}
                    inputProps={{
                                    maxLength: 3000,
                                    style: {
                                        color: "black",
                                        paddingLeft: "5px",
                                        fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                                    },
                                }}
                    rowsMax={5}
                    />


           <TextField
                    className="customtext"
                     multiline
                    rows={15}
                    key="contentKey1"
                    // style={{ border: ((this.state.charLeft !== "" && this.state.charLeft <= 500 && this.state.charLeft > 300) && "3px solid green" || (this.state.charLeft !== "" && this.state.charLeft < 300 && this.state.charLeft > 100) && "3px solid yellow" || (this.state.charLeft !== "" && this.state.charLeft < 100) && '3px solid red') }}
                    value={editcardVal}
                    onChange={handleCard}
                    inputProps={{
                                    maxLength: 3000,
                                    style: {
                                        color: "black",
                                        paddingLeft: "5px",
                                        fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                                    },
                                }}
                    rowsMax={20}
                    />
            <TextField
                    className="customtext displayField"
                     multiline
                    rows={1}
                    placeholder={language_labels.display_link}
                   
                    // style={{ border: ((this.state.charLeft !== "" && this.state.charLeft <= 500 && this.state.charLeft > 300) && "3px solid green" || (this.state.charLeft !== "" && this.state.charLeft < 300 && this.state.charLeft > 100) && "3px solid yellow" || (this.state.charLeft !== "" && this.state.charLeft < 100) && '3px solid red') }}
                    value={editdisplayval}
                    onChange={handleDisplayLink}
                    inputProps={{
                                    maxLength: 200,
                                    style: {
                                        color: "black",
                                        paddingLeft: "5px",
                                        fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                                    },
                                }}
                    rowsMax={2}
                    />
            <TextField
                    className="customtext"
                     multiline
                    rows={1}
                    placeholder={language_labels.link}
                   
                    // style={{ border: ((this.state.charLeft !== "" && this.state.charLeft <= 500 && this.state.charLeft > 300) && "3px solid green" || (this.state.charLeft !== "" && this.state.charLeft < 300 && this.state.charLeft > 100) && "3px solid yellow" || (this.state.charLeft !== "" && this.state.charLeft < 100) && '3px solid red') }}
                    value={editlinkval}
                    onChange={handleLink}
                    inputProps={{
                                    maxLength: 200,
                                    style: {
                                        color: "black",
                                        paddingLeft: "5px",
                                        fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                                    },
                                }}
                    rowsMax={2}
                    />
                    <TextField
                    className="customtext displayField"
                     multiline
                    rows={1}
                    placeholder={language_labels.display_link1}
                   
                    // style={{ border: ((this.state.charLeft !== "" && this.state.charLeft <= 500 && this.state.charLeft > 300) && "3px solid green" || (this.state.charLeft !== "" && this.state.charLeft < 300 && this.state.charLeft > 100) && "3px solid yellow" || (this.state.charLeft !== "" && this.state.charLeft < 100) && '3px solid red') }}
                    value={editdisplayval1}
                    onChange={handleDisplayLink1}
                    inputProps={{
                                    maxLength: 200,
                                    style: {
                                        color: "black",
                                        paddingLeft: "5px",
                                        fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                                    },
                                }}
                                      
                    rowsMax={2}
                    />
                    <TextField
                    className="customtext"
                     multiline
                    rows={1}
                    placeholder={language_labels.link1}
                   
                    // style={{ border: ((this.state.charLeft !== "" && this.state.charLeft <= 500 && this.state.charLeft > 300) && "3px solid green" || (this.state.charLeft !== "" && this.state.charLeft < 300 && this.state.charLeft > 100) && "3px solid yellow" || (this.state.charLeft !== "" && this.state.charLeft < 100) && '3px solid red') }}
                    value={editlinkval1}
                    onChange={handleLink1}
                    inputProps={{
                                    maxLength: 200,
                                    style: {
                                        color: "black",
                                        paddingLeft: "5px",
                                        fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                                    },
                                }}
                    rowsMax={2}
                    />
                    <TextField
                    className="customtext displayField"
                     multiline
                    rows={1}
                    placeholder={language_labels.display_link2}
                   
                    // style={{ border: ((this.state.charLeft !== "" && this.state.charLeft <= 500 && this.state.charLeft > 300) && "3px solid green" || (this.state.charLeft !== "" && this.state.charLeft < 300 && this.state.charLeft > 100) && "3px solid yellow" || (this.state.charLeft !== "" && this.state.charLeft < 100) && '3px solid red') }}
                    value={editdisplayval2}
                    onChange={handleDisplayLink2}
                    inputProps={{
                                    maxLength: 200,
                                    style: {
                                        color: "black",
                                        paddingLeft: "5px",
                                        fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                                    },
                                }}
                    rowsMax={2}
                    />
                    <TextField
                    className="customtext"
                     multiline
                    rows={1}
                    placeholder={language_labels.link1}
                   
                    // style={{ border: ((this.state.charLeft !== "" && this.state.charLeft <= 500 && this.state.charLeft > 300) && "3px solid green" || (this.state.charLeft !== "" && this.state.charLeft < 300 && this.state.charLeft > 100) && "3px solid yellow" || (this.state.charLeft !== "" && this.state.charLeft < 100) && '3px solid red') }}
                    value={editlinkval2}
                    onChange={handleLink2}
                    inputProps={{
                                    maxLength: 200,
                                    style: {
                                        color: "black",
                                        paddingLeft: "5px",
                                        fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                                    },
                                }}
                    rowsMax={2}
                    />
                    
           <Button className="customAdd" variant="outlined" onClick={(event) => saveCard(props.id)}>{language_labels.save}</Button>
        <Button className="customAdd" variant="outlined" onClick={(event) => cancelEdit(props.id)}>{language_labels.cancel}</Button>
        </>
        </div >}
        {editInd !== props.id  && <div>
        <>
        <img src={ props.s3link } 
        alt={ props.alt || language_labels.img_add } />
          <h2>{ props.title }</h2>
          <p>{ props.content }</p> 
          <a  style={props.link == "" ?{display :'none'}:{display:'block', marginTop:'5px', padding:'5px'}} href={props.link} target="_blank">{props.displayLink}</a>
          <a  style={props.link1 == "" ?{display :'none'}:{display:'block', marginTop:'5px', padding:'5px'}} href={props.link1} target="_blank">{props.displayLink1}</a>
          <a  style={props.link2 == "" ?{display :'none'}:{display:'block', marginTop:'5px', padding:'5px'}} href={props.link2} target="_blank">{props.displayLink2}</a>

        </>
        </div >}
        </div >}


    

      </div>
                            <Dialog
                                                open={confirm_dialog}
                                                keepMounted
                                                onClose={handleDialogOk}
                                                disableBackdropClick={true}
                                                disableEscapeKeyDown={true}
                                            >
                                                <DialogTitle >
                                                {language_labels["Confirmation"]}
                                                </DialogTitle>
                                               
                                                <DialogContent>
                                                    <DialogContentText >
                                                   {language_labels.popup_confirm_msg}

                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleDialogOk} className="msg-btn" color="primary">
                                                        {language_labels["ok"]}
                                                    </Button>
                                                    <Button onClick={handleCancel} className="msg-btn" color="primary">
                                                        {language_labels["cancel"]}
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                            <Dialog
                                                open={success_dialog}
                                                keepMounted
                                                onClose={handleSuccessDialog}
                                                disableBackdropClick={true}
                                                disableEscapeKeyDown={true}
                                            >
                                                <DialogTitle >
                                              {language_labels.save_success}
                                                </DialogTitle>
                                               
                                                <DialogContent>
                                                    <DialogContentText >
                                                   {language_labels.popup_display}

                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleSuccessDialog} className="msg-btn" color="primary">
                                                        {language_labels["ok"]}
                                                    </Button>
                                                   
                                                </DialogActions>
                                            </Dialog>
    </div>

    
     )
    
        }; 
  
export default LandingCard;