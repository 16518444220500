
import Axios from 'axios';

export default class AjaxServices {
  // Initializing important variables

  viewSubmissionLog(obj){
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    //headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    return fetch("/invoiceSubmission/getAllLogs", {
      headers: headers,
      method: "POST",
      body: JSON.stringify(obj)
    }).then((res) => {
      console.log(res);

      // Setting the token in localStorage
      return Promise.resolve(res);
    });

  }
  viewQuerySubmissionLog(obj){
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    //headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    return fetch("/querySubmission/getAllLogs", {
      headers: headers,
      method: "POST",
      body: JSON.stringify(obj)
    }).then((res) => {
      console.log(res);

      // Setting the token in localStorage
      return Promise.resolve(res);
    });

  }
  getAllInvoiceToDownload(query_obj) {
    // Get a token from api server using the fetch api
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    //headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    return fetch("/invoiceDetails/getAllInvoiceToDownload", {
      headers: headers,
      method: "POST",
      body: JSON.stringify({
        company_code: query_obj.company_code,
        role: query_obj.role,
        vendor_number: query_obj.vendor_number
      })
    }).then((res) => {
      console.log(res);

      // Setting the token in localStorage
      return Promise.resolve(res);
    });

  }

  //using id
  getinvoicedata(query_obj) {

    // let codes = query_obj.company_code.map(String)


    // Get a token from api server using the fetch api
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    //headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    return fetch("/invoiceDetails/invoicedetailsAll", {
      headers: headers,
      method: "POST",
      body: JSON.stringify(query_obj)
    }).then((res) => {
      console.log(res);

      // Setting the token in localStorage
      return Promise.resolve(res);
    });

  }















  getRegions() {

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    //headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    return fetch("/region/getRegions", {
      headers: headers,
      method: "GET",
    })
      .then((result) => {
        console.log(result)
        // new_result = result.json
        return Promise.resolve(result);
        // return result
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }
  
  invoiceEmail(vendor_number, vendor_name, pmi_entity_name, company_code, cip_value, files_from_invoice,scf_value, country,invoice_mailbox) {
    return new Promise(function (resolve, reject) {
      var params = new FormData();
      files_from_invoice.forEach(element => {
        params.append("files_from_invoice", element)
      })
      params.append("vendor_number", vendor_number)
      params.append("vendor_name", vendor_name)
      params.append("pmi_entity_name", pmi_entity_name)
      params.append("company_code", company_code)
      params.append("cip_value", cip_value)
      params.append("scf_value",scf_value)
      params.append("country",country)
      params.append("mailbox",invoice_mailbox)
      console.log(params)
      Axios.post("/upload/invoiceEmail", params).then((result) => {
        // console.log(result)
        console.log(result.Error)
        resolve(result);
      }).catch((err) => {
        // alert(err.message);
        console.log(err)
        reject(err)
      });
    })
  }
  
  getSelectedVendorDetails(obj, email){
   
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    //headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    return fetch("/loginServices/getVendorDetails", {
      headers: headers,
      method: "POST",
      body: JSON.stringify({
        vendor: obj.vendorId,
        erp:obj.erpSystem,
        email: email
      }),
    })
      .then((result) => {

        return Promise.resolve(result);

      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }

  getPdfDeclaration(cocds, region, market) {
    console.log(cocds, region, market)

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    //headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    return fetch("/pdfDeclaration/pdfNeeded", {
      headers: headers,
      method: "POST",
      body: JSON.stringify({
        cocds: cocds,
        region: region,
        market: market
      }),
    })
      .then((result) => {

        return Promise.resolve(result);

      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }

  getPaymentSchedule(language) {

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    //headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    return fetch("/getSchedule", {
      headers: headers,
      method: "POST",
      body:JSON.stringify({
       language:language
      })
  
    })
      .then((result) => {

        return Promise.resolve(result);

      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }

  uploadPaymentSchedule(files_from_filepond, upload_type, language) {

    return new Promise(function (resolve, reject) {
      var params = new FormData();
      if (files_from_filepond !== undefined && files_from_filepond !== null) {
        files_from_filepond.forEach(element => {
          params.append("files_from_api", element)
        })
      }
      params.append("upload_type", upload_type)
      params.append("language",language)
      Axios.post("/XLSUpload/paymentSchedule", params).then((result) => {
        console.log(result)
        resolve(result);
      }).catch((err) => {
        console.log(err)
        reject(err)
      });
    })
  }



  submitDeclaration(details_obj) {
    console.log("inside submit");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    //headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    return fetch("/pdfDeclaration/pdfCompleted", {
      headers: headers,
      method: "POST",
      body: JSON.stringify({
        vendor_number: details_obj.vendor_number,
        company_code: details_obj.company_code,
        invoice_mailing: details_obj.invoice_mailing,
        vat_number: details_obj.vat_number,
        full_name: details_obj.full_name,
        supplier_name: details_obj.supplier_name,
        supplier_address: details_obj.supplier_address,
        mailbox_id: details_obj.mailbox_id
      }),
    })
      .then((result) => {
        console.log(result);
        return Promise.resolve(result);

      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }

  checkDeclaration(vendor_number, vendor_name, mailbox) {
    console.log(vendor_number, vendor_name);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    //headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    return fetch("/pdfDeclaration/pdfCheck", {
      headers: headers,
      method: "POST",
      body: JSON.stringify({
        vendor_number: vendor_number,
        vendor_name: vendor_name,
        mailbox: mailbox
      })
    })
      .then((result) => {

        return Promise.resolve(result);

      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }

  getVendorMasterData() {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    //headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    return fetch('/adminServices/getVendorMasterData', {
      headers: headers,
      method: "GET"
    })
      .then((result) => {

        return Promise.resolve(result);

      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }

  getAuditTrailData() {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    //headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    return fetch('/adminServices/getSentInviteData', {
      headers: headers,
      method: "GET"
    })
      .then((result) => {

        return Promise.resolve(result);

      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }

  getAllCompanyCodes() {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    //headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    return fetch('/adminServices/getCompanyCodes', {
      headers: headers,
      method: "GET"
    })
      .then((result) => {

        return Promise.resolve(result);

      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }

  filterCompanyCodes(company_code) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    //headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    return fetch('/adminServices/filterCompanyCode', {
      headers: headers,
      method: "POST",
      body: JSON.stringify({
        company_code: company_code
      })
    })
      .then((result) => {

        return Promise.resolve(result);

      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }


  getDownloadData(main_query){
  
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  
    return fetch("/invoiceDetails/getDownloadedData", {
      headers: headers,
      method: "POST",
      body: JSON.stringify(main_query)
    }).then((res) => {
      console.log(res);

      return Promise.resolve(res);
    });
  }
  
  sendInvitation( subject, text, vendors_list,admin) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    //headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    return fetch("/sendemail/sendInvitation", {
      headers: headers,
      method: "POST",
      body: JSON.stringify({
       
        subject: subject,
        text: text,
        
        vendors_list: vendors_list,
        admin:admin
      })
    })
      .then((result) => {

        return Promise.resolve(result);

      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }

  downloadPaymentAdvice(query_string) {
    return new Promise((resolve, reject) => {
      Axios.post("/InvoicelistAPI/payAdviceWHTCERT",  {data: {
        filename: query_string
      }},
        {
          // responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        })
        .then((response) => {
         resolve(response);
        })
        .catch((err) => {
          alert(err.message);
          reject (err);
        }
        );
    }
    )

    // const headers = {
    //   Accept: "application/json",
    //   "Content-Type": "application/json",

    // };
    // return fetch('/InvoicelistAPI/payAdviceWHTCERT', {

    //   headers: headers,
    //   method: "POST",
    //   body: JSON.stringify({
    //     filename : query_string
    //   }),
    //   responseType: "blob"
    // })
    //   .then((result) => {
    //     console.log(result)
    //     return Promise.resolve(result);
    //   })
    //   .catch((err) => {
    //     // console.log(err);
    //     alert(err.message);
    //     return err;
    //   });
  }
  getLoginDetails() {

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    //headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    return fetch("/user-data", {
      headers: headers,
      method: "GET"
    }).then(res => res.json())
      .then((result) => {
        console.log("resullt===", result)

        return Promise.resolve(result);


      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }

  getUserDetails(email) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/loginServices/getUserDetails', {

      headers: headers,
      method: "POST",
      body: JSON.stringify({
      })
    })
      .then((result) => {
        console.log(result)
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }

  getSupportDetails(email) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/loginServices/getUserDetails', {

      headers: headers,
      method: "POST",
      body: JSON.stringify({
        email
      })
    })
      .then((result) => {
        console.log(result)
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }


  getPMIEntities(company_code) {


    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    //headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    return fetch('/pdfDeclaration/getPMIEntities', {
      headers: headers,
      method: "POST",
      body: JSON.stringify({
        company_code: company_code
      })
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }


  getEntityData() {


    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/region/getRegionsForQuery', {
      headers: headers,
      method: "GET"
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }


  queryUploadApi(randomString, textAreaValue, textFieldValue, selectedCategory, files_from_user, supplier_name, supplier_number, pmi_entity, cocds, country, snow_mail_box,global_mailbox,region) { //supplierIndustryKey
    return new Promise(function (resolve, reject) {
      console.log(randomString, files_from_user);
      var params = new FormData();
      files_from_user.forEach(element => {
        params.append("files_from_user", element)
      })
      params.append("uniqueIdFiles", randomString)
      params.append("textAreaValue", textAreaValue)
      params.append("textFieldValue", textFieldValue)
      params.append("supplier_name", supplier_name)
      params.append("supplier_number", supplier_number)
      params.append("pmi_entity", pmi_entity.join(','))
      params.append("cocds", cocds.join(','))
      params.append("selectedCategory", selectedCategory)
      params.append("country", country)
      params.append("snow_mail_box", snow_mail_box)
      params.append("global_mailbox", global_mailbox)
      params.append("region", region)
      //params.append("supplierIndustryKey",supplierIndustryKey)
      Axios.post("/upload/queryPage", params).then((result) => {
        console.log(result)
        resolve(result);
      }).catch((err) => {
        // alert(err.message);
        reject(err)
      });
    })
  }

  invoiceUploadApi(vendor_number, vendor_name, pmi_entity_name, company_code, cip_value, files_from_invoice) {
    return new Promise(function (resolve, reject) {
      var params = new FormData();
      files_from_invoice.forEach(element => {
        params.append("files_from_invoice", element)
      })
      params.append("vendor_number", vendor_number)
      params.append("vendor_name", vendor_name)
      params.append("pmi_entity_name", pmi_entity_name)
      params.append("company_code", company_code)
      params.append("cip_value", cip_value)
      console.log(params)
      Axios.post("/upload/invoicePage", params).then((result) => {
        // console.log(result)
        console.log(result.Error)
        resolve(result);
      }).catch((err) => {
        // alert(err.message);
        console.log(err)
        reject(err)
      });
    })
  }
  SaveBannerImgDB (file, type){
    return new Promise(function (resolve, reject) {
      var params = new FormData();
      params.append("files_from_user", file)
      params.append("type", type)
     
      Axios.post("/upload/bannerImg", params).then((result) => {
        console.log(result)
        resolve(result);
      }).catch((err) => {
       
        reject(err)
      });
    })
  }
   downloadBannerImg (){
   
    return new Promise(function (resolve, reject) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch("/download/bannerImg", {
      headers: headers,
      method: "POST",
      body: JSON.stringify({
       //file:file
      })
    }).then(res => res.json())
    .then((result) => {
        console.log("resullt===", result)
        resolve(result);
      })
      .catch((err) => {
         console.log(err);
        alert(err.message);
        return err;
      });
    });
    
  }
 
  getCardFromDB (){
    return new Promise(function (resolve, reject) {
     
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      return fetch("/adminServices/cardData", {
        headers: headers,
        method: "GET"
      }).then(res => res.json())
      .then((result) => {
          console.log("resullt===", result)
          resolve(result);
        })
        .catch((err) => {
          // console.log(err);
         // alert(err.message);
          return err;
        });
      });
  }
  saveCardFromDB (data){
    return new Promise(function (resolve, reject) {
     
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      return fetch("/adminServices/savecardData", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
         card_details:data
        })
      }).then(res => res.json())
      .then((result) => {
          console.log("resullt===", result)
          resolve(result);
        })
        .catch((err) => {
          // console.log(err);
          alert(err.message);
          return err;
        });
      });
  }
  deleteImgS3 (data){
    return new Promise(function (resolve, reject) {
     
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      return fetch("/adminServices/deleteImg", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
         file:data
        })
      }).then(res => res.json())
      .then((result) => {
          console.log("resullt===", result)
          resolve(result);
        })
        .catch((err) => {
          // console.log(err);
          alert(err.message);
          return err;
        });
      });
  }
  uploadCoupaData() {

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/XLSUpload/coupaUpload', {
      headers: headers,
      method: "POST"
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }

  uploadSapData() {

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/XLSUpload/sapUpload', {
      headers: headers,
      method: "POST"
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });

  }

  uploadMasterDataFilepond(files_from_filepond, upload_type) {

    return new Promise(function (resolve, reject) {
      var params = new FormData();
      if (files_from_filepond !== undefined && files_from_filepond !== null) {
        files_from_filepond.forEach(element => {
          params.append("files_from_api", element)
        })
      }
      params.append("upload_type", upload_type)
      Axios.post("/XLSUpload/pdfDataUpload", params).then((result) => {
        console.log(result)
        resolve(result);
      }).catch((err) => {
        console.log(err)
        reject(err)
      });
    })
  }




  dateFilter(start, end, date_type, role, company_code, vendor_number, page, rowsPerPage, sort_name, sort_direction) {

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/invoiceDetails/dateFilter', {
      headers: headers,
      method: "POST",
      body: JSON.stringify({
        start: start,
        end: end,
        date_type: date_type,
        company_code: company_code,
        role: role,
        vendor_number: vendor_number,
        page: page,
        rowsPerPage: rowsPerPage,
        sort_name: sort_name,
        sort_direction: sort_direction
      })
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }


  getRecordsCount(query_obj) {

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/invoiceDetails/recordCount', {
      headers: headers,
      method: "POST",
      body: JSON.stringify({
        company_code: query_obj.company_code,
        role: query_obj.role,
        vendor_number: query_obj.vendor_number,
      })
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }


  logout() {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/logout', {
      headers: headers,
      method: "GET"
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }

  getFiltersDropDown(vendor_number, company_code) {
    console.log("here")
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/invoiceDetails/filterDropDown', {
      headers: headers,
      method: "POST",
      body: JSON.stringify({

        vendor_number: vendor_number,
        company_code: company_code
      })
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }

  //// query report///
  getQueryReportsFiltersDropDown(vendor_email, vendor_number) {
    console.log("here")
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/queryReports/filterDropDown', {
      headers: headers,
      method: "POST",
      body: JSON.stringify({

        supplier_email: vendor_email,
        supplier_number:vendor_number
      })
    })
      .then((result) => {
        console.log("filter val--", result)
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }
  getReportsFiltersDropDown(vendor_email, vendor_number) {
    console.log("here")
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/invoiceReports/filterDropDown', {
      headers: headers,
      method: "POST",
      body: JSON.stringify({

        vendor_email: vendor_email,
        vendor_number:vendor_number
      })
    })
      .then((result) => {
        console.log("filter val--", result)
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }
  applyFilters(filters_list, query_obj) {
    console.log(query_obj)
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/invoiceDetails/applyFilters', {
      headers: headers,
      method: "POST",
      body: JSON.stringify({
        filters_list: filters_list,
        vendor_details: query_obj,
        sort_name: query_obj.name,
        sort_direction: query_obj.direction
      })
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }

  textSearch(query_obj) {

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/invoiceDetails/textSearch', {
      headers: headers,
      method: "POST",
      body: JSON.stringify({
        search_text: query_obj.search_text,
        rowsPerPage: query_obj.rowsPerPage,
        company_code: query_obj.company_code,
        vendor_number: query_obj.vendor_number,
        page: query_obj.page,
        role: query_obj.role,
        sort_name: query_obj.name,
        sort_direction: query_obj.direction,
        start_date: query_obj.start_date,
        end_date: query_obj.end_date,
        doc_type: query_obj.doc_type
      })
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }

  saveUserLogs(userLog) {
    console.log(userLog)
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/logs/userLogsSave', {
      headers: headers,
      method: "POST",
      async: false,
      body: JSON.stringify({
        userLog: [userLog]
      })
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((err) => {
        return err;
      });
  }


  savePdfLogs(pdf_declaration_logs) {
    console.log(pdf_declaration_logs)
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/logs/pdf_declaration_logsSave', {
      headers: headers,
      method: "POST",
      body: JSON.stringify({
        pdf_declaration_logs: [pdf_declaration_logs]
      })
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((err) => {
        return err;
      });
  }

  getCipScanIds(user_array) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/CipDetails/getCipIds', {
      headers: headers,
      method: "POST",
      body: JSON.stringify({
        user_array: user_array
      })
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }
getAppUrl() {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    //headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    return fetch("/adminServices/get_app_url", {
      headers: headers,
      method: "GET"
    }).then(res => res.json())
      .then((result) => {
        console.log("resullt===", result)
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }
  handleSort(sort_obj) {

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/invoiceDetails/sort', {
      headers: headers,
      method: "POST",
      body: JSON.stringify({
        sort_direction: sort_obj.direction,
        sort_name: sort_obj.name,
        company_code: sort_obj.company_code,
        vendor_number: sort_obj.vendor_number,
        rowsPerPage: sort_obj.rowsPerPage,
        page: sort_obj.page,
      })
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }
  Invite_Others(user_email, supplier_name, vat_number, invitee_email_1,invitee_email_2,invitee_email_3,comments,pmi_entity,vmd_mailbox) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/sendemail/InviteOthers', {

      headers: headers,
      method: "POST",
      body: JSON.stringify({
        user_email: user_email,
        supplier_name:supplier_name,
        vat_number:vat_number,
        invitee_email_1: invitee_email_1,
        invitee_email_2: invitee_email_2,
        invitee_email_3:invitee_email_3,
        comments:comments,
        pmi_entity:pmi_entity,
        vmd_mailbox:vmd_mailbox
      })
    })
      .then((result) => {//
        console.log(result)
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }

  verifyUser(email) {
    console.log(email)
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/loginServices/verify_user', {

      headers: headers,
      method: "POST",
      body: JSON.stringify({
        email:email
      })
    })
      .then((result) => {
        console.log(result)
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }
  

  submitHelp(email,issue,comments) {
    
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch('/loginServices/submit_help', {

      headers: headers,
      method: "POST",
      body: JSON.stringify({
        email: email,
        issue: issue,
        comments:comments
      })
    })
      .then((result) => {
        console.log(result)
        return Promise.resolve(result);
      })
      .catch((err) => {
        // console.log(err);
        alert(err.message);
        return err;
      });
  }
}
