import React, { Component } from "react";
import error from "../error.jpg";
import "../App.css";
import NavBar from "./NavBar";
import { NavLink, Link, withRouter } from "react-router-dom";
import {
    Button
} from "@material-ui/core";
// import '../styles/NotFound.css';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import pmiLogo from "./../assests/pmi-logo.svg";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as AllActions from '../actions'
import PropTypes from 'prop-types'
import AjaxService from "../services/AjaxServices";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import NotFound from './NotFound';
import { labels } from './../resources';
import '../pay.scss';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';


class PaymentSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {

            expanded: false,
            accordion_index: "",
            country_selected: [],
            region_selected: "",
            data_arr: []
        };
    }

    componentDidMount() {
        const element = this.element;
        const hasOverflow = element.scrollHeight > element.offsetHeight;

        console.log(element, hasOverflow);

        console.log(this.props.language)
        this.Ajax = new AjaxService();
        this.Ajax.getPaymentSchedule(this.props.user.language)
            .then(res => res.json())
            .then(result => {
                console.log(result)
                var regions = Object.keys(result)
                regions = regions.sort()
                console.log(regions)
                this.setState({
                    regions: regions,
                    total_data: result
                })
            })

    }

    componentDidUpdate(prevProps) {
        if (prevProps.user.language !== this.props.user.language) {
            console.log("here here update")
            this.Ajax.getPaymentSchedule(this.props.user.language)
                .then(res => res.json())
                .then(result => {
                    console.log(result)
                    var regions = Object.keys(result)
                    regions = regions.sort()
                    console.log(regions)
                    this.setState({
                        regions: regions,
                        total_data: result,
                        accordion_index: "",
                        expanded: false,
                        country_selected: [],
                        data_arr: []
                    })
                })

        }
    }

    handleLogout = () => {
        this.Ajax = new AjaxService();
        this.Ajax.logout().then(result => {
            //console.log("logged out")
            sessionStorage.clear();
            localStorage.clear()
            this.props.actions.return_default()
            // this.props.history.length = 0;

            this.props.history.replace("/");
        })
    }

    handleExpand = (index, ele) => (event, isExpanded) => {
        console.log(isExpanded, index, ele)
        var countries = Object.keys(this.state.total_data[ele])
        countries = countries.sort()
        if (isExpanded) {
            this.setState({ accordion_index: index, expanded: true, countries_list: countries, region_selected: ele, country_selected: [], data_arr: [] });
        } else {
            this.setState({ accordion_index: "", expanded: false, country_selected: [], data_arr: [] });
        }
    };

    handleChangeCountry = (event) => {
        // console.log(event.target.value)
        // var main_arr = this.state.total_data[this.state.region_selected][event.target.value]
        // var data_arr = []

        // main_arr.forEach(function (arrayItem) { 
        //     data_arr.push([
        //         arrayItem["entity"],
        //         arrayItem["type"],
        //         arrayItem["schedule"],
        //         arrayItem["remittance_day"],
        //         arrayItem["notes"]
        //     ])
        // })
        // this.setState({
        //     country_selected: event.target.value,
        //     data_arr:data_arr
        // })

        console.log(event.target.value)

        //  var main_arr = this.state.total_data[this.state.region_selected][event.target.value]
        var data_arr = []

        Object.keys(this.state.total_data[this.state.region_selected]).forEach(el => {
            event.target.value.forEach(name => {
                if (name === el) {
                    console.log(name)
                    console.log(el)
                    console.log(this.state.total_data[this.state.region_selected][el])
                    this.state.total_data[this.state.region_selected][el].forEach(item => {
                        data_arr.push([
                            name,
                            item["entity"],
                            item["type"],
                            item["schedule"],
                            item["remittance_day"],
                            item["notes"]
                        ])
                    })

                }
            })
        })

        console.log(data_arr)
        this.setState({ country_selected: event.target.value, data_arr: data_arr })
    }

    getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTableFilter: {
                    padding: "9px 20px 33px 20px",
                    fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                    title: {
                        fontWeight: 400,
                        marginLeft: 0
                    },
                    resetLink: {
                        fontSize: 14,
                        fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                        fontWeight: 400
                    }
                },
                MuiFormControl: {
                    root: {
                        border: "1px solid #ccc !important",
                        width: "30% !important"
                    }
                },
                MuiFormLabel: {
                    root: {
                        fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                        fontWeight: 400
                    }
                },
                MuiButton: {
                    textPrimary: {
                        color: "#004f90"
                    }
                },
                MUIDataTableBodyCell: {
                    root: {
                        textAlign: "left"
                    }
                },
                MuiGridListTile: {
                    root: {
                        paddingTop: "0 !important"
                    },
                    tile: {
                        marginTop: "0 !important"
                    }
                },
                MUIDataTable: {
                    responsiveScroll: {
                        overflowY: "scroll"
                    }
                },

                MUIDataTableHeadCell: {
                    fixedHeader: {
                        backgroundColor: "#f6f6f6",
                        color: "#0160a5"
                    },
                    root: {
                        padding: "10px",
                        verticalAlign: "top"
                    }
                },
                MuiToolbar: {
                    root: {
                        background: "#f6f6f6"
                    },
                    regular: {
                        paddingLeft: "0"
                    },
                    gutters: {
                        paddingLeft: "0"
                    }
                },
                MuiTableCell: {
                    root: {
                        padding: "15px",
                        verticalAlign: "top",
                        fontFamily:
                            "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                    },
                    body: {
                        fontSize: "14px"
                    },
                    head: {
                        fontSize: "15px",
                        fontWeight: "600"
                    }
                },
                MuiSelect: {
                    icon: {
                        top: "0 !important"
                    }
                },
                MUIDataTableSearch: {
                    main: {
                        position: "absolute",
                        right: "12rem",
                        bottom: "10px",
                        width: "48%"
                    },
                    searchIcon: {
                        display: "none"
                    }
                },
                MuiInputBase: {
                    root: {
                        fontFamily:
                            "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                    },
                    input: {
                        paddingTop: "5px",
                        border: "1px solid #ccc",
                        paddingLeft: "5px",
                        paddingRight: "20px",
                        borderBottom: "1px solid #ccc",
                        fontSize: 13,
                        fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                    }
                },
                MUIDataTableToolbar: {
                    filterPaper: {
                        maxWidth: "46% !important",
                        left: "650px !important"
                    },
                    root: {
                        display: 'none'
                    }


                },
                MUIDataTablePagination: {
                    root: {
                        fontFamily:
                            "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                        "&:last-child": {
                            padding: "0px"
                        }
                    }
                },
                MuiTableFooter: {

                },
                MuiMenuItem: {
                    root: {
                        fontFamily:
                            "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                        fontSize: 13
                    }
                },
                MuiIconButton: {
                    root: {
                        padding: "5px",
                        "&:focus": {
                            outline: "none !important"
                        },
                        "&:hover": {
                            // color: "#004f90 !important",
                            backgroundColor: "transparent !important"
                        }
                    }
                },
                MuiPaper: {
                    elevation4: {
                        boxShadow: "none !important",
                        borderRadius: "0px",
                        border: "1px solid #ccc !important",
                        width: "100%"
                    }

                },
                MuiSvgIcon: {
                    root: {
                        width: "1em",
                        height: "1em",
                        fontFamily:
                            "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                        // paddingLeft: "15px"
                    }
                },
                MuiTablePagination: {
                    select: {
                        border: "none"
                    },
                    selectIcon: {

                    }
                },
                MuiTable: {
                    root: {
                        fontFamily:
                            "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                    }
                },

                MuiTypography: {
                    caption: {
                        fontFamily:
                            "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                        fontSize: "1rem"
                    },
                    h6: {
                        paddingLeft: 8,
                        fontSize: "1.5rem",
                        fontWeight: "normal",
                        fontFamily:
                            "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
                    },

                },
                MUIDataTableFilterList: {
                    chip: {
                        margin: "8px 8px 8px 0px"
                    }
                },
                MuiChip: {
                    root: {
                        fontSize: 13,
                        fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
                        backgroundColor: "#f4f4f4",
                        border: "1px solid #004f90",
                        margin: "0 5px"
                    },
                    deleteIcon: {
                        fill: "#004f90"
                    }
                },
                MuiCheckbox: {
                    MuiIconButton: {
                        root: {
                            color: "#004f90"
                        }
                    }
                },
                MuiTableRow: {
                    root: {
                        '&$selected': {
                            backgroundColor: '#0067bc2e !important'
                        }
                    }
                },
                MUIDataTableSelectCell: {
                    checked: { color: "#004f90 !important" }
                }
            }
        });

    render() {
        const { user } = this.props
        const language_labels = labels[this.props.user.language]["payment_schedule"]
        // console.log(user.role)
        // let data = [
        //     ["ALBANIA", "", "Local Payment", "Once a week, occurs on Wednesday", "Wednesday", "The payment date might differ due to Public Holiday"],
        //     ["ALBANIA", "", "Foreign Payment", "Once a week, occurs on Wednesday", "Wednesday", "The payment date might differ due to Public Holiday"],
        //     ["GERMANY", "PHILIP MORRIS GMBH", "Local Payment", "Twice a week, occurs on Tuesday and Thursday", "Tuesday and Thursday", "The payment date might differ due to Public Holiday"],
        //     ["HONG KONG", "PHILIP MORRIS TRAVEL RETAIL", "Foreign Payment", "Once a week, occurs on Monday", "Monday", "The payment date might differ due to Public Holiday"]
        // ]
        // const regions = [
        //     {
        //         region: "Europe, Middle East & Africa"
        //     },
        //     {
        //         region: "Asia Pacific"
        //     },
        //     {
        //         region: "Latin America & Canada"
        //     }

        // ]
        const columns = [
            {
                name: language_labels.country,
                options: {
                    sort: false
                }
            },
            {
                name: language_labels.entity,
                options: {
                    sort: false
                }
            },
            {
                name: language_labels.type,
                options: {
                    sort: false,

                }
            },
            {
                name: language_labels.schedule,
                options: {
                    sort: false,

                }
            },
            {
                name: language_labels.remittance,
                options: {
                    sort: false,

                }
            },
            {
                name: language_labels.notes,
                options: {
                    sort: false,

                }
            }

        ];
        let options = {
            print: false,
            search: false,
            download: false,
            filter: false,
            viewColumns: false,
            selectableRows: false,
            tableBodyHeight: 'auto',
            downloadOptions: {
                filename: "AuditTrailReport.csv",
                separator: ','
            },


        };
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };
        return (
            <div ref={el => { this.element = el }}>
                {
                    user.role && (user.role === "vendor" || user.role === "support") && <div className="container-fluid no-padding pay-div">
                        <NavBar appClass="pay" />
                        <h3>{language_labels.title}</h3>
                        {this.state.regions && this.state.regions.map((element, index) => {
                            return (
                                <Accordion
                                    key={index}
                                    expanded={this.state.accordion_index === index}
                                    onChange={this.handleExpand(index, element)}
                                >
                                    <AccordionSummary
                                        expandIcon={
                                            this.state.expanded &&
                                                this.state.accordion_index === index ? (
                                                <RemoveIcon className="plus-icon" />
                                            ) : (
                                                <AddIcon className="plus-icon" />
                                            )
                                        }
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        // className="faq-summary"
                                        className={
                                            this.state.expanded && this.state.accordion_index === index
                                                ? "faq-summary-collapsed"
                                                : "faq-summary"
                                        }
                                    >
                                        <Typography className="pay-question">
                                            {element}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="acc-content">
                                        <Grid container>
                                            <Grid item xs={2}>
                                                <h6 class="country-head">{language_labels.select_country}</h6>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl className="multi-select-formcontrol-pay" >
                                                    {/* error={this.state.entity_error ? true : false} */}
                                                    <Select
                                                        className="multi-select-invite"
                                                        multiple
                                                        required
                                                        value={this.state.country_selected}
                                                        onChange={(event) => this.handleChangeCountry(event)}
                                                        placeholder={"Select a Country"}
                                                        input={<Input id="select-multiple-chip" />}
                                                        renderValue={(selected) => {
                                                            // if (selected.length === 0) {
                                                            //     return (<em>{"Select a Country"}</em>);
                                                            // }
                                                            return (<div >
                                                                {selected.map((value) => (
                                                                    <Chip key={value} label={value} />
                                                                ))}
                                                            </div>)
                                                        }
                                                        }
                                                        MenuProps={MenuProps}
                                                    >

                                                        {this.state.countries_list && this.state.countries_list.map((name, i) => (


                                                            <MenuItem key={i} value={name}>

                                                                <Checkbox checked={this.state.country_selected.indexOf(name) > -1} />
                                                                <ListItemText primary={name} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {/* <FormHelperText>{this.state.entity_error_text}</FormHelperText> */}
                                                </FormControl>

                                            </Grid>
                                            <Grid item xs={12} style={{ marginTop: "1%" }}>
                                                {this.state.data_arr.length !== 0 && <MuiThemeProvider theme={this.getMuiTheme()}> <MUIDataTable
                                                    data={this.state.data_arr}
                                                    columns={columns}
                                                    options={options}
                                                /></MuiThemeProvider>}
                                            </Grid>
                                        </Grid>
                                        {/* <FormControl className="countrySelect">

                                            <Select
                                                labelId="demo-dialog-select-label"
                                                id="demo-dialog-select"
                                                placeholder="Select Country"
                                                value={this.state.country_selected === "" ? "Select a Country" : this.state.country_selected}
                                                onChange={(event) => this.handleChangeCountry(event)}
                                            >
                                              
                                                {this.state.countries_list && this.state.countries_list.map((opt, k) => {
                                                    return (
                                                        <MenuItem key={k} value={opt}>{opt}</MenuItem>
                                                    )
                                                })}
                                             
                                            </Select>
                                        </FormControl> */}



                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}

                    </div>
                }
            </div>


        );
    }
}

PaymentSchedule.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    user: state.user
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(AllActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSchedule);
