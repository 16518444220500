import React, { Component } from "react";
import error from "../error.jpg";
import "../App.css";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { NavLink, Link, withRouter } from "react-router-dom";
import {

    Button
} from "@material-ui/core";
// import '../styles/NotFound.css';

export default class NotFound extends Component {
    constructor() {
        super();
        // this.state = { users: [] };
    }

    componentDidMount() {
        // this.callAPI();
        // localStorage.clear();
        // document.getElementsByClassName("navbar-menu");
        // document.getElementsByClassName('footer')[0].style.display="none";
        // //console.log(document.getElementsByClassName('footer'));
    }
    render() {
        return (
            <div>
                {/* <div className="app-bar-faq">
          
        </div> */}
                <AppBar className="app-bar-faq" style={{ boxShadow: "none" }}>
                    <Toolbar className="tool-bar">
                        <h4 className="navbrand-margin">Self Service Portal</h4>
                    </Toolbar>
                </AppBar>

                <div style={{ textAlign: "center", marginTop: "6%" }}>
                    <img className="errorLogo" alt="img" src={error} />
                    <h2 className="errorText mt-3">404! Page Not found!</h2>
                    {/* <Link to="/">
                        <Button className="errorButton">
                            Go to Login Page
            </Button>{" "}
                    </Link> */}
                </div>
            </div>
        );
    }
}
