import React from "react";
import ReactDOM from "react-dom";

import { Provider } from 'react-redux'
import store from './store'

import "./index.css";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import {
  Route,
  BrowserRouter as Router,
  HashRouter,
  Switch,
} from "react-router-dom";

import InvoiceList from "./component/invoiceList";
import DashboardInvoiceListpage from "./pages/DashboardInvoiceListPage";
import LandingPage from "./component/Landing-content";
// import HomeScreen from "./pages/home-screen";
import NewLoginPage from "./component/new_login";
import QueryHome from "./component/QueryHome";
// import QueryScreen from "./pages/query-screen";
import RegionList from './component/RegionsList';
// import RegionsScreen from './pages/Invoice-regions';
import FaqPage from "./component/FAQs";
// import FaqsScreen from "./pages/faqs-screen";
import InvoiceSubmission from './component/invoice-submission';
// import InvoiceSubmissionSCreen from './pages/invoice-submission-screen';
import AdminPage from "./component/admin-page";
import AdminScreen from "./pages/admin-portal"
import AuditTrail from "./component/audit-trail";
import QueryPage from "./component/query_new";
import ApiButtons from "./component/apiButtons";
import NotFound from "./component/NotFound"
import pageNotFound from "./component/pageNotFound"

import PaymentSchedule from "./component/payment-schedule"

const routing = (
  // <Router >
  <Provider store={store}>
  <Router basename={"/po"}>
    <div className="full-height bodybg-gray">
      <HashRouter>
        <Switch>
          {/* <Route
            exact
            path="/forgetPassword"
            exact
            component={ForgetPasswordPage}
          />
          <Route exact path="/login" component={LoginPage} />
         
          <Route
            exact
            path="/reset-password-user"
            component={ResetPasswordPage}
            exact
          /> */}
          {/* <Route path="/dashboard" component={DashboardPage} exact />
          <Route path="/polist" component={DashboardPoListPage} exact /> */}
        
          {/* <Route path="/upload" component={UploadPage} exact />
          <Route path="/auditlogs" component={AuditLogsPage} exact />
          <Route path="/vendor" component={VendorMasterList} exact />
          <Route path="/invoice_upload" component={Invoice_Upload_Vendor} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/tickets" component={TicketPage} />
          <Route path="/vendorinformation/" component={NewVendorInformation} />
          <Route path="/vendornavigation" component={VendorNavigation} />
          <Route path="/vendorinfo" component={VendorInfo} />
          <Route path="/logout" component={LoginPage} />
          <Route path="/bicomponent" component={BIComponent} /> */}
          <Route exact path="/" component={NewLoginPage} />
          {/* <Route path="/raise_query" component={QueryHome} /> */}
          <Route
            path="/invoicelist"
            component={InvoiceList}
            
          />
          <Route path="/QueryHome" component={QueryPage}/>
          <Route path="/faqs" component={FaqPage} />
          <Route path="/home" component={LandingPage} />
          <Route path="/regions" component={RegionList} />
          <Route path="/invoice-submission" component={InvoiceSubmission}/>
          <Route path="/business-admin-page" component={AdminPage}/>
          <Route path="/apibuttons" component={ApiButtons}/>
          <Route path="/business-transaction-listing" component={AuditTrail}/>
          <Route path="/paymentSchedule" component={PaymentSchedule}/>
          <Route  component={NotFound} />
          {/* <Route component={pageNotFound} /> */}
          {/* <Navigation/> */}
          {/* <Route  path="/invoice_upload" component={InvoiceUploadPage} /> */}
        </Switch>
      </HashRouter>
    </div>
  </Router>
  </Provider>
);

ReactDOM.render(routing, document.getElementById("root"));
// ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
