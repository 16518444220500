import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import { withStyles } from "@material-ui/core/styles";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import "../invoice-list.scss";
// import { Redirect } from 'react-router-dom'
// import {
//   withRouter
// } from "react-router-dom";
// import Navigation from "./Navigation";
import AjaxService from "../services/AjaxServices";
import moment from 'moment'
import NavBar from './NavBar'
import FloatingButtons from '../component/floating-buttons'
import Grid from '@material-ui/core/Grid';
// import { green } from '@material-ui/core/colors';
// import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
// import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
// import compose from 'recompose/compose'
import IdleTimer from 'react-idle-timer';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as AllActions from '../actions'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie';
import Snackbar from '@material-ui/core/Snackbar';
import { labels } from './../resources';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
// import DatePicker from "react-datepicker";
import Backdrop from '@material-ui/core/Backdrop';
// import "react-datepicker/dist/react-datepicker.css";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
// import { Typography } from "@material-ui/core";

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as _ from 'lodash';
import NotFound from './NotFound';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import downloadFile from './../assests/pdf_55.svg';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

//add line 22

var options1;

var data_to_downloadAll = [];
var total_data = []

// var json = {};
var main_query = {}
var flag = false



class InvoiceList extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null
    this.handleOnIdle = this.handleOnIdle.bind(this)
    this.state = {
      page: 0,
      rowsPerPage: 50,
      count: 1,
      isLoading: true,
      allData: [],
      deriveddata: [],
      dataloaded: false,
      open: false,
      setOpen: false,
      text: "",
      data: [],
      viewClicked: false,
      showMsg: false,
      search: '',
      handleSearchOpen: false,
      start_date: null,
      end_date: null,
      doc_date_checkbox: false,
      payment_date_checkbox: false,
      inv_date_checkbox: false,
      due_date: false,
      selectedCheckbox: "",
      pmi_entity_list: [],
      currency_list: [],
      supplier_name_list: [],
      status_list: [],
      bank_transfer_num_list: [],
      columns_list: [],
      columns_active: [],
      filters_data: "",
      validate: false,
      validateMessage: "",
      date_range_selected: false,
      on_search_active: false,
      search_text: "",
      applied_filters: false,
      filters_obj: "",
      data_loading: true,
      access_cookie: Cookies.get("access-cookie"),
      tableState: {},
      sortedTableState: {},
      sort_direction: undefined,
      sort_name: undefined,
      reset_click: true,
      pdfFailMsg: false,
      filtermsg: labels[this.props.user.language].invoice_list.loading_wait,
      loadingOpen:false
    };
  }



  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableFilter: {
          padding: "9px 20px 33px 20px",
          fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
          title: {
            fontWeight: 400,
            marginLeft: 0
          },
          resetLink: {
            fontSize: 14,
            fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
            fontWeight: 400
          }
        },
        MuiInputLabel: {
          FormControl: {
            left: "7px !important"
          }
        },
        MuiFormLabel: {
          root: {
            fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
            fontWeight: 400
          }
        },
        MuiButton: {
          root: {
            color: "#004f90",
            fontSize: "15px"
          },
          textPrimary: {
            color: "#004f90"
          },
          text: {
            padding: "2px 8px"
          }
        },
        MuiSelect: {
          icon: {
            top: "-3px",
            right: "-9px"
          }
        },
        MuiFormControl: {
          root: {
            display: "grid"
          }
        },
        MUIDataTableBodyCell: {
          root: {
            textAlign: "left",
            // '&:nth-child(4)': {
            //   textAlign: "center"
            // },
            // '&:nth-child(6)': {
            //   textAlign: "center"
            // }, '&:nth-child(8)': {
            //   textAlign: "center"
            // },
            // '&:nth-child(10)': {
            //   textAlign: "center"
            // },
            // '&:nth-child(12)': {
            //   textAlign: "center"
            // }

          }
        },
        MuiGridListTile: {
          root: {
            paddingTop: "0 !important",
            listStyle: "none !important"
          },
          tile: {
            marginTop: "0 !important"
          }
        },
        MUIDataTable: {
          responsiveScroll: {
            overflowY: "scroll"
          }
        },

        MUIDataTableHeadCell: {
          fixedHeader: {
            backgroundColor: "#f6f6f6",
            color: "#0160a5"
          },
          root: {
            padding: "10px",
            verticalAlign: "top",
            // '&:nth-child(4)': {
            //   textAlign: "center"
            // },
            // '&:nth-child(6)': {
            //  paddingTop:"10px"
            // },
            //  '&:nth-child(8)': {
            //   textAlign: "center"
            // },
            // '&:nth-child(10)': {
            //   textAlign: "center"
            // },
            // '&:nth-child(12)': {
            //   textAlign: "center"
            // }
          }
        },
        MuiToolbar: {
          root: {
            background: "#f6f6f6"
          },
          regular: {
            paddingLeft: "0"
          },
          gutters: {
            paddingLeft: "0"
          }
         
          
        },
        MuiTableCell: {
          root: {
            padding: "15px",
            paddingLeft: "13px",
            verticalAlign: "top",
            fontFamily:
              "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
            // '&:nth-child(4)': {
            //   textAlign: "center"
            // },
            // '&:nth-child(6)': {
            //   textAlign: "center"
            // }, '&:nth-child(8)': {
            //   textAlign: "center"
            // },
            // '&:nth-child(10)': {
            //   textAlign: "center"
            // },
            // '&:nth-child(12)': {
            //   textAlign: "center"
            // }
          },
          body: {
            fontSize: "14px"
          },
          head: {
            fontSize: "15px",
            fontWeight: 600,
            whiteSpace: "nowrap",
            '&:nth-child(7)': {
              width: "12% !important"
            },
          },
          jss915: {
            zIndex: "10"
          }
        },
        MUIDataTableSearch: {
          main: {
            position: "absolute",
            right: "12rem",
            bottom: "10px",
            width: "48%"
          },
          searchIcon: {
            display: "none"
          }
        },
        MuiInputBase: {
          root: {
            fontFamily:
              "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
          },
          input: {
            paddingTop: "5px",
            border: "1px solid #ccc",
            paddingLeft: "5px",
            paddingRight: "20px",
            borderBottom: "1px solid #ccc",
            fontSize: 13,
            fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
          }
        },
        MUIDataTableToolbar: {
          filterPaper: {
            maxWidth: "46% !important",
            left: "650px !important"
           
            
          },
          // title:{
           
          //     fontSize: "16px !important",
          //     fontWeight:"400 !important",
          //     paddingLeft:"8px !important"
            
          // }
        },
        MUIDataTablePagination: {
          root: {
            fontFamily:
              "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
            "&:last-child": {
              padding: "0px"
            }
          }
        },
        MuiMenuItem: {
          root: {
            fontFamily:
              "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
            fontSize: 13,
            paddingLeft: "6px !important"
          }
        },
        MuiIconButton: {
          root: {
            padding: "5px",
            "&:focus": {
              outline: "none !important"
            },
            "&:hover": {
              color: "#004f90 !important",
              backgroundColor: "transparent !important"
            }
          }
        },
        MuiPickersDay: {
          daySelected: {
            "&:hover": {
              backgroundColor: "#004f90 !important",
              color: "#fff !important"
            }
          }
        },
        MuiPaper: {
          elevation4: {

          }

        },
        MuiSvgIcon: {
          root: {
            width: "1.3em",
            height: "1.3em",
            fontFamily:
              "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
            // paddingLeft: "15px"
          }
        },

        MuiTablePagination: {
          select: {
            border: "none"
          },
          selectIcon: {

          }
        },
        MuiTable: {
          root: {
            fontFamily:
              "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
          }
        },
        MuiTypography: {
          caption: {
            fontFamily:
              "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
            fontSize: "1rem"
          },
          h6: {
            paddingLeft: 8,
            fontSize: "1.5rem",
            fontWeight: "600",
            fontFamily:
              "Lato,Helvetica Neue,Helvetica,Arial,sans-serif"
          }
        },
        MUIDataTableFilterList: {
          chip: {
            margin: "8px 8px 8px 0px"
          }
        },
        MuiChip: {
          root: {
            fontSize: 13,
            fontFamily: "Lato,Helvetica Neue,Helvetica,Arial,sans-serif",
            backgroundColor: "#f4f4f4",
            border: "1px solid #004f90"
          },
          deleteIcon: {
            fill: "#004f90"
          }
        },

      }
    });



  handleOnIdle(event) {
    //console.log('last active', this.idleTimer.getLastActiveTime())

    this.Ajax = new AjaxService();
    this.Ajax.logout().then(result => {
      sessionStorage.clear();
      localStorage.clear()
      this.props.actions.return_default()
      this.props.history.length = 0;

      this.props.history.replace("/");
      // window.location = '/logout'
    })
  }

  componentDidMount() {
    var allData = [];
    // var allDatatoDownload = [];

    this.Ajax = new AjaxService();

    var tableCount
    console.log(flag)
    if (this.props.user.role === "vendor" || this.props.user.role === "support") {
      if (this.props.user.invoice_list_data !== "") {
        this.setState({
          data: this.props.user.invoice_list_data,
          dataloaded: true,
          deriveddata: this.props.user.allData,
          filters_data: this.props.user.filter_dropdown,
          tableCount: this.props.user.tableCount,
          data_loading: false
        });
      } else {
        main_query = {
          "role": this.props.user.role,
          "company_code": this.props.user.all_company_codes,
          "vendor_number": this.props.user.vendor_number,
          "page": 0,
          "rowsPerPage": this.state.rowsPerPage
        }

        this.Ajax.getinvoicedata(main_query)
          .then(res => res.json())
          .then(res => {
            flag = true;
            if (res.hasOwnProperty("error")) {
              this.setState({
                validate: true,
                validateMessage: labels[this.props.user.language].invoice_list.err,
                dataloaded: true,
                deriveddata: [],
                data_loading: false
              });

            } else {
              total_data = res.data
              var indvpo = [];
              console.log(res)
              res.data.forEach(function (arrayItem) {
                //console.log(arrayItem["Invoice Reception date"])

                var doc_date = arrayItem["Document Date"] === "" ? "" : arrayItem["Document Date"] === undefined ? "" : arrayItem["Document Date"] === "00000000" ? "" : moment(arrayItem["Document Date"]).format("DD-MMM-YYYY")
                var payment_date = arrayItem["Payment date"] === "" ? "" : arrayItem["Payment date"] === undefined ? "" : arrayItem["Payment date"] === "00000000" ? "" : moment(arrayItem["Payment date"]).format("DD-MMM-YYYY")
                var inv_date = arrayItem["Invoice Reception date"] === "" ? "" : arrayItem["Invoice Reception date"] === undefined ? "" : arrayItem["Invoice Reception date"] === "00000000" ? "" : moment(arrayItem["Invoice Reception date"]).format("DD-MMM-YYYY")

                var due_date = arrayItem["Due date"] === "" ? "" : arrayItem["Due date"] === undefined ? "" : arrayItem["Due date"] === "00000000" ? "" : moment(arrayItem["Due date"]).format("DD-MMM-YYYY")
                var doc_type = ""

                if (arrayItem.hasOwnProperty('erpDocumentType') &&
                  arrayItem["Document Type Description"].toLowerCase() === "coupa" &&
                  arrayItem["File Type"].toLowerCase() === "cipinvoice") {
                  console.log("here")
                  if (arrayItem["erpDocumentType"].toLowerCase() === "kg" || arrayItem["erpDocumentType"].toLowerCase() === "kk") {
                    doc_type = "Credit Note"
                  } else if (arrayItem["erpDocumentType"].toLowerCase() === "kb" || arrayItem["erpDocumentType"].toLowerCase() === "kr") {
                    doc_type = "Invoice"
                  } else if (arrayItem["erpDocumentType"].toLowerCase() === "kd" || arrayItem["erpDocumentType"].toLowerCase() === "kj") {
                    if (arrayItem["Total amount"].includes("-")) {
                      doc_type = "Credit Note"
                    } else {
                      doc_type = "Invoice"
                    }
                  }
                } else {
                  doc_type = arrayItem["Document Type Description"].toLowerCase() === "credit memo" ? "Credit Note" :
                    arrayItem["Document Type Description"].toLowerCase() === "inv" ? "Invoice" :
                      arrayItem["Document Type Description"].toLowerCase() === "invpo" ? "Invoice" :
                        arrayItem["Document Type Description"].toLowerCase() === "cn" ? "Credit Note" :
                          arrayItem["Document Type Description"].toLowerCase() === "cnpo" ? "Credit Note" : arrayItem["Document Type Description"]
                }
                // arrayItem["Document Type Description"].toLowerCase() === "coupa" ? "" :
                // arrayItem["Document Type Description"].toLowerCase() === "other" ? "" : arrayItem["Document Type Description"]

                // PAYMADV_1720_2000000018_2022
                // "PAYMADV_" + rowData[19] + "_" + rowData[11] + "_" + rowData[18]
                indvpo.push([
                  arrayItem["PMI Entity"] === undefined ? arrayItem["Name of PMI Entity"] : arrayItem["PMI Entity"],
                  arrayItem["Supplier name"],
                  arrayItem["External vendor Invoice number"],
                  doc_date,
                  arrayItem["Currency"],
                  arrayItem["Amount Parsed"],
                  arrayItem["payment term description"] === undefined ? arrayItem["Terms of Payment"] : arrayItem["payment term description"],
                  arrayItem["Calculated Status"],
                  payment_date,
                  inv_date,
                  due_date,
                  arrayItem["Payment Document No"],
                  arrayItem["Supplier number"],
                  arrayItem["VAT Registration No"],
                  doc_type,
                  arrayItem["Scan ID"],
                  arrayItem["Contract number"],
                  arrayItem["Erp"],
                  arrayItem["Fiscal year clearing document"],
                  arrayItem["Company Code"],
                  arrayItem["SAP document number"]
                ]);
              });

              allData = allData.concat(indvpo);
              if (res.count !== "") {
                tableCount = res.count
                options1.count = res.count
              }
              this.props.actions.setInvoiceListData(res.data, res.download_data, allData, tableCount)
              this.setState({
                data: res.data,
                dataloaded: true,
                deriveddata: allData,
                filters_data: [],
                tableCount: tableCount,
                data_loading: false,
                main_query: main_query
              });
            }

          })
          .catch(err => {
            console.log(err);
          });

        setTimeout(function () {
          // In the timer function that gets executed after 5 seconds, check // the flag value. If we got the response already it would have been // set to true. Otherwise false
          if (flag === false) {
            this.setState({ timeout_flag: true })
          }
        }.bind(this), 30000);
      }
    }

  }


  componentDidUpdate(prevProps) {
    if (prevProps.user.language !== this.props.user.language) {
      options1.count = this.state.tableCount
      this.setState({
        change_language: true,
        columns_active: []
      })
    }
  }

  componentWillUnmount() {
    flag = false
  }

  commonAPI = (page, rowsPerPage, sortOrder) => {
    let existingFilterData = this.state.filters_data
    console.log(page)
    if (page !== "" || page !== undefined) {
      main_query.page = page
    }
    if (rowsPerPage) {
      main_query.rowsPerPage = rowsPerPage
    }
    if (sortOrder) {
      main_query.sort_name = sortOrder.name
      main_query.sort_direction = sortOrder.direction
    }

    console.log(main_query)
    this.Ajax.getinvoicedata(main_query).then(res => res.json()).then(result => {
      if (result.hasOwnProperty("error")) {
        this.setState({
          validate: true,
          validateMessage: labels[this.props.user.language].invoice_list.err,
          dataloaded: true,
          deriveddata: [],

          data_loading: false
        });
      } else {

        if (result.page === "first") {
          this.setState({
            tableCount: result.count,
            data_loading: false,
            main_query: main_query
          })
          options1.count = result.date_range_count
          this.tableData(result.data)
        } else {
          this.setState({
            filters_data: existingFilterData,
            data_loading: false
          })
          this.tableData(result.data)

        }
      }
    })
  }

  onSearchClose = () => {
    main_query.page = 0
    console.log(main_query)
    this.Ajax.getinvoicedata(main_query).then(res => res.json()).then(result => {
      console.log(result)
      if (result.hasOwnProperty("error")) {
        this.setState({
          validate: true,
          validateMessage: labels[this.props.user.language].invoice_list.err,
          dataloaded: true,
          deriveddata: [],

          data_loading: false
        });
      } else {

        if (result.download_data !== "") {

          this.setState({
            search_text: "",
            tableCount: result.count,
            data_loading: false
          })
          options1.count = result.date_range_count
          this.tableData(result.data)
          // this.downloadDataMod(result.download_data)
        } else {
          this.setState({
            // tableCount: "",
            search_text: "",
            data_loading: false
          })
          this.tableData(result.data)
        }
      }
    })
  }

  handleDateSelect = (date, type) => {
    //console.log("date select--", date)

    if (type === "start") {
      this.setState({ start_date: date })
    } else {
      if (this.state.start_date === null) {
        this.setState({
          validate: true,
          validateMessage: labels[this.props.user.language]["invoice_list"].select_start_date
        })
      } else {
        if (date < this.state.start_date) {
          this.setState({
            validate: true,
            validateMessage: labels[this.props.user.language]["invoice_list"].end_date_note
          })
        } else {
          this.setState({ end_date: date })
        }

      }

    }
  }

  handleClickGo = (data, start, end) => {
    // start = moment(start).format("DD-MMM-YYYY")
    // end = moment(end).format("DD-MMM-YYYY")
    if (this.props.user.role === "vendor" || this.props.user.role === "support") {
      var start_bef = start
      var end_bef = end
      start = moment(start).format("YYYYMMDD")
      end = moment(end).format("YYYYMMDD")

      var selectedDateType = ""
      // var update_filtered;

      if (this.state.selectedCheckbox === "doc-date") {
        selectedDateType = "Document Date"
      } else if (this.state.selectedCheckbox === "payment-date") {
        selectedDateType = "Payment date"
      } else if (this.state.selectedCheckbox === "inv-date") {
        selectedDateType = "Invoice Reception date"
      } else {
        selectedDateType = "Due date"
      }

      console.log(this.state.selectedCheckbox)
      main_query.start_date = start
      main_query.end_date = end
      main_query.date_type = selectedDateType
      main_query.page = 0
      if (this.state.selectedCheckbox !== "" & start_bef !== null & end_bef !== null) {
        this.setState({
          date_range_selected: true,
          data_loading: true,
          deriveddata: [],
          main_query: main_query
        })
        options1.page = 0
        console.log(main_query)

        this.Ajax.getinvoicedata(main_query)
          .then(res => res.json())
          .then(result => {
            console.log(result)
            if (result.hasOwnProperty("error")) {
              this.setState({
                validate: true,
                validateMessage: labels[this.props.user.language].invoice_list.err,
                dataloaded: true,
                deriveddata: [],
                data_loading: false
              });
            } else {

              if (result.download_data !== "") {
                this.setState({
                  tableCount: result.count,
                  data_loading: false
                })
                this.tableData(result.data)
                // this.downloadDataMod(result.download_data)
              } else {
                this.setState({
                  tableCount: "",
                  data_loading: false
                })
                this.tableData(result.data)
              }
            }
          })
      } else {
        if (start_bef === null || end_bef === null) {
          this.setState({ validate: true, validateMessage: labels[this.props.user.language]["invoice_list"].date_one })
        } else {
          this.setState({ validate: true, validateMessage: labels[this.props.user.language]["invoice_list"].date_two })
        }

      }


    }



  }

  handleFilterSubmit = applyFilters => {
    if (this.props.user.role === "vendor" || this.props.user.role === "support") {


      let filterList = applyFilters();
      let columns_list = this.state.columns_list
      var filters_obj = {}
      if (this.state.columns_list.length !== 0) {
        columns_list.forEach((item, i) => {
          filterList.forEach((opt, j) => {
            if (opt.length !== 0 && i === j) {
              filters_obj[item.name] = opt
            }
          })
        })
      }
      console.log(filters_obj)
      main_query.filters = filters_obj
      main_query.page = 0
      options1.page = 0
      this.setState({
        applied_filters: true,
        filters_obj: filters_obj,
        data_loading: true,
        deriveddata: [],
        main_query: main_query
      })
      if (Object.keys(filters_obj).length !== 0) {

        this.Ajax.getinvoicedata(main_query)
          .then(res => res.json())
          .then(result => {
            if (result.hasOwnProperty("error")) {
              this.setState({
                validate: true,
                validateMessage: labels[this.props.user.language].invoice_list.err,
                dataloaded: true,
                deriveddata: [],

                data_loading: false
              });
            } else {

              if (result.download_data !== "") {
                this.setState({
                  tableCount: result.count,
                  data_loading: false
                })
                options1.count = result.date_range_count
                this.tableData(result.data)
              } else {
                this.setState({
                  tableCount: "",
                  data_loading: false
                })
                this.tableData(result.data)
              }
            }
          })
      } else {

        delete main_query.filters
        console.log(main_query)
        this.Ajax.getinvoicedata(main_query)
          .then(res => res.json())
          .then(result => {
            console.log(result)
            if (result.hasOwnProperty("error")) {
              this.setState({
                validate: true,
                validateMessage: labels[this.props.user.language].invoice_list.err,
                dataloaded: true,
                deriveddata: [],

                data_loading: false
              });
            } else {

              if (result.download_data !== "") {
                this.setState({
                  applied_filters: false,
                  filters_obj: "",
                  tableCount: result.count,
                  data_loading: false
                })
                options1.count = result.date_range_count
                this.tableData(result.data)
                this.downloadDataMod(result.download_data)
              } else {
                this.setState({
                  applied_filters: false,
                  filters_obj: "",
                  tableCount: "",
                  data_loading: false
                })
                this.tableData(result.data)
              }
            }
          })
      }
    }

  }




  setColumns = (columns_list) => {
    this.setState({
      columns_list: columns_list
    })
  }




  tableData = (filter_data, table_count) => {
    console.log("table data caled", filter_data, table_count)
    var allData = [];
    // var allDataToDownload = [];
    var indvpo = [];
    // var indvpo1 = [];

    filter_data.forEach(function (arrayItem) {

      var doc_date = arrayItem["Document Date"] === "" ? "" : arrayItem["Document Date"] === undefined ? "" : arrayItem["Document Date"] === "00000000" ? "" : moment(arrayItem["Document Date"]).format("DD-MMM-YYYY")
      var payment_date = arrayItem["Payment date"] === "" ? "" : arrayItem["Payment date"] === undefined ? "" : arrayItem["Payment date"] === "00000000" ? "" : moment(arrayItem["Payment date"]).format("DD-MMM-YYYY")
      var inv_date = arrayItem["Invoice Reception date"] === "" ? "" : arrayItem["Invoice Reception date"] === undefined ? "" : arrayItem["Invoice Reception date"] === "00000000" ? "" : moment(arrayItem["Invoice Reception date"]).format("DD-MMM-YYYY")

      var due_date = arrayItem["Due date"] === "" ? "" : arrayItem["Due date"] === undefined ? "" : arrayItem["Due date"] === "00000000" ? "" : moment(arrayItem["Due date"]).format("DD-MMM-YYYY")



      var doc_type = ""

      if (arrayItem.hasOwnProperty('erpDocumentType') &&
        arrayItem["Document Type Description"].toLowerCase() === "coupa" &&
        arrayItem["File Type"].toLowerCase() === "cipinvoice") {
        console.log("here")
        if (arrayItem["erpDocumentType"].toLowerCase() === "kg" || arrayItem["erpDocumentType"].toLowerCase() === "kk") {
          doc_type = "Credit Note"
        } else if (arrayItem["erpDocumentType"].toLowerCase() === "kb" || arrayItem["erpDocumentType"].toLowerCase() === "kr") {
          doc_type = "Invoice"
        } else if (arrayItem["erpDocumentType"].toLowerCase() === "kd" || arrayItem["erpDocumentType"].toLowerCase() === "kj") {
          if (arrayItem["Total amount"].includes("-")) {
            doc_type = "Credit Note"
          } else {
            doc_type = "Invoice"
          }
        }
      } else {
        doc_type = arrayItem["Document Type Description"].toLowerCase() === "credit memo" ? "Credit Note" :
          arrayItem["Document Type Description"].toLowerCase() === "inv" ? "Invoice" :
            arrayItem["Document Type Description"].toLowerCase() === "invpo" ? "Invoice" :
              arrayItem["Document Type Description"].toLowerCase() === "cn" ? "Credit Note" :
                arrayItem["Document Type Description"].toLowerCase() === "cnpo" ? "Credit Note" : arrayItem["Document Type Description"]
      }

      indvpo.push([
        arrayItem["PMI Entity"] === undefined ? arrayItem["Name of PMI Entity"] : arrayItem["PMI Entity"],
        arrayItem["Supplier name"],
        arrayItem["External vendor Invoice number"],
        doc_date,
        arrayItem["Currency"],
        arrayItem["Amount Parsed"],
        arrayItem["payment term description"] === undefined ? arrayItem["Terms of Payment"] : arrayItem["payment term description"],
        arrayItem["Calculated Status"],
        payment_date,
        inv_date,
        due_date,
        arrayItem["Payment Document No"],
        arrayItem["Supplier number"],
        arrayItem["VAT Registration No"],
        doc_type,
        arrayItem["Scan ID"],
        arrayItem["Contract number"],
        arrayItem["Erp"],
        arrayItem["Fiscal year clearing document"],
        arrayItem["Company Code"],
        arrayItem["SAP document number"]
      ]);
    });

    allData = allData.concat(indvpo);
    options1.count = table_count
    this.setState({ dataloaded: true, deriveddata: allData, data_loading: false });
  }

  downloadDataMod = (filter_alldata) => {
    // var allData = [];
    // var allDataToDownload = [];
    // var indvpo = [];
    var indvpo1 = [];
    var newArray_download = []

    console.log("in download mod", filter_alldata.length)
    filter_alldata.forEach(function (arrayItem, index) {
      var doc_date = arrayItem["Document Date"] === "" ? "" : arrayItem["Document Date"] === undefined ? "" : arrayItem["Document Date"] === "00000000" ? "" : moment(arrayItem["Document Date"]).format("DD-MMM-YY")
      var payment_date = arrayItem["Payment date"] === "" ? "" : arrayItem["Payment date"] === undefined ? "" : arrayItem["Payment date"] === "00000000" ? "" : moment(arrayItem["Payment date"]).format("DD-MMM-YY")
      var inv_date = arrayItem["Invoice Reception date"] === "" ? "" : arrayItem["Invoice Reception date"] === undefined ? "" : arrayItem["Invoice Reception date"] === "00000000" ? "" : moment(arrayItem["Invoice Reception date"]).format("DD-MMM-YY")

      var due_date = arrayItem["Due date"] === "" ? "" : arrayItem["Due date"] === undefined ? "" : arrayItem["Due date"] === "00000000" ? "" : moment(arrayItem["Due date"]).format("DD-MMM-YY")



      var doc_type = arrayItem["Document Type Description"].toLowerCase() === "credit memo" ? "Credit Note" :
        arrayItem["Document Type Description"].toLowerCase() === "inv" ? "Invoice" :
          arrayItem["Document Type Description"].toLowerCase() === "invpo" ? "Invoice" :
            arrayItem["Document Type Description"].toLowerCase() === "cn" ? "Credit Note" :
              arrayItem["Document Type Description"].toLowerCase() === "cnpo" ? "Credit Note" : arrayItem["Document Type Description"]

      if (arrayItem.hasOwnProperty('erpDocumentType') && arrayItem["Document Type Description"].toLowerCase() === "coupa" && arrayItem["File Type"] === "CipInvoice") {
        if (arrayItem["erpDocumentType"].toLowerCase() === "kg" || arrayItem["erpDocumentType"].toLowerCase() === "kk") {
          doc_type = "Credit Note"
        } else if (arrayItem["erpDocumentType"].toLowerCase() === "kb" || arrayItem["erpDocumentType"].toLowerCase() === "kr") {
          doc_type = "Invoice"
        } else if (arrayItem["erpDocumentType"].toLowerCase() === "kd" || arrayItem["erpDocumentType"].toLowerCase() === "kj") {
          if (arrayItem["Total amount"].includes("-")) {
            doc_type = "Credit Note"
          } else {
            doc_type = "Invoice"
          }
        }
      }

      var pay_terms = arrayItem["payment term description"] === undefined ? arrayItem["Terms of Payment"] : arrayItem["payment term description"]


      // var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

      var str = arrayItem["External vendor Invoice number"]

      // if (str !== "" || str !== undefined) {
      //   if (format.test(str)) {
      //     str = arrayItem["External vendor Invoice number"]
      //   } else {
      //     console.log(arrayItem["External vendor Invoice number"])
      //     str = (arrayItem["External vendor Invoice number"]).toString()
      //   }
      // }
      newArray_download.push({

        "PMI Entity": arrayItem["PMI Entity"],
        "Supplier Name": arrayItem["Supplier name"],
        "Invoice Number": str,
        "Document Date": doc_date,
        "Currency": arrayItem["Currency"],
        "Total Amount": arrayItem["Amount Parsed"],
        "Payment Terms": pay_terms,
        "Status": arrayItem["Calculated Status"],
        "Payment Date": payment_date,
        "Baseline Date": inv_date,
        "Due Date": due_date,
        "Payment Document Number": arrayItem["Payment Document No"],
        "Supplier Number": arrayItem["Supplier number"],
        "VAT Registration No": arrayItem["VAT Registration No"],
        "Document Type": doc_type,
        "Barcode Number": arrayItem["Scan ID"],
        "Contract number": arrayItem["Contract number"],
        "SAP Document number":arrayItem["SAP document number"]

      });


      indvpo1.push({
        index: index, data: [
          arrayItem["PMI Entity"] === undefined ? arrayItem["Name of PMI Entity"] : arrayItem["PMI Entity"],
          arrayItem["Supplier name"],
          arrayItem["External vendor Invoice number"],
          // String(arrayItem["External vendor Invoice number"]),
          doc_date,
          arrayItem["Currency"],
          arrayItem["Amount Parsed"],
          arrayItem["payment term description"] === undefined ? arrayItem["Terms of Payment"] : arrayItem["payment term description"],
          arrayItem["Calculated Status"],
          payment_date,
          inv_date,
          due_date,
          arrayItem["Payment Document No"],
          arrayItem["Supplier number"],
          arrayItem["VAT Registration No"],
          doc_type
        ]
      });
    });
    data_to_downloadAll = newArray_download
    return data_to_downloadAll
  }

  handleSpinnerClose = () => {
    this.setState({ loadingOpen: false })
}

  handleReset = () => {

    console.log(this.state.search_text)
    main_query = {
      "role": this.props.user.role,
      "company_code": this.props.user.all_company_codes,
      "vendor_number": this.props.user.vendor_number,
      "page": 0,
      "rowsPerPage": this.state.rowsPerPage,
      "sort_name": this.state.sort_name,
      "sort_direction": this.state.sort_direction,
    }

    if (this.state.search_text) {
      main_query.search_text = this.state.search_text
    }

    if (this.state.filters_obj) {
      main_query.filters = this.state.filters_obj

    }

    this.setState({
      data_loading: true,
      deriveddata: [],
      reset_click: true,
      main_query: main_query
    })

    this.Ajax.getinvoicedata(main_query)
      .then(res => res.json())
      .then(result => {
        if (result.hasOwnProperty("error")) {
          this.setState({
            validate: true,
            validateMessage: labels[this.props.user.language].invoice_list.err,
            dataloaded: true,
            deriveddata: [],

            data_loading: false,

          });
        } else {

          options1.count = result.count
          options1.page = 0
          if (result.download_data !== "") {
            this.setState({
              payment_date_checkbox: false,
              doc_date_checkbox: false,
              inv_date_checkbox: false,
              start_date: null,
              end_date: null,
              tableCount: result.count,
              search_text: "",
              date_range_selected: false,
              on_search_active: false,
              data_loading: false,
              selectedCheckbox: ""
            })

            this.tableData(result.data, result.count)
            // this.downloadDataMod(result.download_data)
          } else {
            this.setState({
              payment_date_checkbox: false,
              doc_date_checkbox: false,
              inv_date_checkbox: false,
              start_date: null,
              end_date: null,

              search_text: "",
              date_range_selected: false,
              on_search_active: false,
              data_loading: false
            })
            this.tableData(result.data)
          }

        }

      }).catch(err => {
        console.log(err);
      });

    //to download all - get all invoices



  }
  handleClosepdfDialog = () => {
    this.setState({
      pdfFailMsg: false,
      pdfNotAllowed: false,
      timeout_flag: false
    })
  }

  handleCheckBoxChange = (event, name) => {
    //console.log(event.target.checked)
    if (name === "doc_date_checkbox") {
      //console.log(name)
      if (event.target.checked) {
        this.setState({ doc_date_checkbox: event.target.checked, payment_date_checkbox: false, inv_date_checkbox: false, due_date: false, selectedCheckbox: "doc-date" })
      } else {
        this.setState({ doc_date_checkbox: event.target.checked, payment_date_checkbox: false, inv_date_checkbox: false, due_date: false, selectedCheckbox: "" })
      }

    } else if (name === "payment_date_checkbox") {
      //console.log(name)
      if (event.target.checked) {
        this.setState({ payment_date_checkbox: event.target.checked, doc_date_checkbox: false, inv_date_checkbox: false, due_date: false, selectedCheckbox: "payment-date" })
      } else {
        this.setState({ payment_date_checkbox: event.target.checked, doc_date_checkbox: false, inv_date_checkbox: false, due_date: false, selectedCheckbox: "" })
      }

    } else if (name === "inv_date_checkbox") {
      //console.log(name)
      if (event.target.checked) {
        this.setState({ inv_date_checkbox: event.target.checked, doc_date_checkbox: false, payment_date_checkbox: false, due_date: false, selectedCheckbox: "inv-date" })
      } else {
        this.setState({ inv_date_checkbox: event.target.checked, doc_date_checkbox: false, payment_date_checkbox: false, due_date: false, selectedCheckbox: "" })
      }

    } else {
      if (event.target.checked) {
        this.setState({ due_date: event.target.checked, doc_date_checkbox: false, payment_date_checkbox: false, inv_date_checkbox: false, selectedCheckbox: "due-date" })
      } else {
        this.setState({ due_date: event.target.checked, doc_date_checkbox: false, payment_date_checkbox: false, inv_date_checkbox: false, selectedCheckbox: "" })
      }

    }
  }

  onSearch = _.debounce((e) => {
    console.log("search text debounce: ", e)
    if (e !== null) {
      main_query.search_text = e
      main_query.page = 0
      options1.page = 0
      this.setState({
        on_search_active: true,
        search_text: e,
        data_loading: true,

        deriveddata: []
      })
      this.Ajax = new AjaxService();

      console.log(main_query)
      this.Ajax.getinvoicedata(main_query)
        .then(search_result => search_result.json())
        .then(result => {
          if (result.hasOwnProperty("error")) {
            this.setState({
              validate: true,
              validateMessage: labels[this.props.user.language].invoice_list.err,
              dataloaded: true,
              deriveddata: [],

              data_loading: false
            });
          } else {

            if (result.download_data !== "") {
              this.setState({
                tableCount: result.count,
                data_loading: false
              })
              options1.count = result.date_range_count
              this.tableData(result.data)

            } else {
              this.setState({
                tableCount: "",
                data_loading: false,
                main_query: main_query
              })
              this.tableData(result.data)
            }
          }

        })
    }

  }, 2000)

  handleCloseFlag = () => {
    this.setState({ validate: false })
  }

  handleActionClickPAYMADV = (rowData) => {
    this.setState({
      loadingOpen:true,
      downloadWaitMsg: labels[this.props.user.language].invoice_list.download_text
    })
    var query_string = "PAYMADV_" + rowData[19] + "_" + rowData[11] + "_" + rowData[18]
    console.log(query_string)
    var query_string_test = "PAYMADV_1720_2000000018_2022"
    console.log(query_string)
    // && rowData[18] !== ""
    if (rowData[11] !== "" && rowData[11] !== undefined && rowData[18] !== undefined && rowData[18] !== "") {
     
      this.Ajax.downloadPaymentAdvice(query_string)
        .then(response => {
          console.log(response)
          if (response.data.hasOwnProperty("error") && response.data.error === "File not available" ) {
            // 
            this.setState({ pdfNotAllowed: true })
          }else if(response.data.hasOwnProperty("error")){
            this.setState({ pdfFailMsg: true })
          } else {
            let buff = response.data
            let pdf_file = Buffer.from(buff, 'base64');
            const url = window.URL.createObjectURL(new Blob([pdf_file]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${query_string}.pdf`);
            document.body.appendChild(link);
            link.click();
          }
          this.setState({
            loadingOpen:false,
            downloadWaitMsg:""
          })

        })
    } else{
    
      this.setState({ pdfFailMsg: true, loadingOpen:false,
        downloadWaitMsg:"" })
    }
  }

  handleActionClickWHTCERT = (rowData) => {
    // WHTCERT_<COCD>_<Payment document>_<Fiscal Year>_<Timestamp>
    this.setState({
      loadingOpen:true,
      downloadWaitMsg: labels[this.props.user.language].invoice_list.download_text
    })
    var query_string = "WHTCERT_" + rowData[19] + "_" + rowData[11] + "_" + rowData[18]
    
    
    console.log(query_string)
    var query_string_test = "WHTCERT_1720_2000000018_2022"
    console.log(query_string)
    // && rowData[18] !== ""
    if (rowData[11] !== "" && rowData[11] !== undefined && rowData[18] !== undefined && rowData[18] !== "") {
      this.Ajax.downloadPaymentAdvice(query_string)
        .then(response => {
          console.log(response)
          if (response.data.hasOwnProperty("error") && response.data.error === "File not available" ) {
            // 
            this.setState({ pdfNotAllowed: true, loadingOpen:false,
              downloadWaitMsg:"" })
          }else if(response.data.hasOwnProperty("error")){
            this.setState({ pdfFailMsg: true, loadingOpen:false,
              downloadWaitMsg:"" })
          }  else {
            let buff = response.data
            let pdf_file = Buffer.from(buff, 'base64');
            const url = window.URL.createObjectURL(new Blob([pdf_file]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${query_string}.pdf`);
            document.body.appendChild(link);
            link.click();
            this.setState({
              loadingOpen:false,
              downloadWaitMsg:""
            })
          }

        })
    }else{
      this.setState({ pdfFailMsg: true, loadingOpen:false,
        downloadWaitMsg:"" })
    }

  }


  render() {
    const { deriveddata, columns_active } = this.state;
    const { classes } = this.props;
    var language_labels = labels[this.props.user.language].invoice_list
    if (columns_active.length === 0) {
      var columns = [];
      columns.push(
        {
          name: "PMI Entity",
          label: language_labels.pmi_entity,
          options: {
            sort: true,
            filter: true,
            // sortThirdClickReset:true,
            filterType: "custom",
            filterOptions: {
              display: (filterList, onChange, index, column) => {

                return (
                  <div>
                    {this.state.filters_data.length !== 0 && < div>
                      <InputLabel >{language_labels.pmi_entity}</InputLabel>
                      <Select
                        multiple
                        className="custom-filters"
                        value={filterList[index]}
                        renderValue={selected => selected.join(', ')}
                        onChange={event => {
                          filterList[index] = event.target.value;
                          onChange(filterList[index], index, column);
                        }}
                      >
                        {this.state.filters_data["PMI Entity"].map(item => (
                          <MenuItem key={item} value={item}>
                            <Checkbox
                              color='primary'
                              checked={filterList[index].indexOf(item) > -1}
                            />
                            <ListItemText primary={item} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>}
                  </div>
                )


              }
            }
          }
        },
        {
          name: "Supplier name",
          label: language_labels.supplier_name,
          options: {
            sort: false,
            filter: true,
            filterType: "custom",
            filterOptions: {
              display: (filterList, onChange, index, column) => {

                return (
                  <div>
                    {this.state.filters_data.length !== 0 && <div>
                      <InputLabel >{language_labels.supplier_name}</InputLabel>
                      <Select
                        multiple
                        className="custom-filters"
                        value={filterList[index]}
                        renderValue={selected => selected.join(', ')}
                        onChange={event => {
                          filterList[index] = event.target.value;
                          onChange(filterList[index], index, column);
                        }}
                      >
                        {this.state.filters_data["Supplier name"].map(item => (
                          <MenuItem key={item} value={item}>
                            <Checkbox
                              color='primary'
                              checked={filterList[index].indexOf(item) > -1}
                            />
                            <ListItemText primary={item} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>}
                  </div>
                )


              }
            }
          }

        },
        {
          name: "External vendor Invoice number",
          label: language_labels.invoice_no,
          options: {
            sort: false,
            filter: false,
          }
        },
        {
          name: "Document Date",
          label: language_labels.doc_date,
          options: {
            sort: true,
            filter: false,
          }
        },
        {
          name: "Currency",
          label: language_labels.currency,
          options: {
            sort: true,
            filter: true,
            filterType: "custom",
            filterOptions: {
              display: (filterList, onChange, index, column) => {

                return (
                  <div>
                    {this.state.filters_data.length !== 0 && <div>
                      <InputLabel >{language_labels.currency}</InputLabel>
                      <Select
                        multiple
                        className="custom-filters"
                        value={filterList[index]}
                        renderValue={selected => selected.join(', ')}
                        onChange={event => {
                          filterList[index] = event.target.value;
                          onChange(filterList[index], index, column);
                        }}
                      >
                        {this.state.filters_data["Currency"].map(item => (
                          <MenuItem key={item} value={item}>
                            <Checkbox
                              color='primary'
                              checked={filterList[index].indexOf(item) > -1}
                            />
                            <ListItemText primary={item} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>}
                  </div>

                )


              }
            }
          }
        },
        {
          name: "Amount Parsed",
          label: language_labels.total_amount,
          options: {
            filter: false,
            sortCompare: (order) => {
              console.log(order)
              return (obj1, obj2) => {
                console.log(order);
                // let val1 = parseInt(obj1.data, 10);
                // let val2 = parseInt(obj2.data, 10);
                return (obj1.data - obj2.data) * (order === 'asc' ? 1 : -1);
              };
            }
            // customBodyRender: (value, tableMeta, updateValue) => (
            //   <Typography>
            //     {value[0]}<sup>{value[1]}</sup>
            //   </Typography>
            // )

          }
        },
        {
          name: "Terms of Payment",
          label: language_labels.payment_terms,
          options: {
            display: true,
            filter: false,
          }
        },
        {
          name: "Calculated Status",
          label: language_labels.status,
          options: {
            filter: true,
            filterType: "custom",
            filterOptions: {
              display: (filterList, onChange, index, column) => {
                //console.log(this.state.filters_data["Status"])

                return (
                  <div>
                    {this.state.filters_data.length !== 0 && <div>
                      <InputLabel >{language_labels.status}</InputLabel>
                      <Select
                        className="custom-filters"
                        multiple
                        value={filterList[index]}
                        renderValue={selected => selected.join(', ')}
                        onChange={event => {
                          filterList[index] = event.target.value;
                          onChange(filterList[index], index, column);
                        }}
                      >
                        {this.state.filters_data["Status"].map(item => (
                          <MenuItem key={item} value={item}>
                            <Checkbox
                              color='primary'
                              checked={filterList[index].indexOf(item) > -1}
                            />
                            <ListItemText primary={item} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>}
                  </div>

                )


              }
            }
          }
        },
        {
          label: language_labels.payment_date,
          name: "Payment date",
          options: {
            filter: false,
          }
        },
        {
          label: language_labels.invoice_date,
          name: "Invoice Reception date",
          options: {
            filter: false,
          }
        },

        {
          label: language_labels.due_date,
          name: "Due date",
          options: {
            display: true,
            filter: false,
          }
        },
        {
          label: language_labels.payment_transfer,
          name: "Payment Document No",
          options: {
            display: false,
            filter: true,
            sort: true,
            filterType: "custom",
            filterOptions: {
              logic: () => { },
              display: (filterList, onChange, index, column) => {

                return (
                  <div>
                    {this.state.filters_data.length !== 0 && <div>
                      <InputLabel >{language_labels.payment_transfer}</InputLabel>
                      <Select
                        multiple
                        className="custom-filters"
                        value={filterList[index]}
                        renderValue={selected => selected.join(', ')}
                        onChange={event => {
                          filterList[index] = event.target.value;
                          onChange(filterList[index], index, column);
                        }}
                      >
                        {this.state.filters_data["Payment Document No"].map(item => (
                          <MenuItem key={item} value={item}>
                            <Checkbox
                              color='primary'
                              checked={filterList[index].indexOf(item) > -1}
                            />
                            <ListItemText primary={item} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>}
                  </div>
                )


              },
              fullWidth: true
            }
          }
        },
        {
          label: language_labels.supplier_no,
          name: "Supplier number",
          options: {
            display: false,
            filter: false,
            sort: false
          }
        },


        {
          name: "VAT Registration Number",
          label: language_labels.vat_no,
          options: {
            display: false,
            filter: false,
            sort: true
          }
        },
        {
          name: "Document Type",
          label: language_labels.doc_type,
          options: {
            display: true,
            filter: false,
            sort: true
          }
        },
        {
          name: "Scan ID",
          label: language_labels.barcode,
          options: {
            display: false,
            filter: false,
            sort: false
          }
        },
        {
          name: "Contract number",
          label: language_labels.contract_num,
          options: {
            display: false,
            filter: false,
            sort: false
          }
        },
        {
          name: "Payment Advice / WHT Certificate",
          label: language_labels.payadv,
          options: {
            display: false,
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {

              return (
                <div style={{ textAlign: "center" }}>
                  {/* { ((tableMeta.rowData[17] === "LCP" || tableMeta.rowData[17] === "LCP") && tableMeta.rowData[7].toLowerCase() === "paid" ) && <Tooltip title="WHT_CERTIFICATE">
                  <img src={downloadFile} className="download-img" onClick={(event) => this.handleActionClickWHTCERT(tableMeta.rowData)}/>
                  </Tooltip>}
                 {((tableMeta.rowData[17] === "LCP" || tableMeta.rowData[17] === "LCP") && tableMeta.rowData[7].toLowerCase() === "paid" ) && <Tooltip title="PAY_ADVICE">
                  <img src={downloadFile} className="download-img" onClick={(event) => this.handleActionClickPAYMADV(tableMeta.rowData)}/>
                  </Tooltip>} */}
                  { ((tableMeta.rowData[17] === "LCP" || tableMeta.rowData[17] === "LCQ" || tableMeta.rowData[17] === "LCT" ) && tableMeta.rowData[7].toLowerCase() === "paid" ) && <Tooltip title="PAY_ADVICE">
                    <img src={downloadFile} className="download-img" onClick={(event) => this.handleActionClickPAYMADV(tableMeta.rowData)} />
                  </Tooltip>}
                  { ((tableMeta.rowData[17] === "LCP" || tableMeta.rowData[17] === "LCQ" || tableMeta.rowData[17] === "LCT") && tableMeta.rowData[7].toLowerCase() === "paid" ) && <Tooltip title="WHT_CERTIFICATE">
                     <img src={downloadFile} className="download-img" onClick={(event) => this.handleActionClickWHTCERT(tableMeta.rowData)} />
                  </Tooltip>}



                  {/* {(tableMeta.rowData[17] === "LCP" || tableMeta.rowData[17] === "LCQ") ? <PictureAsPdfOutlinedIcon /> : ""} */}
                  {/* <PictureAsPdfOutlinedIcon onClick={(event) => this.handleActionClickWHTCERT(tableMeta.rowData)} />
                  <PictureAsPdfOutlinedIcon onClick={(event) => this.handleActionClickPAYMADV(tableMeta.rowData)} /> */}
                </div>
              );
            }
          }
        },

        {
          name: "fiscal year",
          label: "fiscal year",
          options: {
            display: "excluded",
            filter: false,
          }
        },
        {
          name: "company code",
          label: "company code",
          options: {
            display: "excluded",
            filter: false,
          }
        },
        {
          name: "SAP document number",
          label: language_labels.sap_doc_no,
          options: {
            display: false,
            filter: false
          }
        }

        ////
      );
      this.setState({ columns_active: [...columns] })
      // console.log("columns in render 1", columns)
    }
    else {

      columns = [...columns_active];
    }

    options1 = {
      count: this.state.tableCount,
      textLabels: {
        body: {
          noMatch: this.state.data_loading ? language_labels.loading_wait : language_labels.no_results,
          toolTip: language_labels.sort,
        },

        toolbar: {
          search: language_labels.search,
          downloadCsv: language_labels.download,
          viewColumns: language_labels.view_columns,
          filterTable: language_labels.filter_table,
        },
        viewColumns: {
          title: language_labels.view_columns_heading
        },
        pagination: {
          next: language_labels.next,
          previous: language_labels.previous,
          rowsPerPage: language_labels.rowsPerPage,
          displayRows: language_labels.displayRows
        },
        filter: {
          all: language_labels.all,
          title: language_labels.title,
          reset: language_labels.reset,
        },
      },

      rowsPerPage: this.state.rowsPerPage,
      rowsPerPageOptions: [10, 15, 50, 100],
      serverSide: true,
      downloadOptions: {
        filename: "Invoice Status List.csv",
        separator: ','
      },
      onDownload: (buildHead, buildBody, columns, values) => {
        this.Ajax = new AjaxService()
        this.setState({ downloadSnack: true,downloadWaitMsg:language_labels.download_text })
        this.Ajax.getDownloadData(this.state.main_query).then(result => result.json())
          .then(result => {
            console.log(result)
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'

            const json = this.downloadDataMod(result.download_data)
            setTimeout(() => {

              const fileName = `Invoice Status List`
              const ws = utils.json_to_sheet(json)
              const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
              const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
              const data = new Blob([excelBuffer], { type: fileType })
              saveAs(data, fileName + fileExtension)

            }, 1000);
            this.setState({ downloadSnack: false,downloadWaitMsg:"" })
          })
        return false

      },


      print: false,
      search: true,
      download: true,
      filter: true,
      filterType: "dropdown",
      selectableRows: 'none',
      styles: {
        filterList: {
          root: {
            color: "red"
          },
          chip: {
            color: "#FEFEF0"
          }
        }
      },
      responsive: "vertical",
      //serverSide: true,
      onSearchChange: (searchText) => {
        if (this.props.user.role === "vendor" || this.props.user.role === "support") {
          this.onSearch(searchText)
        }
      },
      onSearchClose: () => {
        if (this.props.user.role === "vendor" || this.props.user.role === "support") {

          console.log(main_query.search_text)
          if (main_query.search_text !== undefined) {
            delete main_query.search_text
            this.onSearchClose()
          }


        }
      },
      onTableChange: (action, tableState) => {
        console.log("ontablechange", action, tableState, this.state);


        switch (action) {

          case "changePage":
            if (this.props.user.role === "vendor" || this.props.user.role === "support") {
              console.log(tableState.page)
              this.commonAPI(tableState.page, tableState.rowsPerPage, tableState.sortOrder)
            }

            break;
          case "propsUpdate":
            if (this.state.columns_list.length === 0) {
              this.setColumns(tableState.columns)
            }

            break;

          case "changeRowsPerPage":
            if (this.props.user.role === "vendor" || this.props.user.role === "support") {
              this.setState({
                tableState: tableState,
                rowsPerPage: tableState.rowsPerPage,
                page: 0
              })
              tableState.page = 0;
              console.log("tablestate page", tableState.page)
              this.commonAPI(0, tableState.rowsPerPage, tableState.sortOrder)

            }
            break;

          case "sort":

            if ((this.state.sort_name !== tableState.sortOrder.name || this.state.sort_direction !== tableState.sortOrder.direction)) {
              if (tableState.page === 0) {
                this.setState({
                  sort_name: tableState.sortOrder.name,
                  sort_direction: tableState.sortOrder.direction,

                })

              } else {
                this.setState({
                  sort_name: tableState.sortOrder.name,
                  sort_direction: tableState.sortOrder.direction,
                  tableState: tableState,
                  rowsPerPage: tableState.rowsPerPage,
                  page: 0
                })
                tableState.page = 0;
              }
              if (this.props.user.role === "vendor" || this.props.user.role === "support") {
                this.commonAPI(tableState.page, tableState.rowsPerPage, tableState.sortOrder)
              }
            } else {
              this.setState({
                tableState: tableState,
                rowsPerPage: tableState.rowsPerPage,
                page: 0
              })
              tableState.page = 0;
              if (this.props.user.role === "vendor" || this.props.user.role === "support") {
                this.commonAPI(tableState.page, tableState.rowsPerPage, tableState.sortOrder)
              }
            }

            break;

          default:
            console.log('action not handled.');
        }
      },
      onViewColumnsChange: (changedColumn, action) => {
        console.log("in view columns change")
        columns.map((item, index) => {
          if (item.name == changedColumn) {
            if (action == "add") {
              item.options.display = true
            }
            else {
              item.options.display = false
            }
          }
        })
        this.setState({ columns_active: columns })
      },
      onChangeRowsPerPage: (numberOfRows) => {
        console.log("on rows per page", numberOfRows)
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{ marginTop: '40px' }}>
            {this.state.filters_data.length !== 0 && <Button variant="contained" onClick={() => this.handleFilterSubmit(applyNewFilters)}>{language_labels.apply_filters}</Button>}
            {this.state.filters_data.length === 0 && <p >{this.state.filtermsg}</p>}
          </div>
        );
      },
      onFilterChange: (column, filterList, type) => {
      
        // var len_flag = false
        if (type === 'chip' || type === "reset") {

          var newFilters = () => (filterList);

          this.handleFilterSubmit(newFilters);

        }

      },
      onFilterDialogOpen: () => {
        console.log("filter_data", this.state.filters_data)
        if (this.state.filters_data.length === 0) {
          var filter_dropdown;
          this.Ajax = new AjaxService()
          this.Ajax.getFiltersDropDown(this.props.user.vendor_number, this.props.user.all_company_codes)
            .then(filters => filters.json())
            .then(filters_list => {
              if (filters_list.length !== 0) {
                filter_dropdown = filters_list[0]
                filter_dropdown.Currency = filter_dropdown.Currency.sort()
                filter_dropdown["PMI Entity"] = filter_dropdown["PMI Entity"].sort()
                filter_dropdown["Payment Document No"] = filter_dropdown["Payment Document No"].sort()
                filter_dropdown.Status = filter_dropdown.Status.sort()
                filter_dropdown["Supplier name"] = filter_dropdown["Supplier name"].sort()
                this.setState({
                  filters_data: filter_dropdown,
                  filtermsg: ""
                });
              } else {
                filter_dropdown = filters_list
                this.setState({
                  filters_data: filter_dropdown,
                  filtermsg: language_labels.no_filters
                });
              }

            }).catch(err => {
              console.log(err);
            });
        }

      },
      customSort: (data, colIndex, order, meta) => {
        console.log(data)
        console.log(colIndex)
        console.log(order)
        console.log(meta)
        return data.sort((a, b) => {
          return (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) * (order === 'desc' ? 1 : -1);
        });
      }
    };

    const BlueCheckbox = withStyles({
      root: {
        color: "#004f90",
        '&$checked': {
          color: "#004f90",
        },
      },
      checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    return (
      <div>
        {(this.props.user.role === "vendor" || this.props.user.role === "support") && <section className="pad-b-50 invoice">
          <NavBar appClass="inv-status" />
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            timeout={1000 * 60 * 10}
            onIdle={this.handleOnIdle}
            debounce={250}
          />
          <Snackbar ContentProps={{

          }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.validate}
            onClose={this.handleCloseFlag}
            autoHideDuration={6000}
            message={this.state.validateMessage}
          />
          <Snackbar 
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.downloadSnack}
            // onClose={this.handleCloseFlag}
            autoHideDuration={6000}
            message={this.state.downloadWaitMsg}
          />
          <Snackbar 
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.loadingOpen}
            // onClose={this.handleCloseFlag}
            autoHideDuration={6000}
            message={this.state.downloadWaitMsg}
          />
          <Dialog
            open={this.state.pdfFailMsg}
            onClose={this.handleClosepdfDialog}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}

          >
            <DialogTitle className="text-dialog"> {language_labels.err_title}</DialogTitle>
            <DialogContent>
              <DialogContentText className="text-dialog">
              {language_labels.download_err_msg}
              </DialogContentText>
              <DialogActions>
                <Button onClick={this.handleClosepdfDialog} className="msg-btn">
                  {language_labels.ok_btn}
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>

          <Dialog
            open={this.state.pdfNotAllowed}
            onClose={this.handleClosepdfDialog}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
          >
            <DialogTitle className="text-dialog"> {language_labels.download_not_allowed_heading}</DialogTitle>
            <DialogContent>
              <DialogContentText className="text-dialog">
               {language_labels.download_not_allowed_txt}
              </DialogContentText>
              <DialogActions>
                <Button onClick={this.handleClosepdfDialog} className="msg-btn">
                  {language_labels.ok_btn}
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>

          <Dialog
            open={this.state.timeout_flag}
            onClose={this.handleClosepdfDialog}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
          >
            <DialogTitle className="text-dialog"> {language_labels.err_title}</DialogTitle>
            <DialogContent>
              <DialogContentText className="text-dialog">
                {language_labels.data_fetch_err}
              </DialogContentText>
              <DialogActions>
                <Button onClick={this.handleClosepdfDialog} className="msg-btn">
                  {language_labels.ok_btn}
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
   {/* <Backdrop disableBackdropClick={true} open={this.state.loadingOpen} onClick={(event) => this.handleSpinnerClose()}>
                        <CircularProgress className="circular-spinner" />
                    </Backdrop> */}

          <div className="container-fluid custom-container" >


            <div className="col-12 inv-list-tabel-col">

         
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="flex-start">
                  <FormControlLabel
                    className="checkbox-inv-list"
                    control={<BlueCheckbox checked={this.state.doc_date_checkbox} onChange={(event) => this.handleCheckBoxChange(event, "doc_date_checkbox")} name="doc_date_checkbox" />}
                    label={language_labels.doc_date}
                  />
                  <FormControlLabel
                    className="checkbox-inv-list"
                    control={<BlueCheckbox checked={this.state.payment_date_checkbox} onChange={(event) => this.handleCheckBoxChange(event, "payment_date_checkbox")} name="payment_date_checkbox" />}
                    label={language_labels.payment_date}
                  />
                  <FormControlLabel
                    className="checkbox-inv-list"
                    control={<BlueCheckbox checked={this.state.inv_date_checkbox} onChange={(event) => this.handleCheckBoxChange(event, "inv_date_checkbox")} name="inv_date_checkbox" />}
                    label={language_labels.invoice_date}
                  />

                  <KeyboardDatePicker
                    autoOk={true}
                    InputProps={{ readOnly: true }}
                    disableToolbar
                    variant="inline"
                    format="dd-MMM-yyyy"
                    margin="normal"
                    id="start-date"
                    label={language_labels.start_date}
                    value={this.state.start_date}
                    className="keyboard-datepicker"
                    onChange={(date) => this.handleDateSelect(date, "start")}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <KeyboardDatePicker
                    autoOk={true}
                    InputProps={{ readOnly: true }}
                    disableToolbar
                    variant="inline"
                    format="dd-MMM-yyyy"
                    margin="normal"
                    id="end-date"
                    label={language_labels.end_date}
                    value={this.state.end_date}
                    className="keyboard-datepicker"
                    onChange={(date) => this.handleDateSelect(date, "end")}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <Button disableRipple className="go-btn" onClick={(event) => this.handleClickGo(this.state.data, this.state.start_date, this.state.end_date)}>{language_labels.go}</Button>
                  <Button disableRipple className="go-btn" onClick={(event) => this.handleReset()}>{language_labels.reset}</Button>
                </Grid>
              </MuiPickersUtilsProvider> 
              <ul className="bullet-list-inv">
                <li> {language_labels.line_one}</li>
                <li>{language_labels.line_two}</li>
                <li>{language_labels.line_three}</li>
                <li>{language_labels.line_four}</li>
                <li>{language_labels.line_five}</li>
                <li>{language_labels.line_5}</li>
              </ul>

              
              <div className="list">
                <MuiThemeProvider theme={this.getMuiTheme()}>

                  <MUIDataTable 
                    title={  
                      <p  className= "TableHeaderCustom" >
                        {language_labels["inv_list"] }
                        <span  className ="tableHeaderSub" > {language_labels["inv_list_subheader"]}</span>
                      </p>
                     
                    }
                    //title={language_labels["inv_list"]}
                    data={deriveddata}
                    columns={columns}
                    options={options1}
                  /></MuiThemeProvider>

              </div>
            </div>
          </div>
          <FloatingButtons {...this.props} appClass="inv-list" />
        </section>}
        {(this.props.user.role !== 'vendor' && this.props.user.role !== 'support') && <NotFound history={this.props.history} />}
      </div>
    );
  }
}//

InvoiceList.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  user: state.user
})
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(AllActions, dispatch)
})



export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);

